import { FilterValue } from 'react-table';
import { FiltersObject, FiltersTypesKeys } from '../types';

const FILTERS_CACHE: Partial<Record<FiltersTypesKeys, FilterValue>> = {};

export const getServerCacheFilter = <T extends FiltersTypesKeys>(
	filterKey: T
): FiltersObject[T] | undefined => {
	return FILTERS_CACHE[filterKey];
};

export const setServerCacheFilter = <T extends FiltersTypesKeys>(
	filterKey: T,
	filterValue: FiltersObject[T]
) => {
	FILTERS_CACHE[filterKey] = filterValue;
};
