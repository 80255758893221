import {
	LeaseEscalation,
	LeaseRentBumpsDollar,
	LeaseRentBumpsPercent,
} from 'types/comp';
import { getIsMonthlyMarket, getIsMonthlyMarketFromMarketName } from '../utils';
import { formatCsv } from './formatCsv';
import { formatLeaseEscalations } from './formatLeaseEscalations';
import { formatMoney } from './formatMoney';
import { formatRentBumpsDollar } from './formatRentBumpsDollar';
import { formatRentBumpsPercent } from './formatRentBumpsPercent';

export const formatRentBumpsCombined = (lease: QuasiLease) => {
	let isMonthly = false;

	if (lease.marketId != null) {
		isMonthly = getIsMonthlyMarket(lease.marketId);
	} else if (lease.marketName != null) {
		isMonthly = getIsMonthlyMarketFromMarketName(lease.marketName);
	}

	if (lease.rentBumpsPercent && lease.rentBumpsDollar && lease.rentBumpYears) {
		const percentText = formatRentBumpsPercent(lease.rentBumpsPercent).replace(
			/ /g,
			' '
		);
		const yearsText = lease.rentBumpYears.length === 1 ? 'year' : 'years';

		const bumps = isMonthly
			? lease.rentBumpsDollar.bumps / 12
			: lease.rentBumpsDollar.bumps;

		const dollarsFormatted = formatMoney(bumps);

		return `${percentText} / ${dollarsFormatted} in ${yearsText} ${formatCsv(
			lease.rentBumpYears
		)}`;
	} else if (lease.rentBumpsPercent) {
		return formatRentBumpsPercent(lease.rentBumpsPercent);
	} else if (lease.rentBumpsDollar) {
		return formatRentBumpsDollar(lease.rentBumpsDollar, isMonthly);
	} else if (lease.leaseEscalations) {
		return formatLeaseEscalations(lease.leaseEscalations, isMonthly);
	}
};

// minimal lease shape that satisfies the constraint of this function
type QuasiLease = {
	rentBumpsPercent?: LeaseRentBumpsPercent | null;
	rentBumpsDollar?: LeaseRentBumpsDollar | null;
	rentBumpYears?: number[] | null;
	leaseEscalations?: LeaseEscalation[];
	marketId?: number;
	marketName?: string;
};
