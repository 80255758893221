import { CompType } from 'types';
import { FeatureFlags } from 'api/featureFlags/types';
import formatting from './formatting.json';
import orderSale from './order-sale.json';
import order from './order.json';

export type AttributeType = CompType;

let displayAttributes = {
	lease: [...formatting],
	sale: [...formatting],
};

const leaseOrder = order.reduce(function (acc, item, i) {
	// @ts-expect-error TS7053: Element implicitly has an 'any...
	acc[item] = i + 1;
	return acc;
}, {});

const saleOrder = orderSale.reduce(function (acc, item, i) {
	// @ts-expect-error TS7053: Element implicitly has an 'any...
	acc[item] = i + 1;
	return acc;
}, {});

const sortDisplayAttributes = (type: string) => {
	const order = type === 'sale' ? saleOrder : leaseOrder;
	// @ts-expect-error TS7006: Parameter 'a' implicitly has a...
	return (a, b) => {
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		if (order[a.name] && order[b.name]) {
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			return order[a.name] - order[b.name];
		}
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		if (order[a.name]) {
			return -1;
		}
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		if (order[b.name]) {
			return 1;
		}
		return a.displayName > b.displayName ? 1 : -1;
	};
};

displayAttributes.lease.sort(sortDisplayAttributes('lease'));
displayAttributes.sale.sort(sortDisplayAttributes('sale'));

export type Locale = 'en_US' | 'en_GB';

type LeaseFormatAttributes = (typeof displayAttributes)['lease'];

type SaleFormatAttributes = (typeof displayAttributes)['sale'];

export type CompFormatAttributes = LeaseFormatAttributes | SaleFormatAttributes;

export type CompFormatAttribute = CompFormatAttributes[number];

type CacheType = Record<
	AttributeType,
	{ [key in Locale]: CompFormatAttributes }
>;

// @ts-expect-error TS2352: Conversion of type '{ lease: {...
const listCache = {
	lease: {},
	sale: {},
	property: {},
	portfolios: {},
} as CacheType;

// @ts-expect-error TS2352: Conversion of type '{ lease: {...
const hashCache = {
	lease: {},
	sale: {},
	property: {},
	portfolios: {},
} as CacheType;

// @ts-expect-error TS2352: Conversion of type '{ lease: {...
const byIdCache = {
	lease: {},
	sale: {},
	property: {},
	portfolios: {},
} as CacheType;

// @ts-expect-error TS7006: Parameter 'target' implicitly ...
function extend(target, source) {
	for (const key in source) {
		target[key] = source[key];
	}
	return target;
}

function isValidType(type: AttributeType): type is AttributeType {
	switch (type) {
		case 'lease':
		case 'sale':
		case 'property':
		// @ts-expect-error TS2678: Type '"portfolios"' is not com...
		case 'portfolios':
			return true;
		default:
			throw new Error(
				'You must specify "lease", "sale", "portfolios" or "property" when getting an attribute list, received "' +
					type +
					'"'
			);
	}
}

export const allAttributes = formatting;

export const initializeFlags = function (flags: FeatureFlags) {
	const hiddenByFlag = (attribute: CompFormatAttribute) => {
		// @ts-expect-error expected missing featureFlag & featureFlagOff
		if (attribute.featureFlag != null || attribute.featureFlagOff != null) {
			const hidden =
				// @ts-expect-error expected missing featureFlag & featureFlagOff
				(!flags[attribute.featureFlag as keyof FeatureFlags] ?? true) &&
				// @ts-expect-error expected missing featureFlag & featureFlagOff
				(flags[attribute.featureFlagOff as keyof FeatureFlags] ?? true);

			return {
				...attribute,
				hidden,
			};
		}
		return attribute;
	};

	displayAttributes = {
		// @ts-expect-error TS2719: Type '({ id: number; name: str...
		lease: allAttributes.map(hiddenByFlag),
		// @ts-expect-error TS2719: Type '({ id: number; name: str...
		sale: allAttributes.map(hiddenByFlag),
	};

	displayAttributes.lease.sort(sortDisplayAttributes('lease'));
	displayAttributes.sale.sort(sortDisplayAttributes('sale'));
};

export const list = function (type: AttributeType, locale: Locale = 'en_US') {
	isValidType(type);

	const attributes =
		type === 'sale' ? displayAttributes.sale : displayAttributes.lease;

	listCache[type][locale] = attributes
		.filter(function (attr) {
			return attr.dataTypeConfiguration && attr.dataTypeConfiguration[type];
		})
		.map(function (attr) {
			attr = extend({}, attr);
			attr = extend(attr, attr.dataTypeConfiguration?.[type]);
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			if (attr.localeOverrides[locale]) {
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				attr = extend(attr, attr.localeOverrides[locale]);
			}
			// @ts-expect-error TS2322: Type 'null' is not assignable ...
			attr.localeOverrides = null;
			// @ts-expect-error TS2322: Type 'null' is not assignable ...
			attr.dataTypeConfiguration = null;
			attr.shortName = attr.shortName || attr.displayName;
			return attr;
		});

	return listCache[type][locale];
};

export const hash = function (type: AttributeType, locale: Locale = 'en_US') {
	// @ts-expect-error TS2367: This comparison appears to be ...
	if (type === 'portfolios') {
		return hashCache[type];
	}

	isValidType(type);

	if (!hashCache[type][locale]) {
		const cacheList = list(type, locale);
		hashCache[type][locale] = cacheList.reduce(
			function (acc, prop) {
				// @ts-expect-error TS7015: Element implicitly has an 'any...
				acc[prop.name] = prop;
				// @ts-expect-error TS7015: Element implicitly has an 'any...
				if (prop.backendSortName) acc[prop.backendSortName] = prop;
				// @ts-expect-error TS7015: Element implicitly has an 'any...
				if (prop.filterName) acc[prop.filterName] = prop;
				return acc;
			},
			{} as (typeof hashCache)[typeof type][typeof locale]
		);
	}

	return hashCache[type][locale];
};

export const byId = function (type: AttributeType, locale: Locale = 'en_US') {
	isValidType(type);

	const cacheList = list(type, locale);
	byIdCache[type][locale] = cacheList.reduce(
		function (acc, prop) {
			// @ts-expect-error TS7015: Element implicitly has an 'any...
			acc[prop.id] = prop;
			return acc;
		},
		{} as (typeof byIdCache)[typeof type][typeof locale]
	);
	return byIdCache[type][locale];
};
