import { RETAIN_DATA, RELEASE_DATA, LOAD_DATA, RECEIVE_DATA } from './actions';

// @ts-expect-error TS7006: Parameter 'namespace' implicit...
function retain(namespace, itemKeys, state) {
	// @ts-expect-error TS7006: Parameter 'id' implicitly has ...
	itemKeys.forEach((id) => {
		if (state[namespace][id] === undefined) {
			throw new Error('Tried to retain an unknown item: ' + id);
		}
	});

	state = {
		...state,
		[namespace]: {
			...state[namespace],
		},
	};

	// @ts-expect-error TS7006: Parameter 'id' implicitly has ...
	itemKeys.forEach((id) => {
		state[namespace][id].retainers += 1;
	});

	return state;
}

// @ts-expect-error TS7006: Parameter 'namespace' implicit...
function release(namespace, itemKeys, state) {
	// @ts-expect-error TS7006: Parameter 'id' implicitly has ...
	itemKeys.forEach((id) => {
		if (state[namespace][id] === undefined) {
			throw new Error('Tried to release an unknown item: ' + id);
		}
	});

	state = {
		...state,
		[namespace]: { ...state[namespace] },
	};

	const allIds = Object.keys(state[namespace]);

	const updatedNamespaceState = allIds.reduce((acc, id) => {
		let shape = state[namespace][id];
		shape = {
			...shape,
			retainers: shape.retainers - 1,
		};

		if (shape.retainers !== 0) {
			acc[id] = shape;
		}
		return acc;
	}, state[namespace]);

	return {
		...state,
		[namespace]: updatedNamespaceState,
	};
}

// @ts-expect-error TS7006: Parameter 'namespace' implicit...
function createItems(namespace, itemKeys, state) {
	state = {
		...state,
		[namespace]: { ...state[namespace] },
		loading: true,
	};
	// @ts-expect-error TS7006: Parameter 'itemKey' implicitly...
	itemKeys.forEach((itemKey) => {
		state[namespace][itemKey] = {
			data: null,
			loading: true,
			retainers: 0,
		};
	});
	return state;
}

// @ts-expect-error TS7006: Parameter 'namespace' implicit...
function addDataToItem(namespace, itemKeys, datas, state) {
	state = {
		...state,
		[namespace]: { ...state[namespace] },
		loading: false,
	};
	// @ts-expect-error TS7006: Parameter 'key' implicitly has...
	itemKeys.forEach((key, i) => {
		if (state[namespace][key]) {
			state[namespace][key] = {
				...state[namespace][key],
				data: datas[i],
				loading: false,
			};
		}
	});
	return state;
}

// @ts-expect-error TS7006: Parameter 'action' implicitly ...
export default function (state = {}, action) {
	switch (action.type) {
		case RETAIN_DATA:
			state = retain(action.payload.namespace, action.payload.itemKeys, state);
			break;
		case RELEASE_DATA:
			state = release(action.payload.namespace, action.payload.itemKeys, state);
			break;

		case LOAD_DATA:
			state = createItems(
				action.payload.namespace,
				action.payload.itemKeys,
				state
			);
			break;

		case RECEIVE_DATA:
			state = addDataToItem(
				action.payload.namespace,
				action.payload.itemKeys,
				action.payload.datas,
				state
			);
			break;
	}
	return state;
}
