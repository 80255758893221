import { PermissionsState } from 'Pages/Login/reducers';
import { CompType } from 'types';

export const isMarketAccessible = (
	marketId: number | string,
	compType: CompType,
	permissions: PermissionsState
) => {
	const permission = compType === 'sale' ? 'salesAccess' : 'leaseAccess';

	return permissions[marketId]?.[permission];
};
