export function stringifyErrorOrNull(error: unknown): string | null {
	if (!error) {
		return `*Unexpected falsy error value: |${error}|*`;
	}
	if (typeof error === 'string') {
		return error;
	}
	try {
		if (typeof error === 'object') {
			// This doesn't handle nested objects, like Error.cause.statusText:
			// return JSON.stringify(err, Object.getOwnPropertyNames(err));
			return JSON.stringify(
				// getOwnPropertyNames + fromEntries is needed because keys of
				// Error instances are not enumerable by JSON.stringify.
				// https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify
				Object.fromEntries(
					Object.getOwnPropertyNames(error).map((k) => [
						k,
						(error as Record<string, unknown>)?.[k],
					])
				)
			);
		}
		return JSON.stringify(error);
	} catch (_err) {
		// The value null means the error couldn't be stringified.
		return null;
	}
}
