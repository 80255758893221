import {
	OPEN_MARKET_SELECTOR,
	CLOSE_MARKET_SELECTOR,
	HomeAction,
} from '../actions/home';

interface Home {
	marketSelectorExpanded: boolean;
}

const initialState: Home = {
	marketSelectorExpanded: false,
};

export default (state = initialState, action: HomeAction) => {
	switch (action.type) {
		case OPEN_MARKET_SELECTOR:
			return {
				...state,
				marketSelectorExpanded: true,
			};

		case CLOSE_MARKET_SELECTOR:
			return {
				...state,
				marketSelectorExpanded: false,
			};
	}

	return state;
};
