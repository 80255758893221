import React, { memo } from 'react';
import { getAttributeHash } from 'util/comp-format/src/format';
import { LeaseComp, SalesComp } from 'types/comp';
import LeaseCompRow from './TableRowLease';
import SaleCompRow from './TableRowSale';
import HeaderCell from './TableCellHeader';
import { LineItemLandscapeProps } from './LineItemLandscape';

export const fields = {
	lease: [
		'buildingAddress',
		'executionQuarter',
		'tenantName',
		'transactionSize',
		'floorOccupancies',
		'transactionType',
		'leaseTerm',
		'startingRent',
		'rentBumpsCombined',
		'freeMonths',
		'workValue',
	],
	sale: [
		'buildingAddress',
		'saleQuarter',
		'seller',
		'buyer',
		'transactionSize',
		'totalSalePrice',
		'salePricePsf',
		'capRate',
		'totalNetOperatingIncome',
		'interestType',
		'interestPercentage',
	],
};

export type RowProps = Pick<
	TableProps,
	'editable' | 'hiddenFields' | 'exportActions' | 'compsAddresses'
> & {
	sectionMap: any;
};

export type TableProps = Pick<
	LineItemLandscapeProps,
	'exportActions' | 'compType' | 'editable' | 'compsAddresses'
> & {
	comps: SalesComp[] | LeaseComp[];
	hiddenFields: unknown;
};

const Table = memo<TableProps>(
	({
		compType,
		editable,
		hiddenFields,
		exportActions,
		compsAddresses,
		comps,
	}) => {
		const attributes = getAttributeHash(compType);
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		const sectionMap = fields[compType].reduce((map, field) => {
			map[field] = attributes[field].section;
			return map;
		}, {});
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		const labels = fields[compType].map((field) => {
			return attributes[field].shortName || attributes[field].displayName;
		});

		return (
			<div className={editable ? 'table' : 'table static'}>
				<div className="table-header">
					{/* @ts-expect-error TS7006: Parameter 'label' implicitly h... */}
					{labels.map((label, index) => {
						return (
							<HeaderCell
								hiddenFields={hiddenFields}
								editable={editable}
								exportActions={exportActions}
								key={index}
								value={label}
								// @ts-expect-error TS7053: Element implicitly has an 'any...
								field={fields[compType][index]}
							/>
						);
					})}
				</div>
				{compType === 'sale' ? (
					<div className="table-body">
						<SaleCompRow
							hiddenFields={hiddenFields}
							exportActions={exportActions}
							editable={editable}
							comps={comps as SalesComp[]}
							sectionMap={sectionMap}
							compsAddresses={compsAddresses}
						/>
					</div>
				) : (
					<div className="table-body">
						<LeaseCompRow
							hiddenFields={hiddenFields}
							exportActions={exportActions}
							editable={editable}
							sectionMap={sectionMap}
							compsAddresses={compsAddresses}
						/>
					</div>
				)}
			</div>
		);
	}
);

Table.displayName = 'Table';

export default Table;
