import {
	CompsetResponse,
	PropertyByMarketIdAndBuildingAddressAndCityParams,
} from 'api/compset/types';
import { PropertyComp } from 'types';
import factory from './factory';

function createApiUrl(
	optionsOrId: number | PropertyByMarketIdAndBuildingAddressAndCityParams
) {
	if (typeof optionsOrId === 'number' || typeof optionsOrId === 'string') {
		return '/api/properties/' + optionsOrId;
	} else {
		const suggestionIdParam = optionsOrId.suggestionId
			? `?suggestionId=${optionsOrId.suggestionId}`
			: '';
		let url =
			'/api/properties/' +
			optionsOrId.marketId +
			'/' +
			encodeURIComponent(optionsOrId.propertyAddress) +
			suggestionIdParam;

		if (typeof optionsOrId.includeCompSet === 'boolean') {
			url += '?includeCompSet=' + optionsOrId.includeCompSet;
		}
		return url;
	}
}

const serviceController = factory.create<
	number | PropertyByMarketIdAndBuildingAddressAndCityParams,
	// mapped from response type, not real response
	PropertyComp
>({
	createUrl: createApiUrl,
});

const exportable = serviceController.exportable({
	removePropertiesFromCustomCompSet(
		subjectPropertyId: number,
		propertyIds: number[]
	) {
		removeFromCompSetCallQueue.push({ subjectPropertyId, propertyIds });
		// @ts-expect-error TS7005: Variable 'removeFromCompSetPro...
		if (!removeFromCompSetPromise) {
			removeFromCompSetPromise = asyncQueueHandler();
		}
		return removeFromCompSetPromise;
	},

	resetCompSet(subjectPropertyId: number) {
		const promise = factory.del<number, CompsetResponse>(
			`${createApiUrl(subjectPropertyId)}/actions/resetCompSet`
		);
		promise.then(saveToCache);
		return promise;
	},

	// @ts-expect-error TS7006: Parameter 'data' implicitly ha...
	setBingMapOverrides(subjectPropertyId: number, data) {
		return factory
			.put(`/api/properties/${subjectPropertyId}/bingMapOverride`, data)
			.then(() => {
				exportable.clearAll();
				// @ts-expect-error TS2554: Expected 1 arguments, but got ...
				return exportable.load();
			});
	},

	loadTransitScreen(id: number) {
		return factory.get(`/api/properties/${id}/transitScreen`);
	},

	async loadCompSet(
		idOrData: number | PropertyByMarketIdAndBuildingAddressAndCityParams
	) {
		return oldLoad(idOrData).then(function (result) {
			return (result as unknown as CompsetResponse).propertyCompSet;
		});
	},

	async loadSummary(
		idOrData: number | PropertyByMarketIdAndBuildingAddressAndCityParams
	) {
		return oldLoad(idOrData).then(function (result) {
			return (result as unknown as CompsetResponse).summary;
		});
	},

	async addCustomPropertiesToCompSet(
		subjectPropertyId: number,
		propertiesIds: number[]
	) {
		const promise = factory.put<{ ids: number[] }, CompsetResponse>(
			`${createApiUrl(subjectPropertyId)}/add`,
			{
				ids: propertiesIds,
			}
		);
		promise.then(saveToCache);
		return promise;
	},
});

function saveToCache(response: CompsetResponse) {
	exportable.add(
		{
			marketId: response.subjectProperty.marketId,
			// @ts-expect-error TS2339: Property 'name' does not exist...
			propertyAddress: response.subjectProperty.name,
		},
		response
	);
	exportable.add(
		{
			marketId: response.subjectProperty.marketId,
			propertyAddress: response.subjectProperty.buildingAddressAndCity,
		},
		response
	);
	exportable.add(response.subjectProperty.id, response);
	return response;
}

const oldLoad = exportable.load;
exportable.load = async function (data) {
	// Real response type
	const response = (await oldLoad(data)) as unknown as CompsetResponse;
	if (
		typeof data !== 'number' &&
		typeof data !== 'string' &&
		data.includeCompSet === false
	) {
		saveToCache(response);
	}
	// mapping real response to a mapped type
	return response.subjectProperty;
};

const removeFromCompSetCallQueue: any[] = [];
// @ts-expect-error TS7034: Variable 'removeFromCompSetPro...
let removeFromCompSetPromise;

// @ts-expect-error TS7031: Binding element 'subjectProper...
function makeRemoveFromCompSetPromise({ subjectPropertyId, propertyIds }) {
	const promise = factory.put<{ ids: number[] }, CompsetResponse>(
		`${createApiUrl(subjectPropertyId)}/remove`,
		{
			ids: propertyIds,
		}
	);
	promise.then(saveToCache);
	return promise;
}

// @ts-expect-error TS7023: 'asyncQueueHandler' implicitly...
function asyncQueueHandler() {
	const nextItem = removeFromCompSetCallQueue.shift();
	return makeRemoveFromCompSetPromise(nextItem).then((result) => {
		if (removeFromCompSetCallQueue.length === 0) {
			removeFromCompSetPromise = null;
			return result;
		}
		return asyncQueueHandler();
	});
}

export default exportable;
