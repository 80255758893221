import {
	change,
	FilterAction,
	FILTERS_CHANGE,
	FILTERS_CLOSE_UPGRADE_MODAL,
	FILTERS_INIT,
	FILTERS_RESET,
	FILTERS_SHOW_UPGRADE_MODAL,
	reset,
} from 'actions/filters';
import { createFilters } from 'models/filters';
import produce from 'immer';
import { AppState } from './root';
import { useSelector, useDispatch } from 'react-redux';
import React from 'react';
import { getCompType } from 'middleware/filtersFromRoutes';
import { Market } from '@compstak/common';
import { useMarketByNameOrId } from 'Pages/Login/reducers';
import { setFilters as setFiltersUtil } from 'models/filters/util/setFilters';
import { mergeFilters } from 'models/filters/util/mergeFilters';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { FiltersObject } from 'models/filters/types';
import { CompType } from 'types';
import { isEqual } from 'lodash';
import { LOGOUT, LogoutAction } from 'Pages/Login/actions';

export type FiltersType = 'main' | 'exchange-dashboard';

export type FiltersState = {
	main: FiltersObject;
	'exchange-dashboard': FiltersObject;
	showUpgradeModal: { [key in FiltersType]?: Market[] };
	cache: { [key in CompType]: FiltersObject };
};

const initialState: FiltersState = {
	// @ts-expect-error is initialized via filtersFromRoute middleware
	main: {},
	// @ts-expect-error is initialized via filtersFromRoute middleware
	'exchange-dashboard': {},
	showUpgradeModal: {},
	// @ts-expect-error is initialized via filtersFromRoute middleware
	cache: {},
};

export default function (
	state = initialState,
	action: FilterAction | LogoutAction
) {
	return produce(state, (draftState) => {
		const compType = getCompType();

		const updateCache = (filters: FiltersObject) => {
			draftState.cache[compType] = filters;
			draftState.main = filters;

			const currentMarketIds = (state.main.markets ?? []).map(({ id }) => id);
			const newMarketIds = (filters.markets ?? []).map(({ id }) => id);
			const marketsChanged = !isEqual(
				newMarketIds.sort(),
				currentMarketIds.sort()
			);
			// if markets have changed for any cached filters
			// then sync the markets with other cached filters
			if (marketsChanged) {
				syncMarketsCache(filters.markets);
			}
		};

		const syncMarketsCache = (markets: Market[]) => {
			Object.typedKeys(draftState.cache).forEach((cacheKey) => {
				const compTypeCache = draftState.cache[cacheKey];
				if (compTypeCache) {
					compTypeCache.markets = markets;
				}
			});
		};

		switch (action.type) {
			case FILTERS_INIT: {
				updateCache(action.payload.initialFilters.main);
				draftState['exchange-dashboard'] =
					action.payload.initialFilters['exchange-dashboard'];
				return;
			}

			case FILTERS_CHANGE: {
				if (action.payload.context === 'main') {
					const filters = mergeFilters(
						state.cache[compType],
						action.payload.changes
					);
					updateCache(filters);
				} else {
					const filters = mergeFilters(
						state[action.payload.context],
						action.payload.changes
					);
					draftState[action.payload.context] = filters;
				}
				return;
			}

			case FILTERS_RESET: {
				const originalFilters = state[action.payload.context];
				const market = originalFilters ? originalFilters.market : undefined;
				const markets = originalFilters ? originalFilters.markets : undefined;

				let filters = createFilters(compType);

				if (action.payload.defaults) {
					filters = mergeFilters(filters, action.payload.defaults);
					if (!filters.market) {
						// @ts-expect-error TS2345: Argument of type 'Market | und...
						filters = setFiltersUtil(filters, 'market', market);
					}
					if (action.payload.context === 'main' && !filters.markets) {
						// @ts-expect-error TS2345: Argument of type 'Market[] | u...
						filters = setFiltersUtil(filters, 'markets', markets);
					}
				} else {
					filters = mergeFilters(filters, { market, markets });
				}
				if (action.payload.context === 'main') {
					updateCache(filters);
				} else {
					draftState[action.payload.context] = filters;
				}
				return;
			}

			case FILTERS_SHOW_UPGRADE_MODAL: {
				draftState.showUpgradeModal[action.payload.context] =
					action.payload.nonAccessableMarkets;
				return;
			}

			case FILTERS_CLOSE_UPGRADE_MODAL: {
				delete draftState.showUpgradeModal[action.payload.context];
				return;
			}

			case LOGOUT: {
				return initialState;
			}
		}
	});
}

export const useFilters = (filterContext: FiltersType = 'main') => {
	const dispatch = useDispatch();
	const filters = useSelector<AppState, FiltersObject>(
		(state) => state.filtersV2[filterContext]
	);

	const setFilters = React.useCallback(
		(changes: Partial<FiltersObject>) => {
			dispatch(change(filterContext, changes));
		},
		[dispatch, filterContext]
	);

	const resetFilters = React.useCallback(
		(changes?: Partial<FiltersObject>) => {
			dispatch(reset(filterContext, changes));
		},
		[dispatch, filterContext]
	);

	return [filters, setFilters, resetFilters] as const;
};

/**
 * @returns filters but with `market` prop only
 */
export const useSingleMarketFilters = (marketId?: number) => {
	const [filters, setFilters] = useFilters();
	const singleMarket =
		useMarketByNameOrId(marketId) ?? getFiltersMarkets(filters)[0];

	const singleMarketFilters = mergeFilters(filters, {
		market: singleMarket,
		// @ts-expect-error TS2322: Type 'null' is not assignable ...
		markets: null,
	});

	return [singleMarketFilters, setFilters] as const;
};

export type SetFilters = ReturnType<typeof useFilters>[1];
