import { ReactNode } from 'react';
import styled from 'styled-components';
import IconError from '../../ui/svg_icons/error.svg';

type Props = {
	children: ReactNode;
};

export const CellWarning = ({ children }: Props) => {
	return (
		<Root>
			<StyledIconError width={20} height={20} /> {children}
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

const StyledIconError = styled(IconError)`
	fill: ${(p) => p.theme.colors.neutral.n60};
	flex-shrink: 0;
`;
