import money from './money';
import number from './number';

export default function floorDetails(value: {
	floors: any[];
	size: any;
	takingRent: any;
}) {
	let response = value.floors.join(', ') + ': ' + number(value.size) + ' SF';
	if (value.takingRent) {
		// @ts-expect-error TS2554: Expected 2-4 arguments, but go...
		response += ' / ' + money(value.takingRent) + ' /SF';
	}
	return response;
}
