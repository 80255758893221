import { API } from '@compstak/common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { composeEnabled } from 'api/utils/composeEnabled';
import { useIsMultifamily } from 'hooks';
import { FiltersObject } from 'models/filters/types';
import { filterToServerJSON } from 'models/filters/util/serverJson';
import {
	DEFAULT_SUGGESTIONS_LIMIT,
	Suggestion,
	SuggestionField,
} from 'api/suggestions/suggestions';
import { CompType } from 'types';
import { encodeQuery } from 'util/encodeQuery';
import { trimString } from 'util/trimString';
import { QUERY_KEYS } from '../../constants';
import { ServerFilterItem } from 'types/serverFilters';

export const useSuggestionsV1Query = <T extends SuggestionField>(
	params: UseSuggestionsV1QueryParams<T>,
	options?: UseSuggestionsV1QueryOptions<T>
) => {
	const {
		compType,
		field,
		marketId,
		query: _query,
		limit = DEFAULT_SUGGESTIONS_LIMIT,
		filters,
	} = params;
	const query = trimString(_query);
	const hidden = (
		filterToServerJSON(filters?.hidden, 'hidden') as ServerFilterItem<'hidden'>
	).value;
	const partial = (
		filterToServerJSON(
			filters?.partial ?? null,
			'partial'
		) as ServerFilterItem<'partial'>
	).value;
	const queryParams = encodeQuery({
		field,
		marketId,
		query,
		limit,
		hidden,
		partial,
	});
	const isMufa = useIsMultifamily({ markets: params.marketId });
	const compTypePrefix =
		compType === 'property'
			? `${isMufa ? 'mufa/' : ''}properties/actions`
			: compType === 'sale'
				? 'salesComps'
				: 'comps/actions';

	return useQuery({
		queryKey: [QUERY_KEYS.SUGGESTIONS_V1, params],
		queryFn: async () => {
			const response = await API.get<SuggestionsV1Response<T>>(
				`/api/${compTypePrefix}/suggestions${queryParams}`
			);

			return response.data;
		},
		...options,
		enabled: composeEnabled(query.length > 2, options?.enabled),
	});
};

type UseSuggestionsV1QueryOptions<T extends SuggestionField> = UseQueryOptions<
	SuggestionsV1Response<T>
>;

type UseSuggestionsV1QueryParams<T extends SuggestionField> = {
	compType: CompType;
	query: string;
	marketId: number[];
	limit?: number;
	field?: T;
	filters?: FiltersObject;
};

type SuggestionsV1Response<T extends SuggestionField> = Suggestion<'v1', T>[];
