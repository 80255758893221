import { useInfiniteQuery } from '@tanstack/react-query';
import { QUERY_KEYS, SearchPropertiesResponse } from 'api';
import { createInfiniteSearchFetchFn, SearchInfiniteParams } from '../utils';

const fetchInfiniteProperties =
	createInfiniteSearchFetchFn<SearchPropertiesResponse>({
		url: '/api/properties/actions/search',
		getResponseSize: (res) => res.properties.length,
	});

export const useSearchPropertiesInfiniteQuery = (
	params: SearchInfiniteParams,
	enabled: boolean
) =>
	useInfiniteQuery({
		queryKey: [QUERY_KEYS.SEARCH_PROPERTIES_INFINITE, params],
		queryFn: ({ pageParam = 0, signal }) =>
			fetchInfiniteProperties({ ...params, pageParam, signal }),
		getNextPageParam: (last) => {
			if (!last.hasMore) return;
			return last.pageParam + 1;
		},
		enabled,
	});
