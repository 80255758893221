import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { createFetchFn } from '../utils';
import { SearchSalesPayload, SearchSalesResponse } from './types';

export const fetchSearchSales = createFetchFn<
	SearchSalesPayload,
	SearchSalesResponse
>({
	method: 'post',
	getURL: () => `/api/salesComps/search`,
	getBody: (params) => params,
});

type UseSearchSalesQueryProps = {
	params: SearchSalesPayload;
} & UseQueryOptions<SearchSalesResponse>;

export const useSearchSalesQuery = ({
	params,
	...props
}: UseSearchSalesQueryProps) =>
	useQuery({
		queryKey: [QUERY_KEYS.SEARCH_SALES, params],
		queryFn: fetchSearchSales(params),
		...props,
	});
