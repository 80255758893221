import {
	SearchLeasesPayload,
	SearchLeasesResponse,
	SEARCH_MAX_LIMIT,
} from 'api';
import { Optional } from 'types/utils';
import factory from './factory';

type LeasePointsParams = Optional<
	SearchLeasesPayload,
	'offset' | 'limit' | 'order' | 'sort'
>;

const leasePointsService = factory.create<
	LeasePointsParams,
	SearchLeasesResponse
>({
	createKey: function (identifier) {
		return JSON.stringify(identifier);
	},
	createData: function (identifier) {
		const data = { ...identifier };
		data.offset = data.offset ?? 0;
		data.limit = data.limit ?? SEARCH_MAX_LIMIT;
		data.order = data.order ?? 'asc';
		data.sort = data.sort ?? 'executionDate';
		return data;
	},
	createUrl: function () {
		return '/api/comps/actions/search';
	},
	cache: {
		storage: sessionStorage,
		ttl: 1000 * 60 * 60, // 1 hour
		namespace: 'graphs',
		maxSize: 1024 * 1024 * 3, // 3 MB
	},
});

const exportable = leasePointsService.exportable({});

export default exportable;
