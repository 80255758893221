import { useInfiniteQuery } from '@tanstack/react-query';
import { QUERY_KEYS, SearchMufaPropertiesResponse } from 'api';
import { createInfiniteSearchFetchFn, SearchInfiniteParams } from '../utils';

const fetchInfiniteMufaProperties =
	createInfiniteSearchFetchFn<SearchMufaPropertiesResponse>({
		url: '/api/mufa/properties/actions/search',
		getResponseSize: (res) => res.properties.length,
	});

export const useSearchMufaPropertiesInfiniteQuery = (
	params: SearchInfiniteParams,
	enabled: boolean
) =>
	useInfiniteQuery({
		queryKey: [QUERY_KEYS.SEARCH_MUFA_PROPERTIES_INFINITE, params],
		queryFn: ({ pageParam = 0, signal }) =>
			fetchInfiniteMufaProperties({ ...params, pageParam, signal }),
		getNextPageParam: (last) => {
			if (!last.hasMore) return;
			return last.pageParam + 1;
		},
		enabled,
	});
