import React, { Component } from 'react';
import styled from 'styled-components';
import Typeahead from 'Components/Typeahead';
import Subtract from '../../../ui/svg_icons/subtract.svg';

import '../styles/logoPicker.nomodule.less';

type State = any;

export default class LogoPicker extends Component<{}, State> {
	constructor() {
		// @ts-expect-error ts-migrate(2554) FIXME: Expected 1-2 arguments, but got 0.
		super();
		this.state = {
			editing: false,
		};
	}

	startEditing = () => {
		this.setState({
			editing: true,
		});
	};

	stopEditing = () => {
		this.setState({
			editing: false,
		});
	};

	// @ts-expect-error TS7006: Parameter 'suggestion' implici...
	setLogo = (suggestion) => {
		this.stopEditing();
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'setLogo' does not exist on type 'Readonl... Remove this comment to see the full error message
		this.props.setLogo(this.props.attribute, suggestion);
	};

	// @ts-expect-error TS7006: Parameter 'suggestion' implici...
	getLogo = (suggestion) => {
		return suggestion.logo;
	};

	removeLogo = () => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'setLogo' does not exist on type 'Readonl... Remove this comment to see the full error message
		this.props.setLogo(this.props.attribute, null);
	};

	render() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'logo' does not exist on type 'Readonly<{... Remove this comment to see the full error message
		if (this.props.logo) {
			return (
				<div className="logo">
					<div className="remove-logo" onClick={this.removeLogo}>
						<Subtract />
					</div>
					{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'logo' does not exist on type 'Readonly<{... Remove this comment to see the full error message */}
					<img style={{ width: '60px' }} src={this.props.logo} />
				</div>
			);
		} else if (this.state.editing) {
			return (
				<Typeahead
					className="white"
					placeholder="Search for logo..."
					source="clearbit"
					onSelection={this.setLogo}
					onBlur={this.stopEditing}
					getSuggestionValue={this.getLogo}
					renderSuggestion={(suggestion) => {
						return (
							<Wrap>
								<img
									width="30px"
									height="30px"
									src={suggestion.logo}
									alt={suggestion.name}
								/>
								<span>{suggestion.name}</span>
							</Wrap>
						);
					}}
				/>
			);
		} else {
			return (
				<div className="add-logo-placeholder" onClick={this.startEditing}>
					{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'placeholder' does not exist on type 'Rea... Remove this comment to see the full error message */}
					{this.props.placeholder}
				</div>
			);
		}
	}
}

const Wrap = styled.div`
	display: flex;
	align-items: center;
	img {
		margin-right: 10px;
	}
`;
