import * as Castle from '@castleio/castle-js';

const CASTLE_SANDBOX_PUBLISHABLE_API_KEY =
	'pk_Az2K69AHzDHqiC8sysKz5fL8c4MAE4Td';
const CASTLE_ENTERPRISE_PUBLISHABLE_API_KEY =
	typeof window !== 'undefined' &&
	window?._env_?.REACT_APP_CASTLE_ENTERPRISE_PUBLISHABLE_API_KEY
		? window._env_.REACT_APP_CASTLE_ENTERPRISE_PUBLISHABLE_API_KEY
		: CASTLE_SANDBOX_PUBLISHABLE_API_KEY;
const CASTLE_EXCHANGE_PUBLISHABLE_API_KEY =
	typeof window !== 'undefined' &&
	window?._env_?.REACT_APP_CASTLE_EXCHANGE_PUBLISHABLE_API_KEY
		? window._env_.REACT_APP_CASTLE_EXCHANGE_PUBLISHABLE_API_KEY
		: CASTLE_SANDBOX_PUBLISHABLE_API_KEY;

export const getCastleTokens = async () => {
	Castle.configure({ pk: CASTLE_ENTERPRISE_PUBLISHABLE_API_KEY });
	const enterpriseToken = await Castle.createRequestToken();

	Castle.configure({ pk: CASTLE_EXCHANGE_PUBLISHABLE_API_KEY });
	const exchangeToken = await Castle.createRequestToken();

	return { enterpriseToken, exchangeToken };
};
