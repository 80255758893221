import { useMemo } from 'react';

type Props<Row> = {
	columns: ExcelColumn<Row>[];
	rows: Row[];
};

export const useExcelData = <Row>({ rows, columns }: Props<Row>) => {
	return useMemo(() => {
		return rows.map((row) => {
			const obj: Record<string, string | number> = {};

			for (const c of columns) {
				obj[c.header] = c.body({
					row,
				});
			}

			return obj;
		});
	}, [rows, columns]);
};

export type ExcelColumn<Row> = {
	header: string;
	body: (args: { row: Row }) => string | number;
};
