import { Spinner } from '@compstak/ui-kit';
import IconArrowLeft from 'ui/svg_icons/arrow_left.svg';
import IconArrowRight from 'ui/svg_icons/arrow_right.svg';
import styled from 'styled-components';

const GreyHeaderContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.neutral.n30};
	text-transform: uppercase;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
	font-size: 14px;
	line-height: 14px;
`;

export const PrevNextContainer = styled(GreyHeaderContainer)`
	display: grid;
	grid-template-columns: 160px minmax(380px, 1fr) 160px;
	padding: 24px 16px;
	white-space: nowrap;
`;

export const BackToContainer = styled(GreyHeaderContainer)`
	display: flex;
	justify-content: center;
	height: 56px;
`;

export const Link = styled.span`
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.colors.blue.blue500};
	cursor: pointer;
	svg {
		width: 6px;
		height: 12px;
		fill: ${({ theme }) => theme.colors.blue.blue500};
	}
	&:hover {
		color: ${({ theme }) => theme.colors.blue.blue600};
		svg {
			fill: ${({ theme }) => theme.colors.blue.blue600};
		}
	}
`;

export const UnderlinedLink = styled(Link)`
	text-decoration: solid underline ${({ theme }) => theme.colors.blue.blue500}
		1px;
	text-underline-offset: 2px;
	&:hover {
		text-decoration-color: ${({ theme }) => theme.colors.blue.blue600};
	}
`;

export const CenteredContainer = styled.div`
	display: flex;
	gap: 24px;
	justify-self: center;
	align-items: baseline;
`;

export const StyledIconArrowLeft = styled(IconArrowLeft)`
	margin-right: 12px;
`;

export const StyledIconArrowRight = styled(IconArrowRight)`
	margin-left: 12px;
`;

export const NumberOfResults = styled.span`
	color: ${({ theme }) => theme.colors.gray.gray700};
`;

export const PrevContainer = styled.div`
	justify-self: start;
`;

export const NextContainer = styled.div`
	justify-self: end;
`;

export const StyledSpinner = styled(Spinner)`
	margin: auto 50px;
`;
