import leaseDetailsReducer from 'Components/Lease/Details/reducer';
import loaderData from 'Components/Loader/reducer';
import chartBuilder from 'Pages/Analytics/Builder/reducer';
import analyticsProjects from 'Pages/Analytics/Repository/reducer';
import {
	authState,
	featureFlagsReducer,
	markets,
	mufaReferenceData,
	permissions,
	user,
} from 'Pages/Login/reducers';
import stackingPlan from 'Pages/StackingPlans/reducer';
import exportReducer from 'reducers/export';
import headerNotification from 'reducers/headerNotification';
import unlockReducer, { unlockReducerV2 } from 'reducers/unlock';
import { combineReducers } from 'redux';
import feedbackReducer from 'Singletons/Feedback/reducer';
import menu from 'Singletons/Menu/reducer';
import modal from 'Singletons/Modal/reducer';
import tooltip from 'Singletons/Tooltip/reducer';
import mapSearchResultsV2 from '../Pages/Search/Map/searchMapReducer';
import searchReducer from '../Pages/Search/searchReducer';
import uploads from '../Pages/Upload/reducer';
import filtersV2 from './filtersReducer';
import home from './reducer';
import { referenceData } from './referenceData';
import savedSearches from './savedSearches';
import selection from './selection';
import { routerReducer } from './routerReducer';

const appReducer = combineReducers({
	analyticsProjects,
	appConfig: () => window.appConfig,
	authState,
	chartBuilder,
	export: exportReducer,
	featureFlags: featureFlagsReducer,
	feedback: feedbackReducer,
	filtersV2,
	headerNotification,
	home,
	leaseDetails: leaseDetailsReducer,
	loaderData,
	mapSearchResultsV2,
	markets,
	menu,
	modal,
	permissions,
	referenceData,
	mufaReferenceData,
	router: routerReducer,
	savedSearches,
	searchReducer,
	selection,
	stackingPlan,
	tooltip,
	unlock: unlockReducer,
	unlockV2: unlockReducerV2,
	uploads,
	user,
});

export type AppState = ReturnType<typeof appReducer>;

// @ts-expect-error TS7006: Parameter 'action' implicitly ...
const rootReducer = (state?: AppState, action?): AppState => {
	return appReducer(state, action);
};

export default rootReducer;
