import { DataSetFromServer, DataSetType } from './analytics';

export const dataSetNYC: DataSetFromServer = {
	name: 'NY',
	filters: [
		{ property: 'marketName', value: 'Manhattan' },
		{ property: 'spaceTypeId', value: [1] },
		{
			property: 'submarketId',
			value: [11, 10, 200, 22, 16, 3, 202, 17, 2, 201, 8],
		},
		{ property: 'hidden', value: false },
	],
	series: 'startingRent',
	isVisible: true,
	type: DataSetType.COMMERCIAL,
};
export const dataSetSF: DataSetFromServer = {
	name: 'SF',
	filters: [
		{ property: 'marketName', value: 'San Francisco' },
		{ property: 'spaceTypeId', value: [1] },
		{
			property: 'submarketId',
			value: [37, 26],
		},
		{ property: 'hidden', value: false },
	],
	series: 'startingRent',
	isVisible: true,
	type: DataSetType.COMMERCIAL,
};
export const dataSetLA: DataSetFromServer = {
	name: 'LA',
	filters: [
		{ property: 'marketName', value: 'Los Angeles' },
		{ property: 'spaceTypeId', value: [1] },
		{ property: 'submarketId', value: [1132] },
		{ property: 'hidden', value: false },
	],
	series: 'startingRent',
	isVisible: true,
	type: DataSetType.COMMERCIAL,
};
export const dataSetCHI: DataSetFromServer = {
	name: 'CHI',
	filters: [
		{ property: 'marketName', value: 'Chicago' },
		{ property: 'spaceTypeId', value: [1] },
		{
			property: 'submarketId',
			value: [192, 195, 189, 198, 187, 190],
		},
		{ property: 'hidden', value: false },
	],
	series: 'startingRent',
	isVisible: true,
	type: DataSetType.COMMERCIAL,
};

// @ts-expect-error TS7006: Parameter 'marketName' implici...
export const buildingClassDataSets = (marketName) => {
	return ['A', 'B', 'C'].map((buildingClass, idx) => {
		return {
			name: `Building Class ${buildingClass}`,
			filters: [
				{ property: 'buildingClassId', value: [idx + 1] },
				{ property: 'marketName', value: marketName },
				{ property: 'hidden', value: false },
			],
			series: 'leaseTerm',
			isVisible: true,
			type: DataSetType.COMMERCIAL,
		} as DataSetFromServer;
	});
};

export const mapAnalyticsMarketIds = [
	1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 16, 17, 18, 19, 20, 21, 23, 24,
	26, 27, 28, 29, 32, 37, 38,
];

export const mapAnalyticsLayerIds = [
	'market-rent',
	'market-rent-hexagon-cell',
	'marketrent-submarkets-layer',
	'lease-expiration-layer',
	'tenant-industry-layer',
	'space-type-dot-layer',
	'space-type-dot-layer-points',
	'space-type-pin-layer',
];

export const DRAFTS_PROJECT_NAME = 'Drafts';
