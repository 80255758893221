import { LeaseRentBumpsDollar } from 'types';
import { formatMoney } from './formatMoney';
import { formatPerYearWording } from './formatPerYearWording';

export const formatRentBumpsDollar = (
	value: LeaseRentBumpsDollar,
	isMonthly?: boolean
) => {
	const { bumps, months } = value;
	return `${formatMoney(isMonthly ? bumps / 12 : bumps)} ${formatPerYearWording(
		months / 12
	)}`;
};
