import { numberUtils } from '@compstak/ui-kit';

const moneyCharacters = {
	USD: '$',
	GBP: '£',
};

const decimalPlaces = {
	USD: 2,
	GBP: 2,
};

const neitherNumberNorDot = /[^0-9.]/g;

function format(
	value: string | number,
	currency: string | undefined,
	decimalPrecision?: string | number | boolean | null | undefined,
	options?: { isYAxesFormatting?: boolean }
) {
	if (typeof value === 'string') {
		value = value.replace(neitherNumberNorDot, '');
		value = parseFloat(value);
	}
	if (typeof value !== 'number') {
		throw new Error('Tried to convert a non number to currency');
	}
	if (typeof currency !== 'string') {
		currency = 'USD';
	}
	const ignoredDecimalPrecision =
		decimalPrecision === false ||
		decimalPrecision === '' ||
		decimalPrecision === null ||
		decimalPrecision === undefined;
	if (ignoredDecimalPrecision) {
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		decimalPrecision = decimalPlaces[currency];
	} else if (
		typeof decimalPrecision !== 'number' ||
		Math.floor(decimalPrecision) !== decimalPrecision
	) {
		throw new Error(
			'Trying to format money using decimal precision "' +
				decimalPrecision +
				'" that is not an integer, but of type ' +
				typeof decimalPrecision
		);
	}

	if (options?.isYAxesFormatting) {
		if (
			value <= 999 ||
			(value >= 100_000 && value <= 999_999) ||
			value >= 100_000_000
		) {
			return (
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				moneyCharacters[currency] +
				// @ts-expect-error legacy
				numberUtils.formatCurrency(value, 'integer', 'financial')
			);
		}

		if (
			(value >= 1000 && value <= 99_999) ||
			(value >= 10_000_000 && value <= 99_999_999)
		) {
			return (
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				moneyCharacters[currency] +
				// @ts-expect-error legacy
				numberUtils.formatCurrency(value, 'oneDecimal', 'financial')
			);
		}

		if (value >= 1_000_000 && value <= 9_999_999) {
			return (
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				moneyCharacters[currency] +
				// @ts-expect-error legacy
				numberUtils.formatCurrency(value, 'twoDecimals', 'financial')
			);
		}
	}

	return (
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		moneyCharacters[currency] +
		(value <= 99_999 || (value >= 1_000_000 && value <= 99_999_999)
			? // @ts-expect-error legacy
				numberUtils.formatCurrency(value, 'twoDecimals', 'financial')
			: // @ts-expect-error legacy
				numberUtils.formatCurrency(value, 'oneDecimal', 'financial'))
	);
}

export default format;
