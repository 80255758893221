import { LegacyMenuPosition, MenuComponentNames } from './types';

export const MENU_SHOW = 'MENU_SHOW' as const;
export const MENU_HIDE = 'MENU_HIDE' as const;

export function showMenu<T>(
	componentName: MenuComponentNames,
	target: EventTarget,
	position: LegacyMenuPosition,
	data?: T
) {
	return {
		type: MENU_SHOW,
		payload: {
			component: componentName,
			target,
			position,
			data,
		},
	};
}

export function hideMenu(componentName?: MenuComponentNames) {
	return {
		type: MENU_HIDE,
		payload: {
			component: componentName,
		},
	};
}

export const menuActions = {
	showMenu,
	hideMenu,
};

export type MenuActions = typeof menuActions;
