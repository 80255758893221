import { PINPOINT_CIRCLE_ZOOM_BREAKPOINT } from '@compstak/maps';
import { Position } from '@turf/helpers';
import produce from 'immer';
import { LeaseComp, SalesComp, PropertyComp } from 'types/comp';
import { getPromiseActionType } from 'types/redux-promise-middleware';
import {
	LOAD_COMPS,
	FOCUS_PROPERTY,
	REMOVE_PROPERTY,
	FOCUS_FILTER_SECTION,
	MapSearchAction,
} from './actions';
import { FiltersTypesKeys } from 'models/filters/types';

type CompsByProperties = LeaseComp[] | SalesComp[] | PropertyComp[];

export type PropertyPopup = {
	coordinates: Position;
	zoom: number;
};

export type SearchMapState = {
	compsLoaded: boolean;
	propertyPopup: PropertyPopup | null;
	property: PropertyComp | null;
	compsByProperties: Map<number, CompsByProperties>;
	focusFilterSectionId: FiltersTypesKeys | null;
	lastRequestId: number;
	pendingSingleMarketRequests: number;
	totalCount: number;
};

const initialState = {
	compsLoaded: false,
	propertyPopup: null,
	property: null,
	compsByProperties: new Map<number, CompsByProperties>(),
	focusFilterSectionId: null,
	lastRequestId: 0,
	pendingSingleMarketRequests: 0,
	totalCount: -1,
};

const mapReducer = (
	state: SearchMapState = initialState,
	action: MapSearchAction
): SearchMapState => {
	return produce(state, (draftState) => {
		switch (action.type) {
			case getPromiseActionType(LOAD_COMPS, 'PENDING'): {
				if (draftState.lastRequestId !== action.meta.requestId) {
					draftState.lastRequestId = action.meta.requestId;
					draftState.pendingSingleMarketRequests = action.meta.numberOfMarkets;
					draftState.compsLoaded = false;
					draftState.compsByProperties = new Map<number, CompsByProperties>();
					draftState.totalCount = 0;
				}
				return;
			}

			case getPromiseActionType(LOAD_COMPS, 'FULFILLED'): {
				if (action.meta.requestId === state.lastRequestId) {
					draftState.pendingSingleMarketRequests--;
					draftState.compsLoaded = draftState.pendingSingleMarketRequests < 1;
					draftState.compsByProperties = new Map([
						...draftState.compsByProperties,
						...action.payload.results,
					]);
					draftState.totalCount += action.payload.totalCount;
				}
				return;
			}

			case getPromiseActionType(LOAD_COMPS, 'REJECTED'): {
				if (action.meta.requestId === state.lastRequestId) {
					draftState.pendingSingleMarketRequests--;
					draftState.compsLoaded = draftState.pendingSingleMarketRequests < 1;
				}
				return;
			}

			case getPromiseActionType(FOCUS_PROPERTY, 'PENDING'): {
				const propertyPopup = action.meta.propertyPopup;
				if (propertyPopup) {
					draftState.propertyPopup = propertyPopup;
				} else {
					draftState.propertyPopup = null;
				}
				draftState.property = null;
				return;
			}

			case getPromiseActionType(FOCUS_PROPERTY, 'FULFILLED'): {
				const propertyPopup = action.meta.propertyPopup;
				const { lon, lat } = action.payload.geoPoint;

				if (!propertyPopup && !!lon && !!lat) {
					draftState.propertyPopup = {
						coordinates: [lon, lat],
						zoom: PINPOINT_CIRCLE_ZOOM_BREAKPOINT,
					};
				}
				draftState.property = action.payload;
				return;
			}

			case REMOVE_PROPERTY: {
				draftState.propertyPopup = null;
				draftState.property = null;
				return;
			}

			case FOCUS_FILTER_SECTION: {
				draftState.focusFilterSectionId = action.filterSectionId;
				return;
			}
		}
	});
};

export default mapReducer;
