import { useSearchMufaPropertiesQuery, useSearchPropertiesQuery } from 'api';
import { useIsMultifamily } from 'hooks';
import { FiltersObject } from 'models/filters/types';
import { filtersToServerJSON } from 'models/filters/util';
import { useMemo } from 'react';
import { MufaPropertyComp, PropertyComp } from 'types';
import { createNavHeader } from './createNavHeader';
import { UseNavHeaderDataHook } from './types';
import { useNavHeaderPagination } from './useNavHeaderPagination';

type Args = {
	filters: FiltersObject;
};

export const {
	NavHeader: SearchPropertiesNavHeader,
	NavHeaderProvider: SearchPropertiesNavHeaderProvider,
	useNavHeaderContext: useSearchPropertiesNavHeaderContext,
} = createNavHeader({
	createUseNavDataHook: (args: Args) => createUseNavHeaderPropertyData(args),
	getItemPath: (property) => `/property/${property.id}`,
	itemLabel: 'property',
});

export const createUseNavHeaderPropertyData = ({ filters }: Args) => {
	const useNavHeaderPropertyData: UseNavHeaderDataHook<
		PropertyComp | MufaPropertyComp
	> = ({ initialIndex }) => {
		const {
			currentIndex,
			startIndex,
			pageSize,
			setPrevIndex,
			setNextIndex,
			getIndexInsideOfPage,
		} = useNavHeaderPagination(initialIndex);

		const isMultifamily = useIsMultifamily({ markets: filters.markets });

		const serverFilters = useMemo(() => {
			return filtersToServerJSON(filters, true);
		}, [filters]);

		const { data: propertyData, fetchStatus: propertyFetchStatus } =
			useSearchPropertiesQuery(
				{
					filter: serverFilters,
					offset: startIndex,
					limit: pageSize,
					properties: ['id'],
					sort: filters.sortField,
					order: filters.sortDirection,
				},
				{ enabled: !isMultifamily }
			);

		const { data: mufaData, fetchStatus: mufaFetchStatus } =
			useSearchMufaPropertiesQuery(
				{
					filter: serverFilters,
					offset: startIndex,
					limit: pageSize,
					properties: ['id'],
					sort: filters.sortField,
					order: filters.sortDirection,
				},
				{ enabled: isMultifamily }
			);

		const data = isMultifamily ? mufaData : propertyData;
		const fetchStatus = isMultifamily ? mufaFetchStatus : propertyFetchStatus;

		const totalCount = data?.totalCount;

		return {
			currentIndex,
			totalCount,
			currentItem: data?.properties?.[getIndexInsideOfPage()],
			isLoading: !data || fetchStatus === 'fetching',
			requestPrevItem: setPrevIndex,
			requestNextItem: () => setNextIndex(totalCount),
		};
	};

	return useNavHeaderPropertyData;
};
