import { Data } from '@compstak/ui-kit';
import { TransitScreenDto } from 'api';

export const transformToKeyValueTableData = (
	modes?: TransitScreenDto['modes']
): Data[] => {
	const res: Data[] = [];
	if (!modes) return res;

	type ModeKey = keyof typeof modes;

	for (const key in modes) {
		if (Object.prototype.hasOwnProperty.call(modes, key)) {
			const mode = modes[key as ModeKey];

			if (mode) {
				const { friendlyName, agencies } = mode;

				agencies.forEach(({ agencyShortName }) => {
					res.push([friendlyName, agencyShortName]);
				});
			}
		}
	}

	return res;
};
