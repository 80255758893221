import { formatRent } from './formatRent';
import { renderRentPeriodPostfix } from './renderRentPeriodPostfix';
import { getRentPeriodPostfix } from './getRentPeriodPostfix';
import { Gapper } from './UI';

export const formatRentWithPostfix = (value: number, isMonthly?: boolean) => {
	return (
		<Gapper>
			{formatRent(value, isMonthly)}
			{renderRentPeriodPostfix(isMonthly)}
		</Gapper>
	);
};

export const formatRentWithPostfixAsString = (
	value: number,
	isMonthly?: boolean
) => {
	return `${formatRent(value, isMonthly)} ${getRentPeriodPostfix(isMonthly)}`;
};
