import userService from 'services/user';
import referenceDataService from 'services/referenceData';
import mufaReferenceDataService from 'services/mufaReferenceData';
import marketService from 'services/markets';

import permissionsService from 'services/permissions';
import { MarketPermissions, MarketModel, Market, User } from '@compstak/common';
import { MarketsState } from './reducers';
import { ReduxPromiseAction } from 'types/redux-promise-middleware';
import { fetchLDFlags } from 'api/featureFlags/useFeatureFlagsQuery';
import { refreshAccessToken } from 'auth/auth';

export const USER_UPDATE = 'USER_UPDATE';
export const USER_LOAD = 'USER_LOAD';
export const USER_ADD_CREDITS = 'USER_ADD_CREDITS';
export const LOGOUT = 'LOGOUT' as const;
export const SET_MARKET = 'SET_MARKET';
export const BOOTSTRAP = 'BOOTSTRAP' as const;
export const TRIAL_EXPIRED = 'TRIAL_EXPIRED' as const;
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const SET_GB_COMPANY = 'SET_GB_COMPANY';

export const NOT_LOGGED_IN = 'User not logged in.';

export function updateUser(newUser: User) {
	return {
		type: USER_UPDATE,
		payload: newUser,
	};
}

export function addCreditsToUser(credits: number) {
	return {
		type: USER_ADD_CREDITS,
		payload: credits,
	};
}

export function loadUser(onSuccess?: (user: User) => void) {
	return {
		type: USER_LOAD,
		payload: {
			promise: userService.load().then((user) => {
				onSuccess?.(user);
				return user;
			}),
		},
	};
}
export async function createBootstrapPromise() {
	// refresh access token so bootstrap redux async action is fired with new access token (if possible)
	await refreshAccessToken();

	const [user, marketsResponse, referenceData] = await Promise.all([
		userService.load(),
		marketService.load(),
		referenceDataService.load(),
	]);

	const markets = marketsResponse
		.map(
			(marketResponse) => new MarketModel(marketResponse as Market) as Market
		)
		.reduce<MarketsState>(
			(acc, market) => {
				if (market.name !== 'list') {
					acc[market.name] = market;
				}
				if (market.displayName !== 'list') {
					acc[market.displayName] = market;
				}
				acc[market.id] = market;

				acc.list.push(market);

				return acc;
			},
			{ list: [] as Market[] } as MarketsState
		) as MarketsState;

	// @ts-expect-error TS2322: Type 'Promise<unknown>' is not...
	const permissionsPromise: Promise<{
		[marketId: string]: MarketPermissions;
		// @ts-expect-error TS2345: Argument of type 'number' is n...
	}> = permissionsService.load(user.id);

	const [permissions, featureFlags] = await Promise.all([
		permissionsPromise,
		fetchLDFlags(user),
	]);

	const hasSomeMarketWithMultifamilyAccess = Object.values(permissions).some(
		(p) => p.multifamilyAccess
	);
	const useMultifamily = hasSomeMarketWithMultifamilyAccess;

	const mufaReferenceData = useMultifamily
		? await mufaReferenceDataService.load()
		: null;

	return {
		user,
		referenceData,
		permissions,
		markets,
		featureFlags,
		mufaReferenceData,
	};
}

export const logout = () => {
	return {
		type: LOGOUT,
	};
};

export function bootstrap() {
	return {
		type: BOOTSTRAP,
		payload: {
			promise: createBootstrapPromise(),
		},
	};
}

export function trialExpired({ username }: { username: string }) {
	return {
		type: TRIAL_EXPIRED,
		payload: {
			username,
		},
	};
}

export function acceptTerms(asCompany?: boolean) {
	const promise = userService
		.load()
		.then((user) => {
			return userService.acceptTerms(user, asCompany);
		})
		.then(createBootstrapPromise);

	return {
		type: BOOTSTRAP,
		payload: {
			promise,
		},
	};
}

// @ts-expect-error TS7006: Parameter 'value' implicitly h...
export function setAsCompany(value) {
	return {
		type: SET_GB_COMPANY,
		payload: value,
	};
}

export function forgotPassword(email: string) {
	return {
		type: FORGOT_PASSWORD,
		payload: {
			promise: userService.sendForgotPassword(email),
		},
	};
}

export const loginActions = {
	updateUser,
	loadUser,
	createBootstrapPromise,
	logout,
	bootstrap,
	acceptTerms,
	setAsCompany,
	forgotPassword,
};

export type LoginActions = typeof loginActions;

export type BootstrapAction = ReduxPromiseAction<ReturnType<typeof bootstrap>>;

export type LogoutAction = ReturnType<typeof logout>;
