import { MODAL_SHOW, MODAL_HIDE, ModalAction } from './actions';
import { LOCATION_CHANGE } from 'redux-first-history';
import { LocationChangeRouterAction } from 'middleware/filtersFromRoutes';

interface ModalState {
	data: any;
	component: string | null;
	previous: ModalState | null;
}

const initialState: ModalState = {
	data: null,
	component: null,
	previous: null,
};

export default (
	state: ModalState = initialState,
	action: ModalAction | LocationChangeRouterAction
): ModalState => {
	switch (action.type) {
		case MODAL_SHOW:
			return {
				...state,
				previous: action.payload.stack ? { ...state } : state.previous,
				data: action.payload.data,
				component: action.payload.component,
			};

		case MODAL_HIDE:
		case LOCATION_CHANGE:
			// @ts-expect-error property 'stack' does not exist on type 'LocationChangeRouterAction'
			if (state.previous && action.payload.stack) {
				return {
					...state.previous,
				};
			}
			return {
				...initialState,
			};

		default:
			return state;
	}
};
