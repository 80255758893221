import leaseService from 'services/lease';
import leaseSearchService from 'services/leaseSearch';
import { filtersToServerJSON } from 'models/filters/util/serverJson';
import { filtersFromQueryString } from 'models/filters/util/urls';

export const LOAD_LEASE = 'LOAD_LEASE' as const;
export const SHOW_VERIFY_COMP = 'SHOW_VERIFY_COMP' as const;
export const HIDE_VERIFY_COMP = 'HIDE_VERIFY_COMP' as const;
export const SEND_USER_CORRECTION = 'SEND_USER_CORRECTION' as const;
export const LEASE_PAGE_LOAD = 'LEASE_PAGE_LOAD' as const;

export const CREATE_LEASE_PAGE_SEARCH = 'CREATE_LEASE_PAGE_SEARCH' as const;
export const CLEAR_LEASE_PAGE_DATA = 'CLEAR_LEASE_PAGE_DATA' as const;

// @ts-expect-error TS7006: Parameter 'search' implicitly ...
export function fetch(search, index: number, lowestLoaded: number) {
	let low: number;
	const lowestToCheck = lowestLoaded + 5;
	const highestToCheck = Math.min(lowestLoaded + 35, search.total);

	if (index < lowestToCheck || index > highestToCheck) {
		low = index - 20;
	} else {
		low = lowestLoaded;
	}

	low = Math.max(0, low);
	const high = Math.min(low + 40, search.total);

	let promise;

	if (Array.isArray(search)) {
		promise = Promise.resolve(search);
	} else if (search.loadRange) {
		promise = search.loadRange(low, high);
	}

	return {
		type: LEASE_PAGE_LOAD,
		meta: {
			index,
			lowestLoaded: low,
			search,
		},
		payload: {
			promise,
		},
	};
}

// TODO: make this work with leases and sales comps
// @ts-expect-error TS7006: Parameter 'markets' implicitly...
export function createSearch(markets, queryString) {
	let search;
	try {
		const leaseIds = JSON.parse(queryString);
		search = leaseService.loadMany(leaseIds);
	} catch (e) {
		const filters = filtersFromQueryString({
			compType: 'lease',
			markets,
			queryString,
		});
		search = leaseSearchService.create(
			filtersToServerJSON(filters),
			40,
			filters.sortDirection,
			filters.sortField
		);
	}

	return {
		type: CREATE_LEASE_PAGE_SEARCH,
		payload: {
			promise: search,
		},
	};
}

export function clear() {
	return {
		type: CLEAR_LEASE_PAGE_DATA,
	};
}

export function loadLease(leaseId: number) {
	return {
		type: LOAD_LEASE,
		meta: {
			id: leaseId,
			churnData: {
				name: 'Lease Comp Viewed',
				description: 'User viewed a lease comp',
				count: 1,
				extraData: {},
			},
		},
		payload: {
			id: leaseId,
			promise: leaseService.load(leaseId),
		},
	};
}

export function showVerifyComp() {
	return {
		type: SHOW_VERIFY_COMP,
	};
}

export function hideVerifyComp() {
	return {
		type: HIDE_VERIFY_COMP,
	};
}

// @ts-expect-error TS7006: Parameter 'compId' implicitly ...
export function sendCompSuggestion(compId, compSuggestion, market, compType) {
	return {
		type: SEND_USER_CORRECTION,
		meta: {
			id: compId,
			updateUser: true,
			suggestion: compSuggestion,
			market: market,
			feedback: {
				className: 'center',
				pending: 'Sending corrections...',
				fulfilled: 'Correction sent.',
				rejected: 'There was an error sending this correction.',
			},
		},
		payload: {
			promise: leaseService.sendSuggestion(
				compId,
				compSuggestion,
				market,
				compType
			),
		},
	};
}
