export type FormatNumberOptions = {
	/** @default 0 */
	minDecimals?: number;
	/** @default 2 */
	maxDecimals?: number;
	/**
	 * Whether to shorten numbers over 1000 ( e.g. 1.1k )
	 * @default true */
	shorten?: boolean;
};

export const formatNumber = (value: number, options?: FormatNumberOptions) => {
	const shorten = options?.shorten ?? true;

	return new Intl.NumberFormat('en-US', {
		minimumFractionDigits: options?.minDecimals ?? 0,
		maximumFractionDigits: options?.maxDecimals ?? 2,
		notation: shorten ? 'compact' : 'standard',
		compactDisplay: 'short',
		// @ts-expect-error this exists, but TS doesn't know about it
		roundingPriority: 'lessPrecision',
	}).format(value);
};
