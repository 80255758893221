import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { createFetchFn } from '../utils';
import { PropertyLoanParams, PropertyLoanResponse } from './types';

const fetchPropertyLoan = createFetchFn<
	PropertyLoanParams,
	PropertyLoanResponse
>({
	method: 'get',
	getURL: ({ propertyId: id }) => `/api/properties/action/${id}/loan`,
});

export const usePropertyLoanQuery = (
	params: PropertyLoanParams,
	options?: UseQueryOptions<PropertyLoanResponse>
) => {
	return useQuery({
		queryKey: [QUERY_KEYS.PROPERTY_LOAN, params],
		queryFn: fetchPropertyLoan(params),
		...options,
	});
};
