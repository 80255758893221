import { QUERY_KEYS } from '../constants';
import { createFetchFn, createQueryHook } from '../utils';

const fetchPropertyInfo = createFetchFn<
	PropertyInfoParams,
	PropertyInfoResponse
>({
	method: 'get',
	getURL: ({ propertyId }) => `/mufa/properties/${propertyId}/info`,
});

export const usePropertyInfoQuery = createQueryHook({
	queryKey: QUERY_KEYS.PROPERTY_INFO,
	fetchFn: fetchPropertyInfo,
	enabled: ({ propertyId }) => propertyId != null,
});

type PropertyInfoParams = {
	propertyId?: number | string;
};

export type PropertyInfoResponse = {
	propertyId: number;
	hasRpCompSetData: boolean;
	propertyType: PropertyInfoPropertyType;
};

export enum PropertyInfoPropertyType {
	COMMERCIAL = 'commercial',
	MULTIFAMILY = 'multifamily',
	MIXED_USE = 'mixedUse',
}
