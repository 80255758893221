import { CompsetType, useCompsetType } from 'api/utils';
import { useMufaResetCompsetMutation } from './useMufaResetCompsetMutation';
import { useResetCompsetMutation } from './useResetCompsetMutation';

type Props = {
	propertyId: number;
};

export const useResetCompset = ({ propertyId }: Props) => {
	const compsetType = useCompsetType({ propertyId });

	const resetCompset = useResetCompsetMutation();
	const mufaResetCompset = useMufaResetCompsetMutation();

	return compsetType === CompsetType.COMMERCIAL
		? resetCompset
		: mufaResetCompset;
};
