import { createContext, ReactNode, useContext } from 'react';

type Props<HookProps, ContextValue> = {
	useValue: (props: HookProps) => ContextValue;
};

export const createSimpleProvider = <HookProps, ContextValue>({
	useValue,
}: Props<HookProps, ContextValue>) => {
	const Context = createContext({} as ContextValue);

	const Provider = ({
		children,
		...props
	}: HookProps & { children: ReactNode }) => {
		const contextValue = useValue(props as HookProps);

		return <Context.Provider value={contextValue}>{children}</Context.Provider>;
	};

	const useProviderContext = () => {
		return useContext(Context);
	};

	return {
		Provider,
		useProviderContext,
	};
};
