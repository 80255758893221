export const formatMonths = (months: number, options?: FormatMonthsOptions) => {
	const result: string[] = [];
	const y = Math.floor(months / 12);
	const m = months % 12;
	const d = Math.round((m % 1) * 28);

	const omitDays = options?.omitDays ?? false;

	if (!y && !m) return '0m';

	if (y) {
		result.push(y + 'y');
	}
	if (m) {
		result.push(Math.floor(m) + 'm');
	}
	if (!omitDays && d) {
		result.push(Math.floor(d) + 'd');
	}

	return result.join(' ');
};

type FormatMonthsOptions = {
	/** @default false */
	omitDays?: boolean;
};
