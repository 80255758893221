import { SearchResponse } from 'api';
import { FiltersObject } from 'models/filters/types';
import { Action, Dispatch } from 'redux';
import userService from 'services/user';
import { MENU_SHOW } from 'Singletons/Menu/actions';
import { AppDispatch } from 'store';
import { Comp, CompType, LeaseComp, PropertyComp, SalesComp } from 'types/comp';
import { ReduxPromiseAction } from 'types/redux-promise-middleware';
import { getAttributesById } from 'util/comp-format/src/format';

export const LOAD_FIELDS = 'LOAD_FIELDS' as const;
export const PERSIST_COLUMNS = 'PERSIST_COLUMNS' as const;
export const REQUEST_LEASES = 'REQUEST_LEASES' as const;
export const REQUEST_SALES = 'REQUEST_SALES' as const;
export const REQUEST_PROPERTIES = 'REQUEST_PROPERTIES' as const;
export const COLUMN_REORDER = 'COLUMN_REORDER' as const;
export const APPLY_PRESET = 'APPLY_PRESET' as const;
export const LEASE_UNLOCK = 'LEASE_UNLOCK' as const;
const DEFAULT_SORT_ORDER = 'asc';

let incrementalId = 1;

type ColumnField = {
	compAttributeId: number;
	width: number;
};

type ColumnType =
	| 'columnPreferences'
	| 'salesColumnPreferences'
	| 'propertyColumnPreferences';

export function createFieldArray(
	userFields: ColumnField[],
	compType: CompType
) {
	const formatFields = getAttributesById(compType);

	const fields = userFields
		.filter((field) => formatFields[field.compAttributeId])
		.filter((field) => formatFields[field.compAttributeId].hidden !== true)
		// @ts-expect-error TS2339: Property 'inTableView' does no...
		.filter((field) => formatFields[field.compAttributeId].inTableView === true)
		.map((field) => {
			const formatField = formatFields[field.compAttributeId];
			return {
				width: field.width,
				id: formatField.id,
				displayName: formatField.displayName,
				tooltip: formatField.tooltip,
				name: formatField.name,
				showAlertIfNotPresent: formatField.showAlertIfNotPresent,
				backendSortName: formatField.backendSortName,
				defaultSortOrder: formatField.defaultSortOrder ?? DEFAULT_SORT_ORDER,
			};
		});

	return fields;
}

export function persistColumns(newColumns: ColumnField[], compType: CompType) {
	const fieldArray = createFieldArray(newColumns, compType);

	return {
		type: PERSIST_COLUMNS,
		meta: {
			updateUser: true,
			fields: fieldArray,
		},
		payload: {
			promise: userService.load().then((user) => {
				const preferences = Object.assign({}, user.preferences);
				if (compType === 'sale') {
					preferences.salesColumnPreferences = newColumns;
				} else if (compType === 'property') {
					preferences.propertyColumnPreferences = newColumns;
				} else {
					preferences.columnPreferences = newColumns;
				}
				const newUser = Object.assign({}, user);
				newUser.preferences = preferences;
				return userService.savePreferences(newUser);
			}),
		},
	};
}

const applyPresetInternal = (
	presetName: string,
	compType: CompType,
	columnType: ColumnType
) => {
	return {
		type: APPLY_PRESET,
		meta: {
			updateUser: true,
		},
		payload: {
			promise: userService.load().then((user) => {
				return userService
					.applyPreset(user, columnType, presetName)
					.then((updatedUser) => {
						return createFieldArray(
							updatedUser.preferences[columnType],
							compType
						);
					});
			}),
		},
	};
};

export const applyColumnsPreset =
	(presetName: string, compType: CompType) => (dispatch: AppDispatch) => {
		let columnType: ColumnType = 'columnPreferences';
		if (compType === 'sale') {
			columnType = 'salesColumnPreferences';
		} else if (compType === 'property') {
			columnType = 'propertyColumnPreferences';
		}
		dispatch(applyPresetInternal(presetName, compType, columnType));
	};

// @ts-expect-error TS7006: Parameter 'event' implicitly h...
export function reorderColumns(event, columns, compType: CompType) {
	columns = columns.slice();

	const { columnAfter, reorderColumn } = event;

	// @ts-expect-error TS7006: Parameter 'col' implicitly has...
	const columnIndex = columns.findIndex((col) => col.name === reorderColumn);
	const columnObject = columns[columnIndex];
	columns.splice(columnIndex, 1);

	// @ts-expect-error TS7006: Parameter 'col' implicitly has...
	const insertIndex = columns.findIndex((col) => col.name === columnAfter);

	if (insertIndex === -1) {
		columns.push(columnObject);
	} else {
		columns.splice(insertIndex, 0, columnObject);
	}

	// @ts-expect-error TS7006: Parameter 'field' implicitly h...
	columns = columns.map((field) => {
		return { compAttributeId: field.id, width: field.width };
	});

	return persistColumns(columns, compType);
}

export function loadFields(compType: CompType) {
	return {
		type: LOAD_FIELDS,
		payload: {
			promise: userService.load().then((user) => {
				const userFields =
					compType === 'sale'
						? user.preferences.salesColumnPreferences
						: compType === 'property'
						? user.preferences.propertyColumnPreferences
						: user.preferences.columnPreferences;
				return createFieldArray(userFields, compType);
			}),
		},
	};
}

type SearchService<T extends Comp> = {
	total: number;
	loadRange: (
		firstItem: number,
		lastItem: number
	) => Promise<SearchResponse<T>>;
};

type SearchRequest<A extends Action, T extends Comp> = (
	lowest: number,
	highest: number,
	sizeOfPage: number,
	firstLoaded: number,
	lastLoaded: number,
	filters: FiltersObject,
	search: SearchService<T> | null
) => (dispatch: Dispatch<A>) => void;

const requestLeasesInternal = (
	firstItemToRequest: number,
	lastItemToRequest: number,
	lowest: number,
	highest: number,
	filters: FiltersObject,
	search: SearchService<LeaseComp>
) => {
	return {
		type: REQUEST_LEASES,
		meta: {
			first: firstItemToRequest,
			last: lastItemToRequest,
			lowestLoaded: lowest,
			highestLoaded: highest,
			filters,
			id: incrementalId++,
		},
		payload: {
			promise: search.loadRange(firstItemToRequest, lastItemToRequest),
		},
	};
};

export const requestLeases: SearchRequest<
	{ type: typeof REQUEST_LEASES },
	LeaseComp
> =
	(lowest, highest, sizeOfPage, firstLoaded, lastLoaded, filters, search) =>
	(dispatch) => {
		let needsToLoad = false;

		if (firstLoaded > 0 && lowest - firstLoaded < sizeOfPage) {
			needsToLoad = true;
		}

		if (lastLoaded - highest < sizeOfPage) {
			needsToLoad = true;
		}

		if (needsToLoad && search) {
			const firstItemToRequest = Math.max(0, lowest - sizeOfPage * 2);
			const lastItemToRequest = Math.min(
				search ? search?.total : 0,
				highest + sizeOfPage * 2
			);
			dispatch(
				requestLeasesInternal(
					firstItemToRequest,
					lastItemToRequest,
					lowest,
					highest,
					filters,
					search
				)
			);
		} else {
			console.warn('not loading');
		}
	};

const requestSalesInternal = (
	firstItemToRequest: number,
	lastItemToRequest: number,
	lowest: number,
	highest: number,
	filters: FiltersObject,
	search: SearchService<SalesComp>
) => {
	return {
		type: REQUEST_SALES,
		meta: {
			first: firstItemToRequest,
			last: lastItemToRequest,
			lowestLoaded: lowest,
			highestLoaded: highest,
			filters,
			id: incrementalId++,
		},
		payload: {
			promise: search.loadRange(firstItemToRequest, lastItemToRequest),
		},
	};
};

export const requestSales: SearchRequest<
	{ type: typeof REQUEST_SALES },
	SalesComp
> =
	(lowest, highest, sizeOfPage, firstLoaded, lastLoaded, filters, search) =>
	(dispatch) => {
		let needsToLoad = false;

		if (firstLoaded > 0 && lowest - firstLoaded < sizeOfPage) {
			needsToLoad = true;
		}

		if (lastLoaded - highest < sizeOfPage) {
			needsToLoad = true;
		}

		if (needsToLoad && search != null) {
			const firstItemToRequest = Math.max(0, lowest - sizeOfPage * 2);
			const lastItemToRequest = Math.min(
				search ? search.total : 0,
				highest + sizeOfPage * 2
			);

			dispatch(
				requestSalesInternal(
					firstItemToRequest,
					lastItemToRequest,
					lowest,
					highest,
					filters,
					search
				)
			);
		} else {
			console.warn('not loading');
		}
	};

const requestPropertiesInternal = (
	firstItemToRequest: number,
	lastItemToRequest: number,
	lowest: number,
	highest: number,
	filters: FiltersObject,
	search: SearchService<PropertyComp>
) => {
	return {
		type: REQUEST_PROPERTIES,
		meta: {
			first: firstItemToRequest,
			last: lastItemToRequest,
			lowestLoaded: lowest,
			highestLoaded: highest,
			filters,
			id: incrementalId++,
		},
		payload: {
			promise: search.loadRange(firstItemToRequest, lastItemToRequest),
		},
	};
};

export const requestProperties: SearchRequest<
	{
		type: typeof REQUEST_PROPERTIES;
	},
	PropertyComp
> =
	(lowest, highest, sizeOfPage, firstLoaded, lastLoaded, filters, search) =>
	(dispatch) => {
		let needsToLoad = false;

		if (firstLoaded > 0 && lowest - firstLoaded < sizeOfPage) {
			needsToLoad = true;
		}

		if (lastLoaded - highest < sizeOfPage) {
			needsToLoad = true;
		}

		if (needsToLoad && search != null) {
			const firstItemToRequest = Math.max(0, lowest - sizeOfPage * 2);
			const lastItemToRequest = Math.min(
				search.total,
				highest + sizeOfPage * 2
			);
			dispatch(
				requestPropertiesInternal(
					firstItemToRequest,
					lastItemToRequest,
					lowest,
					highest,
					filters,
					search
				)
			);
		} else {
			console.warn('not loading');
		}
	};

export function showTableSettingsMenu(
	targetButton: EventTarget,
	compType: CompType
) {
	return {
		type: MENU_SHOW,
		payload: {
			component: 'table-settings-menu',
			target: targetButton,
			position: 'below-onright',
			data: {
				compType: compType,
			},
		},
	};
}

export type PageActions = {
	reorderColumns: typeof reorderColumns;
	persistColumns: typeof persistColumns;
	loadFields: typeof loadFields;
	showTableSettingsMenu: typeof showTableSettingsMenu;
	applyColumnsPreset: typeof applyColumnsPreset;
	requestLeases: typeof requestLeases;
	requestSales: typeof requestSales;
	requestProperties: typeof requestProperties;
};

export type PageAction =
	| ReduxPromiseAction<ReturnType<typeof reorderColumns>>
	| ReduxPromiseAction<ReturnType<typeof persistColumns>>
	| ReduxPromiseAction<ReturnType<typeof loadFields>>
	| ReturnType<typeof showTableSettingsMenu>
	| ReduxPromiseAction<ReturnType<typeof applyPresetInternal>>
	| ReduxPromiseAction<ReturnType<typeof requestLeasesInternal>>
	| ReduxPromiseAction<ReturnType<typeof requestSalesInternal>>
	| ReduxPromiseAction<ReturnType<typeof requestPropertiesInternal>>;
