import factory from './factory';
import { SEARCH_MAX_LIMIT, SearchSalesPayload, SearchSalesResponse } from 'api';
import { Optional } from 'types/utils';

type SalePointsParams = Optional<
	SearchSalesPayload,
	'offset' | 'limit' | 'order' | 'sort'
>;

const salePointsService = factory.create<SalePointsParams, SearchSalesResponse>(
	{
		createKey: function (identifier) {
			return JSON.stringify(identifier);
		},
		createData: function (identifier) {
			const data = { ...identifier };
			data.offset = data.offset ?? 0;
			data.limit = data.limit ?? SEARCH_MAX_LIMIT;
			data.order = data.order ?? 'asc';
			data.sort = data.sort ?? 'saleDate';
			return data;
		},
		createUrl: function () {
			return '/api/salesComps/search';
		},
		cache: {
			storage: sessionStorage,
			ttl: 1000 * 60 * 60, // 1 hour
			namespace: 'sales-geo-points',
			maxSize: 1024 * 1024 * 3, // 3 MB
		},
	}
);

const exportable = salePointsService.exportable({});

export default exportable;
