import constate from 'constate';
import { useCallback, useEffect, useState } from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { routes } from './routes';

export const [HistoryProvider, useHistoryState] = constate(() => {
	const [historyStack, setHistoryStack] = useState<Location[]>([]);
	const navigate = useNavigate();

	const location = useLocation();

	useEffect(() => {
		setHistoryStack((s) => [...s, location]);
	}, [location]);

	const goBack = useCallback(() => {
		if (historyStack.length === 1) {
			navigate(routes.home.toHref());
		} else {
			navigate(-1);
		}
	}, [historyStack.length, navigate]);

	return { historyStack, goBack };
});
