import {
	PropertyInfoPropertyType,
	usePropertyByIdQuery,
	usePropertyInfoQuery,
} from 'api';
import { useIsMultifamily } from 'hooks';

type Props = {
	propertyId: number | undefined;
};

export const useCompsetType = ({ propertyId }: Props) => {
	const { data } = usePropertyInfoQuery({ propertyId });

	const { data: property } = usePropertyByIdQuery({ id: propertyId });

	const isMultifamily = useIsMultifamily({
		markets: property && [property.marketId],
	});

	if (!data || !property) return;

	const { propertyType, hasRpCompSetData } = data;

	if (
		isMultifamily &&
		(propertyType === PropertyInfoPropertyType.MULTIFAMILY ||
			propertyType === PropertyInfoPropertyType.MIXED_USE) &&
		hasRpCompSetData
	) {
		return CompsetType.REALPAGE;
	}

	return CompsetType.COMMERCIAL;
};

export enum CompsetType {
	COMMERCIAL = 'COMMERCIAL',
	REALPAGE = 'REALPAGE',
}
