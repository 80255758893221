import React, { ErrorInfo } from 'react';
import { csLogger } from 'util/log/logger';
import { stringifyErrorOrNull } from 'utils/error';

type Props = {
	children: React.ReactNode;
};

export class ErrorBoundary extends React.Component<
	Props,
	{ hasError: boolean }
> {
	constructor(props: Props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		csLogger.error({
			event: 'Main ErrorBoundary caught an error',
			extraInfo: {
				error: stringifyErrorOrNull(error),
				errorInfo,
			},
		});
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<>
					<h1>Something went wrong...</h1>
					Please try to{' '}
					<a href="#" onClick={() => document.location.reload()}>
						refresh the page
					</a>{' '}
					or{' '}
					<a
						href="#"
						onClick={() => {
							history.back();
						}}
					>
						go back
					</a>{' '}
					or <a href="/">go to the Home page</a>
				</>
			);
		}

		return this.props.children;
	}
}
