import { API, User } from '@compstak/common';
import { ReferenceData } from 'api/referenceData';

export const getSession = (sessionId: string) => {
	return API.get<GetSessionResponse>(`/api/sessions/${sessionId}`, {
		includeAuthHeader: false,
	});
};

export const createSession = (payload: CreateSessionPayload) => {
	return API.post<CreateSessionResponse, CreateSessionPayload>(
		'/api/sessions',
		payload,
		{
			includeAuthHeader: false,
		}
	);
};

export const deleteSession = (sessionId: string) => {
	return API.delete<void>(`/api/sessions/${sessionId}`);
};

type GetSessionResponse = {
	accessToken: string;
	endTime: number;
	id: string;
	refreshToken: string;
	startTime: number;
	ttl: number;
	userId: number;
};

export type CreateSessionPayload = {
	username: string;
	password: string;
	rememberMe: boolean;
	totpCode?: string;
};

export type CreateSessionResponse = {
	accessToken: string;
	attributeData: Record<string, any>[];
	id: string;
	referenceData: ReferenceData;
	refreshToken: string;
	user: User;
};
