import { API } from '@compstak/common';
import {
	QueryClient,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';
import { Portfolio } from '../../types';

const updatePortfolio = async (params: UpdatePortfolioParams) => {
	const res = await API.put<UpdatePortfolioResponse>(
		`/pa/v2/api/portfolios/${params.id}`,
		params.body
	);
	return res.data;
};

type Props = {
	onSuccess: (args: {
		data: UpdatePortfolioResponse;
		variables: UpdatePortfolioParams;
		queryClient: QueryClient;
	}) => void;
};

export const useUpdatePortfolioMutation = ({ onSuccess }: Props) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (params: UpdatePortfolioParams) => updatePortfolio(params),
		onSuccess: (data, variables) => {
			onSuccess({ data, variables, queryClient });
		},
	});
};

export type UpdatePortfolioParams = {
	id: number;
	body: {
		id: number;
		name: string;
		description: string | undefined;
		newPropertyIds?: number[];
		removedPropertyIds?: number[];
	};
};

type UpdatePortfolioResponse = Portfolio;
