import { User } from '@compstak/common';
import { Action } from 'redux';

type ChurnActionPayload = {
	name: string;
	description: string;
	count: number;
	extraData: object;
};

// can be any Action<string> actually, but it messes up the middleware typeguards
export type ChurnAction = Action<'CHURN_ZERO'> & {
	meta: {
		churnData: ChurnActionPayload;
	};
} & object;

//Initialize ChurnZero connection for enterprise Users
export function initializeChurnZero(user: User) {
	if (user.isEnterpriseClient && window.appConfig.churnZero) {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'ChurnZero' does not exist on type 'Windo... Remove this comment to see the full error message
		window.ChurnZero = window.ChurnZero || [];
		(function () {
			const cz = document.createElement('script');
			cz.type = 'text/javascript';
			cz.async = true;
			cz.src = 'https://analytics.churnzero.net/churnzero.js';
			const s = document.getElementsByTagName('script')[0];
			// @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
			s.parentNode.insertBefore(cz, s);
		})();

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'ChurnZero' does not exist on type 'Windo... Remove this comment to see the full error message
		window.ChurnZero.push([
			'setAppKey',

			window.appConfig.churnZero.accessToken,
		]);
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'ChurnZero' does not exist on type 'Windo... Remove this comment to see the full error message
		window.ChurnZero.push(['setContact', user.enterpriseId, user.email]);
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'ChurnZero' does not exist on type 'Windo... Remove this comment to see the full error message
		window.ChurnZero.push([
			'setAttribute',
			'contact',
			'Total Saved Searches',
			// @ts-ignore legacy code, the field doesn't exist in User
			user.savedSearchCount,
		]);
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'ChurnZero' does not exist on type 'Windo... Remove this comment to see the full error message
		window.ChurnZero.push([
			'setAttribute',
			'contact',
			'Total Saved Search Alerts',
			// @ts-ignore legacy code, the field doesn't exist in User
			user.savedSearchAlertCount,
		]);
	}
}

export function postChurnZeroEvent(churnObject: ChurnActionPayload) {
	// @ts-expect-error ts-migrate(2339) FIXME: Property 'ChurnZero' does not exist on type 'Windo... Remove this comment to see the full error message
	if (window.ChurnZero) {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'ChurnZero' does not exist on type 'Windo... Remove this comment to see the full error message
		window.ChurnZero.push([
			'trackEvent',
			churnObject.name,
			churnObject.description,
			churnObject.count,
			churnObject.extraData,
		]);
	}
}
