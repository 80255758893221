import { nullValueText } from '@compstak/common';
import { ReactNode } from 'react';

type Props<Value> = {
	value: Value;
	format?: (nonNullValue: NonNullable<Value>) => ReactNode;
	/** @default nullValueText */
	nullRender?: ReactNode;
};

export const renderSafeValue = <Value,>({
	value,
	format,
	nullRender = nullValueText,
}: Props<Value>) => {
	if (value == null) {
		return nullRender;
	}

	if (typeof format === 'function') {
		return format(value);
	}

	return value as any;
};
