import { useIsMultifamily } from 'hooks';
import { AveragesParams } from '../types';
import { useMufaPropertyAveragesQuery } from './useMufaPropertyAveragesQuery';
import { usePropertyAveragesQuery } from './usePropertyAveragesQuery';

type Props = AveragesParams & {
	marketIds: number[];
	enabled?: boolean;
};

export const usePropertyAverages = ({
	marketIds,
	enabled,
	...params
}: Props) => {
	const isMufa = useIsMultifamily({ markets: marketIds });

	const propertyAverages = usePropertyAveragesQuery(params, {
		enabled: enabled && !isMufa,
	});
	const mufaPropertyAverages = useMufaPropertyAveragesQuery(params, {
		enabled: enabled && isMufa,
	});

	return isMufa ? mufaPropertyAverages : propertyAverages;
};
