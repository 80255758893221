import {
	UPDATE_STACKING_PLAN,
	LOAD_STACKING_PLAN,
	STACKING_PLAN_UNDO,
	STACKING_PLAN_REDO,
	STACKING_PLAN_EDIT_SPACE,
	STACKING_PLAN_EDIT_FLOOR,
	SERVER_UPDATE_STACKING_PLAN,
	STACKING_PLAN_CLOSE_EDITOR,
} from './actions';

interface StackingPlanState {
	undos: any[];
	undoIndex: number;
	data: any;
	spaceBeingEdited: any;
	floorBeingEdited: any;
	spinnerIsUp: boolean;
	property: any;
}

const initialState: StackingPlanState = {
	undos: [],
	undoIndex: 0,
	data: {
		isLoading: true,
	},
	spaceBeingEdited: null,
	floorBeingEdited: null,
	spinnerIsUp: false,
	property: undefined,
};

// @ts-expect-error TS7006: Parameter 'action' implicitly ...
export function stackingPlanData(state: any = null, action) {
	switch (action.type) {
		case SERVER_UPDATE_STACKING_PLAN + '_FULFILLED':
		case UPDATE_STACKING_PLAN:
			if (action.payload.propertyId === state.propertyId) {
				return action.payload;
			}
			return state;

		case LOAD_STACKING_PLAN + '_PENDING':
			return {
				isLoading: true,
				propertyId: action.meta.propertyId,
			};

		case LOAD_STACKING_PLAN + '_REJECTED':
			if (action.meta.propertyId === state.propertyId) {
				return {
					...action.payload,
					isLoading: false,
					isError: true,
					propertyId: action.meta.propertyId,
				};
			}
			return state;

		case LOAD_STACKING_PLAN + '_FULFILLED':
			if (action.meta.propertyId === state.propertyId) {
				return action.payload.stackingPlan;
			}
			return state;

		default:
			return state;
	}
}

function updateUndos(state: StackingPlanState, data: any): any[] {
	const undos = [data, ...state.undos.slice(state.undoIndex)];
	return undos.length > 100 ? undos.slice(0, 100) : undos;
}

export default function reducer(
	prevState: StackingPlanState = initialState,
	// @ts-expect-error TS7006: Parameter 'action' implicitly ...
	action
): StackingPlanState {
	let state = prevState;
	const data = stackingPlanData(state.data, action);
	let undoIndex = 0;

	if (data !== state.data) {
		state = { ...state, data };
	}

	switch (action.type) {
		case UPDATE_STACKING_PLAN:
			if (!action.meta.requiresSpinner) {
				return {
					...state,
					undos: updateUndos(state, data),
					undoIndex: state.undoIndex > 0 ? state.undoIndex - 1 : undoIndex,
				};
			} else {
				return { ...state, spinnerIsUp: true };
			}

		case SERVER_UPDATE_STACKING_PLAN + '_FULFILLED':
			if (action.meta.requiresSpinner) {
				return {
					...state,
					undos: updateUndos(state, data),
					spinnerIsUp: false,
				};
			}
			return { ...state, spinnerIsUp: false };

		case SERVER_UPDATE_STACKING_PLAN + '_REJECTED':
			return { ...state, spinnerIsUp: false };

		case LOAD_STACKING_PLAN + '_PENDING':
		case LOAD_STACKING_PLAN + '_REJECTED':
			return {
				...state,
				spinnerIsUp: false,
				undos: [],
			};

		case LOAD_STACKING_PLAN + '_FULFILLED':
			return {
				...state,
				undos: [data],
				spaceBeingEdited: null,
				floorBeingEdited: null,
				spinnerIsUp: false,
				property: action.payload.property,
			};

		case STACKING_PLAN_UNDO:
			undoIndex = state.undoIndex + 1;
			if (undoIndex < state.undos.length) {
				return {
					...state,
					data: state.undos[undoIndex],
					undoIndex: undoIndex,
				};
			}
			return state;

		case STACKING_PLAN_REDO:
			undoIndex = state.undoIndex - 1;
			if (undoIndex >= 0) {
				return {
					...state,
					data: state.undos[undoIndex],
					undoIndex: undoIndex,
				};
			}
			return state;

		case STACKING_PLAN_EDIT_SPACE:
			return { ...state, spaceBeingEdited: action.payload };

		case STACKING_PLAN_EDIT_FLOOR:
			return { ...state, floorBeingEdited: action.payload };

		case STACKING_PLAN_CLOSE_EDITOR:
			return {
				...state,
				floorBeingEdited: null,
				spaceBeingEdited: null,
			};

		default:
			return state;
	}
}
