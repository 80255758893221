import { ServerFilterItem } from 'types/serverFilters';
import { SEARCH_MAX_LIMIT } from '../constants';
import { useSearchLeasesQuery } from './useSearchLeasesQuery';

type Props = {
	filters: ServerFilterItem[];
};

export const useAllSearchLeasesForMap = ({ filters }: Props) => {
	return useSearchLeasesQuery({
		filter: filters,
		offset: 0,
		limit: SEARCH_MAX_LIMIT,
		sort: 'tenantName',
		order: 'asc',
		properties: [
			'propertyId',
			'geoPoint',
			'buildingAddressAndCity',
			'opportunityZoneId',
			'submarket',
		],
	});
};
