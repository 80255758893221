type Options = {
	/** @default 2 */
	decimalPlaces?: number;
	/**
	 * Whether to shorten numbers over 1000 ( e.g. 1.1k )
	 * @default true */
	shorten?: boolean;
};

export const formatMoney = (value: number, options?: Options) => {
	const shorten = options?.shorten ?? true;
	// defaults
	let minFractionDigits = 2;
	let maxFractionDigits = 2;

	// use option if set
	if (options?.decimalPlaces != null) {
		minFractionDigits = options?.decimalPlaces;
		maxFractionDigits = options?.decimalPlaces;
	} else if ((value >= 100_000 && value < 1_000_000) || value >= 100_000_000) {
		minFractionDigits = 1;
		maxFractionDigits = 1;
	}

	return new Intl.NumberFormat('en-US', {
		currency: 'USD',
		style: 'currency',
		minimumFractionDigits: minFractionDigits,
		maximumFractionDigits: maxFractionDigits,
		notation: shorten ? 'compact' : 'standard',
	}).format(value);
};
