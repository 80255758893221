import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { createFetchFn } from 'api/utils';
import { PropertyLease } from 'api/propertyLeases';
import { SUBMARKET_RECENT_LEASES_LIMIT } from 'constants/limits';

const fetchSubmarketRecentLeases = createFetchFn<
	SubmarketRecentLeasesParams,
	SubmarketRecentLeasesResponse
>({
	method: 'get',
	getURL: ({ propertyId, limit }) =>
		`/properties/${propertyId}/submarket/recentLeases?limit=${
			limit ?? SUBMARKET_RECENT_LEASES_LIMIT
		}`,
});

export const useSubmarketRecentLeases = (
	params: SubmarketRecentLeasesParams
) => {
	return useQuery({
		queryKey: [QUERY_KEYS.SUBMARKET_RECENT_LEASES, params],
		queryFn: fetchSubmarketRecentLeases(params),
		enabled: Boolean(params.propertyId),
	});
};

type SubmarketRecentLeasesParams = {
	propertyId: number;
	limit?: number;
};

type SubmarketRecentLeasesResponse = {
	comps: PropertyLease[];
};
