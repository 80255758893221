import { createContext, ReactNode, useContext, useState } from 'react';
import { NAV_KEY } from './constants';
import { NavHeader, NavHeaderProps } from './NavHeader';
import {
	CreateNavHeaderArgs,
	NavHeaderContextValue,
	NavHeaderDataContextValue,
	NavHeaderProviderState,
	UseNavHeaderDataHook,
} from './types';
import { useLocation } from 'react-router';

export const createNavHeader = <Item, Args>({
	createUseNavDataHook,
	getItemPath,
	itemLabel,
}: CreateNavHeaderArgs<Item, Args>) => {
	const context = createContext({} as NavHeaderContextValue<Item, Args>);
	const dataContext = createContext({} as NavHeaderDataContextValue<Item>);

	const NavHeaderProvider = ({ children }: { children: ReactNode }) => {
		const [state, setState] = useState<NavHeaderProviderState<Item>>();

		const initNavHeader = (initialIndex: number, args: Args) => {
			setState({
				initialIndex,
				useNavData: createUseNavDataHook(args),
			});
		};

		const location = useLocation();

		const getIsNavHeaderActive = () => {
			return state != null && location.state?.[NAV_KEY];
		};

		let toRender = children;

		if (state != null) {
			toRender = (
				<NavHeaderDataProvider
					key={state.initialIndex}
					useNavData={state.useNavData}
					initialIndex={state.initialIndex}
				>
					{toRender}
				</NavHeaderDataProvider>
			);
		}

		return (
			<context.Provider
				value={{
					initNavHeader,
					getIsNavHeaderActive,
					getItemPath,
				}}
			>
				<dataContext.Provider
					value={dataContextValueStub as NavHeaderDataContextValue<Item>}
				>
					{toRender}
				</dataContext.Provider>
			</context.Provider>
		);
	};

	const NavHeaderDataProvider = ({
		useNavData,
		initialIndex,
		children,
	}: NavDataProviderProps<Item>) => {
		const navData = useNavData({ initialIndex: initialIndex });

		return (
			<dataContext.Provider value={navData}>{children}</dataContext.Provider>
		);
	};

	const useNavHeaderContext = () => {
		const value = useContext(context);
		const navData = useContext(dataContext);

		return {
			...value,
			navData,
		};
	};

	const NavHeaderContainer = (
		props: Omit<NavHeaderProps<Item, Args>, 'useNavHeaderContext' | 'itemLabel'>
	) => {
		const { navData } = useNavHeaderContext();

		if (Object.keys(navData).length === 0) return null;

		return (
			<NavHeader
				{...props}
				itemLabel={itemLabel}
				useNavHeaderContext={useNavHeaderContext}
			/>
		);
	};

	return {
		NavHeaderProvider,
		useNavHeaderContext,
		NavHeader: NavHeaderContainer,
	};
};

const dataContextValueStub = {};

type NavDataProviderProps<Item> = {
	useNavData: UseNavHeaderDataHook<Item>;
	initialIndex: number;
	children: ReactNode;
};
