import { buildQuery } from '@compstak/common';
import { MarketRentResponse } from '@compstak/fe-react-api-layer';
import { UseQueryOptions } from '@tanstack/react-query';
import { FiltersObject } from 'models/filters/types';
import { useMemo } from 'react';
import { QUERY_KEYS } from '../constants';
import {
	ExchangeDashboardServerFilterItem,
	MarketRentAnalyticsRentType,
} from '../types';
import {
	createFetchFn,
	useQueryHook,
	createFiltersParamWithMarketId,
} from '../utils';

export const fetchMarketRentAnalyticsDashboard = createFetchFn<
	MarketRentAnalyticsParams,
	MarketRentResponse
>({
	method: 'post',
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	getURL: ({ filters, ...queryParams }) => {
		const query = buildQuery(queryParams);

		return `/api/dashboard/marketRent?${query}`;
	},
	getBody: ({ filters }) => ({ filters }),
});

type MarketRentAnalyticsParamsArgs = Omit<
	MarketRentAnalyticsParams,
	'filters'
> & {
	filters: FiltersObject;
};

export const useMarketRentAnalyticsDashboardQuery = (
	params: MarketRentAnalyticsParamsArgs,
	options?: UseQueryOptions<MarketRentResponse>
) => {
	const { filters: paramsFilters, ...otherParams } = params;

	const filters = useMemo(() => {
		return createFiltersParamWithMarketId(paramsFilters);
	}, [paramsFilters]);

	return useQueryHook({
		queryKey: [
			QUERY_KEYS.MARKET_RENT_ANALYTICS_DASHBOARD,
			filters,
			otherParams,
		],
		queryFn: fetchMarketRentAnalyticsDashboard({ ...otherParams, filters }),
		...options,
	});
};

type MarketRentAnalyticsParams = {
	rentType: MarketRentAnalyticsRentType;
	propertyType: MarketRentAnalyticsDashboardPropertyType;
	monthly: boolean;
	submarket: boolean;
	filters: ExchangeDashboardServerFilterItem[];
};

export enum MarketRentAnalyticsDashboardPropertyType {
	OFFICE = 'office',
	INDUSTRIAL = 'industrial',
}
