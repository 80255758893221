import distance from '@turf/distance';
import { PropertyComp } from 'types';

export const getDistanceBetweenProperties = (
	property1: PropertyComp,
	property2: PropertyComp
) => {
	const { lat: subjetPropertyLat, lon: subjectPropertyLon } =
		property1.geoPoint;

	const { lat, lon } = property2.geoPoint;

	return distance([subjectPropertyLon, subjetPropertyLat], [lon, lat], {
		units: 'miles',
	});
};
