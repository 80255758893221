export const parseHref = (href: string) => {
	const a = document.createElement('a');
	a.href = href;

	return {
		pathname: a.pathname,
		search: a.search,
		hash: a.hash,
	};
};
