import React, { Component } from 'react';

import { ContentEditableV2 } from './ContentEditable';

import EditIcon from '../../svg_icons/edit.svg';
import TickIcon from '../../svg_icons/tick.svg';

import './editable.nomodule.css';

type Props = {
	className?: string;
	leaseId: number;
	multiline?: boolean;
	name: string;
	noStyle?: boolean;
	onBlur?: Function;
	onFocus?: Function;
	placeholder?: string;
	section: string;
	updateValue: (...fields: any[]) => void;
};

type State = {
	editing: boolean;
	hidden: boolean;
	value: string;
};

export default class Editable extends Component<Props, State> {
	contentEditableEl = React.createRef<HTMLDivElement | null>();

	// @ts-expect-error TS7006: Parameter 'props' implicitly h...
	constructor(props) {
		super(props);

		this.state = {
			editing: false,
			hidden: false,
			// @ts-expect-error TS2339: Property 'children' does not e...
			value: this.props.children || '',
		};
	}

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	handleKeyPress = (event) => {
		if (event.keyCode === 13) {
			if (!this.props.multiline) {
				event.preventDefault();
				this.blurEdit();
			}
		}
	};

	componentDidMount() {
		if (this.contentEditableEl.current) {
			this.contentEditableEl.current?.addEventListener(
				'keydown',
				this.handleKeyPress,
				true
			);
		}
	}

	componentWillUnmount() {
		if (this.contentEditableEl.current) {
			this.contentEditableEl.current?.removeEventListener(
				'keydown',
				this.handleKeyPress,
				true
			);
		}
	}

	focusEdit = () => {
		this.contentEditableEl.current?.focus();
		this.setState({
			editing: true,
		});

		if (this.props.onFocus) {
			this.props.onFocus();
		}
	};

	blurEdit = () => {
		this.contentEditableEl.current?.blur();
		this.setState({
			editing: false,
		});

		if (this.props.onBlur) {
			this.props.onBlur();
		}
	};

	updateValue = (value: string) => {
		value = value
			.replace(/&nbsp;/g, ' ')
			.replace(/<br>$/, '')
			.trim();
		this.setState({
			value,
		});

		this.props.updateValue?.(
			this.props.name,
			value,
			this.props.leaseId,
			this.props.section
		);
	};

	className() {
		let className = this.props.noStyle
			? this.props.className
			: 'editable ' + this.props.className;

		if (this.state.editing) {
			className += ' editing';
		}

		if (this.state.hidden) {
			className += ' editable-hidden';
		}

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'useEmptyClass' does not exist on type 'R... Remove this comment to see the full error message
		if (this.state.value.length === 0 && this.props.useEmptyClass) {
			className += ' empty';
		}

		return className;
	}

	render() {
		return (
			<div className={this.className()}>
				<ContentEditableV2
					data-qa-id="Editable-ContentEditableV2"
					ref={this.contentEditableEl}
					spellCheck="false"
					// @ts-expect-error TS2339: Property 'children' does not e...
					value={this.props.children}
					onFocus={this.focusEdit}
					onBlur={this.blurEdit}
					onChange={this.updateValue}
					placeholder={this.props.placeholder}
				/>
				<div className="edit-icon">
					{this.state.editing ? (
						<TickIcon onClick={this.blurEdit} />
					) : (
						<EditIcon onClick={this.focusEdit} />
					)}
				</div>
			</div>
		);
	}
}
