import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { SearchPropertiesAverages } from '../searchProperties';
import { AveragesParams } from '../types';
import { composeEnabled, createFetchFn } from '../utils';

const getPropertyAverages = createFetchFn<
	AveragesParams,
	MufaPropertyAveragesResponse
>({
	method: 'post',
	getURL: () => `/api/mufa/properties/actions/search/averages`,
	getBody: (params) => params,
});

export const useMufaPropertyAveragesQuery = (
	params: AveragesParams,
	options?: UseQueryOptions<MufaPropertyAveragesResponse>
) => {
	return useQuery({
		queryKey: [QUERY_KEYS.MUFA_PROPERTY_AVERAGES, params],
		queryFn: getPropertyAverages(params),
		enabled: composeEnabled(params.ids != null, options?.enabled),
		...options,
	});
};

export type MufaPropertyAveragesResponse = SearchPropertiesAverages & {
	withLeaseDataCount: number;
	withRpDataCount: number;
};
