import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants';
import { PortfolioMarket } from '../../types';
import { createFetchFn } from '../../utils';

const fetchAllPortfolios = createFetchFn<void, AllPortfoliosResponse>({
	method: 'get',
	getURL: () => `/pa/v2/api/portfolios`,
});

export const useAllPortfoliosQuery = () =>
	useQuery({
		queryKey: [QUERY_KEYS.ALL_PORTFOLIOS],
		queryFn: fetchAllPortfolios(),
	});

export type AllPortfoliosResponse = PortfolioShort[];

export type PortfolioShort = {
	id: number;
	name: string;
	description?: string;
	dateCreated: string;
	lastUpdated?: string;
	markets: PortfolioMarket[];
};
