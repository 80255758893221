import { removeFeedback } from 'Singletons/Feedback/actions';
import * as modalActions from 'Singletons/Modal/actions';

import exportLimitExceeded from 'Components/Modals/ExportLimitExceeded';
import { Comp, CompType } from 'types/comp';
import { User } from '@compstak/common';

import * as leaseExportService from 'services/leaseExport';
import * as saleExportService from 'services/saleExport';
import * as propertyExportService from 'services/propertyExport';

export const EXPORT_LEASES = 'EXPORT_LEASES' as const;
export const CHECK_LEASES = 'CHECK_LEASES' as const;
export const PREPARE_LEASES = 'PREPARE_LEASES' as const;
export const EXPORT_CUSTOM_LEASES = 'EXPORT_CUSTOM_LEASES' as const;
export const HIDE_CUSTOM_EXPORT_FIELD = 'HIDE_CUSTOM_EXPORT_FIELD' as const;
export const SHOW_CUSTOM_EXPORT_FIELD = 'SHOW_CUSTOM_EXPORT_FIELD' as const;
export const UPDATE_CUSTOM_EXPORT_VALUE = 'UPDATE_CUSTOM_EXPORT_VALUE' as const;
export const UPDATE_CUSTOM_EXPORT_METADATA =
	'UPDATE_CUSTOM_EXPORT_METADATA' as const;
export const RESTORE_EXPORT = 'RESTORE_EXPORT' as const;
export const CLEAR_CUSTOM_EXPORT = 'CLEAR_CUSTOM_EXPORT' as const;

import marketService from 'services/markets';
import leaseService from 'services/lease';
import salesService from 'services/sale';
import propertyService from 'services/property';
import { isMultifamily } from 'util/isMultifamily';
import { AppDispatch, AppGetState } from 'store';
import pluralizeCompType from 'util/pluralizeCompType';
import { ExportComponentCustomProps } from 'Components/Menus/Export';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { ReduxPromiseAction } from '../types/redux-promise-middleware';
import { QueryClient } from '@tanstack/react-query';

const compService = {
	lease: leaseService,
	sale: salesService,
	property: propertyService,
};

const exportServices = {
	lease: leaseExportService,
	sale: saleExportService,
	property: propertyExportService,
};

export function exportComps({
	compIds,
	compType,
	exportType,
	monthly,
	withFinancial,
	queryClient,
}: {
	compIds: number[];
	compType: CompType;
	exportType: string;
	monthly: boolean;
	withFinancial: boolean;
	queryClient: QueryClient;
}) {
	return (dispatch: AppDispatch, getState: AppGetState) => {
		const store = getState();

		const permissions = store.permissions;
		const markets = getFiltersMarkets(store.filtersV2.main);

		const exportPromise =
			compType === 'property'
				? propertyExportService.exportComps({
						compIds,
						monthly,
						useMultifamily: isMultifamily({
							markets,
							permissions,
						}),
						queryClient,
					})
				: exportServices[compType].exportComps({
						exportType,
						compIds,
						monthly,
						withFinancial,
						queryClient,
					});

		const salesExport = {
			name: 'Sales Export',
			description: 'User exported sales comp(s)',
			count: compIds,
			extraData: {},
		};

		const leaseExport = {
			name: 'Lease Export',
			description: 'User exported lease comp(s)',
			count: compIds,
			extraData: {},
		};

		const propertyExport = {
			name: 'Property Export',
			description: 'User exported property/properties',
			count: compIds,
			extraData: {},
		};

		const churnDataMap = {
			lease: leaseExport,
			sale: salesExport,
			property: propertyExport,
		};

		const isEnterpriseTrialUser = getState().user?.enterpriseTrialData;
		if (isEnterpriseTrialUser) {
			exportPromise.catch(() => {
				dispatch(modalActions.pushModal(exportLimitExceeded));
			});
		}

		dispatch({
			type: EXPORT_LEASES,
			meta: {
				updateUser: true,
				ids: compIds,
				type: exportType,
				churnData: churnDataMap[compType],
				feedback: {
					pending: 'Exporting Comps...',
					fulfilled: 'Comps are downloading.',
					rejected: isEnterpriseTrialUser
						? false
						: 'We’re sorry, but you’ve exceeded your monthly export limit.',
				},
			},
			payload: {
				promise: exportPromise,
			},
		});
	};
}

export function checkComps(leaseIds: number[], compType: CompType) {
	const ids = leaseIds;

	const leaseIdNumbers = ids.map((id) => parseInt(String(id), 10));

	return {
		type: CHECK_LEASES,
		meta: {
			ids,
		},
		payload: {
			promise: exportServices[compType]
				.check(leaseIdNumbers)
				.then(function (data) {
					return data;
				}),
		},
	};
}

export function closeMenu() {
	return {
		type: 'MENU_HIDE',
	};
}

export function showExportMenu(
	comps: Array<Comp>,
	compType: CompType,
	targetButton: HTMLElement,
	location = 'onright',
	customData: ExportComponentCustomProps = {},
	className = ''
) {
	return {
		type: 'MENU_SHOW',
		payload: {
			component: 'export-menu',
			target: targetButton,
			position: location,
			className: className,
			data: {
				closeMenu,
				comps: comps,
				compType: compType,
				...customData,
			},
		},
	};
}

type CustomExportAction = {
	type: typeof PREPARE_LEASES;
	meta: {
		leaseIds: Array<number>;
		preparedBy: string;
		compType: CompType;
	};
	payload: {
		promise: Promise<any>;
	};
};

export function prepareCustomExport({
	compIds,
	user,
	compType,
}: {
	compIds: number[];
	user: User;
	compType: CompType;
}): CustomExportAction {
	const preparedBy = `${user.firstName} ${user.lastName}<br />${
		user.email
	}<br />${user.phone ? user.phone : ''}`;
	const compsPromise = compService[compType].loadMany(compIds);

	const marketPromise = compsPromise.then((comps) => {
		return marketService.load(comps[0].marketId);
	});

	const promise = Promise.all([compsPromise, marketPromise]).then(
		([comps, marketResponse]) => {
			return {
				comps,
				market: marketResponse,
			};
		}
	);

	return {
		type: PREPARE_LEASES,
		meta: {
			leaseIds: compIds,
			preparedBy: preparedBy,
			compType: compType,
		},
		payload: {
			promise,
		},
	};
}

type UpdateCustomExportAction = {
	type: typeof UPDATE_CUSTOM_EXPORT_VALUE;
	payload: {
		leaseId: number;
		name: string;
		value: string;
		section: string;
	};
};

export function updateCustomExportValue(
	name: string,
	value: string,
	leaseId: number,
	// @ts-expect-error TS7006: Parameter 'section' implicitly...
	section
): UpdateCustomExportAction {
	return {
		type: UPDATE_CUSTOM_EXPORT_VALUE,
		payload: {
			leaseId: leaseId,
			name: name,
			value: value,
			section: section,
		},
	};
}

type UpdateCustomExportMetaAction = {
	type: typeof UPDATE_CUSTOM_EXPORT_METADATA;
	payload: {
		name: string;
		value: string;
	};
};

export function updateCustomExportMetaData(
	name: string,
	value: string
): UpdateCustomExportMetaAction {
	return {
		type: UPDATE_CUSTOM_EXPORT_METADATA,
		payload: {
			name: name,
			value: value,
		},
	};
}

type HideCustomExportAction = {
	type: typeof HIDE_CUSTOM_EXPORT_FIELD;
	payload: { name: string };
};

export function hideCustomExportField(name: string): HideCustomExportAction {
	return {
		type: HIDE_CUSTOM_EXPORT_FIELD,
		payload: {
			name: name,
		},
	};
}

type ShowCustomExportAction = {
	type: typeof SHOW_CUSTOM_EXPORT_FIELD;
	payload: {
		name: string;
	};
};

export function showCustomExportField(name: string): ShowCustomExportAction {
	return {
		type: SHOW_CUSTOM_EXPORT_FIELD,
		payload: {
			name: name,
		},
	};
}

// @ts-expect-error TS7006: Parameter 'exportData' implici...
export function restoreExport(exportData) {
	return {
		type: RESTORE_EXPORT,
		payload: exportData,
	};
}

export function exportCustomData(
	// @ts-expect-error TS7006: Parameter 'data' implicitly ha...
	data,
	// @ts-expect-error TS7006: Parameter 'showLineItemComps' ...
	showLineItemComps,
	// @ts-expect-error TS7006: Parameter 'showIndividualComps...
	showIndividualComps,
	compType: CompType,
	queryClient: QueryClient
) {
	const json = Object.assign(
		{},
		{
			showLineItemComps: showLineItemComps,
			showIndividualComps: showIndividualComps,
		},
		data
	);

	const leaseIds = [
		// @ts-expect-error TS7006: Parameter 'lease' implicitly h...
		...new Set(json.leases.map((lease) => parseInt(lease.id, 10))),
	];

	// @ts-expect-error TS7006: Parameter 'alertId' implicitly...
	const restore = (alertId) => (dispatch) => {
		dispatch(restoreExport(data));
		dispatch(removeFeedback(alertId));
	};
	const compTypePlural = pluralizeCompType(compType, false);

	return {
		type: EXPORT_CUSTOM_LEASES,
		meta: {
			feedback: {
				pending: 'Exporting ' + json.title,
				rejected: {
					duration: 0,
					// @ts-expect-error TS7006: Parameter 'xhr' implicitly has...
					message: (xhr) => {
						try {
							return JSON.parse(xhr.response).error.replace(
								'assertion failed: ',
								''
							);
						} catch (e) {
							return 'There was an unknown error trying to export.';
						}
					},
					buttons: [
						{
							text: 'Reopen Editor',
							link: {
								href: `/export/${compTypePlural}/${leaseIds.join(',')}`,
								'data-modal': true,
							},
							action: restore,
						},
						{
							text: 'Dismiss',
							// @ts-expect-error TS7006: Parameter 'alertId' implicitly...
							action: (alertId) => removeFeedback(alertId),
						},
					],
				},
				fulfilled: {
					duration: 0,
					message: `Export of ${json.title} complete.`,
					buttons: [
						{
							text: 'Make Changes',
							link: {
								href: `/export/${compTypePlural}/${leaseIds.join(',')}`,
								'data-modal': true,
							},
							action: restore,
						},
						{
							text: 'Dismiss',
							// @ts-expect-error TS7006: Parameter 'alertId' implicitly...
							action: (alertId) => removeFeedback(alertId),
						},
					],
				},
			},
		},
		payload: {
			promise: exportServices[compType]

				.exportComps({
					exportType: 'pdf',
					// @ts-expect-error TS2322: Type 'unknown[]' is not assign...
					compIds: leaseIds,
					customData: json,
					queryClient,
				})
				.then(function (response) {
					return response;
				}),
		},
	};
}

export function clearCustomData() {
	return {
		type: CLEAR_CUSTOM_EXPORT,
	};
}

export type ExportAction =
	| ReduxPromiseAction<ReturnType<typeof checkComps>>
	| UpdateCustomExportAction
	| UpdateCustomExportMetaAction
	| ShowCustomExportAction
	| HideCustomExportAction
	| ReturnType<typeof clearCustomData>
	| ReturnType<typeof restoreExport>;

export const exportActions = {
	exportComps,
	clearCustomData,
	exportCustomData,
	hideCustomExportField,
	prepareCustomExport,
	showCustomExportField,
	updateCustomExportMetaData,
	updateCustomExportValue,
	restoreExport,
};

export type ExportActions = typeof exportActions;
