import { QUERY_KEYS } from 'api/constants';
import factory from './factory';
import leaseService from './lease';

// @ts-expect-error TS7006: Parameter 'exportParams' impli...
export const exportComps = (exportParams) => {
	const {
		exportType,
		compIds,
		monthly,
		newWindow,
		customData,
		withFinancial,
		queryClient,
	} = exportParams;
	const windowId = Math.random();

	// this window will be posted to
	if (exportType === 'pdf' && newWindow) {
		// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
		window.open('about:blank', windowId);
	}

	return factory
		.post('/api/comps/actions/export', {
			format: exportType,
			compIds,
			monthly,
			withFinancial,
		})
		.then(function (data) {
			queryClient.invalidateQueries([QUERY_KEYS.CHECK_LEASES_EXPORT_STATUS]);

			// @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
			if (exportType === 'xls' && data.exportId) {
				window.location.href =
					'/api/comps/actions/export/' +
					// @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
					data.exportId +
					'?canIncludeMarket=true';
			}
			if (exportType === 'pdf') {
				// @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
				const checkIds = data.comps.comps.map((lease) => lease.id);
				// @ts-expect-error TS7006: Parameter 'id' implicitly has ...
				checkIds.forEach((id) => {
					const idIndex = compIds.indexOf(id);
					if (idIndex !== -1) {
						compIds.splice(idIndex, 1);
					}
				});

				if (compIds.length > 0) {
					throw new Error(
						'Leases requested from server are inconsistent with export.'
					);
				}

				const form = document.createElement('form');
				form.method = 'POST';
				form.action = '/api/pdf-gen/comps/';
				if (newWindow) {
					// @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'string'.
					form.target = windowId;
				}
				const input = document.createElement('input');
				input.type = 'hidden';
				input.name = 'jsonData';
				if (customData) {
					input.value = JSON.stringify({
						data: customData,
						directDownload: !newWindow,
						compType: 'lease',
					});
				} else {
					input.value = JSON.stringify({
						compIds: checkIds,
						directDownload: !newWindow,
						compType: 'lease',
					});
				}
				form.appendChild(input);
				document.body.appendChild(form);
				form.submit();

				setTimeout(() => {
					document.body.removeChild(form);
				}, 1000);
			}
			// @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
			data.exportType = exportType;
			return data;
		});
};

// @ts-expect-error TS7006: Parameter 'compIds' implicitly...
export const check = (compIds) => {
	return factory
		.post('/api/comps/actions/checkExportStatus', {
			format: 'xls',
			compIds: compIds,
		})
		.then(function (data) {
			return leaseService.load(compIds[0]).then((lease) => {
				// @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
				data.market = lease.market;
				// @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
				data.marketId = lease.marketId;
				return data;
			});
		});
};
