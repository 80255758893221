import { UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { createFetchFn, useQueryHook } from 'api/utils';
import { ServerFilterItem } from 'types/serverFilters';

const fetchLeaseExpirationData = createFetchFn<
	LeaseExpirationDataParams,
	LeaseExpirationResponse
>({
	method: 'post',
	getURL: (params) =>
		`/api/comps/analytics/expiringLease?expireInMonths=${params.expireInMonths}`,
	getBody: ({ filters }) => ({ filters }),
});

export const useLeaseExpirationQuery = (
	params: LeaseExpirationDataParams,
	options?: UseQueryOptions<LeaseExpirationResponse>
) => {
	return useQueryHook({
		queryKey: [QUERY_KEYS.LEASE_EXPIRATION, params],
		queryFn: fetchLeaseExpirationData(params),
		...options,
	});
};

type LeaseExpirationDataParams = {
	filters: ServerFilterItem<any>[];
	maxBySqft: boolean;
	expireInMonths: number;
};

export type LeaseExpirationDataPoint = {
	latitude: number;
	leaseCount: number;
	longitude: number;
	propertyId: number;
	transactionSize: number;
};

export type LeaseExpirationResponse = LeaseExpirationDataPoint[];
