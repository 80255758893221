import { useInfiniteQuery } from '@tanstack/react-query';
import { QUERY_KEYS, SearchSalesResponse } from 'api';
import { SearchInfiniteParams, createInfiniteSearchFetchFn } from '../utils';

const fetchInfiniteSales = createInfiniteSearchFetchFn<SearchSalesResponse>({
	url: '/api/salesComps/search',
	getResponseSize: (res) => res.comps.length,
});

export const useSearchSalesInfiniteQuery = (params: SearchInfiniteParams) => {
	return useInfiniteQuery({
		queryKey: createSearchSalesInfiniteQueryKey(params),
		queryFn: ({ pageParam = 0, signal }) =>
			fetchInfiniteSales({ ...params, pageParam, signal }),
		getNextPageParam: (last) => {
			if (!last.hasMore) return;
			return last.pageParam + 1;
		},
	});
};

export const createSearchSalesInfiniteQueryKey = (
	params: SearchInfiniteParams
) => {
	return [QUERY_KEYS.SEARCH_SALES_INFINITE, params];
};
