import cloneDeep from 'lodash/cloneDeep';

import userService from 'services/user';
import searchService from 'services/savedSearches';

import {
	BOOTSTRAP,
	createBootstrapPromise,
	loadUser,
	updateUser,
	USER_LOAD,
} from 'Pages/Login/actions';
import { addFeedback } from 'Singletons/Feedback/actions';
import { Market } from '@compstak/common';
import { User, UserUpdateRequest } from '@compstak/common';

export const SET_MARKET = 'SET_MARKET';
export const REQUEST_DEMO = 'REQUEST_DEMO';
export const REFER_A_COLLEAGUE = 'REFER_A_COLLEAGUE';
export const SEND_SAVED_SEARCH = 'SEND_SAVED_SEARCH';

/**
 * Change user preferences markets
 *   - multiMarketMode @default true
 */
export function setMarkets(
	user: User,
	markets: Market[],
	multiMarketMode = true
) {
	user = cloneDeep(user);

	if (multiMarketMode) {
		user.preferences.currentMarketIds = markets.map((market) => market.id);
	} else {
		user.preferences.currentMarketId = markets[0].id;
	}

	return {
		type: SET_MARKET,
		meta: {
			updateUser: true,
			churnData: {
				name: 'Markets Viewed',
				description: 'User viewed market',
				count: 1,
			},
		},
		payload: {
			promise: userService.savePreferences(user),
		},
	};
}

type SetTrialMarket = {
	type: typeof BOOTSTRAP;
	payload: {
		// @ts-expect-error ts-migrate(2314) FIXME: Generic type 'Promise<T>' requires 1 type argument... Remove this comment to see the full error message
		promise: Promise;
	};
};

export function setTrialMarket(marketId: number): SetTrialMarket {
	return {
		type: BOOTSTRAP,
		payload: {
			promise: userService
				.setTrialMarket(marketId)
				// @ts-expect-error TS2345: Argument of type '(payload: st...
				.then(userService.load)
				.then((user) => {
					userService.clearAll();
					user = cloneDeep(user);
					user.preferences.currentMarketId = marketId;
					return userService.savePreferences(user);
				})
				.then(createBootstrapPromise),
		},
	};
}

type RequestDemoAction = {
	type: typeof REQUEST_DEMO;
	payload: {
		usernameOrEmail: string;
		origin: string;
	};
};

export function requestDemo(
	usernameOrEmail: string,
	origin: string,
	uuid: string | null
): RequestDemoAction {
	// @ts-expect-error TS2345: Argument of type 'string | nul...
	userService.requestDemo(usernameOrEmail, origin, uuid);
	return {
		type: REQUEST_DEMO,
		payload: {
			usernameOrEmail,
			origin,
		},
	};
}

export function sendSearch(searchId: string, emails: string[]) {
	return {
		type: SEND_SAVED_SEARCH,
		promise: searchService.sendSearch(searchId, emails),
	};
}

// @ts-expect-error TS7006: Parameter 'originalUser' impli...
export const savePreferences = (originalUser, newPreferences) => (dispatch) => {
	const user = cloneDeep(originalUser);
	user.preferences = {
		...user.preferences,
		...newPreferences,
	};

	dispatch(updateUser(user));

	userService.savePreferences(user).catch((error) => {
		dispatch(
			addFeedback(
				'Sorry, we encountered a problem updating your preferences. We saved what we could and reverted the rest.'
			)
		);
		dispatch(loadUser());
		console.error('Error saving user', error);
	});
};

type UpdateUserAction = {
	type: typeof USER_LOAD;
	meta: {
		feedback: {
			pending: string;
			fulfilled: string;
			rejected: Function;
		};
	};
	payload: {
		// @ts-expect-error ts-migrate(2314) FIXME: Generic type 'Promise<T>' requires 1 type argument... Remove this comment to see the full error message
		promise: Promise;
	};
};

export const changePassword = (
	user: User,
	oldPassword: string,
	newPassword: string
): UpdateUserAction => {
	return {
		type: USER_LOAD,
		meta: {
			feedback: {
				pending: 'Changing your password.',
				fulfilled: 'Password change successful.',
				// @ts-expect-error TS7006: Parameter 'error' implicitly h...
				rejected: (error) => error.response,
			},
		},
		payload: {
			promise: userService.changePassword(user, oldPassword, newPassword),
		},
	};
};

export const updateUserInfo = (
	user: User,
	updateUserRequest: UserUpdateRequest
): UpdateUserAction => {
	return {
		type: USER_LOAD,
		meta: {
			feedback: {
				pending: 'Updating your account info.',
				fulfilled: 'Account info change successful.',
				// @ts-expect-error TS7006: Parameter 'error' implicitly h...
				rejected: (error) => error.response,
			},
		},
		payload: {
			promise: userService.updateUserInfo(user, updateUserRequest),
		},
	};
};

export type UserAction =
	| SetTrialMarket
	| UpdateUserAction
	| RequestDemoAction;

export const userActions = {
	setMarkets,
	setTrialMarket,
	requestDemo,
	sendSearch,
	savePreferences,
	changePassword,
	updateUserInfo,
};

export type UserActions = typeof userActions;
