import React from 'react';

export default function NoteItem({
	// @ts-expect-error TS7031: Binding element 'editable' imp...
	editable,
	// @ts-expect-error TS7031: Binding element 'children' imp...
	children,
	// @ts-expect-error TS7031: Binding element 'hiddenFields'...
	hiddenFields,
	// @ts-expect-error TS7031: Binding element 'field' implic...
	field,
	// @ts-expect-error TS7031: Binding element 'hasValue' imp...
	hasValue,
}) {
	if (editable) {
		return <li>{children}</li>;
	} else if (hiddenFields && hiddenFields.includes(field)) {
		return <li className="hidden" />;
	} else if (!hasValue) {
		return <li className="hidden" />;
	} else {
		return <li>{children}</li>;
	}
}
