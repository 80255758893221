import { API } from '@compstak/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants';
import { Portfolio } from '../../types';

const createPortfolio = async (params: CreatePortfolioParams) => {
	const res = await API.post<Portfolio>('/pa/v2/api/portfolios', params);
	return res.data;
};

export const useCreatePortfolioMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (params: CreatePortfolioParams) => createPortfolio(params),
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.ALL_PORTFOLIOS]);
		},
	});
};

export type CreatePortfolioParams = {
	name: string;
	description?: string;
	propertyIds?: number[];
};
