import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { createFetchFn } from '../utils';
import { SearchLeasesPayload, SearchLeasesResponse } from './types';

export const fetchSearchLeases = createFetchFn<
	SearchLeasesPayload,
	SearchLeasesResponse
>({
	method: 'post',
	getURL: () => `/api/comps/actions/search`,
	getBody: (params) => params,
});

export const useSearchLeasesQuery = (
	params: SearchLeasesPayload,
	options?: UseQueryOptions<SearchLeasesResponse>
) =>
	useQuery({
		queryKey: [QUERY_KEYS.SEARCH_LEASES, params],
		queryFn: fetchSearchLeases(params),
		...options,
	});
