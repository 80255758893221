import { UNLOCK_LEASES } from 'actions/lease';
import { UNLOCK_SALES } from 'actions/sale';
import produce from 'immer';
import { keyBy, uniq, without } from 'lodash';
import { useSelector } from 'react-redux';
import { LeaseComp, SalesComp } from 'types/comp';
import { AppState } from './root';

const initialState: number[] = [];

const unlockReducer = (state = initialState, action = {}) => {
	// @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type '{}'.
	switch (action.type) {
		case UNLOCK_LEASES + '_PENDING':
		case UNLOCK_SALES + '_PENDING':
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type '{}'.
			state = [].concat(state, action.meta.ids);
			state = uniq(state);
			break;

		case UNLOCK_LEASES + '_FULFILLED':
		case UNLOCK_SALES + '_FULFILLED':
			// @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
			state = without(state, ...action.payload.map((comp) => comp.id));
			break;

		case UNLOCK_LEASES + '_REJECTED':
		case UNLOCK_SALES + '_REJECTED':
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type '{}'.
			state = without(action.meta.ids, ...state);
			break;
	}
	return state;
};

type IS = {
	pendingIds: number[];
	status: 'init' | 'pending' | 'unlocked' | 'rejected';
	unlockedComps: {
		[key: string]: LeaseComp | SalesComp;
	};
};

const initialUnlockState: IS = {
	pendingIds: [],
	unlockedComps: {},
	status: 'init',
};

export const unlockReducerV2 = (
	state: IS = initialUnlockState,
	action = {}
) => {
	return produce(state, (draftState) => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type '{}'.
		switch (action.type) {
			case UNLOCK_LEASES + '_PENDING':
			case UNLOCK_SALES + '_PENDING':
				draftState.status = 'pending';
				draftState.pendingIds = uniq(
					// @ts-expect-error TS2769: No overload matches this call....
					[].concat(draftState.pendingIds, action.meta.ids)
				);
				return;

			case UNLOCK_LEASES + '_FULFILLED':
			case UNLOCK_SALES + '_FULFILLED':
				draftState.status = 'unlocked';
				draftState.pendingIds = without(
					draftState.pendingIds,
					// @ts-expect-error TS2339: Property 'payload' does not ex...
					...action.payload.map((comp) => comp.id)
				);
				draftState.unlockedComps = {
					...draftState.unlockedComps,
					// @ts-expect-error TS2339: Property 'payload' does not ex...
					...keyBy(action.payload, 'id'),
				};

				return;

			case UNLOCK_LEASES + '_REJECTED':
			case UNLOCK_SALES + '_REJECTED':
				draftState.status = 'rejected';
				draftState.pendingIds = without(
					// @ts-expect-error TS2339: Property 'meta' does not exist...
					action.meta.ids,
					...draftState.pendingIds
				);
				return;
		}
	});
};

export const useUnlocked = () => {
	const unlocked = useSelector<AppState, IS>((state) => state.unlockV2);
	return unlocked;
};

export default unlockReducer;
