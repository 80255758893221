import { Market } from '@compstak/common';
import {
	APNFilter,
	CompIdFilter,
	FilterDateInterval,
	FilterNumberInterval,
	FilterStringInterval,
	MultiPolygonFilter,
	PolygonFilter,
	RadiusFilter,
	SubmarketsFilter,
} from '../types';

export const filterNumberIntervalStub: FilterNumberInterval = {
	min: 1,
	max: 1,
};

export const filterStringIntervalStub: FilterStringInterval = {
	max: '1y',
	min: '1m',
};

export const filterDateIntervalStub: FilterDateInterval = {
	max: new Date(),
	min: new Date(),
};

export const polygonFilterStub: PolygonFilter = [
	{ lat: 1, lng: 1 },
	{ lat: 1, lng: 0 },
	{ lat: 0, lng: 0 },
	{ lat: 0, lng: 1 },
	{ lat: 1, lng: 1 },
];

export const multiPolygonFilterStub: MultiPolygonFilter = [polygonFilterStub];

export const radiusFilterStub: RadiusFilter = {
	center: { lat: 1, lng: 1 },
	distance: 1,
	buildingAddressAndCity: 'Some address',
};

export const marketFilterStub: Market = {
	lat: 40.7514,
	lon: -73.9806,
	zoom: 12,
	enterpriseBeta: false,
	exchangeBeta: false,
	id: 1,
	name: 'Manhattan',
	areaLat: 40.7514,
	areaLon: -73.9806,
	areaZoom: 12,
	displayName: 'New York City',
	monthly: false,
	status: 'PUBLIC',
	countryCode: 'US',
	leaseComps: true,
	salesComps: true,
	aliases: [],
	states: [],
};

export const submarketsFilterStub: SubmarketsFilter = [
	{ id: 1, name: 'Upper West Side' },
];

export const apnFilterStub: APNFilter = {
	fips: '',
	apn: '',
	area: multiPolygonFilterStub,
};

export const compIdFilterStub: CompIdFilter = {
	value: [1],
	exclude: true,
};
