import {
	useIsMufaProperty,
	useMufaPropertyByIdQuery,
	usePropertyByIdQuery,
} from 'api';

type Props = {
	propertyId: number;
};

/** Returns the basic (commercial) property by id
 * and merges the mufa property data if mufa conditions are satisfied.
 */
export const usePropertyById = ({ propertyId }: Props) => {
	const {
		data: property,
		isFetching: isFetchingProperty,
		isLoading: isLoadingProperty,
	} = usePropertyByIdQuery({ id: propertyId });

	const {
		data: isMufa,
		isFetching: isFetchingIsMufa,
		isLoading: isLoadingIsMufa,
	} = useIsMufaProperty({ propertyId });

	const {
		data: mufaProperty,
		isFetching: isFetchingMufaProperty,
		isLoading: isLoadingMufaProperty,
	} = useMufaPropertyByIdQuery(
		{ propertyId },
		{
			enabled: isMufa,
		}
	);

	return {
		data:
			property && (isMufa ? mufaProperty : true)
				? {
						...property,
						...mufaProperty,
					}
				: undefined,
		isFetching:
			isFetchingIsMufa ||
			isFetchingProperty ||
			(isMufa && isFetchingMufaProperty),
		isLoading:
			isLoadingIsMufa || isLoadingProperty || (isMufa && isLoadingMufaProperty),
	};
};
