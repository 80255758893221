import {
	LeaseAndSaleAveragesResponse,
	SearchPropertiesAverages,
	useLeaseAveragesQuery,
	usePropertyAverages,
	useSaleAveragesQuery,
} from 'api';
import { FiltersObject } from 'models/filters/types';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useMemo } from 'react';
import { CompType } from 'types';
import { useAppSelector } from 'util/useAppSelector';

type Props = {
	compType: CompType;
	searchAverages:
		| LeaseAndSaleAveragesResponse
		| SearchPropertiesAverages
		| undefined;
	filters: FiltersObject;
};

export const useSearchTableAverages = ({
	compType,
	searchAverages,
	filters,
}: Props) => {
	const selectionMap = useAppSelector((s) => s.selection.map);
	const filterMarketIds = useMemo(() => {
		return getFiltersMarkets(filters).map((m) => m.id);
	}, [filters]);

	const ids = useMemo(() => {
		return Array.from(selectionMap.keys());
	}, [selectionMap]);

	const hasSelection = selectionMap.size > 0;

	const leaseAverages = useLeaseAveragesQuery(
		{ ids },
		{ enabled: compType === 'lease' && hasSelection }
	);

	const saleAverages = useSaleAveragesQuery(
		{ ids },
		{ enabled: compType === 'sale' && hasSelection }
	);

	const propertyAverages = usePropertyAverages({
		marketIds: filterMarketIds,
		ids,
		enabled: compType === 'property' && hasSelection,
	});

	const averagesMap = {
		lease: leaseAverages,
		sale: saleAverages,
		property: propertyAverages,
	};

	return {
		data: hasSelection ? averagesMap[compType].data : searchAverages,
	};
};
