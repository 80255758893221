const neitherNumberNorDot = /[^0-9.]/g;
const commaRegex = /(\d)(?=(\d{3})+(?!\d))/g;

function format(value: string | number) {
	if (typeof value === 'string') {
		value = value.replace(neitherNumberNorDot, '');
		value = parseFloat(value);
	}
	if (typeof value !== 'number') {
		throw new Error('Tried to number format a non number');
	}
	// make sure we don't put commas after the decimal point
	const valueParts = value.toString().split('.');
	valueParts[0] = valueParts[0].replace(commaRegex, '$1,');
	return valueParts.join('.');
}

export default format;
