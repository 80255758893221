import { useState } from 'react';

export const useNavHeaderPagination = (
	initialIndex: number,
	pageSize = DEFAULT_PAGE_SIZE
) => {
	const [index, setIndex] = useState(initialIndex);

	const page = getPageFromIndex(index, pageSize);
	const startIndex = page * pageSize - pageSize;

	const getIndexInsideOfPage = () => {
		return index % pageSize;
	};

	return {
		currentIndex: index,
		startIndex,
		pageSize: pageSize,
		setPrevIndex: () => {
			setIndex((prev) => {
				if (prev === 0) return prev;
				return prev - 1;
			});
		},
		setNextIndex: (totalCount: number | undefined) => {
			setIndex((prev) => {
				const nextIndex = prev + 1;
				if (totalCount == null || totalCount === nextIndex) return prev;
				return prev + 1;
			});
		},
		getIndexInsideOfPage,
	};
};

// @ts-expect-error TS7006: Parameter 'pageSize' implicitl...
const getPageFromIndex = (index: number, pageSize) => {
	return Math.floor(index / pageSize + 1);
};

export const DEFAULT_PAGE_SIZE = 30;
