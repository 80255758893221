import { useInfiniteQuery } from '@tanstack/react-query';
import { QUERY_KEYS, SearchLeasesResponse } from 'api';
import { SearchInfiniteParams, createInfiniteSearchFetchFn } from '../utils';

const fetchInfiniteLeases = createInfiniteSearchFetchFn<SearchLeasesResponse>({
	url: '/api/comps/actions/search',
	getResponseSize: (res) => res.comps.length,
});

export const useSearchLeasesInfiniteQuery = (params: SearchInfiniteParams) => {
	return useInfiniteQuery({
		queryKey: createSearchLeasesInfiniteQueryKey(params),
		queryFn: ({ pageParam = 0, signal }) =>
			fetchInfiniteLeases({ ...params, pageParam, signal }),
		getNextPageParam: (last) => {
			if (!last.hasMore) return;
			return last.pageParam + 1;
		},
	});
};

export const createSearchLeasesInfiniteQueryKey = (
	params: SearchInfiniteParams
) => {
	return [QUERY_KEYS.SEARCH_LEASES_INFINITE, params];
};
