import React, { ChangeEvent } from 'react';

import Add from '../../../ui/svg_icons/add.svg';
import Subtract from '../../../ui/svg_icons/subtract.svg';

import './send-search.nomodule.less';

export default class SendSearchInput extends React.Component<{
	idx: number;
	email: {
		text: string;
		class: string;
	};
	handleChange: (idx: number) => (event: ChangeEvent<HTMLInputElement>) => void;
	addEmail: () => void;
	removeEmail: (idx: number) => void;
}> {
	removeEmail = () => {
		this.props.removeEmail(this.props.idx);
	};

	render() {
		const iconEl =
			this.props.idx === 0 ? (
				<div className="email-icon" onClick={this.props.addEmail}>
					<Add width="26px" height="26px" />
				</div>
			) : (
				<div className="email-icon" onClick={this.removeEmail}>
					<Subtract width="26px" height="26px" />
				</div>
			);

		return (
			<div className={'input-wrap ' + this.props.email.class}>
				<input
					type="text"
					name="email"
					onChange={this.props.handleChange(this.props.idx)}
					value={this.props.email.text}
				/>
				<label>Email</label>
				{iconEl}
			</div>
		);
	}
}
