import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { createFetchFn } from 'api/utils';

const fetchPropertySuggestions = createFetchFn<
	PropertySuggestionsParams,
	PropertySuggestionsResponse
>({
	method: 'get',
	getURL: ({ marketId, text }) =>
		`/properties/${marketId}/suggestions/?text=${encodeURIComponent(text)}`,
});

export const usePropertySuggestionsQuery = (
	params: PropertySuggestionsParams
) => {
	return useQuery({
		queryKey: [QUERY_KEYS.PROPERTY_SUGGESTIONS, params],
		queryFn: fetchPropertySuggestions(params),
		enabled: params.marketId != null,
	});
};

type PropertySuggestionsParams = {
	marketId: number | undefined;
	text: string;
};

type PropertySuggestionItem = {
	propertyId: number;
	text: string;
};

type PropertySuggestionsResponse = {
	suggestions: PropertySuggestionItem[];
};
