import { getAccessToken } from 'auth/auth';
import ServiceController, { Options, ServiceURL } from './ServiceController';

import { push } from 'router';

type CastleTokens = {
	enterpriseToken: string;
	exchangeToken: string;
};

export const TERMS_AND_PRIVACY_ERROR_MESSAGES = [
	'Privacy policy not accepted.',
	'Terms of use not accepted.',
];

export default class ServiceFactory {
	// hold on to these so we can clear them all.
	serviceControllers: ServiceController[];
	castleTokens: null | CastleTokens;

	constructor() {
		this.castleTokens = null;
		this.serviceControllers = [];
	}

	get authToken() {
		/**
		 * We use accessToken from the localStorage and not the this.authToken because we have multiple factories that need JWT.
		 * Problem is that when token is refreshed in one factory, other factories use old token.
		 * This way, we always take the most recent token from the local storage.
		 * This is not the most optimal solution, and probably should be changed in the future.
		 */
		return getAccessToken();
	}

	setCastleTokens = (castleTokens: null | CastleTokens) => {
		this.castleTokens = castleTokens;
	};

	create = <Payload = unknown, Response = unknown>(
		options: Options<Payload>
	) => {
		if (options.createUrl) {
			// @ts-expect-error TS2345: Argument of type 'ServiceURL<P...
			options.createUrl = this.addPrefixToUrl(options.createUrl);
		}

		const serviceController = new ServiceController<Payload, Response>(
			options,
			this.makeConnection
		);
		// @ts-expect-error TS2345: Argument of type 'ServiceContr...
		this.serviceControllers.push(serviceController);

		return serviceController;
	};

	get = <Response = unknown>(url: string, tryAgainOnFailure = true) => {
		return this.makeConnection<void, Response>(
			'GET',
			this.addPrefixToUrl(url),
			undefined,
			tryAgainOnFailure
		).promise;
	};

	getWithCancellation = <Response = unknown>(
		url: string,
		tryAgainOnFailure = true
	) => {
		return this.makeConnection<void, Response>(
			'GET',
			this.addPrefixToUrl(url),
			undefined,
			Boolean(tryAgainOnFailure)
		);
	};

	post = <Payload = unknown, Response = unknown>(
		url: string,
		data?: Payload,
		tryAgainOnFailure?: boolean
	) => {
		return this.makeConnection<Payload, Response>(
			'POST',
			this.addPrefixToUrl(url),
			data,
			tryAgainOnFailure
		).promise;
	};

	postWithCancellation = <Payload = unknown, Response = unknown>(
		url: string,
		data?: Payload,
		tryAgainOnFailure?: boolean
	) => {
		return this.makeConnection<Payload, Response>(
			'POST',
			this.addPrefixToUrl(url),
			data,
			tryAgainOnFailure
		);
	};

	put = <Payload = unknown, Response = unknown>(
		url: string,
		data?: Payload,
		tryAgainOnFailure?: boolean
	) => {
		return this.makeConnection<Payload, Response>(
			'PUT',
			this.addPrefixToUrl(url),
			data,
			tryAgainOnFailure
		).promise;
	};

	del = <Payload = unknown, Response = unknown>(
		url: string,
		data?: Payload,
		tryAgainOnFailure?: boolean
	) => {
		return this.makeConnection<Payload, Response>(
			'DELETE',
			this.addPrefixToUrl(url),
			data,
			tryAgainOnFailure
		).promise;
	};

	clearAll = () => {
		this.serviceControllers.forEach(function (serviceController) {
			serviceController.service.clearAll();
		});
	};

	addPrefixToUrl = <
		T extends ServiceURL | string,
		R = T extends ServiceURL ? ServiceURL : string,
	>(
		url: T
	): R => {
		if (typeof url === 'function') {
			return ((id?: string) => url(id)) as R;
		}

		if (typeof url === 'string') {
			return url as R;
		}
		return '' as R;
	};

	makeConnection = <Payload = unknown, Response = unknown>(
		type: string,
		url: string,
		data?: Payload,
		tryAgainOnFailure?: boolean
	) => {
		let xhr: XMLHttpRequest;
		const cancel = () => xhr.abort();

		const promise = new Promise<Response>((resolve, reject) => {
			const doReject = (xhr: XMLHttpRequest) => {
				reject(xhr);
			};

			let tries = 0;
			const tryRequest = () => {
				tries += 1;
				// @ts-expect-error TS2339: Property 'XDomainRequest' does...
				if (typeof window.XDomainRequest !== 'undefined') {
					// handles CORS in IE 8 and 9
					// @ts-expect-error TS2339: Property 'XDomainRequest' does...
					xhr = new window.XDomainRequest();
				} else {
					xhr = new window.XMLHttpRequest() || new XMLHttpRequest();
				}
				// @ts-expect-error TS2339: Property 'onRetrySuccess' does...
				xhr.onRetrySuccess = () => {
					if (tries > 2) {
						doReject(xhr);
						return;
					}
					tryRequest();
				};
				// @ts-expect-error TS2339: Property 'onRetryError' does n...
				xhr.onRetryError = doReject;

				xhr.onload = () => {
					if (xhr.status === 200 || xhr.status === 201) {
						let response = xhr.responseText;
						try {
							response = JSON.parse(response);
						} catch (e) {
							// I guess it's not JSON... Hope that's on purpose.
						}
						// @ts-expect-error TS2345: Argument of type 'string' is n...
						resolve(response);
					} else if (xhr.status === 203 || xhr.status === 204) {
						// @ts-expect-error TS2345: Argument of type 'undefined' i...
						resolve(undefined);
					} else if (xhr.status === 401) {
						// United point of refresh here YET: src/middleware/userUpdate.ts
						// must be purged in a future :'(
						if (
							['/api/user', '/api/sessions'].some((responseUrl) =>
								xhr.responseURL.endsWith(responseUrl)
							)
						) {
							doReject(xhr);
						}
					} else if (xhr.status === 403) {
						doReject(xhr);
						try {
							const responseJson = JSON.parse(xhr.response);
							if (
								responseJson &&
								TERMS_AND_PRIVACY_ERROR_MESSAGES.includes(responseJson.error)
							) {
								// we can't just import store or actions from parent directory due to circular dependency conflicts
								// as it turned out, we have a global available instance of our store,
								// as simplest fix we can use it and making a manual request yet.
								// The store usage will be removed right after auth branch is merged.
								// Action usage is much harder to replace, we'll see later about this.
								fetch('/api/user', {
									method: 'GET',
									credentials: 'include',
									headers: {
										'Content-Type': 'application/json',
										Authorization: 'Bearer ' + this.authToken,
									},
								})
									.then((response) => response.json())
									.then((data) => {
										// @ts-expect-error TS2552: Cannot find name 'store'. Did ...
										store.dispatch({
											type: 'USER_LOAD_FULFILLED',
											payload: data,
										});
										push('/');
									});
							}
						} catch (e) {
							console.error(e);
						}
					} else if ((xhr.status === 0 || tryAgainOnFailure) && tries < 2) {
						tryRequest();
					} else {
						doReject(xhr);
					}
				};

				xhr.onerror = function () {
					if (tryAgainOnFailure && tries < 2) {
						tryRequest();
					} else {
						doReject(xhr);
					}
				};

				xhr.open(type, url, true);

				if (this.authToken) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + this.authToken);
				}

				if (this.castleTokens) {
					xhr.setRequestHeader(
						'X-Castle-Enterprise-Token',
						this.castleTokens.enterpriseToken
					);
					xhr.setRequestHeader(
						'X-Castle-Exchange-Token',
						this.castleTokens.exchangeToken
					);

					this.setCastleTokens(null);
				}

				if (type !== 'GET') {
					const jsonString = JSON.stringify(data);
					xhr.setRequestHeader('content-type', 'application/json');
					xhr.send(jsonString);
				} else {
					xhr.send();
				}
			};

			tryRequest();
		});

		return { cancel, promise };
	};
}
