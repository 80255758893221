import { API } from '@compstak/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { UpdatePropertyCompsetParams } from './types';
import { MufaCompsetResponse } from './useMufaCompsetQuery';

const addProperty = async ({ id, ids }: UpdatePropertyCompsetParams) => {
	const res = await API.post<MufaCompsetResponse>(
		`/mufa/properties/${id}/compSet/actions/add`,
		{
			ids,
		}
	);
	return res.data;
};

export const useMufaAddPropertyMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (params: UpdatePropertyCompsetParams) => addProperty(params),
		onSuccess: (data) => {
			queryClient.invalidateQueries([
				QUERY_KEYS.MUFA_COMPSET,
				{ propertyId: data.subjectProperty.id },
			]);
			queryClient.invalidateQueries([QUERY_KEYS.MUFA_PROPERTY_MARKET_TREND]);
			queryClient.invalidateQueries([QUERY_KEYS.PROPERTY_COMPSET_TREND]);
		},
	});
};
