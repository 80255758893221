import { LeaseComp } from 'types';

export const isLeaseDateEstimated = (
	lease: LeaseComp,
	field: LeaseEstimatedFieldName
) => {
	return lease[ESTIMATED_DATE_MAP[field]];
};

const ESTIMATED_DATE_MAP = {
	commencementDate: 'estimatedCommencementDate',
	executionDate: 'estimatedExecutionDate',
	expirationDate: 'estimatedExpirationDate',
	leaseTerm: 'estimatedLeaseTerm',
} as const;

export type LeaseEstimatedFieldName = keyof typeof ESTIMATED_DATE_MAP;
