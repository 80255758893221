import {
	QueryFunction,
	QueryKey,
	QueryOptions,
	useQuery,
	UseQueryOptions,
} from '@tanstack/react-query';

type RetryValue<TError> = QueryOptions<unknown, TError>['retry'];

type Props<Params, Response, TError> = {
	queryKey: string | ((params: Params) => QueryKey);
	fetchFn: (params: Params) => QueryFunction<Response>;
	defaultStaleTime?: number;
	defaultCacheTime?: number;
	enabled?: boolean | ((params: Params) => boolean);
	retry?: RetryValue<TError>;
};

export const createQueryHook = <Params, Response, TError>({
	queryKey,
	fetchFn,
	enabled,
}: Props<Params, Response, TError>) => {
	return (params: Params, options?: UseQueryOptions<Response, TError>) => {
		const { enabled: enabledFromOptions, ...restOfOptions } = options ?? {};

		// compose default enabled and enabled from user options
		let enabledToPass = true;

		if (typeof enabled === 'function') {
			enabledToPass = enabled(params);
		} else if (typeof enabled === 'boolean') {
			enabledToPass = enabled;
		}

		if (enabledFromOptions != null) {
			enabledToPass = enabledToPass && enabledFromOptions;
		}

		return useQuery({
			queryKey:
				typeof queryKey === 'string' ? [queryKey, params] : queryKey(params),
			queryFn: fetchFn(params),
			enabled: enabledToPass,
			...restOfOptions,
		});
	};
};

export const useQueryHook = <Response, TError>({
	...props
}: UseQueryOptions<Response, TError>) => {
	return useQuery({
		...props,
	});
};
