export const OPEN_MARKET_SELECTOR = 'OPEN_MARKET_SELECTOR' as const;
export const CLOSE_MARKET_SELECTOR = 'CLOSE_MARKET_SELECTOR' as const;

export function openMarketSelector() {
	return {
		type: OPEN_MARKET_SELECTOR,
	};
}

export function closeMarketSelector() {
	return {
		type: CLOSE_MARKET_SELECTOR,
	};
}

export type HomeAction =
	| ReturnType<typeof openMarketSelector>
	| ReturnType<typeof closeMarketSelector>;
