import { Pending } from '@compstak/ui-kit';
import styled from 'styled-components';
import {
	CenteredContainer,
	Link,
	NextContainer,
	NumberOfResults,
	PrevContainer,
	PrevNextContainer,
	StyledIconArrowLeft,
	StyledIconArrowRight,
	UnderlinedLink,
} from 'ui/PrevNextHeaderUI';
import { UseNavHeaderContext } from './types';
import { useNavHeaderNavigation } from './useNavHeaderNavigation';

export type NavHeaderProps<Item, Args> = {
	itemLabel: string;
	useNavHeaderContext: UseNavHeaderContext<Item, Args>;
	className?: string;
};

export const NavHeader = <Item, Args>({
	className,
	itemLabel,
	useNavHeaderContext,
}: NavHeaderProps<Item, Args>) => {
	const {
		currentIndex,
		totalCount,
		isLoading,
		goToNextItem,
		goToPrevItem,
		goToList,
	} = useNavHeaderNavigation(useNavHeaderContext);

	if (isLoading) {
		return (
			<div>
				<StyledPending margin="0" />
			</div>
		);
	}

	const hasPrevItem = currentIndex > 0;
	const hasNextItem = totalCount != null && currentIndex + 1 < totalCount;

	return (
		<StyledPrevNextContainer className={className}>
			<PrevContainer>
				{hasPrevItem && (
					<Link onClick={goToPrevItem}>
						<StyledIconArrowLeft />
						Previous result
					</Link>
				)}
			</PrevContainer>
			<CenteredContainer>
				<NumberOfResults>
					Viewing {itemLabel} result {currentIndex + 1} of {totalCount}
				</NumberOfResults>
				<UnderlinedLink onClick={goToList}>Back to list</UnderlinedLink>
			</CenteredContainer>
			<NextContainer>
				{hasNextItem && (
					<Link onClick={goToNextItem}>
						Next result
						<StyledIconArrowRight />
					</Link>
				)}
			</NextContainer>
		</StyledPrevNextContainer>
	);
};

const HEADER_HEIGHT = 60;

const StyledPending = styled(Pending)`
	height: ${HEADER_HEIGHT}px;
`;

const StyledPrevNextContainer = styled(PrevNextContainer)`
	width: 100%;
	z-index: 1;
	height: ${HEADER_HEIGHT}px;
	position: sticky;
	top: 0;
`;
