import { Market } from '@compstak/common';
import { requestDemo } from 'actions/user';
import { useUser } from 'Pages/Login/reducers';
import { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ContactUsParagraph } from './ContactUsParagraph';
import button from 'ui/styles/button.less';
import styled from 'styled-components';

type MarketUpgradeModalProps = {
	market: Market;
	closeModal?: NoArgCallback;
	inline?: boolean;
};

export const MarketUpgradeModal = memo((props: MarketUpgradeModalProps) => {
	const [requestSent, setRequestSent] = useState(false);

	const dispatch = useDispatch();
	const user = useUser()!;

	const requestDemoHandler = () => {
		dispatch(requestDemo(user.username, 'change market', null));
		setRequestSent(true);
	};

	return (
		<MarketUpgradeModalContainer inline={props.inline}>
			<h3>
				Your subscription does not include properties in{' '}
				{props.market.displayName}
			</h3>
			<p className="lg grey">
				While CompStak Enterprise is available in major markets across the US,
				you must upgrade to access comps in this market
			</p>

			{requestSent ? (
				<div className={button.disabled}>Thanks! We'll contact you shortly</div>
			) : (
				<div className={button.button} onClick={requestDemoHandler}>
					Upgrade to Access {props.market.displayName}
				</div>
			)}
			<a
				href="#"
				className="normal-cta"
				data-close-button="true"
				onClick={props.closeModal}
			>
				I’m not ready to access other markets yet.
			</a>

			<ContactUsParagraph />
		</MarketUpgradeModalContainer>
	);
});

MarketUpgradeModal.displayName = 'MarketUpgradeModal';

const MarketUpgradeModalContainer = styled.div<{ inline?: boolean }>`
	padding: 50px 30px 55px;
	${({ inline }) =>
		inline ? 'width: 100%; height: 100%;' : 'max-width: 640px;'}
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	justify-content: center;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};

	p {
		font-size: 14px;
		line-height: 1.5em;
		font-weight: 400;
		max-width: 35em;
		&.lg {
			font-size: 13px;
			margin-bottom: 20px;
		}
		&.grey {
			color: ${({ theme }) => theme.colors.gray.n70};
		}
	}

	.normal-cta {
		color: ${({ theme }) => theme.colors.blue.blue400};
		font-size: 12px;
		line-height: 1.5em;
		font-weight: 400;
		transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
		padding-bottom: 0.25em;

		&:hover {
			color: ${({ theme }) => theme.colors.gray.n70};
			border-color: ${({ theme }) => theme.colors.gray.n70};
		}

		&:before {
			content: ' ';
			display: block;
			height: 18px;
		}
	}
`;
