import React from 'react';

import Tooltip from 'Singletons/Tooltip';
import Menu from 'Singletons/Menu';
import Feedback from 'Singletons/Feedback';
import Modal from 'Singletons/Modal';

export const TYPEAHEAD_PORTAL_ID = 'typeahead-portal';

const Singletons = React.memo(() => {
	return (
		<div>
			<Tooltip />
			<Menu />
			<Feedback />
			<Modal />
			<div id={TYPEAHEAD_PORTAL_ID} />
		</div>
	);
});

export default Singletons;
