import {
	SELECT_COMPS,
	DESELECT_COMPS,
	SELECT_ALL_COMPS,
	DESELECT_ALL,
	GET_AVERAGES,
	SelectionAction,
} from '../actions/selection';
import {
	FilterAction,
	FILTERS_CHANGE,
	FILTERS_RESET,
} from '../actions/filters';
import { LeaseAction, UNLOCK_LEASES } from 'actions/lease';
import { SaleAction, UNLOCK_SALES } from 'actions/sale';
import produce from 'immer';
import { Comp } from 'types';
import {
	getPromiseActionType,
	GetPromiseActionType,
} from 'types/redux-promise-middleware';
import { AveragesDisplayMapping } from 'services/util/formatAverages';

export type Selection = {
	map: Map<number, Comp>;
	list: Comp[];
	averages: AveragesDisplayMapping | null;
	isAveragesLoading: boolean;
	allSelected: boolean;
};

const initialState: Selection = {
	map: new Map<number, Comp>(),
	list: [],
	averages: null,
	isAveragesLoading: false,
	allSelected: false,
};

type ProcessedFilterAction =
	| Extract<FilterAction, { type: typeof FILTERS_CHANGE }>
	| Extract<FilterAction, { type: typeof FILTERS_RESET }>;
type ProcessedUnlockAction =
	| Extract<LeaseAction, { type: GetPromiseActionType<typeof UNLOCK_LEASES> }>
	| Extract<SaleAction, { type: GetPromiseActionType<typeof UNLOCK_SALES> }>;

const selection = (
	state = initialState,
	action: SelectionAction | ProcessedFilterAction | ProcessedUnlockAction
) => {
	return produce(state, (draftState) => {
		function selectComps(
			selectAction:
				| Extract<SelectionAction, { type: typeof SELECT_COMPS }>
				| Extract<
						SelectionAction,
						{ type: GetPromiseActionType<typeof SELECT_ALL_COMPS> }
				  >
		) {
			const filteredComps = selectAction.payload.filter(
				(comp) => !state.map.get(comp.id)
			);

			draftState.list = [...state.list, ...filteredComps];
			for (const comp of filteredComps) {
				draftState.map.set(comp.id, comp);
			}
			draftState.averages = null;
		}

		switch (action.type) {
			case getPromiseActionType(SELECT_ALL_COMPS, 'PENDING'): {
				draftState.averages = null;
				draftState.allSelected = true;
				break;
			}

			case getPromiseActionType(SELECT_ALL_COMPS, 'REJECTED'): {
				draftState.averages = null;
				draftState.allSelected = false;
				break;
			}

			case SELECT_COMPS: {
				selectComps(action);
				break;
			}

			case getPromiseActionType(SELECT_ALL_COMPS, 'FULFILLED'): {
				if (state.allSelected) {
					selectComps(action);
				}
				break;
			}

			case DESELECT_COMPS: {
				draftState.averages = null;
				draftState.allSelected = false;

				draftState.list = state.list.filter(
					(comp) => action.payload.indexOf(comp.id) === -1
				);
				action.payload.forEach((id) => draftState.map.delete(id));

				break;
			}

			case FILTERS_CHANGE:
			case FILTERS_RESET:
			case DESELECT_ALL: {
				return initialState;
			}

			case getPromiseActionType(GET_AVERAGES, 'PENDING'): {
				draftState.averages = null;
				draftState.isAveragesLoading = true;
				break;
			}

			case getPromiseActionType(GET_AVERAGES, 'REJECTED'): {
				if (state.list.length !== action.meta.leaseIds.length) {
					break;
				}
				draftState.averages = null;
				draftState.isAveragesLoading = false;
				break;
			}

			case getPromiseActionType(GET_AVERAGES, 'FULFILLED'): {
				if (state.list.length !== action.meta.leaseIds.length) {
					break;
				}
				const currentIds = state.list.map((comp) => comp.id);
				for (let i = 0; i < currentIds.length; i++) {
					if (currentIds[i] !== action.meta.leaseIds[i]) {
						break;
					}
				}
				draftState.averages = action.payload;
				draftState.isAveragesLoading = false;
				break;
			}

			case getPromiseActionType(UNLOCK_LEASES, 'FULFILLED'):
			case getPromiseActionType(UNLOCK_SALES, 'FULFILLED'): {
				// @ts-expect-error TS2339: Property 'reduce' does not exi...
				const compMap = action.payload.reduce((acc, comp) => {
					if (draftState.map.get(comp.id)) {
						draftState.map.set(comp.id, comp);
					}
					acc[comp.id] = comp;
					return acc;
				}, {});

				draftState.list = state.list.map((comp) => {
					if (compMap[comp.id]) {
						return compMap[comp.id];
					}
					return comp;
				});

				break;
			}
		}
	});
};

export default selection;
