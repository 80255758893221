import { API } from '@compstak/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { CompsetResponse, ResetCompsetParams } from './types';

const resetCompset = async ({ propertyId }: ResetCompsetParams) => {
	const res = await API.delete<CompsetResponse>(
		`/api/properties/${propertyId}/actions/resetCompSet`
	);
	return res.data;
};

export const useResetCompsetMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: resetCompset,
		onSuccess: (data) => {
			const queryKey = [
				QUERY_KEYS.PROPERTY_COMPSET,
				{
					id: data.subjectProperty.id,
				},
			];
			queryClient.setQueryData<CompsetResponse>(queryKey, (prevData) => {
				if (!prevData) return;
				return data;
			});
			queryClient.invalidateQueries(queryKey);
		},
	});
};
