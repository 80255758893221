// NOTE: Be careful with changing legacy imports ORDERS at this level,
// 1) CSS issues are possible until we migrate to styled-components entirely.
// 2) We experienced some circular dependency issues due to the redux store: import store from './store';
import { API } from '@compstak/common';
import {
	BingMapsConfigProvider,
	GoogleMapConfigProvider,
	MapboxConfigProvider,
} from '@compstak/maps';
import { defaultTheme, NotificationProvider } from '@compstak/ui-kit';
import { QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'Components/ErrorBoundary';
import 'Components/Modals';
import { enableMapSet } from 'immer';
import { LogoutEffects } from 'LogoutEffects';
import React, { Suspense, lazy } from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { ExchangeDashboardProvider } from 'reducers/exchangeDashboard';
import Singletons from 'Singletons';
import { ThemeProvider, StyleSheetManager, WebTarget } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import 'stylesheets/base.nomodule.less';
import 'ui/styles/fonts.less';
import { QUERY_CLIENT } from 'api/queryClient';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import store, { history } from './store';
import { HistoryProvider } from 'router/HistoryProvider';
import {
	SearchPropertiesNavHeaderProvider,
	SearchSalesNavHeaderProvider,
} from 'Components/PrevNextHeader';
import { ModalProvider } from 'providers/ModalProvider';
import { PropertyPageTabProvider } from 'Pages/PropertyPageV3/TabProvider';
import { getAccessToken } from 'auth/auth';

const RoutesComponent = lazy(() => import('./router/RoutesComponent'));

API.init({ getAccessToken });

enableMapSet();

const AppEntry = () => {
	const appConfig = store.getState().appConfig;

	return (
		<ErrorBoundary>
			<Provider store={store}>
				<Router history={history}>
					<StyleSheetManager shouldForwardProp={shouldForwardProp}>
						<ThemeProvider theme={defaultTheme}>
							<NotificationProvider>
								<ExchangeDashboardProvider>
									<QueryClientProvider client={QUERY_CLIENT}>
										<ModalProvider>
											<LogoutEffects />
											<GoogleMapConfigProvider
												googleApiKey={appConfig.google.apiKey}
											>
												<MapboxConfigProvider
													accessToken={appConfig.mapbox.accessToken}
												>
													<BingMapsConfigProvider
														bingMapsKey={appConfig.bing.accessToken}
													>
														<HistoryProvider>
															<PropertyPageTabProvider>
																<SearchSalesNavHeaderProvider>
																	<SearchPropertiesNavHeaderProvider>
																		<Suspense>
																			<RoutesComponent />
																		</Suspense>
																	</SearchPropertiesNavHeaderProvider>
																</SearchSalesNavHeaderProvider>
															</PropertyPageTabProvider>
														</HistoryProvider>
													</BingMapsConfigProvider>
												</MapboxConfigProvider>
											</GoogleMapConfigProvider>
											<Singletons />
										</ModalProvider>
									</QueryClientProvider>
								</ExchangeDashboardProvider>
							</NotificationProvider>
						</ThemeProvider>
					</StyleSheetManager>
				</Router>
			</Provider>
		</ErrorBoundary>
	);
};

export default DragDropContext(HTML5Backend)(AppEntry);

function shouldForwardProp(propName: string, target: WebTarget) {
	if (typeof target === 'string') {
		// For HTML elements, forward the prop if it is a valid HTML attribute
		return isPropValid(propName);
	}
	// For other elements, forward all props
	return true;
}
