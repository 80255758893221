import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { noRetryFor404 } from 'api/utils/queryRetriesMisc';
import { SalesComp } from 'types';
import { QUERY_KEYS } from '../constants';
import { composeEnabled, createFetchFn } from '../utils';

const fetchSaleComp = createFetchFn<SaleCompParams, SalesComp>({
	method: 'get',
	getURL: ({ id }) => `/api/salesComps/${id}`,
});

export const useSaleCompQuery = (
	params: SaleCompParams,
	options?: UseQueryOptions<SalesComp>
) =>
	useQuery({
		queryKey: createSaleCompQueryKey(params),
		queryFn: fetchSaleComp(params),
		enabled: composeEnabled(params.id != null, options?.enabled),
		retry: noRetryFor404,
		...options,
	});

export const createSaleCompQueryKey = (params: SaleCompParams) => [
	QUERY_KEYS.SALE_COMP,
	params,
];

export type SaleCompParams = {
	id?: number;
};
