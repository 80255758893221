import { User } from '@compstak/common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useUser } from 'Pages/Login/reducers';
import { QUERY_KEYS } from '../constants';
import { createFetchFn } from '../utils';

const fetchUser = createFetchFn<void, User>({
	method: 'get',
	getURL: () => `/api/user`,
});

/** Non null assertion because user is prefetched / needs to be present */
export const useUserQuery = (options?: UseQueryOptions<User>) => {
	const user = useUser();

	const { data, ...result } = useQuery({
		queryFn: fetchUser(),
		queryKey: [QUERY_KEYS.USER],
		initialData: user,
		...options,
	});

	return {
		...result,
		// TODO remove to `user` fallback after fully migrating user to RQ
		data: data ?? user,
	};
};
