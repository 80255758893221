import { useMarkets } from 'hooks/useMarkets';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import {
	MarketFeatureKey,
	useMarketsFeaturesQuery,
} from './useMarketsFeaturesQuery';

type Props = {
	feature: MarketFeatureKey;
	/** @default all markets */
	marketIds?: number[];
	isEnabled?: boolean;
	/** @default every */
	method?: 'some' | 'every';
};

export const useMarketsHaveFeature = ({
	feature,
	marketIds,
	isEnabled = true,
	method = 'every',
}: Props) => {
	const {
		data: marketsFeatures,
		isFetching,
		isLoading,
	} = useMarketsFeaturesQuery({
		enabled: isEnabled,
	});

	const markets = useMarkets().list;

	return {
		data: useMemo(() => {
			if (!isEnabled) return;

			const marketIdsToCheck = marketIds ? marketIds : markets.map((m) => m.id);

			return marketIdsToCheck[method]((marketId) => {
				return marketsFeatures[marketId][feature];
			});
		}, [isEnabled, marketsFeatures, marketIds, markets, feature, method]),
		isFetching,
		isLoading,
	};
};

export const useCurrentFiltersMarketsHaveFeature = (
	feature: MarketFeatureKey,
	method: 'every' | 'some' = 'every'
) => {
	const [filters] = useFilters();
	return useMarketsHaveFeature({
		feature,
		marketIds: getFiltersMarkets(filters).map(({ id }) => id),
		method,
	});
};
