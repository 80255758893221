import { API } from '@compstak/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { ResetCompsetParams } from './types';
import { MufaCompsetResponse } from './useMufaCompsetQuery';

const resetMufaCompset = async ({ propertyId }: ResetCompsetParams) => {
	const res = await API.post<MufaCompsetResponse>(
		`/mufa/properties/${propertyId}/compSet/actions/reset`
	);
	return res.data;
};

export const useMufaResetCompsetMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: resetMufaCompset,
		onSuccess: (data) => {
			const queryKey = [
				QUERY_KEYS.MUFA_COMPSET,
				{
					propertyId: data.subjectProperty.id,
				},
			];
			queryClient.setQueryData<MufaCompsetResponse>(queryKey, (prevData) => {
				if (!prevData) return;
				return data;
			});
			queryClient.invalidateQueries(queryKey);
		},
	});
};
