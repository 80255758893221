import dayjs from 'dayjs';
import DayJSIsSameOrAfterPlugin from 'dayjs/plugin/isSameOrAfter';
import { createRoot } from 'react-dom/client';
import 'regenerator-runtime/runtime';
import AppEntry from './AppEntry';
import { APP_CONTAINER_ID } from 'ui/UI';

dayjs.extend(DayJSIsSameOrAfterPlugin);
Object.typedKeys = Object.keys;

const container = document.getElementById(APP_CONTAINER_ID) as HTMLElement;
const root = createRoot(container);

root.render(<AppEntry />);
