// core imports
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// middleware
// @ts-expect-error TS7016: Could not find a declaration f...
import promiseMiddleware from 'redux-promise-middleware';
// @ts-expect-error TS7016: Could not find a declaration f...
import createLogger from 'redux-logger';
import userUpdate from 'middleware/userUpdate';
import filtersFromRoutes from 'middleware/filtersFromRoutes';
import {
	promiseFeedbackMiddleware,
	feedbackMiddleware,
} from 'Singletons/Feedback/middleware';
import trackingMiddleware from 'middleware/tracking/tracking';
import { httpErrorMiddleware } from 'util/httpError';

// app related stuff
import reducer from './reducers/root';
import { createReduxHistory, routerMiddleware } from 'reducers/routerReducer';

const store = createStore(
	reducer,
	compose(
		applyMiddleware.apply(null, [
			...[
				thunk,
				routerMiddleware,
				trackingMiddleware(),
				promiseFeedbackMiddleware(),
				promiseMiddleware(),
				httpErrorMiddleware(),
				userUpdate(),
				feedbackMiddleware(),
				filtersFromRoutes(),
			],
			...(!localStorage.getItem('logs') ? [] : [createLogger()]),
		]),
		// @ts-expect-error ts-migrate(2339) FIXME: Property '__REDUX_DEVTOOLS_EXTENSION__' does not e... Remove this comment to see the full error message
		window.__REDUX_DEVTOOLS_EXTENSION__
			? // @ts-expect-error ts-migrate(2339) FIXME: Property '__REDUX_DEVTOOLS_EXTENSION__' does not e... Remove this comment to see the full error message
				window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 })
			: // @ts-expect-error TS7006: Parameter 'f' implicitly has a...
				(f) => f
	)
);

export const history = createReduxHistory(store);

export default store;
export type AppDispatch = typeof store.dispatch;
export type AppGetState = typeof store.getState;

// @ts-expect-error TS2551: Property 'store' does not exis...
window.store = store;
