import { FiltersObject } from 'models/filters/types';

export const getFiltersMarkets = (filters: Partial<FiltersObject>) => {
	// we assume that if markets is absent then market is not and vice versa
	return 'markets' in filters && filters.markets
		? [...filters.markets]
		: 'market' in filters && filters.market
			? [filters.market]
			: [];
};
