import { QUERY_KEYS } from 'api';
import {
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query';
import { API, buildQuery, formatRequestString } from '@compstak/common';
import {
	APIDateRequestParams,
	Indicator,
	Metric,
} from '../../Pages/PropertyPageV2_1/api/interfaces';
import { addEmptyData, Timeframes } from '@compstak/common';

type FetchParams = {
	id: string | number;
	metric: Metric;
	fromDate: APIDateRequestParams['fromDate'];
	toDate: APIDateRequestParams['toDate'];
	months: string;
};

type DTOKey = 'compSetAverage' | 'submarket';
const trendlineTypes = ['compSetAverage', 'submarket'];
export type CompSetTrendDTO = Record<DTOKey, Indicator>;

export type UseCompSetTrendResponse = UseQueryResult<CompSetTrendDTO>;

const addEmptyDataByMonth = addEmptyData(trendlineTypes, Timeframes.MoM);

const fetchCompSetTrend = async ({
	id,
	metric,
	...rest
}: FetchParams): Promise<CompSetTrendDTO> => {
	const query = buildQuery({ ...rest });

	const { data } = await API.get<CompSetTrendDTO>(
		formatRequestString({
			entityUrl: `/properties/${id}/compSet/trend/${metric}?${query}`,
		})
	);

	return addEmptyDataByMonth(data);
};

export const useCompSetTrendRequest = (
	params: FetchParams,
	queryConfig?: Pick<UseQueryOptions, 'enabled'>
): UseCompSetTrendResponse =>
	useQuery(
		[QUERY_KEYS.PROPERTY_COMPSET_TREND, params],
		() => fetchCompSetTrend(params),
		queryConfig
	);
