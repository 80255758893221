export default {
	usStates: [
		{
			name: 'Alabama',
			abbreviation: 'AL',
		},
		{
			name: 'Alaska',
			abbreviation: 'AK',
		},
		{
			name: 'American Samoa',
			abbreviation: 'AS',
		},
		{
			name: 'Arizona',
			abbreviation: 'AZ',
		},
		{
			name: 'Arkansas',
			abbreviation: 'AR',
		},
		{
			name: 'California',
			abbreviation: 'CA',
		},
		{
			name: 'Colorado',
			abbreviation: 'CO',
		},
		{
			name: 'Connecticut',
			abbreviation: 'CT',
		},
		{
			name: 'Delaware',
			abbreviation: 'DE',
		},
		{
			name: 'District of Columbia',
			abbreviation: 'DC',
		},
		{
			name: 'Federated States Of Micronesia',
			abbreviation: 'FM',
		},
		{
			name: 'Florida',
			abbreviation: 'FL',
		},
		{
			name: 'Georgia',
			abbreviation: 'GA',
		},
		{
			name: 'Guam',
			abbreviation: 'GU',
		},
		{
			name: 'Hawaii',
			abbreviation: 'HI',
		},
		{
			name: 'Idaho',
			abbreviation: 'ID',
		},
		{
			name: 'Illinois',
			abbreviation: 'IL',
		},
		{
			name: 'Indiana',
			abbreviation: 'IN',
		},
		{
			name: 'Iowa',
			abbreviation: 'IA',
		},
		{
			name: 'Kansas',
			abbreviation: 'KS',
		},
		{
			name: 'Kentucky',
			abbreviation: 'KY',
		},
		{
			name: 'Louisiana',
			abbreviation: 'LA',
		},
		{
			name: 'Maine',
			abbreviation: 'ME',
		},
		{
			name: 'Marshall Islands',
			abbreviation: 'MH',
		},
		{
			name: 'Maryland',
			abbreviation: 'MD',
		},
		{
			name: 'Massachusetts',
			abbreviation: 'MA',
		},
		{
			name: 'Michigan',
			abbreviation: 'MI',
		},
		{
			name: 'Minnesota',
			abbreviation: 'MN',
		},
		{
			name: 'Mississippi',
			abbreviation: 'MS',
		},
		{
			name: 'Missouri',
			abbreviation: 'MO',
		},
		{
			name: 'Montana',
			abbreviation: 'MT',
		},
		{
			name: 'Nebraska',
			abbreviation: 'NE',
		},
		{
			name: 'Nevada',
			abbreviation: 'NV',
		},
		{
			name: 'New Hampshire',
			abbreviation: 'NH',
		},
		{
			name: 'New Jersey',
			abbreviation: 'NJ',
		},
		{
			name: 'New Mexico',
			abbreviation: 'NM',
		},
		{
			name: 'New York',
			abbreviation: 'NY',
		},
		{
			name: 'North Carolina',
			abbreviation: 'NC',
		},
		{
			name: 'North Dakota',
			abbreviation: 'ND',
		},
		{
			name: 'Northern Mariana Islands',
			abbreviation: 'MP',
		},
		{
			name: 'Ohio',
			abbreviation: 'OH',
		},
		{
			name: 'Oklahoma',
			abbreviation: 'OK',
		},
		{
			name: 'Oregon',
			abbreviation: 'OR',
		},
		{
			name: 'Palau',
			abbreviation: 'PW',
		},
		{
			name: 'Pennsylvania',
			abbreviation: 'PA',
		},
		{
			name: 'Puerto Rico',
			abbreviation: 'PR',
		},
		{
			name: 'Rhode Island',
			abbreviation: 'RI',
		},
		{
			name: 'South Carolina',
			abbreviation: 'SC',
		},
		{
			name: 'South Dakota',
			abbreviation: 'SD',
		},
		{
			name: 'Tennessee',
			abbreviation: 'TN',
		},
		{
			name: 'Texas',
			abbreviation: 'TX',
		},
		{
			name: 'Utah',
			abbreviation: 'UT',
		},
		{
			name: 'Vermont',
			abbreviation: 'VT',
		},
		{
			name: 'Virgin Islands',
			abbreviation: 'VI',
		},
		{
			name: 'Virginia',
			abbreviation: 'VA',
		},
		{
			name: 'Washington',
			abbreviation: 'WA',
		},
		{
			name: 'West Virginia',
			abbreviation: 'WV',
		},
		{
			name: 'Wisconsin',
			abbreviation: 'WI',
		},
		{
			name: 'Wyoming',
			abbreviation: 'WY',
		},
	],
	months: [
		{
			name: 'January',
			abbreviation: 'Jan',
			number: '01',
		},
		{
			name: 'February',
			abbreviation: 'Feb',
			number: '02',
		},
		{
			name: 'March',
			abbreviation: 'Mar',
			number: '03',
		},
		{
			name: 'April',
			abbreviation: 'Apr',
			number: '04',
		},
		{
			name: 'May',
			abbreviation: 'May',
			number: '05',
		},
		{
			name: 'June',
			abbreviation: 'June',
			number: '06',
		},
		{
			name: 'July',
			abbreviation: 'July',
			number: '07',
		},
		{
			name: 'August',
			abbreviation: 'Aug',
			number: '08',
		},
		{
			name: 'September',
			abbreviation: 'Sept',
			number: '09',
		},
		{
			name: 'October',
			abbreviation: 'Oct',
			number: '10',
		},
		{
			name: 'November',
			abbreviation: 'Nov',
			number: '11',
		},
		{
			name: 'December',
			abbreviation: 'Dec',
			number: '12',
		},
	],
	workTypes: [
		'NBI',
		'Pre Built',
		'Paint & Carpet',
		'Base Building Work',
		'Turnkey',
		'Spec Suite',
		'Built to Suit',
		'Plug & Play',
		'Workletter',
		'TI',
		'Other',
		'As-Is',
	],
	propertyTypes: [
		'Office',
		'Multi-Family',
		'Retail: Neighborhood Shopping Center',
		'Retail: Community Shopping Center',
		'Retail: Shopping Mall',
		'Medical',
		'Industrial',
		'Land',
		'Flex',
		'Retail: Freestanding',
		'Retail: Outlet',
		'Retail: Lifestyle Shopping Center',
		'Retail: Power Shopping Center',
		'Mixed-Use',
		'Retail: Strip Center',
		'Retail',
		'Hotel',
	],
	interestTypes: ['Full', 'Partial'],
};

export const FILTERS_DEBOUNCE_TIMEOUT = 300;
