import { colors } from '@compstak/ui-kit';
import IconLock from 'ui/svg_icons/lock.svg';
import styled from 'styled-components';

export const APP_CONTAINER_ID = 'app-container';
export const APP_WRAPPER_ID = 'app-wrapper';

export const DarkSidebar = styled.div<{ sideBarState: 'open' | 'closed' }>`
	display: flex;
	flex-direction: column;
	min-width: 300px;
	max-width: 300px;
	background-color: #303441;
	position: relative;
	overflow-y: auto;
	transition: transform 0.2s ease-in-out;
	height: 100%;

	@media (max-width: 768px) {
		position: absolute;
		top: 0;
		transform: translate(
			${(props) => (props.sideBarState === 'open' ? '0' : '-300px')}
		);
	}
`;

export const IconLockGrey = styled(IconLock)`
	fill: ${colors.neutral.n70};
	width: 13px;
	height: 15px;
`;
