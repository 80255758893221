import { HideableHeaderControlWithHandlers } from '../Hideable/HideableControl';

const HeaderCell = ({
	// @ts-expect-error TS7031: Binding element 'editable' imp...
	editable,
	// @ts-expect-error TS7031: Binding element 'field' implic...
	field,
	// @ts-expect-error TS7031: Binding element 'value' implic...
	value,
	// @ts-expect-error TS7031: Binding element 'hiddenFields'...
	hiddenFields,
	// @ts-expect-error TS7031: Binding element 'exportActions...
	exportActions,
}) => {
	return editable ? (
		<div className={'table-cell ' + field}>
			<div className="data">
				<HideableHeaderControlWithHandlers
					// @ts-expect-error ts-migrate(2769) FIXME: Property 'html' does not exist on type 'IntrinsicA... Remove this comment to see the full error message
					html={value}
					target={field}
					hiddenFields={hiddenFields}
					hideCustomExportField={exportActions.hideCustomExportField}
					showCustomExportField={exportActions.showCustomExportField}
				/>
			</div>
		</div>
	) : (
		<div className={'table-cell ' + field}>
			<div className="data">{value}</div>
		</div>
	);
};

export default HeaderCell;
