import { CompType } from '../types/comp';

export default function pluralizeCompType<
	T extends boolean,
	R = T extends true
		? Capitalize<ReturnType<typeof getPlural>>
		: ReturnType<typeof getPlural>
>(type: CompType, capitalize: T): R {
	const plural = getPlural(type);
	if (capitalize) {
		return (plural.charAt(0).toUpperCase() + plural.slice(1)) as R;
	} else {
		return plural as R;
	}
}

const getPlural = (type: CompType) => {
	switch (type) {
		case 'lease':
		case 'sale':
			return `${type}s` as const;
		case 'property':
			return 'properties' as const;
	}
};
