import { QueryOptions } from '@tanstack/react-query';
import { loadUser } from 'Pages/Login/actions';
import { push } from 'router';
import { TERMS_AND_PRIVACY_ERROR_MESSAGES } from 'services/impl/ServiceFactory';
import store from '../../store';
import { isAPIClientNotOkResponseError } from './queryRetriesMisc';
import { refreshAccessToken } from 'auth/auth';

function qcRetryFactory({
	maxRetries,
}: {
	maxRetries: number;
}): QueryOptions['retry'] {
	let isTryingToSetNewAccessToken = false;

	const nonEagerRefreshAccessToken = async () => {
		if (isTryingToSetNewAccessToken) {
			return;
		}

		isTryingToSetNewAccessToken = true;
		await refreshAccessToken();
		isTryingToSetNewAccessToken = false;
	};

	return function qcRetry(failureCount, error) {
		// Note: failureCount starts at 0.
		if (!isAPIClientNotOkResponseError<{ error: string } | undefined>(error)) {
			return failureCount < maxRetries;
		}
		const { status } = error.response;
		if (status === 404) {
			return false;
		}
		if (status === 401) {
			// retry is not async, at least for react-query v4
			// thus we retry the failed 401 request + tryToSetNewAccessToken on first retry only
			nonEagerRefreshAccessToken();
			// We retry 5 times for 401 errors to leave time for token to be set.
			// It's not ideal but without async retry it's good enough.
			return failureCount < 5;
		}
		if (
			status === 403 &&
			TERMS_AND_PRIVACY_ERROR_MESSAGES.includes(
				error.response.data?.error ?? ''
			)
		) {
			store.dispatch(
				loadUser(() => {
					push('/');
				})
			);
			return false;
		}
		return failureCount < maxRetries;
	};
}

export const qcRetryDefault = qcRetryFactory({ maxRetries: 3 });
export const qcRetryOnlyAuth = qcRetryFactory({ maxRetries: 0 });
