import { FiltersTypesKeys, FiltersObject } from 'models/filters/types';
import {
	isAPNFilter,
	isArrayFilter,
	isCompIdFilter,
	isFilterKey,
	isIntervalFilterValue,
	isMarketFilter,
	isRadiusFilter,
} from './filterHelpers';

export function isSet<K extends FiltersTypesKeys>(
	value: FiltersObject[K] | undefined,
	key: K
) {
	if (!isFilterKey(key)) {
		throw new Error(`Unknown filter (key: ${key}, value: ${value})`);
	}

	if (value == null) {
		return false;
	}

	if (key === 'sortField' || key === 'sortDirection') {
		return false;
	}

	if (isCompIdFilter(value)) {
		return value.value.length > 0;
	}

	if (
		typeof value === 'boolean' ||
		isMarketFilter(value) ||
		isRadiusFilter(value) ||
		isAPNFilter(value)
	) {
		return true;
	}

	if (typeof value === 'number') {
		return !isNaN(value);
	}

	if (typeof value === 'string') {
		return !!value;
	}

	if (isArrayFilter(value)) {
		return value.length > 0;
	}

	if (isIntervalFilterValue(value)) {
		return (
			value.max != null || value.min != null || value.allowFallback === false
		);
	}

	return false;
}

export function getSetFilterKeys(filters: Partial<FiltersObject>) {
	let keys = Object.keys(filters) as FiltersTypesKeys[];

	keys = keys.filter((key) => isSet(filters[key], key));

	return keys;
}

export const getSetFilters = (filters: FiltersObject) => {
	return getSetFilterKeys(filters).reduce<Partial<FiltersObject>>(
		(acc, key) => {
			return { ...acc, [key]: filters[key] };
		},
		{}
	);
};
