import { nullValueText } from '@compstak/common';
import { formatRent } from './formatRent';

export const formatRentWithPsfPostfix = (
	isMonthlyMarket: boolean,
	rent?: number
) => {
	if (rent === undefined) return nullValueText;

	return `${formatRent(rent, isMonthlyMarket)} PSF`;
};
