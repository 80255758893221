export enum ApiQueryKeys {
	property = 'property',
	muFaProperty = 'muFaProperty',
	propertyFloorPlans = 'propertyFloorPlans',
	propertySummary = 'propertySummary',
	propertyLeaseTradeOut = 'propertyLeaseTradeOut',
	trendChange = 'trendChange',
	transitScreen = 'transitScreen',
	marketTrend = 'marketTrend',
	muFaPropertyUnitMixSummary = 'muFaPropertyUnitMixSummary',
	mufaSubmarketPolygons = 'mufaSubmarketPolygons',
	submarketDemand = 'submarketDemand',
	submarketSupply = 'submarketSupply',
	submarketRentTrend = 'submarketRentTrend',
	submarketMarketTrend = 'submarketMarketTrend',
	occupancy = 'occupancy',
	noiTrend = 'noiTrend',
	constructionPipeline = 'constructionPipeline',
	compSetTrend = 'compSetTrend',
	expensesPerUnit = 'expensesPerUnit',
	muFaKeyPropertyMetrics = 'muFaKeyPropertyMetrics',
	commercialLeaseSummary = 'commercialLeaseSummary',
	suggestions = 'suggestions',
	propertyShort = 'propertyShort',
	leases = 'leases',
	sales = 'sales',
	distribution = 'distribution',
	submarketRecentLeases = 'submarketRecentLeases',
	info = 'info',
	chartBuilderShapeLoader = 'chartBuilderDataSetLoader',
	propertyAverages = 'propertyAverages',
}
