import { PortfolioItem } from '../types';

export const getNumberOfDefinedValuesInPortfolio = (
	portfolio: PortfolioItem[],
	fieldName: keyof PortfolioItem
) => {
	let numOfValues = 0;
	for (const p of portfolio) {
		if (p[fieldName] != null) {
			numOfValues++;
		}
	}
	return numOfValues;
};
