import { CompatibleFilter } from 'types/serverFilters';
import { FiltersObject } from '../types';
import { processFilters } from './filtersCompatibilityUtils';

// Supports merging obsolete filters
export const mergeFilters = <T extends Partial<FiltersObject>>(
	filters: T,
	mergedFilters: Partial<T & CompatibleFilter>
) => {
	processFilters(mergedFilters, false);
	return { ...filters, ...mergedFilters };
};
