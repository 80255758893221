export const SUBMARKETS_ID_TO_NAME: Record<
	number,
	{ id: number; name: string; marketId: number }
> = {
	'1': {
		id: 1,
		name: 'Upper West Side',
		marketId: 1,
	},
	'2': {
		id: 2,
		name: 'Times Square',
		marketId: 1,
	},
	'3': {
		id: 3,
		name: 'Park Avenue',
		marketId: 1,
	},
	'4': {
		id: 4,
		name: 'City Hall Insurance',
		marketId: 1,
	},
	'5': {
		id: 5,
		name: 'Tribeca',
		marketId: 1,
	},
	'6': {
		id: 6,
		name: 'NoHo Greenwich Village',
		marketId: 1,
	},
	'7': {
		id: 7,
		name: 'Financial District',
		marketId: 1,
	},
	'8': {
		id: 8,
		name: 'UN Plaza',
		marketId: 1,
	},
	'9': {
		id: 9,
		name: 'North Manhattan',
		marketId: 1,
	},
	'10': {
		id: 10,
		name: 'Grand Central',
		marketId: 1,
	},
	'11': {
		id: 11,
		name: 'Columbus Circle',
		marketId: 1,
	},
	'13': {
		id: 13,
		name: 'Upper East Side',
		marketId: 1,
	},
	'14': {
		id: 14,
		name: 'Gramercy Park/Union Square',
		marketId: 1,
	},
	'15': {
		id: 15,
		name: 'Chelsea',
		marketId: 1,
	},
	'16': {
		id: 16,
		name: 'Midtown Eastside',
		marketId: 1,
	},
	'17': {
		id: 17,
		name: 'Sixth Avenue',
		marketId: 1,
	},
	'18': {
		id: 18,
		name: 'World Trade Center',
		marketId: 1,
	},
	'19': {
		id: 19,
		name: 'SoHo',
		marketId: 1,
	},
	'21': {
		id: 21,
		name: 'Hudson Square',
		marketId: 1,
	},
	'22': {
		id: 22,
		name: 'Madison/Fifth Avenue',
		marketId: 1,
	},
	'23': {
		id: 23,
		name: 'Murray Hill',
		marketId: 1,
	},
	'24': {
		id: 24,
		name: 'North Waterfront',
		marketId: 2,
	},
	'25': {
		id: 25,
		name: 'West of Kearny Street',
		marketId: 2,
	},
	'26': {
		id: 26,
		name: 'South Financial District',
		marketId: 2,
	},
	'27': {
		id: 27,
		name: 'Civic Center',
		marketId: 2,
	},
	'28': {
		id: 28,
		name: 'Rincon Hill/South Beach',
		marketId: 2,
	},
	'29': {
		id: 29,
		name: 'Showplace Square',
		marketId: 2,
	},
	'30': {
		id: 30,
		name: 'Mission Bay/China Basin',
		marketId: 2,
	},
	'31': {
		id: 31,
		name: 'Southern City',
		marketId: 2,
	},
	'32': {
		id: 32,
		name: 'Jackson Square',
		marketId: 2,
	},
	'33': {
		id: 33,
		name: 'Potrero',
		marketId: 2,
	},
	'34': {
		id: 34,
		name: 'West of Van Ness Corridor',
		marketId: 2,
	},
	'35': {
		id: 35,
		name: 'Union Square',
		marketId: 2,
	},
	'36': {
		id: 36,
		name: 'Yerba Buena',
		marketId: 2,
	},
	'37': {
		id: 37,
		name: 'North Financial District',
		marketId: 2,
	},
	'38': {
		id: 38,
		name: 'Lower South of Market',
		marketId: 2,
	},
	'39': {
		id: 39,
		name: 'India Basin',
		marketId: 2,
	},
	'40': {
		id: 40,
		name: 'Bayview-Hunters Point',
		marketId: 2,
	},
	'41': {
		id: 41,
		name: 'South of Market',
		marketId: 2,
	},
	'42': {
		id: 42,
		name: 'Van Ness Corridor',
		marketId: 2,
	},
	'43': {
		id: 43,
		name: 'Mill Valley/Sausalito',
		marketId: 3,
	},
	'44': {
		id: 44,
		name: 'San Rafael',
		marketId: 3,
	},
	'45': {
		id: 45,
		name: 'Novato',
		marketId: 3,
	},
	'46': {
		id: 46,
		name: 'Suburban Marin County',
		marketId: 3,
	},
	'47': {
		id: 47,
		name: 'Alameda',
		marketId: 3,
	},
	'48': {
		id: 48,
		name: 'Richmond/San Pablo',
		marketId: 3,
	},
	'49': {
		id: 49,
		name: 'San Bruno Millbrae',
		marketId: 3,
	},
	'50': {
		id: 50,
		name: 'South San Francisco',
		marketId: 3,
	},
	'51': {
		id: 51,
		name: 'Oakland',
		marketId: 3,
	},
	'52': {
		id: 52,
		name: 'Berkeley',
		marketId: 3,
	},
	'53': {
		id: 53,
		name: 'Emeryville',
		marketId: 3,
	},
	'54': {
		id: 54,
		name: 'Oakland Airport South Oakland',
		marketId: 3,
	},
	'55': {
		id: 55,
		name: 'San Leandro',
		marketId: 3,
	},
	'56': {
		id: 56,
		name: 'Hayward',
		marketId: 3,
	},
	'57': {
		id: 57,
		name: 'Newark/Fremont',
		marketId: 3,
	},
	'58': {
		id: 58,
		name: 'Milpitas',
		marketId: 3,
	},
	'59': {
		id: 59,
		name: 'Campbell/Los Gatos',
		marketId: 3,
	},
	'60': {
		id: 60,
		name: 'North San Jose',
		marketId: 3,
	},
	'61': {
		id: 61,
		name: 'Downtown San Jose',
		marketId: 3,
	},
	'62': {
		id: 62,
		name: 'South San Jose',
		marketId: 3,
	},
	'63': {
		id: 63,
		name: 'Santa Clara',
		marketId: 3,
	},
	'64': {
		id: 64,
		name: 'Sunnyvale/Cupertino',
		marketId: 3,
	},
	'65': {
		id: 65,
		name: 'Mountain View/Los Altos',
		marketId: 3,
	},
	'66': {
		id: 66,
		name: 'Palo Alto',
		marketId: 3,
	},
	'67': {
		id: 67,
		name: 'Menlo Park',
		marketId: 3,
	},
	'68': {
		id: 68,
		name: 'Redwood City San Carlos',
		marketId: 3,
	},
	'69': {
		id: 69,
		name: 'Redwood Foster City',
		marketId: 3,
	},
	'70': {
		id: 70,
		name: 'San Mateo',
		marketId: 3,
	},
	'71': {
		id: 71,
		name: 'Burlingame',
		marketId: 3,
	},
	'72': {
		id: 72,
		name: 'Daly City Brisbane',
		marketId: 3,
	},
	'92': {
		id: 92,
		name: 'Lafayette/Moraga/Orinda',
		marketId: 3,
	},
	'93': {
		id: 93,
		name: 'Livermore',
		marketId: 3,
	},
	'94': {
		id: 94,
		name: 'Concord',
		marketId: 3,
	},
	'95': {
		id: 95,
		name: 'Martinez Pacheco',
		marketId: 3,
	},
	'96': {
		id: 96,
		name: 'Pleasant Hill',
		marketId: 3,
	},
	'97': {
		id: 97,
		name: 'Walnut Creek',
		marketId: 3,
	},
	'98': {
		id: 98,
		name: 'Danville Alamo',
		marketId: 3,
	},
	'99': {
		id: 99,
		name: 'Pittsburg',
		marketId: 3,
	},
	'100': {
		id: 100,
		name: 'Pleasanton',
		marketId: 3,
	},
	'101': {
		id: 101,
		name: 'San Ramon',
		marketId: 3,
	},
	'102': {
		id: 102,
		name: 'Antioch',
		marketId: 3,
	},
	'103': {
		id: 103,
		name: 'Angwin',
		marketId: 3,
	},
	'104': {
		id: 104,
		name: 'Berryessa',
		marketId: 3,
	},
	'105': {
		id: 105,
		name: 'Calistoga',
		marketId: 3,
	},
	'106': {
		id: 106,
		name: 'Cloverdale/Geyserville',
		marketId: 3,
	},
	'108': {
		id: 108,
		name: 'Healdsburg/Windsor',
		marketId: 3,
	},
	'109': {
		id: 109,
		name: 'Napa',
		marketId: 3,
	},
	'110': {
		id: 110,
		name: 'Petaluma',
		marketId: 3,
	},
	'111': {
		id: 111,
		name: 'Russian River-Coastal',
		marketId: 3,
	},
	'112': {
		id: 112,
		name: 'Sebastopol',
		marketId: 3,
	},
	'113': {
		id: 113,
		name: 'Santa Rosa',
		marketId: 3,
	},
	'114': {
		id: 114,
		name: 'Sonoma',
		marketId: 3,
	},
	'115': {
		id: 115,
		name: 'St. Helena',
		marketId: 3,
	},
	'184': {
		id: 184,
		name: 'South Suburban',
		marketId: 6,
	},
	'185': {
		id: 185,
		name: 'Indiana',
		marketId: 6,
	},
	'186': {
		id: 186,
		name: 'Northwest Suburban',
		marketId: 6,
	},
	'187': {
		id: 187,
		name: 'CBD South Loop',
		marketId: 6,
	},
	'188': {
		id: 188,
		name: 'North Suburban',
		marketId: 6,
	},
	'189': {
		id: 189,
		name: 'CBD North Michigan Avenue',
		marketId: 6,
	},
	'190': {
		id: 190,
		name: 'CBD West Loop',
		marketId: 6,
	},
	'191': {
		id: 191,
		name: 'South Chicago',
		marketId: 6,
	},
	'192': {
		id: 192,
		name: 'CBD Central Loop',
		marketId: 6,
	},
	'193': {
		id: 193,
		name: 'Kenosha',
		marketId: 6,
	},
	'194': {
		id: 194,
		name: 'East-West Corridor',
		marketId: 6,
	},
	'195': {
		id: 195,
		name: 'CBD East Loop',
		marketId: 6,
	},
	'196': {
		id: 196,
		name: 'North Chicago',
		marketId: 6,
	},
	'197': {
		id: 197,
		name: "O'Hare",
		marketId: 6,
	},
	'198': {
		id: 198,
		name: 'CBD River North',
		marketId: 6,
	},
	'199': {
		id: 199,
		name: 'West Cook County',
		marketId: 6,
	},
	'200': {
		id: 200,
		name: 'Hudson Yards',
		marketId: 1,
	},
	'201': {
		id: 201,
		name: 'Times Square South',
		marketId: 1,
	},
	'202': {
		id: 202,
		name: 'Penn Station',
		marketId: 1,
	},
	'203': {
		id: 203,
		name: 'Midway',
		marketId: 8,
	},
	'204': {
		id: 204,
		name: 'Minneapolis CBD',
		marketId: 8,
	},
	'205': {
		id: 205,
		name: 'St. Paul CBD',
		marketId: 8,
	},
	'206': {
		id: 206,
		name: 'Apple Valley Burnsville Eagan',
		marketId: 8,
	},
	'207': {
		id: 207,
		name: 'Northwest',
		marketId: 8,
	},
	'208': {
		id: 208,
		name: 'Southwest I-494',
		marketId: 8,
	},
	'209': {
		id: 209,
		name: 'St. Paul Suburban',
		marketId: 8,
	},
	'210': {
		id: 210,
		name: 'West I-394',
		marketId: 8,
	},
	'211': {
		id: 211,
		name: 'Addison Quorum Bent Tree',
		marketId: 10,
	},
	'212': {
		id: 212,
		name: 'Arlington',
		marketId: 10,
	},
	'213': {
		id: 213,
		name: 'Central Expressway',
		marketId: 10,
	},
	'214': {
		id: 214,
		name: 'Dallas CBD',
		marketId: 10,
	},
	'215': {
		id: 215,
		name: 'East Dallas',
		marketId: 10,
	},
	'216': {
		id: 216,
		name: 'Fort Worth CBD',
		marketId: 10,
	},
	'217': {
		id: 217,
		name: 'Frisco',
		marketId: 10,
	},
	'218': {
		id: 218,
		name: 'Grand Prairie',
		marketId: 10,
	},
	'219': {
		id: 219,
		name: 'Las Colinas',
		marketId: 10,
	},
	'220': {
		id: 220,
		name: 'LBJ Freeway',
		marketId: 10,
	},
	'221': {
		id: 221,
		name: 'Lewisville Denton',
		marketId: 10,
	},
	'222': {
		id: 222,
		name: 'Mid-Cities',
		marketId: 10,
	},
	'223': {
		id: 223,
		name: 'North Fort Worth',
		marketId: 10,
	},
	'224': {
		id: 224,
		name: 'Northeast Fort Worth',
		marketId: 10,
	},
	'225': {
		id: 225,
		name: 'Plano/Allen/McKinney',
		marketId: 10,
	},
	'226': {
		id: 226,
		name: 'Preston Center',
		marketId: 10,
	},
	'227': {
		id: 227,
		name: 'Richardson',
		marketId: 10,
	},
	'228': {
		id: 228,
		name: 'South Fort Worth',
		marketId: 10,
	},
	'229': {
		id: 229,
		name: 'South Irving',
		marketId: 10,
	},
	'230': {
		id: 230,
		name: 'Southwest Dallas',
		marketId: 10,
	},
	'231': {
		id: 231,
		name: 'Stemmons Freeway',
		marketId: 10,
	},
	'232': {
		id: 232,
		name: 'Upper Tollway',
		marketId: 10,
	},
	'233': {
		id: 233,
		name: 'Uptown Turtle Creek',
		marketId: 10,
	},
	'234': {
		id: 234,
		name: 'Buckhead',
		marketId: 11,
	},
	'235': {
		id: 235,
		name: 'Central Perimeter',
		marketId: 11,
	},
	'236': {
		id: 236,
		name: 'Cumberland',
		marketId: 11,
	},
	'237': {
		id: 237,
		name: 'Downtown',
		marketId: 11,
	},
	'238': {
		id: 238,
		name: 'Midtown',
		marketId: 11,
	},
	'239': {
		id: 239,
		name: 'North Fulton',
		marketId: 11,
	},
	'240': {
		id: 240,
		name: 'Northeast Atlanta',
		marketId: 11,
	},
	'241': {
		id: 241,
		name: 'Northlake',
		marketId: 11,
	},
	'242': {
		id: 242,
		name: 'Northwest Atlanta',
		marketId: 11,
	},
	'243': {
		id: 243,
		name: 'South Atlanta',
		marketId: 11,
	},
	'244': {
		id: 244,
		name: 'West Atlanta',
		marketId: 11,
	},
	'245': {
		id: 245,
		name: 'DC Capitol Hill',
		marketId: 5,
	},
	'246': {
		id: 246,
		name: 'DC CBD',
		marketId: 5,
	},
	'247': {
		id: 247,
		name: 'DC East End',
		marketId: 5,
	},
	'248': {
		id: 248,
		name: 'DC Georgetown',
		marketId: 5,
	},
	'249': {
		id: 249,
		name: 'DC NoMa',
		marketId: 5,
	},
	'250': {
		id: 250,
		name: 'DC Northeast',
		marketId: 5,
	},
	'251': {
		id: 251,
		name: 'DC Southeast',
		marketId: 5,
	},
	'252': {
		id: 252,
		name: 'DC Southwest',
		marketId: 5,
	},
	'253': {
		id: 253,
		name: 'DC Uptown',
		marketId: 5,
	},
	'254': {
		id: 254,
		name: 'DC West End',
		marketId: 5,
	},
	'255': {
		id: 255,
		name: 'MD Beltsville/Laurel',
		marketId: 5,
	},
	'256': {
		id: 256,
		name: 'MD Bethesda',
		marketId: 5,
	},
	'257': {
		id: 257,
		name: 'MD Bowie/Upper Marlboro',
		marketId: 5,
	},
	'258': {
		id: 258,
		name: 'MD College Park/Greenbelt',
		marketId: 5,
	},
	'259': {
		id: 259,
		name: 'MD Gaithersburg',
		marketId: 5,
	},
	'260': {
		id: 260,
		name: 'MD Germantown',
		marketId: 5,
	},
	'261': {
		id: 261,
		name: 'MD Lanham/Landover',
		marketId: 5,
	},
	'262': {
		id: 262,
		name: 'MD North Bethesda/Potomac',
		marketId: 5,
	},
	'263': {
		id: 263,
		name: 'MD Rockville',
		marketId: 5,
	},
	'264': {
		id: 264,
		name: 'MD Silver Spring',
		marketId: 5,
	},
	'265': {
		id: 265,
		name: 'MD South PG County',
		marketId: 5,
	},
	'266': {
		id: 266,
		name: 'MD Wheaton/Olney',
		marketId: 5,
	},
	'267': {
		id: 267,
		name: 'VA Chantilly/Centreville',
		marketId: 5,
	},
	'268': {
		id: 268,
		name: 'VA Crystal City',
		marketId: 5,
	},
	'269': {
		id: 269,
		name: 'VA Eisenhower Avenue',
		marketId: 5,
	},
	'270': {
		id: 270,
		name: 'VA Fairfax',
		marketId: 5,
	},
	'271': {
		id: 271,
		name: 'VA Great Falls',
		marketId: 5,
	},
	'272': {
		id: 272,
		name: 'VA Herndon',
		marketId: 5,
	},
	'273': {
		id: 273,
		name: 'VA I-395 Corridor',
		marketId: 5,
	},
	'274': {
		id: 274,
		name: 'VA Loudoun County',
		marketId: 5,
	},
	'275': {
		id: 275,
		name: 'VA McLean',
		marketId: 5,
	},
	'276': {
		id: 276,
		name: 'VA Merrifield/Annandale',
		marketId: 5,
	},
	'277': {
		id: 277,
		name: 'VA Old Town Alexandria',
		marketId: 5,
	},
	'278': {
		id: 278,
		name: 'VA Prince William County',
		marketId: 5,
	},
	'279': {
		id: 279,
		name: 'VA Reston',
		marketId: 5,
	},
	'280': {
		id: 280,
		name: 'VA Rosslyn/Ballston',
		marketId: 5,
	},
	'281': {
		id: 281,
		name: 'VA Route 1 Corridor',
		marketId: 5,
	},
	'282': {
		id: 282,
		name: 'VA Springfield',
		marketId: 5,
	},
	'283': {
		id: 283,
		name: 'VA Tysons Corner',
		marketId: 5,
	},
	'284': {
		id: 284,
		name: 'VA Vienna',
		marketId: 5,
	},
	'285': {
		id: 285,
		name: 'Greenway Plaza',
		marketId: 13,
	},
	'286': {
		id: 286,
		name: 'I10 East',
		marketId: 13,
	},
	'287': {
		id: 287,
		name: 'Katy Freeway',
		marketId: 13,
	},
	'288': {
		id: 288,
		name: 'Kingwood Humble',
		marketId: 13,
	},
	'289': {
		id: 289,
		name: 'North Belt',
		marketId: 13,
	},
	'290': {
		id: 290,
		name: 'Northwest',
		marketId: 13,
	},
	'291': {
		id: 291,
		name: 'Pasadena',
		marketId: 13,
	},
	'292': {
		id: 292,
		name: 'Richmond/Fountain View',
		marketId: 13,
	},
	'293': {
		id: 293,
		name: 'San Felipe Voss',
		marketId: 13,
	},
	'294': {
		id: 294,
		name: 'South Main',
		marketId: 13,
	},
	'295': {
		id: 295,
		name: 'South',
		marketId: 13,
	},
	'296': {
		id: 296,
		name: 'Southwest',
		marketId: 13,
	},
	'297': {
		id: 297,
		name: 'Sugarland',
		marketId: 13,
	},
	'298': {
		id: 298,
		name: 'West Loop',
		marketId: 13,
	},
	'299': {
		id: 299,
		name: 'Westchase',
		marketId: 13,
	},
	'300': {
		id: 300,
		name: 'Woodlands',
		marketId: 13,
	},
	'301': {
		id: 301,
		name: 'Bellaire',
		marketId: 13,
	},
	'302': {
		id: 302,
		name: 'Brookshire',
		marketId: 13,
	},
	'303': {
		id: 303,
		name: 'Clear Lake NASA',
		marketId: 13,
	},
	'304': {
		id: 304,
		name: 'Conroe',
		marketId: 13,
	},
	'305': {
		id: 305,
		name: 'Downtown',
		marketId: 13,
	},
	'306': {
		id: 306,
		name: 'FM 1960',
		marketId: 13,
	},
	'307': {
		id: 307,
		name: 'Calgary Woods',
		marketId: 13,
	},
	'308': {
		id: 308,
		name: '5th Avenue',
		marketId: 16,
	},
	'309': {
		id: 309,
		name: 'Carlsbad',
		marketId: 16,
	},
	'310': {
		id: 310,
		name: 'Del Mar Heights',
		marketId: 16,
	},
	'311': {
		id: 311,
		name: 'Downtown',
		marketId: 16,
	},
	'312': {
		id: 312,
		name: 'East County',
		marketId: 16,
	},
	'313': {
		id: 313,
		name: 'Escondido',
		marketId: 16,
	},
	'314': {
		id: 314,
		name: 'Governor Park',
		marketId: 16,
	},
	'315': {
		id: 315,
		name: 'Kearny Mesa',
		marketId: 16,
	},
	'316': {
		id: 316,
		name: 'La Jolla',
		marketId: 16,
	},
	'317': {
		id: 317,
		name: 'Miramar',
		marketId: 16,
	},
	'318': {
		id: 318,
		name: 'Mission Gorge',
		marketId: 16,
	},
	'319': {
		id: 319,
		name: 'Mission Valley',
		marketId: 16,
	},
	'320': {
		id: 320,
		name: 'Naval Air Station North Island',
		marketId: 16,
	},
	'321': {
		id: 321,
		name: 'North Beach Cities',
		marketId: 16,
	},
	'322': {
		id: 322,
		name: 'Oceanside',
		marketId: 16,
	},
	'323': {
		id: 323,
		name: 'Point Loma/Old Town',
		marketId: 16,
	},
	'324': {
		id: 324,
		name: 'Rancho Bernardo/Poway',
		marketId: 16,
	},
	'325': {
		id: 325,
		name: 'Rose Canyon',
		marketId: 16,
	},
	'326': {
		id: 326,
		name: 'Rural Area',
		marketId: 16,
	},
	'327': {
		id: 327,
		name: 'San Marcos/Vista',
		marketId: 16,
	},
	'328': {
		id: 328,
		name: 'Scripps Ranch',
		marketId: 16,
	},
	'329': {
		id: 329,
		name: 'Sorrento Mesa',
		marketId: 16,
	},
	'330': {
		id: 330,
		name: 'South San Diego',
		marketId: 16,
	},
	'331': {
		id: 331,
		name: 'UTC',
		marketId: 16,
	},
	'333': {
		id: 333,
		name: 'Gilroy',
		marketId: 3,
	},
	'334': {
		id: 334,
		name: 'Morgan Hill',
		marketId: 3,
	},
	'335': {
		id: 335,
		name: 'Pajaro',
		marketId: 3,
	},
	'336': {
		id: 336,
		name: 'San Lorenzo Valley',
		marketId: 3,
	},
	'337': {
		id: 337,
		name: 'Santa Cruz',
		marketId: 3,
	},
	'338': {
		id: 338,
		name: 'Watsonville',
		marketId: 3,
	},
	'339': {
		id: 339,
		name: 'West Santa Cruz',
		marketId: 3,
	},
	'340': {
		id: 340,
		name: 'Castroville',
		marketId: 3,
	},
	'341': {
		id: 341,
		name: 'Salinas',
		marketId: 3,
	},
	'342': {
		id: 342,
		name: 'Seaside/Monterey',
		marketId: 3,
	},
	'343': {
		id: 343,
		name: 'Dixon',
		marketId: 3,
	},
	'344': {
		id: 344,
		name: 'Fairfield/Suisun City',
		marketId: 3,
	},
	'345': {
		id: 345,
		name: 'Rio Vista',
		marketId: 3,
	},
	'346': {
		id: 346,
		name: 'Vacaville',
		marketId: 3,
	},
	'347': {
		id: 347,
		name: 'Vallejo',
		marketId: 3,
	},
	'348': {
		id: 348,
		name: 'Campus Commons',
		marketId: 9,
	},
	'349': {
		id: 349,
		name: 'Carmichael/Fair Oaks',
		marketId: 9,
	},
	'350': {
		id: 350,
		name: 'Citrus Heights/Orangevale',
		marketId: 9,
	},
	'351': {
		id: 351,
		name: 'Downtown',
		marketId: 9,
	},
	'352': {
		id: 352,
		name: 'El Dorado County',
		marketId: 9,
	},
	'353': {
		id: 353,
		name: 'Folsom',
		marketId: 9,
	},
	'354': {
		id: 354,
		name: 'Highway 50 Corridor',
		marketId: 9,
	},
	'355': {
		id: 355,
		name: 'Howe Ave/Fulton Ave',
		marketId: 9,
	},
	'356': {
		id: 356,
		name: 'Midtown',
		marketId: 9,
	},
	'357': {
		id: 357,
		name: 'Natomas/Northgate',
		marketId: 9,
	},
	'358': {
		id: 358,
		name: 'North Highlands',
		marketId: 9,
	},
	'359': {
		id: 359,
		name: 'Point West',
		marketId: 9,
	},
	'360': {
		id: 360,
		name: 'Roseville/Rocklin',
		marketId: 9,
	},
	'361': {
		id: 361,
		name: 'South Natomas',
		marketId: 9,
	},
	'362': {
		id: 362,
		name: 'South Sacramento',
		marketId: 9,
	},
	'363': {
		id: 363,
		name: 'Watt Avenue',
		marketId: 9,
	},
	'364': {
		id: 364,
		name: 'West Sacramento',
		marketId: 9,
	},
	'365': {
		id: 365,
		name: 'Woodland/Davis',
		marketId: 9,
	},
	'366': {
		id: 366,
		name: 'Fresno',
		marketId: 9,
	},
	'367': {
		id: 367,
		name: 'Lodi',
		marketId: 9,
	},
	'368': {
		id: 368,
		name: 'Madera',
		marketId: 9,
	},
	'369': {
		id: 369,
		name: 'Mariposa',
		marketId: 9,
	},
	'370': {
		id: 370,
		name: 'Merced',
		marketId: 9,
	},
	'371': {
		id: 371,
		name: 'Modesto',
		marketId: 9,
	},
	'372': {
		id: 372,
		name: 'Nevada',
		marketId: 9,
	},
	'373': {
		id: 373,
		name: 'Redding',
		marketId: 9,
	},
	'374': {
		id: 374,
		name: 'Stockton',
		marketId: 9,
	},
	'375': {
		id: 375,
		name: 'Tracy',
		marketId: 9,
	},
	'376': {
		id: 376,
		name: 'Tulare',
		marketId: 9,
	},
	'377': {
		id: 377,
		name: 'Turlock',
		marketId: 9,
	},
	'378': {
		id: 378,
		name: 'Tehama',
		marketId: 9,
	},
	'379': {
		id: 379,
		name: 'Tuolumne',
		marketId: 9,
	},
	'380': {
		id: 380,
		name: 'Calaveras',
		marketId: 9,
	},
	'381': {
		id: 381,
		name: 'Amador',
		marketId: 9,
	},
	'382': {
		id: 382,
		name: 'Yuba City',
		marketId: 9,
	},
	'383': {
		id: 383,
		name: 'Sutter County',
		marketId: 9,
	},
	'384': {
		id: 384,
		name: 'Oakdale/Waterford',
		marketId: 9,
	},
	'385': {
		id: 385,
		name: 'Manteca/Lathrop',
		marketId: 9,
	},
	'386': {
		id: 386,
		name: 'Kings County',
		marketId: 9,
	},
	'387': {
		id: 387,
		name: 'Chico/Oroville',
		marketId: 9,
	},
	'388': {
		id: 388,
		name: 'Glenn County',
		marketId: 9,
	},
	'389': {
		id: 389,
		name: 'Bakersfield',
		marketId: 9,
	},
	'390': {
		id: 390,
		name: 'Colusa',
		marketId: 9,
	},
	'391': {
		id: 391,
		name: 'Brooklyn',
		marketId: 1,
	},
	'392': {
		id: 392,
		name: 'Bronx',
		marketId: 1,
	},
	'393': {
		id: 393,
		name: 'Queens',
		marketId: 1,
	},
	'423': {
		id: 423,
		name: 'West Cambridge',
		marketId: 7,
	},
	'424': {
		id: 424,
		name: 'Allston-Brighton',
		marketId: 7,
	},
	'425': {
		id: 425,
		name: 'Back Bay',
		marketId: 7,
	},
	'426': {
		id: 426,
		name: 'Brookline',
		marketId: 7,
	},
	'427': {
		id: 427,
		name: 'Burlington Lowell',
		marketId: 7,
	},
	'428': {
		id: 428,
		name: 'Charlestown',
		marketId: 7,
	},
	'429': {
		id: 429,
		name: 'Concord',
		marketId: 7,
	},
	'430': {
		id: 430,
		name: 'East Boston',
		marketId: 7,
	},
	'431': {
		id: 431,
		name: 'East Cambridge',
		marketId: 7,
	},
	'432': {
		id: 432,
		name: 'Far North',
		marketId: 7,
	},
	'433': {
		id: 433,
		name: 'Financial District',
		marketId: 7,
	},
	'434': {
		id: 434,
		name: 'I-95 South',
		marketId: 7,
	},
	'435': {
		id: 435,
		name: 'Metro West',
		marketId: 7,
	},
	'436': {
		id: 436,
		name: 'Mid-Cambridge',
		marketId: 7,
	},
	'437': {
		id: 437,
		name: 'Mid-Town',
		marketId: 7,
	},
	'438': {
		id: 438,
		name: 'Near North',
		marketId: 7,
	},
	'439': {
		id: 439,
		name: 'Newton/Wellesley',
		marketId: 7,
	},
	'440': {
		id: 440,
		name: 'North End',
		marketId: 7,
	},
	'441': {
		id: 441,
		name: 'North Station Government Center',
		marketId: 7,
	},
	'442': {
		id: 442,
		name: 'Quincy Route 3 Corridor',
		marketId: 7,
	},
	'443': {
		id: 443,
		name: 'Roxbury/Dorchester',
		marketId: 7,
	},
	'444': {
		id: 444,
		name: 'Seaport',
		marketId: 7,
	},
	'445': {
		id: 445,
		name: 'Waltham Watertown',
		marketId: 7,
	},
	'446': {
		id: 446,
		name: 'Vashon Island',
		marketId: 17,
	},
	'447': {
		id: 447,
		name: '520 Corridor',
		marketId: 17,
	},
	'448': {
		id: 448,
		name: 'Ballard University District',
		marketId: 17,
	},
	'449': {
		id: 449,
		name: 'Bellevue CBD',
		marketId: 17,
	},
	'450': {
		id: 450,
		name: 'Bellevue Suburban',
		marketId: 17,
	},
	'451': {
		id: 451,
		name: 'Bothell/Kenmore',
		marketId: 17,
	},
	'452': {
		id: 452,
		name: 'Capitol Hill East Seattle',
		marketId: 17,
	},
	'453': {
		id: 453,
		name: 'Denny Triangle Regrade',
		marketId: 17,
	},
	'454': {
		id: 454,
		name: 'East King County',
		marketId: 17,
	},
	'455': {
		id: 455,
		name: 'East Pierce County',
		marketId: 17,
	},
	'456': {
		id: 456,
		name: 'East Snohomish County',
		marketId: 17,
	},
	'457': {
		id: 457,
		name: 'Everett',
		marketId: 17,
	},
	'458': {
		id: 458,
		name: 'Federal Way Auburn',
		marketId: 17,
	},
	'459': {
		id: 459,
		name: 'I90 Corridor',
		marketId: 17,
	},
	'460': {
		id: 460,
		name: 'Kent Valley',
		marketId: 17,
	},
	'461': {
		id: 461,
		name: 'Kirkland',
		marketId: 17,
	},
	'462': {
		id: 462,
		name: 'Lake Union',
		marketId: 17,
	},
	'463': {
		id: 463,
		name: 'Mercer Island',
		marketId: 17,
	},
	'464': {
		id: 464,
		name: 'Northend',
		marketId: 17,
	},
	'465': {
		id: 465,
		name: 'Northgate',
		marketId: 17,
	},
	'466': {
		id: 466,
		name: 'Pioneer Square Waterfront',
		marketId: 17,
	},
	'467': {
		id: 467,
		name: 'Queen Avenue Magnolia',
		marketId: 17,
	},
	'468': {
		id: 468,
		name: 'Redmond',
		marketId: 17,
	},
	'469': {
		id: 469,
		name: 'Renton/Tukwila',
		marketId: 17,
	},
	'470': {
		id: 470,
		name: 'SeaTac Vurien',
		marketId: 17,
	},
	'471': {
		id: 471,
		name: 'Seattle CBD',
		marketId: 17,
	},
	'472': {
		id: 472,
		name: 'South Seattle',
		marketId: 17,
	},
	'473': {
		id: 473,
		name: 'Tacoma CBD',
		marketId: 17,
	},
	'474': {
		id: 474,
		name: 'Tacoma Suburban',
		marketId: 17,
	},
	'504': {
		id: 504,
		name: 'Western I-80 Corridor',
		marketId: 18,
	},
	'505': {
		id: 505,
		name: 'Western Route 78',
		marketId: 18,
	},
	'506': {
		id: 506,
		name: 'Central Bergen',
		marketId: 18,
	},
	'507': {
		id: 507,
		name: 'Meadowlands',
		marketId: 18,
	},
	'508': {
		id: 508,
		name: 'Monmouth',
		marketId: 18,
	},
	'509': {
		id: 509,
		name: 'Morristown',
		marketId: 18,
	},
	'510': {
		id: 510,
		name: 'Palisades',
		marketId: 18,
	},
	'511': {
		id: 511,
		name: 'Parkway Corridor',
		marketId: 18,
	},
	'512': {
		id: 512,
		name: 'Parsippany',
		marketId: 18,
	},
	'513': {
		id: 513,
		name: 'Princeton',
		marketId: 18,
	},
	'514': {
		id: 514,
		name: 'Urban Essex',
		marketId: 18,
	},
	'515': {
		id: 515,
		name: 'Waterfront',
		marketId: 18,
	},
	'516': {
		id: 516,
		name: 'Montvale/Woodcliff Lake',
		marketId: 18,
	},
	'517': {
		id: 517,
		name: 'Paterson/Wayne/Route 23',
		marketId: 18,
	},
	'518': {
		id: 518,
		name: 'Piscataway/Brunswicks/Route 287',
		marketId: 18,
	},
	'519': {
		id: 519,
		name: 'Route 78/287 Interchange',
		marketId: 18,
	},
	'520': {
		id: 520,
		name: 'Suburban Essex/Eastern Morris',
		marketId: 18,
	},
	'521': {
		id: 521,
		name: 'West Bergen/Route 17 Corridor',
		marketId: 18,
	},
	'522': {
		id: 522,
		name: 'Chatham/Millburn/Short Hills',
		marketId: 18,
	},
	'523': {
		id: 523,
		name: 'West',
		marketId: 14,
	},
	'524': {
		id: 524,
		name: 'Aurora',
		marketId: 14,
	},
	'525': {
		id: 525,
		name: 'Boulder',
		marketId: 14,
	},
	'526': {
		id: 526,
		name: 'Broomfield',
		marketId: 14,
	},
	'527': {
		id: 527,
		name: 'Central',
		marketId: 14,
	},
	'528': {
		id: 528,
		name: 'Downtown',
		marketId: 14,
	},
	'529': {
		id: 529,
		name: 'Fort Collins/Loveland',
		marketId: 14,
	},
	'530': {
		id: 530,
		name: 'Glendale',
		marketId: 14,
	},
	'531': {
		id: 531,
		name: 'Longmont',
		marketId: 14,
	},
	'532': {
		id: 532,
		name: 'North',
		marketId: 14,
	},
	'533': {
		id: 533,
		name: 'North Central',
		marketId: 14,
	},
	'534': {
		id: 534,
		name: 'Northeast',
		marketId: 14,
	},
	'535': {
		id: 535,
		name: 'Parker/Castel Rock',
		marketId: 14,
	},
	'536': {
		id: 536,
		name: 'South Central',
		marketId: 14,
	},
	'537': {
		id: 537,
		name: 'Southeast',
		marketId: 14,
	},
	'538': {
		id: 538,
		name: 'Southwest',
		marketId: 14,
	},
	'539': {
		id: 539,
		name: 'Colorado Springs',
		marketId: 14,
	},
	'540': {
		id: 540,
		name: 'CBD',
		marketId: 28,
	},
	'541': {
		id: 541,
		name: 'North Central',
		marketId: 28,
	},
	'542': {
		id: 542,
		name: 'Northeast',
		marketId: 28,
	},
	'543': {
		id: 543,
		name: 'Northwest',
		marketId: 28,
	},
	'544': {
		id: 544,
		name: 'South',
		marketId: 28,
	},
	'554': {
		id: 554,
		name: 'CBD',
		marketId: 27,
	},
	'555': {
		id: 555,
		name: 'Central',
		marketId: 27,
	},
	'556': {
		id: 556,
		name: 'East',
		marketId: 27,
	},
	'557': {
		id: 557,
		name: 'Far Northwest',
		marketId: 27,
	},
	'558': {
		id: 558,
		name: 'North',
		marketId: 27,
	},
	'559': {
		id: 559,
		name: 'Northeast',
		marketId: 27,
	},
	'560': {
		id: 560,
		name: 'Northwest',
		marketId: 27,
	},
	'561': {
		id: 561,
		name: 'South',
		marketId: 27,
	},
	'562': {
		id: 562,
		name: 'Southwest',
		marketId: 27,
	},
	'579': {
		id: 579,
		name: 'Alaska',
		marketId: 41,
	},
	'581': {
		id: 581,
		name: 'Arizona',
		marketId: 43,
	},
	'582': {
		id: 582,
		name: 'Arkansas',
		marketId: 44,
	},
	'583': {
		id: 583,
		name: 'California',
		marketId: 45,
	},
	'584': {
		id: 584,
		name: 'Colorado',
		marketId: 46,
	},
	'588': {
		id: 588,
		name: 'Georgia',
		marketId: 50,
	},
	'591': {
		id: 591,
		name: 'Idaho',
		marketId: 53,
	},
	'592': {
		id: 592,
		name: 'Illinois',
		marketId: 54,
	},
	'596': {
		id: 596,
		name: 'Kansas',
		marketId: 58,
	},
	'598': {
		id: 598,
		name: 'Louisiana',
		marketId: 60,
	},
	'599': {
		id: 599,
		name: 'Maine',
		marketId: 61,
	},
	'601': {
		id: 601,
		name: 'Massachusetts',
		marketId: 63,
	},
	'602': {
		id: 602,
		name: 'Michigan',
		marketId: 64,
	},
	'603': {
		id: 603,
		name: 'Minnesota',
		marketId: 65,
	},
	'604': {
		id: 604,
		name: 'Mississippi',
		marketId: 66,
	},
	'605': {
		id: 605,
		name: 'Missouri',
		marketId: 67,
	},
	'606': {
		id: 606,
		name: 'Montana',
		marketId: 68,
	},
	'608': {
		id: 608,
		name: 'Nevada',
		marketId: 70,
	},
	'609': {
		id: 609,
		name: 'New Hampshire',
		marketId: 71,
	},
	'612': {
		id: 612,
		name: 'New York',
		marketId: 74,
	},
	'613': {
		id: 613,
		name: 'North Carolina',
		marketId: 75,
	},
	'614': {
		id: 614,
		name: 'North Dakota',
		marketId: 76,
	},
	'615': {
		id: 615,
		name: 'Ohio',
		marketId: 77,
	},
	'616': {
		id: 616,
		name: 'Oklahoma',
		marketId: 78,
	},
	'617': {
		id: 617,
		name: 'Oregon',
		marketId: 79,
	},
	'618': {
		id: 618,
		name: 'Pennsylvania',
		marketId: 80,
	},
	'620': {
		id: 620,
		name: 'Rhode Island',
		marketId: 82,
	},
	'622': {
		id: 622,
		name: 'South Dakota',
		marketId: 84,
	},
	'623': {
		id: 623,
		name: 'Tennessee',
		marketId: 85,
	},
	'625': {
		id: 625,
		name: 'Utah',
		marketId: 87,
	},
	'626': {
		id: 626,
		name: 'Vermont',
		marketId: 88,
	},
	'628': {
		id: 628,
		name: 'Virginia',
		marketId: 90,
	},
	'629': {
		id: 629,
		name: 'Washington',
		marketId: 91,
	},
	'630': {
		id: 630,
		name: 'West Virginia',
		marketId: 92,
	},
	'631': {
		id: 631,
		name: 'Wisconsin',
		marketId: 93,
	},
	'632': {
		id: 632,
		name: 'Wyoming',
		marketId: 94,
	},
	'635': {
		id: 635,
		name: 'Maryland East',
		marketId: 62,
	},
	'636': {
		id: 636,
		name: 'Maryland West',
		marketId: 62,
	},
	'671': {
		id: 671,
		name: 'Airport International Center',
		marketId: 32,
	},
	'672': {
		id: 672,
		name: 'CBD',
		marketId: 32,
	},
	'673': {
		id: 673,
		name: 'South West Valley',
		marketId: 32,
	},
	'674': {
		id: 674,
		name: 'Davis/Weber County',
		marketId: 32,
	},
	'675': {
		id: 675,
		name: 'Research Park',
		marketId: 32,
	},
	'676': {
		id: 676,
		name: 'Park City',
		marketId: 32,
	},
	'677': {
		id: 677,
		name: 'Draper',
		marketId: 32,
	},
	'678': {
		id: 678,
		name: 'Central Valley',
		marketId: 32,
	},
	'679': {
		id: 679,
		name: 'Union Park/Cottonwood',
		marketId: 32,
	},
	'680': {
		id: 680,
		name: 'Central Valley West',
		marketId: 32,
	},
	'681': {
		id: 681,
		name: 'Provo/Orem',
		marketId: 32,
	},
	'682': {
		id: 682,
		name: 'West Valley',
		marketId: 32,
	},
	'683': {
		id: 683,
		name: 'Sandy',
		marketId: 32,
	},
	'684': {
		id: 684,
		name: 'Sugarhouse/Foothill Corridor',
		marketId: 32,
	},
	'685': {
		id: 685,
		name: 'Central Valley East',
		marketId: 32,
	},
	'686': {
		id: 686,
		name: 'Lehigh/Northampton',
		marketId: 15,
	},
	'687': {
		id: 687,
		name: 'Horsham/Willow Grove',
		marketId: 15,
	},
	'688': {
		id: 688,
		name: 'Main Line',
		marketId: 15,
	},
	'689': {
		id: 689,
		name: 'Columbia/Schuylkill County',
		marketId: 15,
	},
	'690': {
		id: 690,
		name: 'Chester County',
		marketId: 15,
	},
	'691': {
		id: 691,
		name: 'Sussex County',
		marketId: 15,
	},
	'692': {
		id: 692,
		name: 'Wilmington',
		marketId: 15,
	},
	'693': {
		id: 693,
		name: 'Atlantic County',
		marketId: 15,
	},
	'694': {
		id: 694,
		name: 'Camden County',
		marketId: 15,
	},
	'695': {
		id: 695,
		name: 'Market Street East',
		marketId: 15,
	},
	'696': {
		id: 696,
		name: 'Harrisburg East',
		marketId: 15,
	},
	'697': {
		id: 697,
		name: 'Delaware County',
		marketId: 15,
	},
	'698': {
		id: 698,
		name: 'Conshohocken',
		marketId: 15,
	},
	'699': {
		id: 699,
		name: 'Lancaster County',
		marketId: 15,
	},
	'700': {
		id: 700,
		name: 'Kent County',
		marketId: 15,
	},
	'701': {
		id: 701,
		name: 'Fort Washington',
		marketId: 15,
	},
	'702': {
		id: 702,
		name: 'Burlington County',
		marketId: 15,
	},
	'703': {
		id: 703,
		name: 'Gloucester County',
		marketId: 15,
	},
	'704': {
		id: 704,
		name: 'Outer Philadelphia',
		marketId: 15,
	},
	'705': {
		id: 705,
		name: 'I-81 Corridor',
		marketId: 15,
	},
	'706': {
		id: 706,
		name: 'Bucks County',
		marketId: 15,
	},
	'707': {
		id: 707,
		name: 'King of Prussia',
		marketId: 15,
	},
	'708': {
		id: 708,
		name: 'Carbon/Monroe County',
		marketId: 15,
	},
	'709': {
		id: 709,
		name: 'Market Street West',
		marketId: 15,
	},
	'710': {
		id: 710,
		name: 'Plymouth Meeting',
		marketId: 15,
	},
	'711': {
		id: 711,
		name: 'Southern New Jersey',
		marketId: 15,
	},
	'712': {
		id: 712,
		name: 'Adams/York County',
		marketId: 15,
	},
	'713': {
		id: 713,
		name: 'Harrisburg West',
		marketId: 15,
	},
	'714': {
		id: 714,
		name: 'Berks County',
		marketId: 15,
	},
	'715': {
		id: 715,
		name: 'Independence Hall',
		marketId: 15,
	},
	'716': {
		id: 716,
		name: 'West Montgomery County',
		marketId: 15,
	},
	'717': {
		id: 717,
		name: 'Downtown',
		marketId: 26,
	},
	'718': {
		id: 718,
		name: 'Scottsdale Airpark',
		marketId: 26,
	},
	'719': {
		id: 719,
		name: 'Mesa',
		marketId: 26,
	},
	'720': {
		id: 720,
		name: 'Scottsdale Central',
		marketId: 26,
	},
	'721': {
		id: 721,
		name: 'Camelback Corridor',
		marketId: 26,
	},
	'722': {
		id: 722,
		name: 'Chandler/Gilbert',
		marketId: 26,
	},
	'723': {
		id: 723,
		name: 'Arrowhead',
		marketId: 26,
	},
	'724': {
		id: 724,
		name: 'Deer Valley',
		marketId: 26,
	},
	'725': {
		id: 725,
		name: 'Piestewa Peak Corridor',
		marketId: 26,
	},
	'726': {
		id: 726,
		name: '44th Street Corridor',
		marketId: 26,
	},
	'727': {
		id: 727,
		name: 'South I-10/Ahwatukee',
		marketId: 26,
	},
	'728': {
		id: 728,
		name: 'Glendale/Peoria',
		marketId: 26,
	},
	'729': {
		id: 729,
		name: 'West Phoenix',
		marketId: 26,
	},
	'730': {
		id: 730,
		name: 'Scottsdale South',
		marketId: 26,
	},
	'731': {
		id: 731,
		name: 'Metrocenter',
		marketId: 26,
	},
	'732': {
		id: 732,
		name: 'Midtown',
		marketId: 26,
	},
	'733': {
		id: 733,
		name: 'Tempe North',
		marketId: 26,
	},
	'734': {
		id: 734,
		name: 'North Phoenix',
		marketId: 26,
	},
	'735': {
		id: 735,
		name: 'Sky Harbor',
		marketId: 26,
	},
	'736': {
		id: 736,
		name: 'East Central Phoenix',
		marketId: 26,
	},
	'770': {
		id: 770,
		name: 'Mid-Pinellas',
		marketId: 21,
	},
	'771': {
		id: 771,
		name: 'Downtown Tampa',
		marketId: 21,
	},
	'772': {
		id: 772,
		name: 'Spring Hill/Dade City',
		marketId: 21,
	},
	'773': {
		id: 773,
		name: 'Southwest Tampa',
		marketId: 21,
	},
	'774': {
		id: 774,
		name: 'Bradenton/Palmetto',
		marketId: 21,
	},
	'775': {
		id: 775,
		name: 'Southeast Tampa',
		marketId: 21,
	},
	'776': {
		id: 776,
		name: 'Southeast St. Petersburg',
		marketId: 21,
	},
	'777': {
		id: 777,
		name: 'East Tampa',
		marketId: 21,
	},
	'778': {
		id: 778,
		name: 'Westshore',
		marketId: 21,
	},
	'779': {
		id: 779,
		name: 'Clearwater',
		marketId: 21,
	},
	'780': {
		id: 780,
		name: 'Lakeland/Winter Haven',
		marketId: 21,
	},
	'781': {
		id: 781,
		name: 'Northeast Tampa',
		marketId: 21,
	},
	'782': {
		id: 782,
		name: 'Northwest Tampa',
		marketId: 21,
	},
	'783': {
		id: 783,
		name: 'Southwest St. Petersburg',
		marketId: 21,
	},
	'784': {
		id: 784,
		name: 'North Pinellas',
		marketId: 21,
	},
	'785': {
		id: 785,
		name: 'Gainesville',
		marketId: 22,
	},
	'786': {
		id: 786,
		name: 'Putnam',
		marketId: 22,
	},
	'787': {
		id: 787,
		name: 'Northside',
		marketId: 22,
	},
	'788': {
		id: 788,
		name: 'Arlington',
		marketId: 22,
	},
	'789': {
		id: 789,
		name: 'I-95 Corridor',
		marketId: 22,
	},
	'790': {
		id: 790,
		name: 'St. Johns/Flagler',
		marketId: 22,
	},
	'791': {
		id: 791,
		name: 'East Butler',
		marketId: 22,
	},
	'792': {
		id: 792,
		name: 'Baker',
		marketId: 22,
	},
	'793': {
		id: 793,
		name: 'Mandarin',
		marketId: 22,
	},
	'794': {
		id: 794,
		name: 'Westside',
		marketId: 22,
	},
	'795': {
		id: 795,
		name: 'Clay',
		marketId: 22,
	},
	'796': {
		id: 796,
		name: 'Southside',
		marketId: 22,
	},
	'797': {
		id: 797,
		name: 'Downtown',
		marketId: 22,
	},
	'798': {
		id: 798,
		name: 'Jacksonville Beaches',
		marketId: 22,
	},
	'799': {
		id: 799,
		name: 'Tallahassee',
		marketId: 97,
	},
	'800': {
		id: 800,
		name: 'Panama City',
		marketId: 97,
	},
	'801': {
		id: 801,
		name: 'Destin',
		marketId: 97,
	},
	'802': {
		id: 802,
		name: 'Pensacola',
		marketId: 97,
	},
	'803': {
		id: 803,
		name: 'Ocala',
		marketId: 97,
	},
	'804': {
		id: 804,
		name: 'Perry/Lake City',
		marketId: 97,
	},
	'805': {
		id: 805,
		name: 'Marianna',
		marketId: 97,
	},
	'806': {
		id: 806,
		name: 'Cape Coral/Fort Myers',
		marketId: 98,
	},
	'807': {
		id: 807,
		name: 'Highland/Glades/Hendry',
		marketId: 98,
	},
	'808': {
		id: 808,
		name: 'Naples',
		marketId: 98,
	},
	'809': {
		id: 809,
		name: 'Sarasota',
		marketId: 98,
	},
	'811': {
		id: 811,
		name: 'Maitland Center',
		marketId: 33,
	},
	'812': {
		id: 812,
		name: 'Indian River/St. Lucie',
		marketId: 33,
	},
	'813': {
		id: 813,
		name: 'East Orange',
		marketId: 33,
	},
	'814': {
		id: 814,
		name: 'Lake Mary/Heathrow',
		marketId: 33,
	},
	'815': {
		id: 815,
		name: 'Seminole',
		marketId: 33,
	},
	'816': {
		id: 816,
		name: 'North Orlando',
		marketId: 33,
	},
	'817': {
		id: 817,
		name: 'East Orlando',
		marketId: 33,
	},
	'818': {
		id: 818,
		name: 'Brevard/Palm Bay',
		marketId: 33,
	},
	'819': {
		id: 819,
		name: 'Downtown',
		marketId: 33,
	},
	'820': {
		id: 820,
		name: 'Osceola',
		marketId: 33,
	},
	'821': {
		id: 821,
		name: 'South Orlando',
		marketId: 33,
	},
	'822': {
		id: 822,
		name: 'Lake County',
		marketId: 33,
	},
	'823': {
		id: 823,
		name: 'Kissimmee/St. Cloud',
		marketId: 33,
	},
	'824': {
		id: 824,
		name: 'Sand Lake/Tourist',
		marketId: 33,
	},
	'825': {
		id: 825,
		name: 'Commercial Corridor',
		marketId: 19,
	},
	'826': {
		id: 826,
		name: 'Aventura',
		marketId: 19,
	},
	'827': {
		id: 827,
		name: 'Sunny Isles',
		marketId: 19,
	},
	'828': {
		id: 828,
		name: 'Miami Lakes',
		marketId: 19,
	},
	'829': {
		id: 829,
		name: 'Miami Airport',
		marketId: 19,
	},
	'830': {
		id: 830,
		name: 'North Palm Beach',
		marketId: 19,
	},
	'831': {
		id: 831,
		name: 'Boynton Beach',
		marketId: 19,
	},
	'832': {
		id: 832,
		name: 'Hollywood/Hallandale',
		marketId: 19,
	},
	'833': {
		id: 833,
		name: 'Opa-locka/Hialeah',
		marketId: 19,
	},
	'834': {
		id: 834,
		name: 'Coral Springs',
		marketId: 19,
	},
	'835': {
		id: 835,
		name: 'Belle Glade',
		marketId: 19,
	},
	'836': {
		id: 836,
		name: 'West Palm Beach',
		marketId: 19,
	},
	'837': {
		id: 837,
		name: 'Palm Beach',
		marketId: 19,
	},
	'838': {
		id: 838,
		name: 'Miami Beach',
		marketId: 19,
	},
	'839': {
		id: 839,
		name: 'Lake Worth',
		marketId: 19,
	},
	'840': {
		id: 840,
		name: 'Kendall/Homestead',
		marketId: 19,
	},
	'841': {
		id: 841,
		name: 'Pompano/Lauderdale',
		marketId: 19,
	},
	'842': {
		id: 842,
		name: 'Key West',
		marketId: 19,
	},
	'843': {
		id: 843,
		name: 'North Miami',
		marketId: 19,
	},
	'844': {
		id: 844,
		name: 'Delray Beach',
		marketId: 19,
	},
	'845': {
		id: 845,
		name: 'Miami CBD',
		marketId: 19,
	},
	'846': {
		id: 846,
		name: 'Southwest Broward',
		marketId: 19,
	},
	'847': {
		id: 847,
		name: 'Jupiter',
		marketId: 19,
	},
	'848': {
		id: 848,
		name: 'Fort Lauderdale',
		marketId: 19,
	},
	'849': {
		id: 849,
		name: 'Brickell',
		marketId: 19,
	},
	'850': {
		id: 850,
		name: 'Downtown Fort Lauderdale',
		marketId: 19,
	},
	'851': {
		id: 851,
		name: 'Northeast Broward',
		marketId: 19,
	},
	'852': {
		id: 852,
		name: 'Everglades',
		marketId: 19,
	},
	'853': {
		id: 853,
		name: 'Coral Gables',
		marketId: 19,
	},
	'854': {
		id: 854,
		name: 'Coconut Grove',
		marketId: 19,
	},
	'855': {
		id: 855,
		name: 'Boca Raton',
		marketId: 19,
	},
	'856': {
		id: 856,
		name: 'Biscayne Corridor',
		marketId: 19,
	},
	'857': {
		id: 857,
		name: 'Plantation',
		marketId: 19,
	},
	'858': {
		id: 858,
		name: 'North Baltimore',
		marketId: 23,
	},
	'859': {
		id: 859,
		name: 'Howard County',
		marketId: 23,
	},
	'860': {
		id: 860,
		name: 'East Baltimore',
		marketId: 23,
	},
	'861': {
		id: 861,
		name: 'Columbia',
		marketId: 23,
	},
	'862': {
		id: 862,
		name: 'Towson',
		marketId: 23,
	},
	'863': {
		id: 863,
		name: 'West Side',
		marketId: 23,
	},
	'864': {
		id: 864,
		name: 'I-83 Corridor',
		marketId: 23,
	},
	'865': {
		id: 865,
		name: 'Annapolis',
		marketId: 23,
	},
	'866': {
		id: 866,
		name: 'Baltimore County East',
		marketId: 23,
	},
	'867': {
		id: 867,
		name: 'West Baltimore',
		marketId: 23,
	},
	'868': {
		id: 868,
		name: 'BWI Corridor',
		marketId: 23,
	},
	'869': {
		id: 869,
		name: 'Reisterstown Road Corridor',
		marketId: 23,
	},
	'870': {
		id: 870,
		name: 'Route 2 Corridor',
		marketId: 23,
	},
	'871': {
		id: 871,
		name: 'Harford County',
		marketId: 23,
	},
	'872': {
		id: 872,
		name: 'Anne Arundel County',
		marketId: 23,
	},
	'873': {
		id: 873,
		name: 'CBD',
		marketId: 23,
	},
	'874': {
		id: 874,
		name: 'Stamford Suburban',
		marketId: 20,
	},
	'875': {
		id: 875,
		name: 'Central Fairfield County',
		marketId: 20,
	},
	'876': {
		id: 876,
		name: 'North Fairfield County',
		marketId: 20,
	},
	'877': {
		id: 877,
		name: 'White Plains Suburban',
		marketId: 20,
	},
	'878': {
		id: 878,
		name: 'Westchester East',
		marketId: 20,
	},
	'879': {
		id: 879,
		name: 'Westchester West',
		marketId: 20,
	},
	'880': {
		id: 880,
		name: 'Westchester North',
		marketId: 20,
	},
	'881': {
		id: 881,
		name: 'Greenwich',
		marketId: 20,
	},
	'882': {
		id: 882,
		name: 'Westchester South',
		marketId: 20,
	},
	'883': {
		id: 883,
		name: 'East Fairfield County',
		marketId: 20,
	},
	'884': {
		id: 884,
		name: 'White Plains CBD',
		marketId: 20,
	},
	'885': {
		id: 885,
		name: 'Stamford CBD',
		marketId: 20,
	},
	'886': {
		id: 886,
		name: 'Staten Island',
		marketId: 1,
	},
	'889': {
		id: 889,
		name: 'Kansas City Kansas',
		marketId: 101,
	},
	'890': {
		id: 890,
		name: 'Kansas City North',
		marketId: 101,
	},
	'891': {
		id: 891,
		name: 'Midtown',
		marketId: 101,
	},
	'892': {
		id: 892,
		name: 'Downtown',
		marketId: 101,
	},
	'893': {
		id: 893,
		name: 'South Kansas City',
		marketId: 101,
	},
	'894': {
		id: 894,
		name: 'North Johnson County',
		marketId: 101,
	},
	'895': {
		id: 895,
		name: 'South Johnson County',
		marketId: 101,
	},
	'896': {
		id: 896,
		name: 'East Kansas City',
		marketId: 101,
	},
	'897': {
		id: 897,
		name: 'St. Louis City',
		marketId: 100,
	},
	'898': {
		id: 898,
		name: 'Northwest County',
		marketId: 100,
	},
	'899': {
		id: 899,
		name: 'Mid-County',
		marketId: 100,
	},
	'900': {
		id: 900,
		name: 'East St. Louis',
		marketId: 100,
	},
	'901': {
		id: 901,
		name: 'South County',
		marketId: 100,
	},
	'902': {
		id: 902,
		name: 'CBD',
		marketId: 100,
	},
	'903': {
		id: 903,
		name: 'St. Charles County',
		marketId: 100,
	},
	'904': {
		id: 904,
		name: 'West County',
		marketId: 100,
	},
	'916': {
		id: 916,
		name: 'Cotswold',
		marketId: 25,
	},
	'917': {
		id: 917,
		name: 'East',
		marketId: 25,
	},
	'918': {
		id: 918,
		name: 'Uptown',
		marketId: 25,
	},
	'919': {
		id: 919,
		name: 'Airport',
		marketId: 25,
	},
	'920': {
		id: 920,
		name: 'Highway 51/Southeast',
		marketId: 25,
	},
	'921': {
		id: 921,
		name: 'Crown Point/Matthews',
		marketId: 25,
	},
	'922': {
		id: 922,
		name: 'Midtown',
		marketId: 25,
	},
	'923': {
		id: 923,
		name: 'North',
		marketId: 25,
	},
	'924': {
		id: 924,
		name: 'Park Road',
		marketId: 25,
	},
	'925': {
		id: 925,
		name: 'South Park',
		marketId: 25,
	},
	'926': {
		id: 926,
		name: 'Northeast',
		marketId: 25,
	},
	'927': {
		id: 927,
		name: 'Oregon City',
		marketId: 29,
	},
	'928': {
		id: 928,
		name: 'Lloyd Center',
		marketId: 29,
	},
	'929': {
		id: 929,
		name: 'Wilsonville',
		marketId: 29,
	},
	'930': {
		id: 930,
		name: 'Airport Way',
		marketId: 29,
	},
	'931': {
		id: 931,
		name: 'Johns Landing',
		marketId: 29,
	},
	'932': {
		id: 932,
		name: 'Barbur Boulevard',
		marketId: 29,
	},
	'933': {
		id: 933,
		name: 'Lake Oswego',
		marketId: 29,
	},
	'934': {
		id: 934,
		name: 'West Hills',
		marketId: 29,
	},
	'935': {
		id: 935,
		name: 'Vancouver',
		marketId: 29,
	},
	'936': {
		id: 936,
		name: 'Hillsboro',
		marketId: 29,
	},
	'937': {
		id: 937,
		name: 'Tualatin',
		marketId: 29,
	},
	'938': {
		id: 938,
		name: 'Clackamas',
		marketId: 29,
	},
	'939': {
		id: 939,
		name: 'CBD',
		marketId: 29,
	},
	'940': {
		id: 940,
		name: 'Forest Park',
		marketId: 29,
	},
	'941': {
		id: 941,
		name: 'Eastside',
		marketId: 29,
	},
	'942': {
		id: 942,
		name: 'Multnomah',
		marketId: 29,
	},
	'943': {
		id: 943,
		name: 'Beaverton',
		marketId: 29,
	},
	'944': {
		id: 944,
		name: 'Northwest Portland',
		marketId: 29,
	},
	'945': {
		id: 945,
		name: 'Tigard',
		marketId: 29,
	},
	'946': {
		id: 946,
		name: 'Laurelwood/Buckheaven',
		marketId: 29,
	},
	'947': {
		id: 947,
		name: 'Kruse Way',
		marketId: 29,
	},
	'948': {
		id: 948,
		name: 'West',
		marketId: 37,
	},
	'949': {
		id: 949,
		name: 'South',
		marketId: 37,
	},
	'950': {
		id: 950,
		name: 'Southwest',
		marketId: 37,
	},
	'951': {
		id: 951,
		name: 'Downtown',
		marketId: 37,
	},
	'952': {
		id: 952,
		name: 'Northwest',
		marketId: 37,
	},
	'953': {
		id: 953,
		name: 'North',
		marketId: 37,
	},
	'954': {
		id: 954,
		name: 'Henderson/Boulder City',
		marketId: 37,
	},
	'955': {
		id: 955,
		name: 'Central East',
		marketId: 37,
	},
	'956': {
		id: 956,
		name: 'Central North',
		marketId: 37,
	},
	'957': {
		id: 957,
		name: 'New Haven Suburban',
		marketId: 20,
	},
	'958': {
		id: 958,
		name: 'New London',
		marketId: 20,
	},
	'959': {
		id: 959,
		name: 'Tolland',
		marketId: 20,
	},
	'960': {
		id: 960,
		name: 'Litchfield',
		marketId: 20,
	},
	'961': {
		id: 961,
		name: 'New Haven CBD',
		marketId: 20,
	},
	'962': {
		id: 962,
		name: 'Hartford Suburban',
		marketId: 20,
	},
	'963': {
		id: 963,
		name: 'Hartford CBD',
		marketId: 20,
	},
	'964': {
		id: 964,
		name: 'Windham',
		marketId: 20,
	},
	'965': {
		id: 965,
		name: 'Middlesex',
		marketId: 20,
	},
	'966': {
		id: 966,
		name: 'Birmingham/Bloomfield',
		marketId: 38,
	},
	'967': {
		id: 967,
		name: 'Detroit',
		marketId: 38,
	},
	'968': {
		id: 968,
		name: 'Ann Arbor',
		marketId: 38,
	},
	'969': {
		id: 969,
		name: 'Farmington Hills/West Bloomfield',
		marketId: 38,
	},
	'970': {
		id: 970,
		name: 'Auburn Hills',
		marketId: 38,
	},
	'971': {
		id: 971,
		name: 'Southfield',
		marketId: 38,
	},
	'972': {
		id: 972,
		name: 'Macomb County',
		marketId: 38,
	},
	'973': {
		id: 973,
		name: 'I-275 Corridor',
		marketId: 38,
	},
	'974': {
		id: 974,
		name: 'Troy',
		marketId: 38,
	},
	'975': {
		id: 975,
		name: 'Rochester/Rochester Hills',
		marketId: 38,
	},
	'976': {
		id: 976,
		name: 'Dearborn',
		marketId: 38,
	},
	'977': {
		id: 977,
		name: 'Gallatin',
		marketId: 12,
	},
	'978': {
		id: 978,
		name: 'Murfreesboro',
		marketId: 12,
	},
	'979': {
		id: 979,
		name: 'West End/Belle Meade',
		marketId: 12,
	},
	'980': {
		id: 980,
		name: 'Downtown',
		marketId: 12,
	},
	'981': {
		id: 981,
		name: 'Airport North',
		marketId: 12,
	},
	'982': {
		id: 982,
		name: 'Airport South',
		marketId: 12,
	},
	'983': {
		id: 983,
		name: 'North Nashville',
		marketId: 12,
	},
	'984': {
		id: 984,
		name: 'Metrocenter',
		marketId: 12,
	},
	'985': {
		id: 985,
		name: 'Hills/Brentwood/Franklin',
		marketId: 12,
	},
	'986': {
		id: 986,
		name: 'Lebanon',
		marketId: 12,
	},
	'987': {
		id: 987,
		name: 'Green Hills/21st Street/Music Row',
		marketId: 12,
	},
	'997': {
		id: 997,
		name: 'Charleston',
		marketId: 110,
	},
	'1001': {
		id: 1001,
		name: 'Providence',
		marketId: 114,
	},
	'1002': {
		id: 1002,
		name: 'Buffalo',
		marketId: 115,
	},
	'1003': {
		id: 1003,
		name: 'Knoxville',
		marketId: 116,
	},
	'1004': {
		id: 1004,
		name: 'Cameron Village',
		marketId: 24,
	},
	'1005': {
		id: 1005,
		name: 'Orange County/Chapel Hill',
		marketId: 24,
	},
	'1006': {
		id: 1006,
		name: 'Downtown Raleigh',
		marketId: 24,
	},
	'1007': {
		id: 1007,
		name: 'North Durham',
		marketId: 24,
	},
	'1008': {
		id: 1008,
		name: 'South Wake County',
		marketId: 24,
	},
	'1009': {
		id: 1009,
		name: 'Six Forks Road',
		marketId: 24,
	},
	'1010': {
		id: 1010,
		name: 'West Raleigh',
		marketId: 24,
	},
	'1011': {
		id: 1011,
		name: 'US 1/Capitol Blvd',
		marketId: 24,
	},
	'1012': {
		id: 1012,
		name: 'Downtown Durham',
		marketId: 24,
	},
	'1013': {
		id: 1013,
		name: 'South Durham',
		marketId: 24,
	},
	'1014': {
		id: 1014,
		name: 'Cary',
		marketId: 24,
	},
	'1015': {
		id: 1015,
		name: 'East Wake County',
		marketId: 24,
	},
	'1016': {
		id: 1016,
		name: 'RTP/I-40 Corridor',
		marketId: 24,
	},
	'1017': {
		id: 1017,
		name: 'US 70/Glenwood Ave',
		marketId: 24,
	},
	'1018': {
		id: 1018,
		name: 'Falls of Neuse Road',
		marketId: 24,
	},
	'1019': {
		id: 1019,
		name: 'Flint',
		marketId: 38,
	},
	'1020': {
		id: 1020,
		name: 'Lansing',
		marketId: 38,
	},
	'1021': {
		id: 1021,
		name: 'South Lapeer County',
		marketId: 38,
	},
	'1022': {
		id: 1022,
		name: 'Livingston County',
		marketId: 38,
	},
	'1023': {
		id: 1023,
		name: 'Cleveland CBD',
		marketId: 102,
	},
	'1024': {
		id: 1024,
		name: 'Canton',
		marketId: 102,
	},
	'1025': {
		id: 1025,
		name: 'Akron/Kent',
		marketId: 102,
	},
	'1026': {
		id: 1026,
		name: 'East Cleveland',
		marketId: 102,
	},
	'1027': {
		id: 1027,
		name: 'Trumble/Mahoning County',
		marketId: 102,
	},
	'1028': {
		id: 1028,
		name: 'Southwest Cleveland',
		marketId: 102,
	},
	'1029': {
		id: 1029,
		name: 'Alliance/Salem',
		marketId: 102,
	},
	'1030': {
		id: 1030,
		name: 'Elyria',
		marketId: 102,
	},
	'1031': {
		id: 1031,
		name: 'South Cleveland',
		marketId: 102,
	},
	'1032': {
		id: 1032,
		name: 'Northeast Cleveland',
		marketId: 102,
	},
	'1033': {
		id: 1033,
		name: 'West Cleveland',
		marketId: 102,
	},
	'1034': {
		id: 1034,
		name: 'Southeast Cleveland',
		marketId: 102,
	},
	'1035': {
		id: 1035,
		name: 'Medina/Orrville',
		marketId: 102,
	},
	'1036': {
		id: 1036,
		name: 'Ashtabula County',
		marketId: 102,
	},
	'1037': {
		id: 1037,
		name: 'Springfield',
		marketId: 103,
	},
	'1038': {
		id: 1038,
		name: 'West Hamilton County',
		marketId: 103,
	},
	'1039': {
		id: 1039,
		name: 'East Cincinnati',
		marketId: 103,
	},
	'1040': {
		id: 1040,
		name: 'Cincinnati CBD',
		marketId: 103,
	},
	'1041': {
		id: 1041,
		name: 'Highland County',
		marketId: 103,
	},
	'1042': {
		id: 1042,
		name: 'Central Cincinnati',
		marketId: 103,
	},
	'1043': {
		id: 1043,
		name: 'Kenwood',
		marketId: 103,
	},
	'1044': {
		id: 1044,
		name: 'West Chester',
		marketId: 103,
	},
	'1045': {
		id: 1045,
		name: 'Blue Ash',
		marketId: 103,
	},
	'1046': {
		id: 1046,
		name: 'Clinton County',
		marketId: 103,
	},
	'1047': {
		id: 1047,
		name: 'Greene County',
		marketId: 103,
	},
	'1048': {
		id: 1048,
		name: 'Dayton',
		marketId: 103,
	},
	'1049': {
		id: 1049,
		name: 'Fields Ertel/Mason',
		marketId: 103,
	},
	'1050': {
		id: 1050,
		name: 'Northern Kentucky',
		marketId: 103,
	},
	'1051': {
		id: 1051,
		name: 'Butler County',
		marketId: 103,
	},
	'1052': {
		id: 1052,
		name: 'Eaton',
		marketId: 103,
	},
	'1053': {
		id: 1053,
		name: 'Tri County',
		marketId: 103,
	},
	'1054': {
		id: 1054,
		name: 'Brown County',
		marketId: 103,
	},
	'1055': {
		id: 1055,
		name: 'North Warren County',
		marketId: 103,
	},
	'1056': {
		id: 1056,
		name: 'Hilliard',
		marketId: 104,
	},
	'1057': {
		id: 1057,
		name: 'Licking County',
		marketId: 104,
	},
	'1058': {
		id: 1058,
		name: 'Grandview',
		marketId: 104,
	},
	'1059': {
		id: 1059,
		name: 'Polaris',
		marketId: 104,
	},
	'1060': {
		id: 1060,
		name: 'Airport',
		marketId: 104,
	},
	'1061': {
		id: 1061,
		name: 'Southern Columbus',
		marketId: 104,
	},
	'1062': {
		id: 1062,
		name: 'Upper Arlington',
		marketId: 104,
	},
	'1063': {
		id: 1063,
		name: 'Gahanna',
		marketId: 104,
	},
	'1064': {
		id: 1064,
		name: 'Reynoldsburg',
		marketId: 104,
	},
	'1065': {
		id: 1065,
		name: 'Dublin',
		marketId: 104,
	},
	'1066': {
		id: 1066,
		name: 'Delaware County South',
		marketId: 104,
	},
	'1067': {
		id: 1067,
		name: 'CBD',
		marketId: 104,
	},
	'1068': {
		id: 1068,
		name: 'Westerville',
		marketId: 104,
	},
	'1069': {
		id: 1069,
		name: 'New Albany',
		marketId: 104,
	},
	'1070': {
		id: 1070,
		name: 'Fairfield County',
		marketId: 104,
	},
	'1071': {
		id: 1071,
		name: 'Bexley/Whitehall',
		marketId: 104,
	},
	'1072': {
		id: 1072,
		name: 'Easton',
		marketId: 104,
	},
	'1073': {
		id: 1073,
		name: 'Bethel Road',
		marketId: 104,
	},
	'1074': {
		id: 1074,
		name: 'Worthington',
		marketId: 104,
	},
	'1075': {
		id: 1075,
		name: 'Cape Cod',
		marketId: 7,
	},
	'1076': {
		id: 1076,
		name: 'Gloucester',
		marketId: 7,
	},
	'1077': {
		id: 1077,
		name: 'Hawaii',
		marketId: 52,
	},
	'1078': {
		id: 1078,
		name: 'Oakland',
		marketId: 105,
	},
	'1079': {
		id: 1079,
		name: 'Parkway East',
		marketId: 105,
	},
	'1080': {
		id: 1080,
		name: 'South Pittsburgh',
		marketId: 105,
	},
	'1081': {
		id: 1081,
		name: 'CBD',
		marketId: 105,
	},
	'1082': {
		id: 1082,
		name: 'Cranberry',
		marketId: 105,
	},
	'1083': {
		id: 1083,
		name: 'Downtown Fringe',
		marketId: 105,
	},
	'1084': {
		id: 1084,
		name: 'Parkway West/Airport',
		marketId: 105,
	},
	'1085': {
		id: 1085,
		name: 'Parkway North',
		marketId: 105,
	},
	'1086': {
		id: 1086,
		name: 'Waukesha South',
		marketId: 106,
	},
	'1087': {
		id: 1087,
		name: 'Delafield/Wales/Mukwonago',
		marketId: 106,
	},
	'1088': {
		id: 1088,
		name: 'Suburban North',
		marketId: 106,
	},
	'1089': {
		id: 1089,
		name: 'Waukesha North',
		marketId: 106,
	},
	'1090': {
		id: 1090,
		name: 'Milwaukee Southeast',
		marketId: 106,
	},
	'1091': {
		id: 1091,
		name: 'Milwaukee Central',
		marketId: 106,
	},
	'1092': {
		id: 1092,
		name: 'Waukesha/Pewaukee',
		marketId: 106,
	},
	'1093': {
		id: 1093,
		name: 'Suburban South',
		marketId: 106,
	},
	'1094': {
		id: 1094,
		name: 'Milwaukee Northwest',
		marketId: 106,
	},
	'1095': {
		id: 1095,
		name: 'CBD',
		marketId: 106,
	},
	'1096': {
		id: 1096,
		name: 'West Allis',
		marketId: 106,
	},
	'1097': {
		id: 1097,
		name: 'Milwaukee North Shore',
		marketId: 106,
	},
	'1098': {
		id: 1098,
		name: 'Brookfield',
		marketId: 106,
	},
	'1099': {
		id: 1099,
		name: 'Mayfair/Wauwatosa',
		marketId: 106,
	},
	'1100': {
		id: 1100,
		name: "Third Ward/Walker's Point",
		marketId: 106,
	},
	'1101': {
		id: 1101,
		name: 'Milwaukee Southwest',
		marketId: 106,
	},
	'1102': {
		id: 1102,
		name: 'Brentwood/Westwood',
		marketId: 4,
	},
	'1103': {
		id: 1103,
		name: 'Palm Springs',
		marketId: 4,
	},
	'1104': {
		id: 1104,
		name: 'North Orange County',
		marketId: 4,
	},
	'1105': {
		id: 1105,
		name: 'Tri Cities',
		marketId: 4,
	},
	'1106': {
		id: 1106,
		name: 'Santa Monica Mountains',
		marketId: 4,
	},
	'1107': {
		id: 1107,
		name: 'Malibu',
		marketId: 4,
	},
	'1108': {
		id: 1108,
		name: 'Perris Valley',
		marketId: 4,
	},
	'1109': {
		id: 1109,
		name: 'Inglewood',
		marketId: 4,
	},
	'1110': {
		id: 1110,
		name: 'Lower Westside',
		marketId: 4,
	},
	'1111': {
		id: 1111,
		name: 'Santa Monica',
		marketId: 4,
	},
	'1112': {
		id: 1112,
		name: 'Victorville',
		marketId: 4,
	},
	'1113': {
		id: 1113,
		name: 'Airport Area',
		marketId: 4,
	},
	'1114': {
		id: 1114,
		name: 'West Los Angeles',
		marketId: 4,
	},
	'1115': {
		id: 1115,
		name: 'Century City/Beverly Hills',
		marketId: 4,
	},
	'1116': {
		id: 1116,
		name: 'Culver City',
		marketId: 4,
	},
	'1117': {
		id: 1117,
		name: 'Ventura',
		marketId: 4,
	},
	'1118': {
		id: 1118,
		name: 'Corona',
		marketId: 4,
	},
	'1119': {
		id: 1119,
		name: 'Riverside',
		marketId: 4,
	},
	'1120': {
		id: 1120,
		name: 'South Orange County',
		marketId: 4,
	},
	'1121': {
		id: 1121,
		name: 'Chino',
		marketId: 4,
	},
	'1122': {
		id: 1122,
		name: 'Camarillo',
		marketId: 4,
	},
	'1123': {
		id: 1123,
		name: 'Rialto/Colton',
		marketId: 4,
	},
	'1124': {
		id: 1124,
		name: 'San Bernardino/Redlands',
		marketId: 4,
	},
	'1125': {
		id: 1125,
		name: 'South East LA',
		marketId: 4,
	},
	'1126': {
		id: 1126,
		name: 'South Bay',
		marketId: 4,
	},
	'1127': {
		id: 1127,
		name: 'Santa Clarita Valley',
		marketId: 4,
	},
	'1128': {
		id: 1128,
		name: 'Conejo Valley',
		marketId: 4,
	},
	'1129': {
		id: 1129,
		name: 'Mid-Wilshire',
		marketId: 4,
	},
	'1130': {
		id: 1130,
		name: 'Moreno Valley',
		marketId: 4,
	},
	'1131': {
		id: 1131,
		name: 'San Gabriel Valley',
		marketId: 4,
	},
	'1132': {
		id: 1132,
		name: 'Downtown Los Angeles',
		marketId: 4,
	},
	'1133': {
		id: 1133,
		name: 'Rancho Cucamonga',
		marketId: 4,
	},
	'1134': {
		id: 1134,
		name: 'Murrieta/Temecula',
		marketId: 4,
	},
	'1135': {
		id: 1135,
		name: 'Palmdale',
		marketId: 4,
	},
	'1136': {
		id: 1136,
		name: 'Oxnard',
		marketId: 4,
	},
	'1137': {
		id: 1137,
		name: 'Long Beach',
		marketId: 4,
	},
	'1138': {
		id: 1138,
		name: 'Ontario-Upland-Montclair',
		marketId: 4,
	},
	'1139': {
		id: 1139,
		name: 'Simi Valley',
		marketId: 4,
	},
	'1140': {
		id: 1140,
		name: 'San Fernando Valley',
		marketId: 4,
	},
	'1141': {
		id: 1141,
		name: 'Central Orange County',
		marketId: 4,
	},
	'1142': {
		id: 1142,
		name: 'West Orange County',
		marketId: 4,
	},
	'1147': {
		id: 1147,
		name: 'Indiana',
		marketId: 55,
	},
	'1149': {
		id: 1149,
		name: 'Iowa',
		marketId: 57,
	},
	'1150': {
		id: 1150,
		name: 'Texas',
		marketId: 86,
	},
	'1152': {
		id: 1152,
		name: 'New Mexico',
		marketId: 73,
	},
	'1154': {
		id: 1154,
		name: 'Alabama',
		marketId: 40,
	},
	'1156': {
		id: 1156,
		name: 'Kentucky',
		marketId: 59,
	},
	'1159': {
		id: 1159,
		name: 'New Jersey - Other',
		marketId: 72,
	},
	'1160': {
		id: 1160,
		name: 'New Jersey - Warren County',
		marketId: 72,
	},
	'1161': {
		id: 1161,
		name: 'Central Suffolk County',
		marketId: 99,
	},
	'1162': {
		id: 1162,
		name: 'West Suffolk County',
		marketId: 99,
	},
	'1163': {
		id: 1163,
		name: 'East Nassau County',
		marketId: 99,
	},
	'1164': {
		id: 1164,
		name: 'West Nassau County',
		marketId: 99,
	},
	'1165': {
		id: 1165,
		name: 'East Suffolk County',
		marketId: 99,
	},
	'1166': {
		id: 1166,
		name: 'Central Nassau County',
		marketId: 99,
	},
	'1177': {
		id: 1177,
		name: 'Canyon County',
		marketId: 107,
	},
	'1178': {
		id: 1178,
		name: 'Ada County',
		marketId: 107,
	},
	'1179': {
		id: 1179,
		name: 'El Paso CBD',
		marketId: 126,
	},
	'1180': {
		id: 1180,
		name: 'Doña Ana County',
		marketId: 126,
	},
	'1181': {
		id: 1181,
		name: 'Central El Paso',
		marketId: 126,
	},
	'1182': {
		id: 1182,
		name: 'El Paso County',
		marketId: 126,
	},
	'1183': {
		id: 1183,
		name: 'Pickens County',
		marketId: 111,
	},
	'1184': {
		id: 1184,
		name: 'Greenville County',
		marketId: 111,
	},
	'1185': {
		id: 1185,
		name: 'Anderson County',
		marketId: 111,
	},
	'1186': {
		id: 1186,
		name: 'Spartanburg County',
		marketId: 111,
	},
	'1187': {
		id: 1187,
		name: 'South Carolina',
		marketId: 83,
	},
	'1188': {
		id: 1188,
		name: 'Midtown Birmingham',
		marketId: 120,
	},
	'1189': {
		id: 1189,
		name: 'Vulcan/Oxmoor',
		marketId: 120,
	},
	'1190': {
		id: 1190,
		name: 'Jefferson County',
		marketId: 120,
	},
	'1191': {
		id: 1191,
		name: 'Chilton County',
		marketId: 120,
	},
	'1192': {
		id: 1192,
		name: 'St. Clair County',
		marketId: 120,
	},
	'1193': {
		id: 1193,
		name: 'Blount County',
		marketId: 120,
	},
	'1194': {
		id: 1194,
		name: 'Highway 280/Shelby County',
		marketId: 120,
	},
	'1195': {
		id: 1195,
		name: 'Bibb County',
		marketId: 120,
	},
	'1196': {
		id: 1196,
		name: 'Shelby County',
		marketId: 120,
	},
	'1197': {
		id: 1197,
		name: 'Walker County',
		marketId: 120,
	},
	'1198': {
		id: 1198,
		name: 'Downtown Birmingham',
		marketId: 120,
	},
	'1199': {
		id: 1199,
		name: 'Tuscaloosa County',
		marketId: 120,
	},
	'1200': {
		id: 1200,
		name: 'Boone County',
		marketId: 56,
	},
	'1201': {
		id: 1201,
		name: 'Johnson County',
		marketId: 56,
	},
	'1202': {
		id: 1202,
		name: 'Marion County',
		marketId: 56,
	},
	'1203': {
		id: 1203,
		name: 'Hendricks County',
		marketId: 56,
	},
	'1204': {
		id: 1204,
		name: 'Hamilton County',
		marketId: 56,
	},
	'1205': {
		id: 1205,
		name: 'South Hancock County',
		marketId: 56,
	},
	'1206': {
		id: 1206,
		name: 'Morgan County',
		marketId: 56,
	},
	'1207': {
		id: 1207,
		name: 'North Hancock County',
		marketId: 56,
	},
	'1208': {
		id: 1208,
		name: 'Hardin County',
		marketId: 121,
	},
	'1209': {
		id: 1209,
		name: 'Downtown Lexington',
		marketId: 121,
	},
	'1210': {
		id: 1210,
		name: 'Clark County',
		marketId: 121,
	},
	'1211': {
		id: 1211,
		name: 'Franklin County',
		marketId: 121,
	},
	'1212': {
		id: 1212,
		name: 'Henry County',
		marketId: 121,
	},
	'1213': {
		id: 1213,
		name: 'Anderson County',
		marketId: 121,
	},
	'1214': {
		id: 1214,
		name: 'Harrison County',
		marketId: 121,
	},
	'1215': {
		id: 1215,
		name: 'Fayette County',
		marketId: 121,
	},
	'1216': {
		id: 1216,
		name: 'Scott County',
		marketId: 121,
	},
	'1217': {
		id: 1217,
		name: 'Jessamine County',
		marketId: 121,
	},
	'1218': {
		id: 1218,
		name: 'Bullitt County',
		marketId: 121,
	},
	'1219': {
		id: 1219,
		name: 'Central Louisville',
		marketId: 121,
	},
	'1220': {
		id: 1220,
		name: 'Floyd County',
		marketId: 121,
	},
	'1221': {
		id: 1221,
		name: 'Jefferson County',
		marketId: 121,
	},
	'1222': {
		id: 1222,
		name: 'Meade County',
		marketId: 121,
	},
	'1223': {
		id: 1223,
		name: 'Washington County',
		marketId: 121,
	},
	'1224': {
		id: 1224,
		name: 'Spencer County',
		marketId: 121,
	},
	'1225': {
		id: 1225,
		name: 'Nelson County',
		marketId: 121,
	},
	'1226': {
		id: 1226,
		name: 'Shelby County',
		marketId: 121,
	},
	'1227': {
		id: 1227,
		name: 'Woodford County',
		marketId: 121,
	},
	'1228': {
		id: 1228,
		name: 'Trimble County',
		marketId: 121,
	},
	'1229': {
		id: 1229,
		name: 'Oldham County',
		marketId: 121,
	},
	'1230': {
		id: 1230,
		name: 'DeSoto County',
		marketId: 112,
	},
	'1231': {
		id: 1231,
		name: 'East - Germantown - Collierville',
		marketId: 112,
	},
	'1232': {
		id: 1232,
		name: 'Midtown',
		marketId: 112,
	},
	'1233': {
		id: 1233,
		name: 'Shelby County',
		marketId: 112,
	},
	'1234': {
		id: 1234,
		name: 'Downtown Memphis',
		marketId: 112,
	},
	'1235': {
		id: 1235,
		name: 'Crittenden County',
		marketId: 112,
	},
	'1236': {
		id: 1236,
		name: 'St. Charles Parish',
		marketId: 113,
	},
	'1237': {
		id: 1237,
		name: 'Orleans Parish',
		marketId: 113,
	},
	'1238': {
		id: 1238,
		name: 'Jefferson Parish',
		marketId: 113,
	},
	'1239': {
		id: 1239,
		name: 'St. John the Baptist Parish',
		marketId: 113,
	},
	'1240': {
		id: 1240,
		name: 'Plaquemines Parish',
		marketId: 113,
	},
	'1241': {
		id: 1241,
		name: 'Saint Bernard Parish',
		marketId: 113,
	},
	'1242': {
		id: 1242,
		name: 'Chesapeake',
		marketId: 109,
	},
	'1243': {
		id: 1243,
		name: 'Newport News',
		marketId: 109,
	},
	'1244': {
		id: 1244,
		name: 'Suffolk',
		marketId: 109,
	},
	'1245': {
		id: 1245,
		name: 'Virginia Beach',
		marketId: 109,
	},
	'1246': {
		id: 1246,
		name: 'Portsmouth',
		marketId: 109,
	},
	'1247': {
		id: 1247,
		name: 'Hampton',
		marketId: 109,
	},
	'1248': {
		id: 1248,
		name: 'Norfolk',
		marketId: 109,
	},
	'1249': {
		id: 1249,
		name: 'Harrison County',
		marketId: 125,
	},
	'1250': {
		id: 1250,
		name: 'Saunders County',
		marketId: 125,
	},
	'1251': {
		id: 1251,
		name: 'Pottawattamie County',
		marketId: 125,
	},
	'1252': {
		id: 1252,
		name: 'Washington County',
		marketId: 125,
	},
	'1253': {
		id: 1253,
		name: 'Douglas County',
		marketId: 125,
	},
	'1254': {
		id: 1254,
		name: 'Cass County',
		marketId: 125,
	},
	'1255': {
		id: 1255,
		name: 'Sarpy County',
		marketId: 125,
	},
	'1256': {
		id: 1256,
		name: 'Mills County',
		marketId: 125,
	},
	'1257': {
		id: 1257,
		name: 'Amelia County',
		marketId: 108,
	},
	'1258': {
		id: 1258,
		name: 'Hanover County',
		marketId: 108,
	},
	'1259': {
		id: 1259,
		name: 'Surry County',
		marketId: 108,
	},
	'1260': {
		id: 1260,
		name: 'Petersburg',
		marketId: 108,
	},
	'1261': {
		id: 1261,
		name: 'Powhatan County',
		marketId: 108,
	},
	'1262': {
		id: 1262,
		name: 'Hopewell',
		marketId: 108,
	},
	'1263': {
		id: 1263,
		name: 'Dinwiddle County',
		marketId: 108,
	},
	'1264': {
		id: 1264,
		name: 'Prince George County',
		marketId: 108,
	},
	'1265': {
		id: 1265,
		name: 'Colonial Heights',
		marketId: 108,
	},
	'1266': {
		id: 1266,
		name: 'Chesterfield County',
		marketId: 108,
	},
	'1267': {
		id: 1267,
		name: 'Richmond',
		marketId: 108,
	},
	'1268': {
		id: 1268,
		name: 'Goochland County',
		marketId: 108,
	},
	'1269': {
		id: 1269,
		name: 'Henrico County',
		marketId: 108,
	},
	'1270': {
		id: 1270,
		name: 'Nebraska',
		marketId: 69,
	},
};

export const SUBMARKETS_NAME_TO_IDS: Record<
	string,
	{ ids: number[]; name: string; marketIds: number[] }
> = {
	'Upper West Side': {
		ids: [1],
		name: 'Upper West Side',
		marketIds: [1],
	},
	'Times Square': {
		ids: [2],
		name: 'Times Square',
		marketIds: [1],
	},
	'Park Avenue': {
		ids: [3],
		name: 'Park Avenue',
		marketIds: [1],
	},
	'City Hall Insurance': {
		ids: [4],
		name: 'City Hall Insurance',
		marketIds: [1],
	},
	Tribeca: {
		ids: [5],
		name: 'Tribeca',
		marketIds: [1],
	},
	'NoHo Greenwich Village': {
		ids: [6],
		name: 'NoHo Greenwich Village',
		marketIds: [1],
	},
	'Financial District': {
		ids: [7, 433],
		name: 'Financial District',
		marketIds: [1, 7],
	},
	'UN Plaza': {
		ids: [8],
		name: 'UN Plaza',
		marketIds: [1],
	},
	'North Manhattan': {
		ids: [9],
		name: 'North Manhattan',
		marketIds: [1],
	},
	'Grand Central': {
		ids: [10],
		name: 'Grand Central',
		marketIds: [1],
	},
	'Columbus Circle': {
		ids: [11],
		name: 'Columbus Circle',
		marketIds: [1],
	},
	'Upper East Side': {
		ids: [13],
		name: 'Upper East Side',
		marketIds: [1],
	},
	'Gramercy Park/Union Square': {
		ids: [14],
		name: 'Gramercy Park/Union Square',
		marketIds: [1],
	},
	Chelsea: {
		ids: [15],
		name: 'Chelsea',
		marketIds: [1],
	},
	'Midtown Eastside': {
		ids: [16],
		name: 'Midtown Eastside',
		marketIds: [1],
	},
	'Sixth Avenue': {
		ids: [17],
		name: 'Sixth Avenue',
		marketIds: [1],
	},
	'World Trade Center': {
		ids: [18],
		name: 'World Trade Center',
		marketIds: [1],
	},
	SoHo: {
		ids: [19],
		name: 'SoHo',
		marketIds: [1],
	},
	'Hudson Square': {
		ids: [21],
		name: 'Hudson Square',
		marketIds: [1],
	},
	'Madison/Fifth Avenue': {
		ids: [22],
		name: 'Madison/Fifth Avenue',
		marketIds: [1],
	},
	'Murray Hill': {
		ids: [23],
		name: 'Murray Hill',
		marketIds: [1],
	},
	'Hudson Yards': {
		ids: [200],
		name: 'Hudson Yards',
		marketIds: [1],
	},
	'Times Square South': {
		ids: [201],
		name: 'Times Square South',
		marketIds: [1],
	},
	'Penn Station': {
		ids: [202],
		name: 'Penn Station',
		marketIds: [1],
	},
	Brooklyn: {
		ids: [391],
		name: 'Brooklyn',
		marketIds: [1],
	},
	Bronx: {
		ids: [392],
		name: 'Bronx',
		marketIds: [1],
	},
	Queens: {
		ids: [393],
		name: 'Queens',
		marketIds: [1],
	},
	'Staten Island': {
		ids: [886],
		name: 'Staten Island',
		marketIds: [1],
	},
	'North Waterfront': {
		ids: [24],
		name: 'North Waterfront',
		marketIds: [2],
	},
	'West of Kearny Street': {
		ids: [25],
		name: 'West of Kearny Street',
		marketIds: [2],
	},
	'South Financial District': {
		ids: [26],
		name: 'South Financial District',
		marketIds: [2],
	},
	'Civic Center': {
		ids: [27],
		name: 'Civic Center',
		marketIds: [2],
	},
	'Rincon Hill/South Beach': {
		ids: [28],
		name: 'Rincon Hill/South Beach',
		marketIds: [2],
	},
	'Showplace Square': {
		ids: [29],
		name: 'Showplace Square',
		marketIds: [2],
	},
	'Mission Bay/China Basin': {
		ids: [30],
		name: 'Mission Bay/China Basin',
		marketIds: [2],
	},
	'Southern City': {
		ids: [31],
		name: 'Southern City',
		marketIds: [2],
	},
	'Jackson Square': {
		ids: [32],
		name: 'Jackson Square',
		marketIds: [2],
	},
	Potrero: {
		ids: [33],
		name: 'Potrero',
		marketIds: [2],
	},
	'West of Van Ness Corridor': {
		ids: [34],
		name: 'West of Van Ness Corridor',
		marketIds: [2],
	},
	'Union Square': {
		ids: [35],
		name: 'Union Square',
		marketIds: [2],
	},
	'Yerba Buena': {
		ids: [36],
		name: 'Yerba Buena',
		marketIds: [2],
	},
	'North Financial District': {
		ids: [37],
		name: 'North Financial District',
		marketIds: [2],
	},
	'Lower South of Market': {
		ids: [38],
		name: 'Lower South of Market',
		marketIds: [2],
	},
	'India Basin': {
		ids: [39],
		name: 'India Basin',
		marketIds: [2],
	},
	'Bayview-Hunters Point': {
		ids: [40],
		name: 'Bayview-Hunters Point',
		marketIds: [2],
	},
	'South of Market': {
		ids: [41],
		name: 'South of Market',
		marketIds: [2],
	},
	'Van Ness Corridor': {
		ids: [42],
		name: 'Van Ness Corridor',
		marketIds: [2],
	},
	'Mill Valley/Sausalito': {
		ids: [43],
		name: 'Mill Valley/Sausalito',
		marketIds: [3],
	},
	'San Rafael': {
		ids: [44],
		name: 'San Rafael',
		marketIds: [3],
	},
	Novato: {
		ids: [45],
		name: 'Novato',
		marketIds: [3],
	},
	'Suburban Marin County': {
		ids: [46],
		name: 'Suburban Marin County',
		marketIds: [3],
	},
	Alameda: {
		ids: [47],
		name: 'Alameda',
		marketIds: [3],
	},
	'Richmond/San Pablo': {
		ids: [48],
		name: 'Richmond/San Pablo',
		marketIds: [3],
	},
	'San Bruno Millbrae': {
		ids: [49],
		name: 'San Bruno Millbrae',
		marketIds: [3],
	},
	'South San Francisco': {
		ids: [50],
		name: 'South San Francisco',
		marketIds: [3],
	},
	Oakland: {
		ids: [51, 1078],
		name: 'Oakland',
		marketIds: [3, 105],
	},
	Berkeley: {
		ids: [52],
		name: 'Berkeley',
		marketIds: [3],
	},
	Emeryville: {
		ids: [53],
		name: 'Emeryville',
		marketIds: [3],
	},
	'Oakland Airport South Oakland': {
		ids: [54],
		name: 'Oakland Airport South Oakland',
		marketIds: [3],
	},
	'San Leandro': {
		ids: [55],
		name: 'San Leandro',
		marketIds: [3],
	},
	Hayward: {
		ids: [56],
		name: 'Hayward',
		marketIds: [3],
	},
	'Newark/Fremont': {
		ids: [57],
		name: 'Newark/Fremont',
		marketIds: [3],
	},
	Milpitas: {
		ids: [58],
		name: 'Milpitas',
		marketIds: [3],
	},
	'Campbell/Los Gatos': {
		ids: [59],
		name: 'Campbell/Los Gatos',
		marketIds: [3],
	},
	'North San Jose': {
		ids: [60],
		name: 'North San Jose',
		marketIds: [3],
	},
	'Downtown San Jose': {
		ids: [61],
		name: 'Downtown San Jose',
		marketIds: [3],
	},
	'South San Jose': {
		ids: [62],
		name: 'South San Jose',
		marketIds: [3],
	},
	'Santa Clara': {
		ids: [63],
		name: 'Santa Clara',
		marketIds: [3],
	},
	'Sunnyvale/Cupertino': {
		ids: [64],
		name: 'Sunnyvale/Cupertino',
		marketIds: [3],
	},
	'Mountain View/Los Altos': {
		ids: [65],
		name: 'Mountain View/Los Altos',
		marketIds: [3],
	},
	'Palo Alto': {
		ids: [66],
		name: 'Palo Alto',
		marketIds: [3],
	},
	'Menlo Park': {
		ids: [67],
		name: 'Menlo Park',
		marketIds: [3],
	},
	'Redwood City San Carlos': {
		ids: [68],
		name: 'Redwood City San Carlos',
		marketIds: [3],
	},
	'Redwood Foster City': {
		ids: [69],
		name: 'Redwood Foster City',
		marketIds: [3],
	},
	'San Mateo': {
		ids: [70],
		name: 'San Mateo',
		marketIds: [3],
	},
	Burlingame: {
		ids: [71],
		name: 'Burlingame',
		marketIds: [3],
	},
	'Daly City Brisbane': {
		ids: [72],
		name: 'Daly City Brisbane',
		marketIds: [3],
	},
	'Lafayette/Moraga/Orinda': {
		ids: [92],
		name: 'Lafayette/Moraga/Orinda',
		marketIds: [3],
	},
	Livermore: {
		ids: [93],
		name: 'Livermore',
		marketIds: [3],
	},
	Concord: {
		ids: [94, 429],
		name: 'Concord',
		marketIds: [3, 7],
	},
	'Martinez Pacheco': {
		ids: [95],
		name: 'Martinez Pacheco',
		marketIds: [3],
	},
	'Pleasant Hill': {
		ids: [96],
		name: 'Pleasant Hill',
		marketIds: [3],
	},
	'Walnut Creek': {
		ids: [97],
		name: 'Walnut Creek',
		marketIds: [3],
	},
	'Danville Alamo': {
		ids: [98],
		name: 'Danville Alamo',
		marketIds: [3],
	},
	Pittsburg: {
		ids: [99],
		name: 'Pittsburg',
		marketIds: [3],
	},
	Pleasanton: {
		ids: [100],
		name: 'Pleasanton',
		marketIds: [3],
	},
	'San Ramon': {
		ids: [101],
		name: 'San Ramon',
		marketIds: [3],
	},
	Antioch: {
		ids: [102],
		name: 'Antioch',
		marketIds: [3],
	},
	Angwin: {
		ids: [103],
		name: 'Angwin',
		marketIds: [3],
	},
	Berryessa: {
		ids: [104],
		name: 'Berryessa',
		marketIds: [3],
	},
	Calistoga: {
		ids: [105],
		name: 'Calistoga',
		marketIds: [3],
	},
	'Cloverdale/Geyserville': {
		ids: [106],
		name: 'Cloverdale/Geyserville',
		marketIds: [3],
	},
	'Healdsburg/Windsor': {
		ids: [108],
		name: 'Healdsburg/Windsor',
		marketIds: [3],
	},
	Napa: {
		ids: [109],
		name: 'Napa',
		marketIds: [3],
	},
	Petaluma: {
		ids: [110],
		name: 'Petaluma',
		marketIds: [3],
	},
	'Russian River-Coastal': {
		ids: [111],
		name: 'Russian River-Coastal',
		marketIds: [3],
	},
	Sebastopol: {
		ids: [112],
		name: 'Sebastopol',
		marketIds: [3],
	},
	'Santa Rosa': {
		ids: [113],
		name: 'Santa Rosa',
		marketIds: [3],
	},
	Sonoma: {
		ids: [114],
		name: 'Sonoma',
		marketIds: [3],
	},
	'St. Helena': {
		ids: [115],
		name: 'St. Helena',
		marketIds: [3],
	},
	Gilroy: {
		ids: [333],
		name: 'Gilroy',
		marketIds: [3],
	},
	'Morgan Hill': {
		ids: [334],
		name: 'Morgan Hill',
		marketIds: [3],
	},
	Pajaro: {
		ids: [335],
		name: 'Pajaro',
		marketIds: [3],
	},
	'San Lorenzo Valley': {
		ids: [336],
		name: 'San Lorenzo Valley',
		marketIds: [3],
	},
	'Santa Cruz': {
		ids: [337],
		name: 'Santa Cruz',
		marketIds: [3],
	},
	Watsonville: {
		ids: [338],
		name: 'Watsonville',
		marketIds: [3],
	},
	'West Santa Cruz': {
		ids: [339],
		name: 'West Santa Cruz',
		marketIds: [3],
	},
	Castroville: {
		ids: [340],
		name: 'Castroville',
		marketIds: [3],
	},
	Salinas: {
		ids: [341],
		name: 'Salinas',
		marketIds: [3],
	},
	'Seaside/Monterey': {
		ids: [342],
		name: 'Seaside/Monterey',
		marketIds: [3],
	},
	Dixon: {
		ids: [343],
		name: 'Dixon',
		marketIds: [3],
	},
	'Fairfield/Suisun City': {
		ids: [344],
		name: 'Fairfield/Suisun City',
		marketIds: [3],
	},
	'Rio Vista': {
		ids: [345],
		name: 'Rio Vista',
		marketIds: [3],
	},
	Vacaville: {
		ids: [346],
		name: 'Vacaville',
		marketIds: [3],
	},
	Vallejo: {
		ids: [347],
		name: 'Vallejo',
		marketIds: [3],
	},
	'Brentwood/Westwood': {
		ids: [1102],
		name: 'Brentwood/Westwood',
		marketIds: [4],
	},
	'Palm Springs': {
		ids: [1103],
		name: 'Palm Springs',
		marketIds: [4],
	},
	'North Orange County': {
		ids: [1104],
		name: 'North Orange County',
		marketIds: [4],
	},
	'Tri Cities': {
		ids: [1105],
		name: 'Tri Cities',
		marketIds: [4],
	},
	'Santa Monica Mountains': {
		ids: [1106],
		name: 'Santa Monica Mountains',
		marketIds: [4],
	},
	Malibu: {
		ids: [1107],
		name: 'Malibu',
		marketIds: [4],
	},
	'Perris Valley': {
		ids: [1108],
		name: 'Perris Valley',
		marketIds: [4],
	},
	Inglewood: {
		ids: [1109],
		name: 'Inglewood',
		marketIds: [4],
	},
	'Lower Westside': {
		ids: [1110],
		name: 'Lower Westside',
		marketIds: [4],
	},
	'Santa Monica': {
		ids: [1111],
		name: 'Santa Monica',
		marketIds: [4],
	},
	Victorville: {
		ids: [1112],
		name: 'Victorville',
		marketIds: [4],
	},
	'Airport Area': {
		ids: [1113],
		name: 'Airport Area',
		marketIds: [4],
	},
	'West Los Angeles': {
		ids: [1114],
		name: 'West Los Angeles',
		marketIds: [4],
	},
	'Century City/Beverly Hills': {
		ids: [1115],
		name: 'Century City/Beverly Hills',
		marketIds: [4],
	},
	'Culver City': {
		ids: [1116],
		name: 'Culver City',
		marketIds: [4],
	},
	Ventura: {
		ids: [1117],
		name: 'Ventura',
		marketIds: [4],
	},
	Corona: {
		ids: [1118],
		name: 'Corona',
		marketIds: [4],
	},
	Riverside: {
		ids: [1119],
		name: 'Riverside',
		marketIds: [4],
	},
	'South Orange County': {
		ids: [1120],
		name: 'South Orange County',
		marketIds: [4],
	},
	Chino: {
		ids: [1121],
		name: 'Chino',
		marketIds: [4],
	},
	Camarillo: {
		ids: [1122],
		name: 'Camarillo',
		marketIds: [4],
	},
	'Rialto/Colton': {
		ids: [1123],
		name: 'Rialto/Colton',
		marketIds: [4],
	},
	'San Bernardino/Redlands': {
		ids: [1124],
		name: 'San Bernardino/Redlands',
		marketIds: [4],
	},
	'South East LA': {
		ids: [1125],
		name: 'South East LA',
		marketIds: [4],
	},
	'South Bay': {
		ids: [1126],
		name: 'South Bay',
		marketIds: [4],
	},
	'Santa Clarita Valley': {
		ids: [1127],
		name: 'Santa Clarita Valley',
		marketIds: [4],
	},
	'Conejo Valley': {
		ids: [1128],
		name: 'Conejo Valley',
		marketIds: [4],
	},
	'Mid-Wilshire': {
		ids: [1129],
		name: 'Mid-Wilshire',
		marketIds: [4],
	},
	'Moreno Valley': {
		ids: [1130],
		name: 'Moreno Valley',
		marketIds: [4],
	},
	'San Gabriel Valley': {
		ids: [1131],
		name: 'San Gabriel Valley',
		marketIds: [4],
	},
	'Downtown Los Angeles': {
		ids: [1132],
		name: 'Downtown Los Angeles',
		marketIds: [4],
	},
	'Rancho Cucamonga': {
		ids: [1133],
		name: 'Rancho Cucamonga',
		marketIds: [4],
	},
	'Murrieta/Temecula': {
		ids: [1134],
		name: 'Murrieta/Temecula',
		marketIds: [4],
	},
	Palmdale: {
		ids: [1135],
		name: 'Palmdale',
		marketIds: [4],
	},
	Oxnard: {
		ids: [1136],
		name: 'Oxnard',
		marketIds: [4],
	},
	'Long Beach': {
		ids: [1137],
		name: 'Long Beach',
		marketIds: [4],
	},
	'Ontario-Upland-Montclair': {
		ids: [1138],
		name: 'Ontario-Upland-Montclair',
		marketIds: [4],
	},
	'Simi Valley': {
		ids: [1139],
		name: 'Simi Valley',
		marketIds: [4],
	},
	'San Fernando Valley': {
		ids: [1140],
		name: 'San Fernando Valley',
		marketIds: [4],
	},
	'Central Orange County': {
		ids: [1141],
		name: 'Central Orange County',
		marketIds: [4],
	},
	'West Orange County': {
		ids: [1142],
		name: 'West Orange County',
		marketIds: [4],
	},
	'DC Capitol Hill': {
		ids: [245],
		name: 'DC Capitol Hill',
		marketIds: [5],
	},
	'DC CBD': {
		ids: [246],
		name: 'DC CBD',
		marketIds: [5],
	},
	'DC East End': {
		ids: [247],
		name: 'DC East End',
		marketIds: [5],
	},
	'DC Georgetown': {
		ids: [248],
		name: 'DC Georgetown',
		marketIds: [5],
	},
	'DC NoMa': {
		ids: [249],
		name: 'DC NoMa',
		marketIds: [5],
	},
	'DC Northeast': {
		ids: [250],
		name: 'DC Northeast',
		marketIds: [5],
	},
	'DC Southeast': {
		ids: [251],
		name: 'DC Southeast',
		marketIds: [5],
	},
	'DC Southwest': {
		ids: [252],
		name: 'DC Southwest',
		marketIds: [5],
	},
	'DC Uptown': {
		ids: [253],
		name: 'DC Uptown',
		marketIds: [5],
	},
	'DC West End': {
		ids: [254],
		name: 'DC West End',
		marketIds: [5],
	},
	'MD Beltsville/Laurel': {
		ids: [255],
		name: 'MD Beltsville/Laurel',
		marketIds: [5],
	},
	'MD Bethesda': {
		ids: [256],
		name: 'MD Bethesda',
		marketIds: [5],
	},
	'MD Bowie/Upper Marlboro': {
		ids: [257],
		name: 'MD Bowie/Upper Marlboro',
		marketIds: [5],
	},
	'MD College Park/Greenbelt': {
		ids: [258],
		name: 'MD College Park/Greenbelt',
		marketIds: [5],
	},
	'MD Gaithersburg': {
		ids: [259],
		name: 'MD Gaithersburg',
		marketIds: [5],
	},
	'MD Germantown': {
		ids: [260],
		name: 'MD Germantown',
		marketIds: [5],
	},
	'MD Lanham/Landover': {
		ids: [261],
		name: 'MD Lanham/Landover',
		marketIds: [5],
	},
	'MD North Bethesda/Potomac': {
		ids: [262],
		name: 'MD North Bethesda/Potomac',
		marketIds: [5],
	},
	'MD Rockville': {
		ids: [263],
		name: 'MD Rockville',
		marketIds: [5],
	},
	'MD Silver Spring': {
		ids: [264],
		name: 'MD Silver Spring',
		marketIds: [5],
	},
	'MD South PG County': {
		ids: [265],
		name: 'MD South PG County',
		marketIds: [5],
	},
	'MD Wheaton/Olney': {
		ids: [266],
		name: 'MD Wheaton/Olney',
		marketIds: [5],
	},
	'VA Chantilly/Centreville': {
		ids: [267],
		name: 'VA Chantilly/Centreville',
		marketIds: [5],
	},
	'VA Crystal City': {
		ids: [268],
		name: 'VA Crystal City',
		marketIds: [5],
	},
	'VA Eisenhower Avenue': {
		ids: [269],
		name: 'VA Eisenhower Avenue',
		marketIds: [5],
	},
	'VA Fairfax': {
		ids: [270],
		name: 'VA Fairfax',
		marketIds: [5],
	},
	'VA Great Falls': {
		ids: [271],
		name: 'VA Great Falls',
		marketIds: [5],
	},
	'VA Herndon': {
		ids: [272],
		name: 'VA Herndon',
		marketIds: [5],
	},
	'VA I-395 Corridor': {
		ids: [273],
		name: 'VA I-395 Corridor',
		marketIds: [5],
	},
	'VA Loudoun County': {
		ids: [274],
		name: 'VA Loudoun County',
		marketIds: [5],
	},
	'VA McLean': {
		ids: [275],
		name: 'VA McLean',
		marketIds: [5],
	},
	'VA Merrifield/Annandale': {
		ids: [276],
		name: 'VA Merrifield/Annandale',
		marketIds: [5],
	},
	'VA Old Town Alexandria': {
		ids: [277],
		name: 'VA Old Town Alexandria',
		marketIds: [5],
	},
	'VA Prince William County': {
		ids: [278],
		name: 'VA Prince William County',
		marketIds: [5],
	},
	'VA Reston': {
		ids: [279],
		name: 'VA Reston',
		marketIds: [5],
	},
	'VA Rosslyn/Ballston': {
		ids: [280],
		name: 'VA Rosslyn/Ballston',
		marketIds: [5],
	},
	'VA Route 1 Corridor': {
		ids: [281],
		name: 'VA Route 1 Corridor',
		marketIds: [5],
	},
	'VA Springfield': {
		ids: [282],
		name: 'VA Springfield',
		marketIds: [5],
	},
	'VA Tysons Corner': {
		ids: [283],
		name: 'VA Tysons Corner',
		marketIds: [5],
	},
	'VA Vienna': {
		ids: [284],
		name: 'VA Vienna',
		marketIds: [5],
	},
	'South Suburban': {
		ids: [184],
		name: 'South Suburban',
		marketIds: [6],
	},
	Indiana: {
		ids: [185, 1147],
		name: 'Indiana',
		marketIds: [6, 55],
	},
	'Northwest Suburban': {
		ids: [186],
		name: 'Northwest Suburban',
		marketIds: [6],
	},
	'CBD South Loop': {
		ids: [187],
		name: 'CBD South Loop',
		marketIds: [6],
	},
	'North Suburban': {
		ids: [188],
		name: 'North Suburban',
		marketIds: [6],
	},
	'CBD North Michigan Avenue': {
		ids: [189],
		name: 'CBD North Michigan Avenue',
		marketIds: [6],
	},
	'CBD West Loop': {
		ids: [190],
		name: 'CBD West Loop',
		marketIds: [6],
	},
	'South Chicago': {
		ids: [191],
		name: 'South Chicago',
		marketIds: [6],
	},
	'CBD Central Loop': {
		ids: [192],
		name: 'CBD Central Loop',
		marketIds: [6],
	},
	Kenosha: {
		ids: [193],
		name: 'Kenosha',
		marketIds: [6],
	},
	'East-West Corridor': {
		ids: [194],
		name: 'East-West Corridor',
		marketIds: [6],
	},
	'CBD East Loop': {
		ids: [195],
		name: 'CBD East Loop',
		marketIds: [6],
	},
	'North Chicago': {
		ids: [196],
		name: 'North Chicago',
		marketIds: [6],
	},
	"O'Hare": {
		ids: [197],
		name: "O'Hare",
		marketIds: [6],
	},
	'CBD River North': {
		ids: [198],
		name: 'CBD River North',
		marketIds: [6],
	},
	'West Cook County': {
		ids: [199],
		name: 'West Cook County',
		marketIds: [6],
	},
	'West Cambridge': {
		ids: [423],
		name: 'West Cambridge',
		marketIds: [7],
	},
	'Allston-Brighton': {
		ids: [424],
		name: 'Allston-Brighton',
		marketIds: [7],
	},
	'Back Bay': {
		ids: [425],
		name: 'Back Bay',
		marketIds: [7],
	},
	Brookline: {
		ids: [426],
		name: 'Brookline',
		marketIds: [7],
	},
	'Burlington Lowell': {
		ids: [427],
		name: 'Burlington Lowell',
		marketIds: [7],
	},
	Charlestown: {
		ids: [428],
		name: 'Charlestown',
		marketIds: [7],
	},
	'East Boston': {
		ids: [430],
		name: 'East Boston',
		marketIds: [7],
	},
	'East Cambridge': {
		ids: [431],
		name: 'East Cambridge',
		marketIds: [7],
	},
	'Far North': {
		ids: [432],
		name: 'Far North',
		marketIds: [7],
	},
	'I-95 South': {
		ids: [434],
		name: 'I-95 South',
		marketIds: [7],
	},
	'Metro West': {
		ids: [435],
		name: 'Metro West',
		marketIds: [7],
	},
	'Mid-Cambridge': {
		ids: [436],
		name: 'Mid-Cambridge',
		marketIds: [7],
	},
	'Mid-Town': {
		ids: [437],
		name: 'Mid-Town',
		marketIds: [7],
	},
	'Near North': {
		ids: [438],
		name: 'Near North',
		marketIds: [7],
	},
	'Newton/Wellesley': {
		ids: [439],
		name: 'Newton/Wellesley',
		marketIds: [7],
	},
	'North End': {
		ids: [440],
		name: 'North End',
		marketIds: [7],
	},
	'North Station Government Center': {
		ids: [441],
		name: 'North Station Government Center',
		marketIds: [7],
	},
	'Quincy Route 3 Corridor': {
		ids: [442],
		name: 'Quincy Route 3 Corridor',
		marketIds: [7],
	},
	'Roxbury/Dorchester': {
		ids: [443],
		name: 'Roxbury/Dorchester',
		marketIds: [7],
	},
	Seaport: {
		ids: [444],
		name: 'Seaport',
		marketIds: [7],
	},
	'Waltham Watertown': {
		ids: [445],
		name: 'Waltham Watertown',
		marketIds: [7],
	},
	'Cape Cod': {
		ids: [1075],
		name: 'Cape Cod',
		marketIds: [7],
	},
	Gloucester: {
		ids: [1076],
		name: 'Gloucester',
		marketIds: [7],
	},
	Midway: {
		ids: [203],
		name: 'Midway',
		marketIds: [8],
	},
	'Minneapolis CBD': {
		ids: [204],
		name: 'Minneapolis CBD',
		marketIds: [8],
	},
	'St. Paul CBD': {
		ids: [205],
		name: 'St. Paul CBD',
		marketIds: [8],
	},
	'Apple Valley Burnsville Eagan': {
		ids: [206],
		name: 'Apple Valley Burnsville Eagan',
		marketIds: [8],
	},
	Northwest: {
		ids: [207, 290, 560, 543, 952],
		name: 'Northwest',
		marketIds: [8, 13, 27, 28, 37],
	},
	'Southwest I-494': {
		ids: [208],
		name: 'Southwest I-494',
		marketIds: [8],
	},
	'St. Paul Suburban': {
		ids: [209],
		name: 'St. Paul Suburban',
		marketIds: [8],
	},
	'West I-394': {
		ids: [210],
		name: 'West I-394',
		marketIds: [8],
	},
	'Campus Commons': {
		ids: [348],
		name: 'Campus Commons',
		marketIds: [9],
	},
	'Carmichael/Fair Oaks': {
		ids: [349],
		name: 'Carmichael/Fair Oaks',
		marketIds: [9],
	},
	'Citrus Heights/Orangevale': {
		ids: [350],
		name: 'Citrus Heights/Orangevale',
		marketIds: [9],
	},
	Downtown: {
		ids: [351, 237, 980, 305, 528, 311, 797, 717, 819, 951, 892],
		name: 'Downtown',
		marketIds: [9, 11, 12, 13, 14, 16, 22, 26, 33, 37, 101],
	},
	'El Dorado County': {
		ids: [352],
		name: 'El Dorado County',
		marketIds: [9],
	},
	Folsom: {
		ids: [353],
		name: 'Folsom',
		marketIds: [9],
	},
	'Highway 50 Corridor': {
		ids: [354],
		name: 'Highway 50 Corridor',
		marketIds: [9],
	},
	'Howe Ave/Fulton Ave': {
		ids: [355],
		name: 'Howe Ave/Fulton Ave',
		marketIds: [9],
	},
	Midtown: {
		ids: [356, 238, 922, 732, 891, 1232],
		name: 'Midtown',
		marketIds: [9, 11, 25, 26, 101, 112],
	},
	'Natomas/Northgate': {
		ids: [357],
		name: 'Natomas/Northgate',
		marketIds: [9],
	},
	'North Highlands': {
		ids: [358],
		name: 'North Highlands',
		marketIds: [9],
	},
	'Point West': {
		ids: [359],
		name: 'Point West',
		marketIds: [9],
	},
	'Roseville/Rocklin': {
		ids: [360],
		name: 'Roseville/Rocklin',
		marketIds: [9],
	},
	'South Natomas': {
		ids: [361],
		name: 'South Natomas',
		marketIds: [9],
	},
	'South Sacramento': {
		ids: [362],
		name: 'South Sacramento',
		marketIds: [9],
	},
	'Watt Avenue': {
		ids: [363],
		name: 'Watt Avenue',
		marketIds: [9],
	},
	'West Sacramento': {
		ids: [364],
		name: 'West Sacramento',
		marketIds: [9],
	},
	'Woodland/Davis': {
		ids: [365],
		name: 'Woodland/Davis',
		marketIds: [9],
	},
	Fresno: {
		ids: [366],
		name: 'Fresno',
		marketIds: [9],
	},
	Lodi: {
		ids: [367],
		name: 'Lodi',
		marketIds: [9],
	},
	Madera: {
		ids: [368],
		name: 'Madera',
		marketIds: [9],
	},
	Mariposa: {
		ids: [369],
		name: 'Mariposa',
		marketIds: [9],
	},
	Merced: {
		ids: [370],
		name: 'Merced',
		marketIds: [9],
	},
	Modesto: {
		ids: [371],
		name: 'Modesto',
		marketIds: [9],
	},
	Nevada: {
		ids: [372, 608],
		name: 'Nevada',
		marketIds: [9, 70],
	},
	Redding: {
		ids: [373],
		name: 'Redding',
		marketIds: [9],
	},
	Stockton: {
		ids: [374],
		name: 'Stockton',
		marketIds: [9],
	},
	Tracy: {
		ids: [375],
		name: 'Tracy',
		marketIds: [9],
	},
	Tulare: {
		ids: [376],
		name: 'Tulare',
		marketIds: [9],
	},
	Turlock: {
		ids: [377],
		name: 'Turlock',
		marketIds: [9],
	},
	Tehama: {
		ids: [378],
		name: 'Tehama',
		marketIds: [9],
	},
	Tuolumne: {
		ids: [379],
		name: 'Tuolumne',
		marketIds: [9],
	},
	Calaveras: {
		ids: [380],
		name: 'Calaveras',
		marketIds: [9],
	},
	Amador: {
		ids: [381],
		name: 'Amador',
		marketIds: [9],
	},
	'Yuba City': {
		ids: [382],
		name: 'Yuba City',
		marketIds: [9],
	},
	'Sutter County': {
		ids: [383],
		name: 'Sutter County',
		marketIds: [9],
	},
	'Oakdale/Waterford': {
		ids: [384],
		name: 'Oakdale/Waterford',
		marketIds: [9],
	},
	'Manteca/Lathrop': {
		ids: [385],
		name: 'Manteca/Lathrop',
		marketIds: [9],
	},
	'Kings County': {
		ids: [386],
		name: 'Kings County',
		marketIds: [9],
	},
	'Chico/Oroville': {
		ids: [387],
		name: 'Chico/Oroville',
		marketIds: [9],
	},
	'Glenn County': {
		ids: [388],
		name: 'Glenn County',
		marketIds: [9],
	},
	Bakersfield: {
		ids: [389],
		name: 'Bakersfield',
		marketIds: [9],
	},
	Colusa: {
		ids: [390],
		name: 'Colusa',
		marketIds: [9],
	},
	'Addison Quorum Bent Tree': {
		ids: [211],
		name: 'Addison Quorum Bent Tree',
		marketIds: [10],
	},
	Arlington: {
		ids: [212, 788],
		name: 'Arlington',
		marketIds: [10, 22],
	},
	'Central Expressway': {
		ids: [213],
		name: 'Central Expressway',
		marketIds: [10],
	},
	'Dallas CBD': {
		ids: [214],
		name: 'Dallas CBD',
		marketIds: [10],
	},
	'East Dallas': {
		ids: [215],
		name: 'East Dallas',
		marketIds: [10],
	},
	'Fort Worth CBD': {
		ids: [216],
		name: 'Fort Worth CBD',
		marketIds: [10],
	},
	Frisco: {
		ids: [217],
		name: 'Frisco',
		marketIds: [10],
	},
	'Grand Prairie': {
		ids: [218],
		name: 'Grand Prairie',
		marketIds: [10],
	},
	'Las Colinas': {
		ids: [219],
		name: 'Las Colinas',
		marketIds: [10],
	},
	'LBJ Freeway': {
		ids: [220],
		name: 'LBJ Freeway',
		marketIds: [10],
	},
	'Lewisville Denton': {
		ids: [221],
		name: 'Lewisville Denton',
		marketIds: [10],
	},
	'Mid-Cities': {
		ids: [222],
		name: 'Mid-Cities',
		marketIds: [10],
	},
	'North Fort Worth': {
		ids: [223],
		name: 'North Fort Worth',
		marketIds: [10],
	},
	'Northeast Fort Worth': {
		ids: [224],
		name: 'Northeast Fort Worth',
		marketIds: [10],
	},
	'Plano/Allen/McKinney': {
		ids: [225],
		name: 'Plano/Allen/McKinney',
		marketIds: [10],
	},
	'Preston Center': {
		ids: [226],
		name: 'Preston Center',
		marketIds: [10],
	},
	Richardson: {
		ids: [227],
		name: 'Richardson',
		marketIds: [10],
	},
	'South Fort Worth': {
		ids: [228],
		name: 'South Fort Worth',
		marketIds: [10],
	},
	'South Irving': {
		ids: [229],
		name: 'South Irving',
		marketIds: [10],
	},
	'Southwest Dallas': {
		ids: [230],
		name: 'Southwest Dallas',
		marketIds: [10],
	},
	'Stemmons Freeway': {
		ids: [231],
		name: 'Stemmons Freeway',
		marketIds: [10],
	},
	'Upper Tollway': {
		ids: [232],
		name: 'Upper Tollway',
		marketIds: [10],
	},
	'Uptown Turtle Creek': {
		ids: [233],
		name: 'Uptown Turtle Creek',
		marketIds: [10],
	},
	Buckhead: {
		ids: [234],
		name: 'Buckhead',
		marketIds: [11],
	},
	'Central Perimeter': {
		ids: [235],
		name: 'Central Perimeter',
		marketIds: [11],
	},
	Cumberland: {
		ids: [236],
		name: 'Cumberland',
		marketIds: [11],
	},
	'North Fulton': {
		ids: [239],
		name: 'North Fulton',
		marketIds: [11],
	},
	'Northeast Atlanta': {
		ids: [240],
		name: 'Northeast Atlanta',
		marketIds: [11],
	},
	Northlake: {
		ids: [241],
		name: 'Northlake',
		marketIds: [11],
	},
	'Northwest Atlanta': {
		ids: [242],
		name: 'Northwest Atlanta',
		marketIds: [11],
	},
	'South Atlanta': {
		ids: [243],
		name: 'South Atlanta',
		marketIds: [11],
	},
	'West Atlanta': {
		ids: [244],
		name: 'West Atlanta',
		marketIds: [11],
	},
	Gallatin: {
		ids: [977],
		name: 'Gallatin',
		marketIds: [12],
	},
	Murfreesboro: {
		ids: [978],
		name: 'Murfreesboro',
		marketIds: [12],
	},
	'West End/Belle Meade': {
		ids: [979],
		name: 'West End/Belle Meade',
		marketIds: [12],
	},
	'Airport North': {
		ids: [981],
		name: 'Airport North',
		marketIds: [12],
	},
	'Airport South': {
		ids: [982],
		name: 'Airport South',
		marketIds: [12],
	},
	'North Nashville': {
		ids: [983],
		name: 'North Nashville',
		marketIds: [12],
	},
	Metrocenter: {
		ids: [984, 731],
		name: 'Metrocenter',
		marketIds: [12, 26],
	},
	'Hills/Brentwood/Franklin': {
		ids: [985],
		name: 'Hills/Brentwood/Franklin',
		marketIds: [12],
	},
	Lebanon: {
		ids: [986],
		name: 'Lebanon',
		marketIds: [12],
	},
	'Green Hills/21st Street/Music Row': {
		ids: [987],
		name: 'Green Hills/21st Street/Music Row',
		marketIds: [12],
	},
	'Greenway Plaza': {
		ids: [285],
		name: 'Greenway Plaza',
		marketIds: [13],
	},
	'I10 East': {
		ids: [286],
		name: 'I10 East',
		marketIds: [13],
	},
	'Katy Freeway': {
		ids: [287],
		name: 'Katy Freeway',
		marketIds: [13],
	},
	'Kingwood Humble': {
		ids: [288],
		name: 'Kingwood Humble',
		marketIds: [13],
	},
	'North Belt': {
		ids: [289],
		name: 'North Belt',
		marketIds: [13],
	},
	Pasadena: {
		ids: [291],
		name: 'Pasadena',
		marketIds: [13],
	},
	'Richmond/Fountain View': {
		ids: [292],
		name: 'Richmond/Fountain View',
		marketIds: [13],
	},
	'San Felipe Voss': {
		ids: [293],
		name: 'San Felipe Voss',
		marketIds: [13],
	},
	'South Main': {
		ids: [294],
		name: 'South Main',
		marketIds: [13],
	},
	South: {
		ids: [295, 561, 544, 949],
		name: 'South',
		marketIds: [13, 27, 28, 37],
	},
	Southwest: {
		ids: [296, 538, 562, 950],
		name: 'Southwest',
		marketIds: [13, 14, 27, 37],
	},
	Sugarland: {
		ids: [297],
		name: 'Sugarland',
		marketIds: [13],
	},
	'West Loop': {
		ids: [298],
		name: 'West Loop',
		marketIds: [13],
	},
	Westchase: {
		ids: [299],
		name: 'Westchase',
		marketIds: [13],
	},
	Woodlands: {
		ids: [300],
		name: 'Woodlands',
		marketIds: [13],
	},
	Bellaire: {
		ids: [301],
		name: 'Bellaire',
		marketIds: [13],
	},
	Brookshire: {
		ids: [302],
		name: 'Brookshire',
		marketIds: [13],
	},
	'Clear Lake NASA': {
		ids: [303],
		name: 'Clear Lake NASA',
		marketIds: [13],
	},
	Conroe: {
		ids: [304],
		name: 'Conroe',
		marketIds: [13],
	},
	'FM 1960': {
		ids: [306],
		name: 'FM 1960',
		marketIds: [13],
	},
	'Calgary Woods': {
		ids: [307],
		name: 'Calgary Woods',
		marketIds: [13],
	},
	West: {
		ids: [523, 948],
		name: 'West',
		marketIds: [14, 37],
	},
	Aurora: {
		ids: [524],
		name: 'Aurora',
		marketIds: [14],
	},
	Boulder: {
		ids: [525],
		name: 'Boulder',
		marketIds: [14],
	},
	Broomfield: {
		ids: [526],
		name: 'Broomfield',
		marketIds: [14],
	},
	Central: {
		ids: [527, 555],
		name: 'Central',
		marketIds: [14, 27],
	},
	'Fort Collins/Loveland': {
		ids: [529],
		name: 'Fort Collins/Loveland',
		marketIds: [14],
	},
	Glendale: {
		ids: [530],
		name: 'Glendale',
		marketIds: [14],
	},
	Longmont: {
		ids: [531],
		name: 'Longmont',
		marketIds: [14],
	},
	North: {
		ids: [532, 923, 558, 953],
		name: 'North',
		marketIds: [14, 25, 27, 37],
	},
	'North Central': {
		ids: [533, 541],
		name: 'North Central',
		marketIds: [14, 28],
	},
	Northeast: {
		ids: [534, 926, 559, 542],
		name: 'Northeast',
		marketIds: [14, 25, 27, 28],
	},
	'Parker/Castel Rock': {
		ids: [535],
		name: 'Parker/Castel Rock',
		marketIds: [14],
	},
	'South Central': {
		ids: [536],
		name: 'South Central',
		marketIds: [14],
	},
	Southeast: {
		ids: [537],
		name: 'Southeast',
		marketIds: [14],
	},
	'Colorado Springs': {
		ids: [539],
		name: 'Colorado Springs',
		marketIds: [14],
	},
	'Lehigh/Northampton': {
		ids: [686],
		name: 'Lehigh/Northampton',
		marketIds: [15],
	},
	'Horsham/Willow Grove': {
		ids: [687],
		name: 'Horsham/Willow Grove',
		marketIds: [15],
	},
	'Main Line': {
		ids: [688],
		name: 'Main Line',
		marketIds: [15],
	},
	'Columbia/Schuylkill County': {
		ids: [689],
		name: 'Columbia/Schuylkill County',
		marketIds: [15],
	},
	'Chester County': {
		ids: [690],
		name: 'Chester County',
		marketIds: [15],
	},
	'Sussex County': {
		ids: [691],
		name: 'Sussex County',
		marketIds: [15],
	},
	Wilmington: {
		ids: [692],
		name: 'Wilmington',
		marketIds: [15],
	},
	'Atlantic County': {
		ids: [693],
		name: 'Atlantic County',
		marketIds: [15],
	},
	'Camden County': {
		ids: [694],
		name: 'Camden County',
		marketIds: [15],
	},
	'Market Street East': {
		ids: [695],
		name: 'Market Street East',
		marketIds: [15],
	},
	'Harrisburg East': {
		ids: [696],
		name: 'Harrisburg East',
		marketIds: [15],
	},
	'Delaware County': {
		ids: [697],
		name: 'Delaware County',
		marketIds: [15],
	},
	Conshohocken: {
		ids: [698],
		name: 'Conshohocken',
		marketIds: [15],
	},
	'Lancaster County': {
		ids: [699],
		name: 'Lancaster County',
		marketIds: [15],
	},
	'Kent County': {
		ids: [700],
		name: 'Kent County',
		marketIds: [15],
	},
	'Fort Washington': {
		ids: [701],
		name: 'Fort Washington',
		marketIds: [15],
	},
	'Burlington County': {
		ids: [702],
		name: 'Burlington County',
		marketIds: [15],
	},
	'Gloucester County': {
		ids: [703],
		name: 'Gloucester County',
		marketIds: [15],
	},
	'Outer Philadelphia': {
		ids: [704],
		name: 'Outer Philadelphia',
		marketIds: [15],
	},
	'I-81 Corridor': {
		ids: [705],
		name: 'I-81 Corridor',
		marketIds: [15],
	},
	'Bucks County': {
		ids: [706],
		name: 'Bucks County',
		marketIds: [15],
	},
	'King of Prussia': {
		ids: [707],
		name: 'King of Prussia',
		marketIds: [15],
	},
	'Carbon/Monroe County': {
		ids: [708],
		name: 'Carbon/Monroe County',
		marketIds: [15],
	},
	'Market Street West': {
		ids: [709],
		name: 'Market Street West',
		marketIds: [15],
	},
	'Plymouth Meeting': {
		ids: [710],
		name: 'Plymouth Meeting',
		marketIds: [15],
	},
	'Southern New Jersey': {
		ids: [711],
		name: 'Southern New Jersey',
		marketIds: [15],
	},
	'Adams/York County': {
		ids: [712],
		name: 'Adams/York County',
		marketIds: [15],
	},
	'Harrisburg West': {
		ids: [713],
		name: 'Harrisburg West',
		marketIds: [15],
	},
	'Berks County': {
		ids: [714],
		name: 'Berks County',
		marketIds: [15],
	},
	'Independence Hall': {
		ids: [715],
		name: 'Independence Hall',
		marketIds: [15],
	},
	'West Montgomery County': {
		ids: [716],
		name: 'West Montgomery County',
		marketIds: [15],
	},
	'5th Avenue': {
		ids: [308],
		name: '5th Avenue',
		marketIds: [16],
	},
	Carlsbad: {
		ids: [309],
		name: 'Carlsbad',
		marketIds: [16],
	},
	'Del Mar Heights': {
		ids: [310],
		name: 'Del Mar Heights',
		marketIds: [16],
	},
	'East County': {
		ids: [312],
		name: 'East County',
		marketIds: [16],
	},
	Escondido: {
		ids: [313],
		name: 'Escondido',
		marketIds: [16],
	},
	'Governor Park': {
		ids: [314],
		name: 'Governor Park',
		marketIds: [16],
	},
	'Kearny Mesa': {
		ids: [315],
		name: 'Kearny Mesa',
		marketIds: [16],
	},
	'La Jolla': {
		ids: [316],
		name: 'La Jolla',
		marketIds: [16],
	},
	Miramar: {
		ids: [317],
		name: 'Miramar',
		marketIds: [16],
	},
	'Mission Gorge': {
		ids: [318],
		name: 'Mission Gorge',
		marketIds: [16],
	},
	'Mission Valley': {
		ids: [319],
		name: 'Mission Valley',
		marketIds: [16],
	},
	'Naval Air Station North Island': {
		ids: [320],
		name: 'Naval Air Station North Island',
		marketIds: [16],
	},
	'North Beach Cities': {
		ids: [321],
		name: 'North Beach Cities',
		marketIds: [16],
	},
	Oceanside: {
		ids: [322],
		name: 'Oceanside',
		marketIds: [16],
	},
	'Point Loma/Old Town': {
		ids: [323],
		name: 'Point Loma/Old Town',
		marketIds: [16],
	},
	'Rancho Bernardo/Poway': {
		ids: [324],
		name: 'Rancho Bernardo/Poway',
		marketIds: [16],
	},
	'Rose Canyon': {
		ids: [325],
		name: 'Rose Canyon',
		marketIds: [16],
	},
	'Rural Area': {
		ids: [326],
		name: 'Rural Area',
		marketIds: [16],
	},
	'San Marcos/Vista': {
		ids: [327],
		name: 'San Marcos/Vista',
		marketIds: [16],
	},
	'Scripps Ranch': {
		ids: [328],
		name: 'Scripps Ranch',
		marketIds: [16],
	},
	'Sorrento Mesa': {
		ids: [329],
		name: 'Sorrento Mesa',
		marketIds: [16],
	},
	'South San Diego': {
		ids: [330],
		name: 'South San Diego',
		marketIds: [16],
	},
	UTC: {
		ids: [331],
		name: 'UTC',
		marketIds: [16],
	},
	'Vashon Island': {
		ids: [446],
		name: 'Vashon Island',
		marketIds: [17],
	},
	'520 Corridor': {
		ids: [447],
		name: '520 Corridor',
		marketIds: [17],
	},
	'Ballard University District': {
		ids: [448],
		name: 'Ballard University District',
		marketIds: [17],
	},
	'Bellevue CBD': {
		ids: [449],
		name: 'Bellevue CBD',
		marketIds: [17],
	},
	'Bellevue Suburban': {
		ids: [450],
		name: 'Bellevue Suburban',
		marketIds: [17],
	},
	'Bothell/Kenmore': {
		ids: [451],
		name: 'Bothell/Kenmore',
		marketIds: [17],
	},
	'Capitol Hill East Seattle': {
		ids: [452],
		name: 'Capitol Hill East Seattle',
		marketIds: [17],
	},
	'Denny Triangle Regrade': {
		ids: [453],
		name: 'Denny Triangle Regrade',
		marketIds: [17],
	},
	'East King County': {
		ids: [454],
		name: 'East King County',
		marketIds: [17],
	},
	'East Pierce County': {
		ids: [455],
		name: 'East Pierce County',
		marketIds: [17],
	},
	'East Snohomish County': {
		ids: [456],
		name: 'East Snohomish County',
		marketIds: [17],
	},
	Everett: {
		ids: [457],
		name: 'Everett',
		marketIds: [17],
	},
	'Federal Way Auburn': {
		ids: [458],
		name: 'Federal Way Auburn',
		marketIds: [17],
	},
	'I90 Corridor': {
		ids: [459],
		name: 'I90 Corridor',
		marketIds: [17],
	},
	'Kent Valley': {
		ids: [460],
		name: 'Kent Valley',
		marketIds: [17],
	},
	Kirkland: {
		ids: [461],
		name: 'Kirkland',
		marketIds: [17],
	},
	'Lake Union': {
		ids: [462],
		name: 'Lake Union',
		marketIds: [17],
	},
	'Mercer Island': {
		ids: [463],
		name: 'Mercer Island',
		marketIds: [17],
	},
	Northend: {
		ids: [464],
		name: 'Northend',
		marketIds: [17],
	},
	Northgate: {
		ids: [465],
		name: 'Northgate',
		marketIds: [17],
	},
	'Pioneer Square Waterfront': {
		ids: [466],
		name: 'Pioneer Square Waterfront',
		marketIds: [17],
	},
	'Queen Avenue Magnolia': {
		ids: [467],
		name: 'Queen Avenue Magnolia',
		marketIds: [17],
	},
	Redmond: {
		ids: [468],
		name: 'Redmond',
		marketIds: [17],
	},
	'Renton/Tukwila': {
		ids: [469],
		name: 'Renton/Tukwila',
		marketIds: [17],
	},
	'SeaTac Vurien': {
		ids: [470],
		name: 'SeaTac Vurien',
		marketIds: [17],
	},
	'Seattle CBD': {
		ids: [471],
		name: 'Seattle CBD',
		marketIds: [17],
	},
	'South Seattle': {
		ids: [472],
		name: 'South Seattle',
		marketIds: [17],
	},
	'Tacoma CBD': {
		ids: [473],
		name: 'Tacoma CBD',
		marketIds: [17],
	},
	'Tacoma Suburban': {
		ids: [474],
		name: 'Tacoma Suburban',
		marketIds: [17],
	},
	'Western I-80 Corridor': {
		ids: [504],
		name: 'Western I-80 Corridor',
		marketIds: [18],
	},
	'Western Route 78': {
		ids: [505],
		name: 'Western Route 78',
		marketIds: [18],
	},
	'Central Bergen': {
		ids: [506],
		name: 'Central Bergen',
		marketIds: [18],
	},
	Meadowlands: {
		ids: [507],
		name: 'Meadowlands',
		marketIds: [18],
	},
	Monmouth: {
		ids: [508],
		name: 'Monmouth',
		marketIds: [18],
	},
	Morristown: {
		ids: [509],
		name: 'Morristown',
		marketIds: [18],
	},
	Palisades: {
		ids: [510],
		name: 'Palisades',
		marketIds: [18],
	},
	'Parkway Corridor': {
		ids: [511],
		name: 'Parkway Corridor',
		marketIds: [18],
	},
	Parsippany: {
		ids: [512],
		name: 'Parsippany',
		marketIds: [18],
	},
	Princeton: {
		ids: [513],
		name: 'Princeton',
		marketIds: [18],
	},
	'Urban Essex': {
		ids: [514],
		name: 'Urban Essex',
		marketIds: [18],
	},
	Waterfront: {
		ids: [515],
		name: 'Waterfront',
		marketIds: [18],
	},
	'Montvale/Woodcliff Lake': {
		ids: [516],
		name: 'Montvale/Woodcliff Lake',
		marketIds: [18],
	},
	'Paterson/Wayne/Route 23': {
		ids: [517],
		name: 'Paterson/Wayne/Route 23',
		marketIds: [18],
	},
	'Piscataway/Brunswicks/Route 287': {
		ids: [518],
		name: 'Piscataway/Brunswicks/Route 287',
		marketIds: [18],
	},
	'Route 78/287 Interchange': {
		ids: [519],
		name: 'Route 78/287 Interchange',
		marketIds: [18],
	},
	'Suburban Essex/Eastern Morris': {
		ids: [520],
		name: 'Suburban Essex/Eastern Morris',
		marketIds: [18],
	},
	'West Bergen/Route 17 Corridor': {
		ids: [521],
		name: 'West Bergen/Route 17 Corridor',
		marketIds: [18],
	},
	'Chatham/Millburn/Short Hills': {
		ids: [522],
		name: 'Chatham/Millburn/Short Hills',
		marketIds: [18],
	},
	'Commercial Corridor': {
		ids: [825],
		name: 'Commercial Corridor',
		marketIds: [19],
	},
	Aventura: {
		ids: [826],
		name: 'Aventura',
		marketIds: [19],
	},
	'Sunny Isles': {
		ids: [827],
		name: 'Sunny Isles',
		marketIds: [19],
	},
	'Miami Lakes': {
		ids: [828],
		name: 'Miami Lakes',
		marketIds: [19],
	},
	'Miami Airport': {
		ids: [829],
		name: 'Miami Airport',
		marketIds: [19],
	},
	'North Palm Beach': {
		ids: [830],
		name: 'North Palm Beach',
		marketIds: [19],
	},
	'Boynton Beach': {
		ids: [831],
		name: 'Boynton Beach',
		marketIds: [19],
	},
	'Hollywood/Hallandale': {
		ids: [832],
		name: 'Hollywood/Hallandale',
		marketIds: [19],
	},
	'Opa-locka/Hialeah': {
		ids: [833],
		name: 'Opa-locka/Hialeah',
		marketIds: [19],
	},
	'Coral Springs': {
		ids: [834],
		name: 'Coral Springs',
		marketIds: [19],
	},
	'Belle Glade': {
		ids: [835],
		name: 'Belle Glade',
		marketIds: [19],
	},
	'West Palm Beach': {
		ids: [836],
		name: 'West Palm Beach',
		marketIds: [19],
	},
	'Palm Beach': {
		ids: [837],
		name: 'Palm Beach',
		marketIds: [19],
	},
	'Miami Beach': {
		ids: [838],
		name: 'Miami Beach',
		marketIds: [19],
	},
	'Lake Worth': {
		ids: [839],
		name: 'Lake Worth',
		marketIds: [19],
	},
	'Kendall/Homestead': {
		ids: [840],
		name: 'Kendall/Homestead',
		marketIds: [19],
	},
	'Pompano/Lauderdale': {
		ids: [841],
		name: 'Pompano/Lauderdale',
		marketIds: [19],
	},
	'Key West': {
		ids: [842],
		name: 'Key West',
		marketIds: [19],
	},
	'North Miami': {
		ids: [843],
		name: 'North Miami',
		marketIds: [19],
	},
	'Delray Beach': {
		ids: [844],
		name: 'Delray Beach',
		marketIds: [19],
	},
	'Miami CBD': {
		ids: [845],
		name: 'Miami CBD',
		marketIds: [19],
	},
	'Southwest Broward': {
		ids: [846],
		name: 'Southwest Broward',
		marketIds: [19],
	},
	Jupiter: {
		ids: [847],
		name: 'Jupiter',
		marketIds: [19],
	},
	'Fort Lauderdale': {
		ids: [848],
		name: 'Fort Lauderdale',
		marketIds: [19],
	},
	Brickell: {
		ids: [849],
		name: 'Brickell',
		marketIds: [19],
	},
	'Downtown Fort Lauderdale': {
		ids: [850],
		name: 'Downtown Fort Lauderdale',
		marketIds: [19],
	},
	'Northeast Broward': {
		ids: [851],
		name: 'Northeast Broward',
		marketIds: [19],
	},
	Everglades: {
		ids: [852],
		name: 'Everglades',
		marketIds: [19],
	},
	'Coral Gables': {
		ids: [853],
		name: 'Coral Gables',
		marketIds: [19],
	},
	'Coconut Grove': {
		ids: [854],
		name: 'Coconut Grove',
		marketIds: [19],
	},
	'Boca Raton': {
		ids: [855],
		name: 'Boca Raton',
		marketIds: [19],
	},
	'Biscayne Corridor': {
		ids: [856],
		name: 'Biscayne Corridor',
		marketIds: [19],
	},
	Plantation: {
		ids: [857],
		name: 'Plantation',
		marketIds: [19],
	},
	'Stamford Suburban': {
		ids: [874],
		name: 'Stamford Suburban',
		marketIds: [20],
	},
	'Central Fairfield County': {
		ids: [875],
		name: 'Central Fairfield County',
		marketIds: [20],
	},
	'North Fairfield County': {
		ids: [876],
		name: 'North Fairfield County',
		marketIds: [20],
	},
	'White Plains Suburban': {
		ids: [877],
		name: 'White Plains Suburban',
		marketIds: [20],
	},
	'Westchester East': {
		ids: [878],
		name: 'Westchester East',
		marketIds: [20],
	},
	'Westchester West': {
		ids: [879],
		name: 'Westchester West',
		marketIds: [20],
	},
	'Westchester North': {
		ids: [880],
		name: 'Westchester North',
		marketIds: [20],
	},
	Greenwich: {
		ids: [881],
		name: 'Greenwich',
		marketIds: [20],
	},
	'Westchester South': {
		ids: [882],
		name: 'Westchester South',
		marketIds: [20],
	},
	'East Fairfield County': {
		ids: [883],
		name: 'East Fairfield County',
		marketIds: [20],
	},
	'White Plains CBD': {
		ids: [884],
		name: 'White Plains CBD',
		marketIds: [20],
	},
	'Stamford CBD': {
		ids: [885],
		name: 'Stamford CBD',
		marketIds: [20],
	},
	'New Haven Suburban': {
		ids: [957],
		name: 'New Haven Suburban',
		marketIds: [20],
	},
	'New London': {
		ids: [958],
		name: 'New London',
		marketIds: [20],
	},
	Tolland: {
		ids: [959],
		name: 'Tolland',
		marketIds: [20],
	},
	Litchfield: {
		ids: [960],
		name: 'Litchfield',
		marketIds: [20],
	},
	'New Haven CBD': {
		ids: [961],
		name: 'New Haven CBD',
		marketIds: [20],
	},
	'Hartford Suburban': {
		ids: [962],
		name: 'Hartford Suburban',
		marketIds: [20],
	},
	'Hartford CBD': {
		ids: [963],
		name: 'Hartford CBD',
		marketIds: [20],
	},
	Windham: {
		ids: [964],
		name: 'Windham',
		marketIds: [20],
	},
	Middlesex: {
		ids: [965],
		name: 'Middlesex',
		marketIds: [20],
	},
	'Mid-Pinellas': {
		ids: [770],
		name: 'Mid-Pinellas',
		marketIds: [21],
	},
	'Downtown Tampa': {
		ids: [771],
		name: 'Downtown Tampa',
		marketIds: [21],
	},
	'Spring Hill/Dade City': {
		ids: [772],
		name: 'Spring Hill/Dade City',
		marketIds: [21],
	},
	'Southwest Tampa': {
		ids: [773],
		name: 'Southwest Tampa',
		marketIds: [21],
	},
	'Bradenton/Palmetto': {
		ids: [774],
		name: 'Bradenton/Palmetto',
		marketIds: [21],
	},
	'Southeast Tampa': {
		ids: [775],
		name: 'Southeast Tampa',
		marketIds: [21],
	},
	'Southeast St. Petersburg': {
		ids: [776],
		name: 'Southeast St. Petersburg',
		marketIds: [21],
	},
	'East Tampa': {
		ids: [777],
		name: 'East Tampa',
		marketIds: [21],
	},
	Westshore: {
		ids: [778],
		name: 'Westshore',
		marketIds: [21],
	},
	Clearwater: {
		ids: [779],
		name: 'Clearwater',
		marketIds: [21],
	},
	'Lakeland/Winter Haven': {
		ids: [780],
		name: 'Lakeland/Winter Haven',
		marketIds: [21],
	},
	'Northeast Tampa': {
		ids: [781],
		name: 'Northeast Tampa',
		marketIds: [21],
	},
	'Northwest Tampa': {
		ids: [782],
		name: 'Northwest Tampa',
		marketIds: [21],
	},
	'Southwest St. Petersburg': {
		ids: [783],
		name: 'Southwest St. Petersburg',
		marketIds: [21],
	},
	'North Pinellas': {
		ids: [784],
		name: 'North Pinellas',
		marketIds: [21],
	},
	Gainesville: {
		ids: [785],
		name: 'Gainesville',
		marketIds: [22],
	},
	Putnam: {
		ids: [786],
		name: 'Putnam',
		marketIds: [22],
	},
	Northside: {
		ids: [787],
		name: 'Northside',
		marketIds: [22],
	},
	'I-95 Corridor': {
		ids: [789],
		name: 'I-95 Corridor',
		marketIds: [22],
	},
	'St. Johns/Flagler': {
		ids: [790],
		name: 'St. Johns/Flagler',
		marketIds: [22],
	},
	'East Butler': {
		ids: [791],
		name: 'East Butler',
		marketIds: [22],
	},
	Baker: {
		ids: [792],
		name: 'Baker',
		marketIds: [22],
	},
	Mandarin: {
		ids: [793],
		name: 'Mandarin',
		marketIds: [22],
	},
	Westside: {
		ids: [794],
		name: 'Westside',
		marketIds: [22],
	},
	Clay: {
		ids: [795],
		name: 'Clay',
		marketIds: [22],
	},
	Southside: {
		ids: [796],
		name: 'Southside',
		marketIds: [22],
	},
	'Jacksonville Beaches': {
		ids: [798],
		name: 'Jacksonville Beaches',
		marketIds: [22],
	},
	'North Baltimore': {
		ids: [858],
		name: 'North Baltimore',
		marketIds: [23],
	},
	'Howard County': {
		ids: [859],
		name: 'Howard County',
		marketIds: [23],
	},
	'East Baltimore': {
		ids: [860],
		name: 'East Baltimore',
		marketIds: [23],
	},
	Columbia: {
		ids: [861],
		name: 'Columbia',
		marketIds: [23],
	},
	Towson: {
		ids: [862],
		name: 'Towson',
		marketIds: [23],
	},
	'West Side': {
		ids: [863],
		name: 'West Side',
		marketIds: [23],
	},
	'I-83 Corridor': {
		ids: [864],
		name: 'I-83 Corridor',
		marketIds: [23],
	},
	Annapolis: {
		ids: [865],
		name: 'Annapolis',
		marketIds: [23],
	},
	'Baltimore County East': {
		ids: [866],
		name: 'Baltimore County East',
		marketIds: [23],
	},
	'West Baltimore': {
		ids: [867],
		name: 'West Baltimore',
		marketIds: [23],
	},
	'BWI Corridor': {
		ids: [868],
		name: 'BWI Corridor',
		marketIds: [23],
	},
	'Reisterstown Road Corridor': {
		ids: [869],
		name: 'Reisterstown Road Corridor',
		marketIds: [23],
	},
	'Route 2 Corridor': {
		ids: [870],
		name: 'Route 2 Corridor',
		marketIds: [23],
	},
	'Harford County': {
		ids: [871],
		name: 'Harford County',
		marketIds: [23],
	},
	'Anne Arundel County': {
		ids: [872],
		name: 'Anne Arundel County',
		marketIds: [23],
	},
	CBD: {
		ids: [873, 554, 540, 939, 672, 902, 1067, 1081, 1095],
		name: 'CBD',
		marketIds: [23, 27, 28, 29, 32, 100, 104, 105, 106],
	},
	'Cameron Village': {
		ids: [1004],
		name: 'Cameron Village',
		marketIds: [24],
	},
	'Orange County/Chapel Hill': {
		ids: [1005],
		name: 'Orange County/Chapel Hill',
		marketIds: [24],
	},
	'Downtown Raleigh': {
		ids: [1006],
		name: 'Downtown Raleigh',
		marketIds: [24],
	},
	'North Durham': {
		ids: [1007],
		name: 'North Durham',
		marketIds: [24],
	},
	'South Wake County': {
		ids: [1008],
		name: 'South Wake County',
		marketIds: [24],
	},
	'Six Forks Road': {
		ids: [1009],
		name: 'Six Forks Road',
		marketIds: [24],
	},
	'West Raleigh': {
		ids: [1010],
		name: 'West Raleigh',
		marketIds: [24],
	},
	'US 1/Capitol Blvd': {
		ids: [1011],
		name: 'US 1/Capitol Blvd',
		marketIds: [24],
	},
	'Downtown Durham': {
		ids: [1012],
		name: 'Downtown Durham',
		marketIds: [24],
	},
	'South Durham': {
		ids: [1013],
		name: 'South Durham',
		marketIds: [24],
	},
	Cary: {
		ids: [1014],
		name: 'Cary',
		marketIds: [24],
	},
	'East Wake County': {
		ids: [1015],
		name: 'East Wake County',
		marketIds: [24],
	},
	'RTP/I-40 Corridor': {
		ids: [1016],
		name: 'RTP/I-40 Corridor',
		marketIds: [24],
	},
	'US 70/Glenwood Ave': {
		ids: [1017],
		name: 'US 70/Glenwood Ave',
		marketIds: [24],
	},
	'Falls of Neuse Road': {
		ids: [1018],
		name: 'Falls of Neuse Road',
		marketIds: [24],
	},
	Cotswold: {
		ids: [916],
		name: 'Cotswold',
		marketIds: [25],
	},
	East: {
		ids: [917, 556],
		name: 'East',
		marketIds: [25, 27],
	},
	Uptown: {
		ids: [918],
		name: 'Uptown',
		marketIds: [25],
	},
	Airport: {
		ids: [919, 1060],
		name: 'Airport',
		marketIds: [25, 104],
	},
	'Highway 51/Southeast': {
		ids: [920],
		name: 'Highway 51/Southeast',
		marketIds: [25],
	},
	'Crown Point/Matthews': {
		ids: [921],
		name: 'Crown Point/Matthews',
		marketIds: [25],
	},
	'Park Road': {
		ids: [924],
		name: 'Park Road',
		marketIds: [25],
	},
	'South Park': {
		ids: [925],
		name: 'South Park',
		marketIds: [25],
	},
	'Scottsdale Airpark': {
		ids: [718],
		name: 'Scottsdale Airpark',
		marketIds: [26],
	},
	Mesa: {
		ids: [719],
		name: 'Mesa',
		marketIds: [26],
	},
	'Scottsdale Central': {
		ids: [720],
		name: 'Scottsdale Central',
		marketIds: [26],
	},
	'Camelback Corridor': {
		ids: [721],
		name: 'Camelback Corridor',
		marketIds: [26],
	},
	'Chandler/Gilbert': {
		ids: [722],
		name: 'Chandler/Gilbert',
		marketIds: [26],
	},
	Arrowhead: {
		ids: [723],
		name: 'Arrowhead',
		marketIds: [26],
	},
	'Deer Valley': {
		ids: [724],
		name: 'Deer Valley',
		marketIds: [26],
	},
	'Piestewa Peak Corridor': {
		ids: [725],
		name: 'Piestewa Peak Corridor',
		marketIds: [26],
	},
	'44th Street Corridor': {
		ids: [726],
		name: '44th Street Corridor',
		marketIds: [26],
	},
	'South I-10/Ahwatukee': {
		ids: [727],
		name: 'South I-10/Ahwatukee',
		marketIds: [26],
	},
	'Glendale/Peoria': {
		ids: [728],
		name: 'Glendale/Peoria',
		marketIds: [26],
	},
	'West Phoenix': {
		ids: [729],
		name: 'West Phoenix',
		marketIds: [26],
	},
	'Scottsdale South': {
		ids: [730],
		name: 'Scottsdale South',
		marketIds: [26],
	},
	'Tempe North': {
		ids: [733],
		name: 'Tempe North',
		marketIds: [26],
	},
	'North Phoenix': {
		ids: [734],
		name: 'North Phoenix',
		marketIds: [26],
	},
	'Sky Harbor': {
		ids: [735],
		name: 'Sky Harbor',
		marketIds: [26],
	},
	'East Central Phoenix': {
		ids: [736],
		name: 'East Central Phoenix',
		marketIds: [26],
	},
	'Far Northwest': {
		ids: [557],
		name: 'Far Northwest',
		marketIds: [27],
	},
	'Oregon City': {
		ids: [927],
		name: 'Oregon City',
		marketIds: [29],
	},
	'Lloyd Center': {
		ids: [928],
		name: 'Lloyd Center',
		marketIds: [29],
	},
	Wilsonville: {
		ids: [929],
		name: 'Wilsonville',
		marketIds: [29],
	},
	'Airport Way': {
		ids: [930],
		name: 'Airport Way',
		marketIds: [29],
	},
	'Johns Landing': {
		ids: [931],
		name: 'Johns Landing',
		marketIds: [29],
	},
	'Barbur Boulevard': {
		ids: [932],
		name: 'Barbur Boulevard',
		marketIds: [29],
	},
	'Lake Oswego': {
		ids: [933],
		name: 'Lake Oswego',
		marketIds: [29],
	},
	'West Hills': {
		ids: [934],
		name: 'West Hills',
		marketIds: [29],
	},
	Vancouver: {
		ids: [935],
		name: 'Vancouver',
		marketIds: [29],
	},
	Hillsboro: {
		ids: [936],
		name: 'Hillsboro',
		marketIds: [29],
	},
	Tualatin: {
		ids: [937],
		name: 'Tualatin',
		marketIds: [29],
	},
	Clackamas: {
		ids: [938],
		name: 'Clackamas',
		marketIds: [29],
	},
	'Forest Park': {
		ids: [940],
		name: 'Forest Park',
		marketIds: [29],
	},
	Eastside: {
		ids: [941],
		name: 'Eastside',
		marketIds: [29],
	},
	Multnomah: {
		ids: [942],
		name: 'Multnomah',
		marketIds: [29],
	},
	Beaverton: {
		ids: [943],
		name: 'Beaverton',
		marketIds: [29],
	},
	'Northwest Portland': {
		ids: [944],
		name: 'Northwest Portland',
		marketIds: [29],
	},
	Tigard: {
		ids: [945],
		name: 'Tigard',
		marketIds: [29],
	},
	'Laurelwood/Buckheaven': {
		ids: [946],
		name: 'Laurelwood/Buckheaven',
		marketIds: [29],
	},
	'Kruse Way': {
		ids: [947],
		name: 'Kruse Way',
		marketIds: [29],
	},
	'Airport International Center': {
		ids: [671],
		name: 'Airport International Center',
		marketIds: [32],
	},
	'South West Valley': {
		ids: [673],
		name: 'South West Valley',
		marketIds: [32],
	},
	'Davis/Weber County': {
		ids: [674],
		name: 'Davis/Weber County',
		marketIds: [32],
	},
	'Research Park': {
		ids: [675],
		name: 'Research Park',
		marketIds: [32],
	},
	'Park City': {
		ids: [676],
		name: 'Park City',
		marketIds: [32],
	},
	Draper: {
		ids: [677],
		name: 'Draper',
		marketIds: [32],
	},
	'Central Valley': {
		ids: [678],
		name: 'Central Valley',
		marketIds: [32],
	},
	'Union Park/Cottonwood': {
		ids: [679],
		name: 'Union Park/Cottonwood',
		marketIds: [32],
	},
	'Central Valley West': {
		ids: [680],
		name: 'Central Valley West',
		marketIds: [32],
	},
	'Provo/Orem': {
		ids: [681],
		name: 'Provo/Orem',
		marketIds: [32],
	},
	'West Valley': {
		ids: [682],
		name: 'West Valley',
		marketIds: [32],
	},
	Sandy: {
		ids: [683],
		name: 'Sandy',
		marketIds: [32],
	},
	'Sugarhouse/Foothill Corridor': {
		ids: [684],
		name: 'Sugarhouse/Foothill Corridor',
		marketIds: [32],
	},
	'Central Valley East': {
		ids: [685],
		name: 'Central Valley East',
		marketIds: [32],
	},
	'Maitland Center': {
		ids: [811],
		name: 'Maitland Center',
		marketIds: [33],
	},
	'Indian River/St. Lucie': {
		ids: [812],
		name: 'Indian River/St. Lucie',
		marketIds: [33],
	},
	'East Orange': {
		ids: [813],
		name: 'East Orange',
		marketIds: [33],
	},
	'Lake Mary/Heathrow': {
		ids: [814],
		name: 'Lake Mary/Heathrow',
		marketIds: [33],
	},
	Seminole: {
		ids: [815],
		name: 'Seminole',
		marketIds: [33],
	},
	'North Orlando': {
		ids: [816],
		name: 'North Orlando',
		marketIds: [33],
	},
	'East Orlando': {
		ids: [817],
		name: 'East Orlando',
		marketIds: [33],
	},
	'Brevard/Palm Bay': {
		ids: [818],
		name: 'Brevard/Palm Bay',
		marketIds: [33],
	},
	Osceola: {
		ids: [820],
		name: 'Osceola',
		marketIds: [33],
	},
	'South Orlando': {
		ids: [821],
		name: 'South Orlando',
		marketIds: [33],
	},
	'Lake County': {
		ids: [822],
		name: 'Lake County',
		marketIds: [33],
	},
	'Kissimmee/St. Cloud': {
		ids: [823],
		name: 'Kissimmee/St. Cloud',
		marketIds: [33],
	},
	'Sand Lake/Tourist': {
		ids: [824],
		name: 'Sand Lake/Tourist',
		marketIds: [33],
	},
	'Henderson/Boulder City': {
		ids: [954],
		name: 'Henderson/Boulder City',
		marketIds: [37],
	},
	'Central East': {
		ids: [955],
		name: 'Central East',
		marketIds: [37],
	},
	'Central North': {
		ids: [956],
		name: 'Central North',
		marketIds: [37],
	},
	'Birmingham/Bloomfield': {
		ids: [966],
		name: 'Birmingham/Bloomfield',
		marketIds: [38],
	},
	Detroit: {
		ids: [967],
		name: 'Detroit',
		marketIds: [38],
	},
	'Ann Arbor': {
		ids: [968],
		name: 'Ann Arbor',
		marketIds: [38],
	},
	'Farmington Hills/West Bloomfield': {
		ids: [969],
		name: 'Farmington Hills/West Bloomfield',
		marketIds: [38],
	},
	'Auburn Hills': {
		ids: [970],
		name: 'Auburn Hills',
		marketIds: [38],
	},
	Southfield: {
		ids: [971],
		name: 'Southfield',
		marketIds: [38],
	},
	'Macomb County': {
		ids: [972],
		name: 'Macomb County',
		marketIds: [38],
	},
	'I-275 Corridor': {
		ids: [973],
		name: 'I-275 Corridor',
		marketIds: [38],
	},
	Troy: {
		ids: [974],
		name: 'Troy',
		marketIds: [38],
	},
	'Rochester/Rochester Hills': {
		ids: [975],
		name: 'Rochester/Rochester Hills',
		marketIds: [38],
	},
	Dearborn: {
		ids: [976],
		name: 'Dearborn',
		marketIds: [38],
	},
	Flint: {
		ids: [1019],
		name: 'Flint',
		marketIds: [38],
	},
	Lansing: {
		ids: [1020],
		name: 'Lansing',
		marketIds: [38],
	},
	'South Lapeer County': {
		ids: [1021],
		name: 'South Lapeer County',
		marketIds: [38],
	},
	'Livingston County': {
		ids: [1022],
		name: 'Livingston County',
		marketIds: [38],
	},
	Alabama: {
		ids: [1154],
		name: 'Alabama',
		marketIds: [40],
	},
	Alaska: {
		ids: [579],
		name: 'Alaska',
		marketIds: [41],
	},
	Arizona: {
		ids: [581],
		name: 'Arizona',
		marketIds: [43],
	},
	Arkansas: {
		ids: [582],
		name: 'Arkansas',
		marketIds: [44],
	},
	California: {
		ids: [583],
		name: 'California',
		marketIds: [45],
	},
	Colorado: {
		ids: [584],
		name: 'Colorado',
		marketIds: [46],
	},
	Georgia: {
		ids: [588],
		name: 'Georgia',
		marketIds: [50],
	},
	Hawaii: {
		ids: [1077],
		name: 'Hawaii',
		marketIds: [52],
	},
	Idaho: {
		ids: [591],
		name: 'Idaho',
		marketIds: [53],
	},
	Illinois: {
		ids: [592],
		name: 'Illinois',
		marketIds: [54],
	},
	'Boone County': {
		ids: [1200],
		name: 'Boone County',
		marketIds: [56],
	},
	'Johnson County': {
		ids: [1201],
		name: 'Johnson County',
		marketIds: [56],
	},
	'Marion County': {
		ids: [1202],
		name: 'Marion County',
		marketIds: [56],
	},
	'Hendricks County': {
		ids: [1203],
		name: 'Hendricks County',
		marketIds: [56],
	},
	'Hamilton County': {
		ids: [1204],
		name: 'Hamilton County',
		marketIds: [56],
	},
	'South Hancock County': {
		ids: [1205],
		name: 'South Hancock County',
		marketIds: [56],
	},
	'Morgan County': {
		ids: [1206],
		name: 'Morgan County',
		marketIds: [56],
	},
	'North Hancock County': {
		ids: [1207],
		name: 'North Hancock County',
		marketIds: [56],
	},
	Iowa: {
		ids: [1149],
		name: 'Iowa',
		marketIds: [57],
	},
	Kansas: {
		ids: [596],
		name: 'Kansas',
		marketIds: [58],
	},
	Kentucky: {
		ids: [1156],
		name: 'Kentucky',
		marketIds: [59],
	},
	Louisiana: {
		ids: [598],
		name: 'Louisiana',
		marketIds: [60],
	},
	Maine: {
		ids: [599],
		name: 'Maine',
		marketIds: [61],
	},
	'Maryland East': {
		ids: [635],
		name: 'Maryland East',
		marketIds: [62],
	},
	'Maryland West': {
		ids: [636],
		name: 'Maryland West',
		marketIds: [62],
	},
	Massachusetts: {
		ids: [601],
		name: 'Massachusetts',
		marketIds: [63],
	},
	Michigan: {
		ids: [602],
		name: 'Michigan',
		marketIds: [64],
	},
	Minnesota: {
		ids: [603],
		name: 'Minnesota',
		marketIds: [65],
	},
	Mississippi: {
		ids: [604],
		name: 'Mississippi',
		marketIds: [66],
	},
	Missouri: {
		ids: [605],
		name: 'Missouri',
		marketIds: [67],
	},
	Montana: {
		ids: [606],
		name: 'Montana',
		marketIds: [68],
	},
	Nebraska: {
		ids: [1270],
		name: 'Nebraska',
		marketIds: [69],
	},
	'New Hampshire': {
		ids: [609],
		name: 'New Hampshire',
		marketIds: [71],
	},
	'New Jersey - Other': {
		ids: [1159],
		name: 'New Jersey - Other',
		marketIds: [72],
	},
	'New Jersey - Warren County': {
		ids: [1160],
		name: 'New Jersey - Warren County',
		marketIds: [72],
	},
	'New Mexico': {
		ids: [1152],
		name: 'New Mexico',
		marketIds: [73],
	},
	'New York': {
		ids: [612],
		name: 'New York',
		marketIds: [74],
	},
	'North Carolina': {
		ids: [613],
		name: 'North Carolina',
		marketIds: [75],
	},
	'North Dakota': {
		ids: [614],
		name: 'North Dakota',
		marketIds: [76],
	},
	Ohio: {
		ids: [615],
		name: 'Ohio',
		marketIds: [77],
	},
	Oklahoma: {
		ids: [616],
		name: 'Oklahoma',
		marketIds: [78],
	},
	Oregon: {
		ids: [617],
		name: 'Oregon',
		marketIds: [79],
	},
	Pennsylvania: {
		ids: [618],
		name: 'Pennsylvania',
		marketIds: [80],
	},
	'Rhode Island': {
		ids: [620],
		name: 'Rhode Island',
		marketIds: [82],
	},
	'South Carolina': {
		ids: [1187],
		name: 'South Carolina',
		marketIds: [83],
	},
	'South Dakota': {
		ids: [622],
		name: 'South Dakota',
		marketIds: [84],
	},
	Tennessee: {
		ids: [623],
		name: 'Tennessee',
		marketIds: [85],
	},
	Texas: {
		ids: [1150],
		name: 'Texas',
		marketIds: [86],
	},
	Utah: {
		ids: [625],
		name: 'Utah',
		marketIds: [87],
	},
	Vermont: {
		ids: [626],
		name: 'Vermont',
		marketIds: [88],
	},
	Virginia: {
		ids: [628],
		name: 'Virginia',
		marketIds: [90],
	},
	Washington: {
		ids: [629],
		name: 'Washington',
		marketIds: [91],
	},
	'West Virginia': {
		ids: [630],
		name: 'West Virginia',
		marketIds: [92],
	},
	Wisconsin: {
		ids: [631],
		name: 'Wisconsin',
		marketIds: [93],
	},
	Wyoming: {
		ids: [632],
		name: 'Wyoming',
		marketIds: [94],
	},
	Tallahassee: {
		ids: [799],
		name: 'Tallahassee',
		marketIds: [97],
	},
	'Panama City': {
		ids: [800],
		name: 'Panama City',
		marketIds: [97],
	},
	Destin: {
		ids: [801],
		name: 'Destin',
		marketIds: [97],
	},
	Pensacola: {
		ids: [802],
		name: 'Pensacola',
		marketIds: [97],
	},
	Ocala: {
		ids: [803],
		name: 'Ocala',
		marketIds: [97],
	},
	'Perry/Lake City': {
		ids: [804],
		name: 'Perry/Lake City',
		marketIds: [97],
	},
	Marianna: {
		ids: [805],
		name: 'Marianna',
		marketIds: [97],
	},
	'Cape Coral/Fort Myers': {
		ids: [806],
		name: 'Cape Coral/Fort Myers',
		marketIds: [98],
	},
	'Highland/Glades/Hendry': {
		ids: [807],
		name: 'Highland/Glades/Hendry',
		marketIds: [98],
	},
	Naples: {
		ids: [808],
		name: 'Naples',
		marketIds: [98],
	},
	Sarasota: {
		ids: [809],
		name: 'Sarasota',
		marketIds: [98],
	},
	'Central Suffolk County': {
		ids: [1161],
		name: 'Central Suffolk County',
		marketIds: [99],
	},
	'West Suffolk County': {
		ids: [1162],
		name: 'West Suffolk County',
		marketIds: [99],
	},
	'East Nassau County': {
		ids: [1163],
		name: 'East Nassau County',
		marketIds: [99],
	},
	'West Nassau County': {
		ids: [1164],
		name: 'West Nassau County',
		marketIds: [99],
	},
	'East Suffolk County': {
		ids: [1165],
		name: 'East Suffolk County',
		marketIds: [99],
	},
	'Central Nassau County': {
		ids: [1166],
		name: 'Central Nassau County',
		marketIds: [99],
	},
	'St. Louis City': {
		ids: [897],
		name: 'St. Louis City',
		marketIds: [100],
	},
	'Northwest County': {
		ids: [898],
		name: 'Northwest County',
		marketIds: [100],
	},
	'Mid-County': {
		ids: [899],
		name: 'Mid-County',
		marketIds: [100],
	},
	'East St. Louis': {
		ids: [900],
		name: 'East St. Louis',
		marketIds: [100],
	},
	'South County': {
		ids: [901],
		name: 'South County',
		marketIds: [100],
	},
	'St. Charles County': {
		ids: [903],
		name: 'St. Charles County',
		marketIds: [100],
	},
	'West County': {
		ids: [904],
		name: 'West County',
		marketIds: [100],
	},
	'Kansas City Kansas': {
		ids: [889],
		name: 'Kansas City Kansas',
		marketIds: [101],
	},
	'Kansas City North': {
		ids: [890],
		name: 'Kansas City North',
		marketIds: [101],
	},
	'South Kansas City': {
		ids: [893],
		name: 'South Kansas City',
		marketIds: [101],
	},
	'North Johnson County': {
		ids: [894],
		name: 'North Johnson County',
		marketIds: [101],
	},
	'South Johnson County': {
		ids: [895],
		name: 'South Johnson County',
		marketIds: [101],
	},
	'East Kansas City': {
		ids: [896],
		name: 'East Kansas City',
		marketIds: [101],
	},
	'Cleveland CBD': {
		ids: [1023],
		name: 'Cleveland CBD',
		marketIds: [102],
	},
	Canton: {
		ids: [1024],
		name: 'Canton',
		marketIds: [102],
	},
	'Akron/Kent': {
		ids: [1025],
		name: 'Akron/Kent',
		marketIds: [102],
	},
	'East Cleveland': {
		ids: [1026],
		name: 'East Cleveland',
		marketIds: [102],
	},
	'Trumble/Mahoning County': {
		ids: [1027],
		name: 'Trumble/Mahoning County',
		marketIds: [102],
	},
	'Southwest Cleveland': {
		ids: [1028],
		name: 'Southwest Cleveland',
		marketIds: [102],
	},
	'Alliance/Salem': {
		ids: [1029],
		name: 'Alliance/Salem',
		marketIds: [102],
	},
	Elyria: {
		ids: [1030],
		name: 'Elyria',
		marketIds: [102],
	},
	'South Cleveland': {
		ids: [1031],
		name: 'South Cleveland',
		marketIds: [102],
	},
	'Northeast Cleveland': {
		ids: [1032],
		name: 'Northeast Cleveland',
		marketIds: [102],
	},
	'West Cleveland': {
		ids: [1033],
		name: 'West Cleveland',
		marketIds: [102],
	},
	'Southeast Cleveland': {
		ids: [1034],
		name: 'Southeast Cleveland',
		marketIds: [102],
	},
	'Medina/Orrville': {
		ids: [1035],
		name: 'Medina/Orrville',
		marketIds: [102],
	},
	'Ashtabula County': {
		ids: [1036],
		name: 'Ashtabula County',
		marketIds: [102],
	},
	Springfield: {
		ids: [1037],
		name: 'Springfield',
		marketIds: [103],
	},
	'West Hamilton County': {
		ids: [1038],
		name: 'West Hamilton County',
		marketIds: [103],
	},
	'East Cincinnati': {
		ids: [1039],
		name: 'East Cincinnati',
		marketIds: [103],
	},
	'Cincinnati CBD': {
		ids: [1040],
		name: 'Cincinnati CBD',
		marketIds: [103],
	},
	'Highland County': {
		ids: [1041],
		name: 'Highland County',
		marketIds: [103],
	},
	'Central Cincinnati': {
		ids: [1042],
		name: 'Central Cincinnati',
		marketIds: [103],
	},
	Kenwood: {
		ids: [1043],
		name: 'Kenwood',
		marketIds: [103],
	},
	'West Chester': {
		ids: [1044],
		name: 'West Chester',
		marketIds: [103],
	},
	'Blue Ash': {
		ids: [1045],
		name: 'Blue Ash',
		marketIds: [103],
	},
	'Clinton County': {
		ids: [1046],
		name: 'Clinton County',
		marketIds: [103],
	},
	'Greene County': {
		ids: [1047],
		name: 'Greene County',
		marketIds: [103],
	},
	Dayton: {
		ids: [1048],
		name: 'Dayton',
		marketIds: [103],
	},
	'Fields Ertel/Mason': {
		ids: [1049],
		name: 'Fields Ertel/Mason',
		marketIds: [103],
	},
	'Northern Kentucky': {
		ids: [1050],
		name: 'Northern Kentucky',
		marketIds: [103],
	},
	'Butler County': {
		ids: [1051],
		name: 'Butler County',
		marketIds: [103],
	},
	Eaton: {
		ids: [1052],
		name: 'Eaton',
		marketIds: [103],
	},
	'Tri County': {
		ids: [1053],
		name: 'Tri County',
		marketIds: [103],
	},
	'Brown County': {
		ids: [1054],
		name: 'Brown County',
		marketIds: [103],
	},
	'North Warren County': {
		ids: [1055],
		name: 'North Warren County',
		marketIds: [103],
	},
	Hilliard: {
		ids: [1056],
		name: 'Hilliard',
		marketIds: [104],
	},
	'Licking County': {
		ids: [1057],
		name: 'Licking County',
		marketIds: [104],
	},
	Grandview: {
		ids: [1058],
		name: 'Grandview',
		marketIds: [104],
	},
	Polaris: {
		ids: [1059],
		name: 'Polaris',
		marketIds: [104],
	},
	'Southern Columbus': {
		ids: [1061],
		name: 'Southern Columbus',
		marketIds: [104],
	},
	'Upper Arlington': {
		ids: [1062],
		name: 'Upper Arlington',
		marketIds: [104],
	},
	Gahanna: {
		ids: [1063],
		name: 'Gahanna',
		marketIds: [104],
	},
	Reynoldsburg: {
		ids: [1064],
		name: 'Reynoldsburg',
		marketIds: [104],
	},
	Dublin: {
		ids: [1065],
		name: 'Dublin',
		marketIds: [104],
	},
	'Delaware County South': {
		ids: [1066],
		name: 'Delaware County South',
		marketIds: [104],
	},
	Westerville: {
		ids: [1068],
		name: 'Westerville',
		marketIds: [104],
	},
	'New Albany': {
		ids: [1069],
		name: 'New Albany',
		marketIds: [104],
	},
	'Fairfield County': {
		ids: [1070],
		name: 'Fairfield County',
		marketIds: [104],
	},
	'Bexley/Whitehall': {
		ids: [1071],
		name: 'Bexley/Whitehall',
		marketIds: [104],
	},
	Easton: {
		ids: [1072],
		name: 'Easton',
		marketIds: [104],
	},
	'Bethel Road': {
		ids: [1073],
		name: 'Bethel Road',
		marketIds: [104],
	},
	Worthington: {
		ids: [1074],
		name: 'Worthington',
		marketIds: [104],
	},
	'Parkway East': {
		ids: [1079],
		name: 'Parkway East',
		marketIds: [105],
	},
	'South Pittsburgh': {
		ids: [1080],
		name: 'South Pittsburgh',
		marketIds: [105],
	},
	Cranberry: {
		ids: [1082],
		name: 'Cranberry',
		marketIds: [105],
	},
	'Downtown Fringe': {
		ids: [1083],
		name: 'Downtown Fringe',
		marketIds: [105],
	},
	'Parkway West/Airport': {
		ids: [1084],
		name: 'Parkway West/Airport',
		marketIds: [105],
	},
	'Parkway North': {
		ids: [1085],
		name: 'Parkway North',
		marketIds: [105],
	},
	'Waukesha South': {
		ids: [1086],
		name: 'Waukesha South',
		marketIds: [106],
	},
	'Delafield/Wales/Mukwonago': {
		ids: [1087],
		name: 'Delafield/Wales/Mukwonago',
		marketIds: [106],
	},
	'Suburban North': {
		ids: [1088],
		name: 'Suburban North',
		marketIds: [106],
	},
	'Waukesha North': {
		ids: [1089],
		name: 'Waukesha North',
		marketIds: [106],
	},
	'Milwaukee Southeast': {
		ids: [1090],
		name: 'Milwaukee Southeast',
		marketIds: [106],
	},
	'Milwaukee Central': {
		ids: [1091],
		name: 'Milwaukee Central',
		marketIds: [106],
	},
	'Waukesha/Pewaukee': {
		ids: [1092],
		name: 'Waukesha/Pewaukee',
		marketIds: [106],
	},
	'Suburban South': {
		ids: [1093],
		name: 'Suburban South',
		marketIds: [106],
	},
	'Milwaukee Northwest': {
		ids: [1094],
		name: 'Milwaukee Northwest',
		marketIds: [106],
	},
	'West Allis': {
		ids: [1096],
		name: 'West Allis',
		marketIds: [106],
	},
	'Milwaukee North Shore': {
		ids: [1097],
		name: 'Milwaukee North Shore',
		marketIds: [106],
	},
	Brookfield: {
		ids: [1098],
		name: 'Brookfield',
		marketIds: [106],
	},
	'Mayfair/Wauwatosa': {
		ids: [1099],
		name: 'Mayfair/Wauwatosa',
		marketIds: [106],
	},
	"Third Ward/Walker's Point": {
		ids: [1100],
		name: "Third Ward/Walker's Point",
		marketIds: [106],
	},
	'Milwaukee Southwest': {
		ids: [1101],
		name: 'Milwaukee Southwest',
		marketIds: [106],
	},
	'Canyon County': {
		ids: [1177],
		name: 'Canyon County',
		marketIds: [107],
	},
	'Ada County': {
		ids: [1178],
		name: 'Ada County',
		marketIds: [107],
	},
	'Amelia County': {
		ids: [1257],
		name: 'Amelia County',
		marketIds: [108],
	},
	'Hanover County': {
		ids: [1258],
		name: 'Hanover County',
		marketIds: [108],
	},
	'Surry County': {
		ids: [1259],
		name: 'Surry County',
		marketIds: [108],
	},
	Petersburg: {
		ids: [1260],
		name: 'Petersburg',
		marketIds: [108],
	},
	'Powhatan County': {
		ids: [1261],
		name: 'Powhatan County',
		marketIds: [108],
	},
	Hopewell: {
		ids: [1262],
		name: 'Hopewell',
		marketIds: [108],
	},
	'Dinwiddle County': {
		ids: [1263],
		name: 'Dinwiddle County',
		marketIds: [108],
	},
	'Prince George County': {
		ids: [1264],
		name: 'Prince George County',
		marketIds: [108],
	},
	'Colonial Heights': {
		ids: [1265],
		name: 'Colonial Heights',
		marketIds: [108],
	},
	'Chesterfield County': {
		ids: [1266],
		name: 'Chesterfield County',
		marketIds: [108],
	},
	Richmond: {
		ids: [1267],
		name: 'Richmond',
		marketIds: [108],
	},
	'Goochland County': {
		ids: [1268],
		name: 'Goochland County',
		marketIds: [108],
	},
	'Henrico County': {
		ids: [1269],
		name: 'Henrico County',
		marketIds: [108],
	},
	Chesapeake: {
		ids: [1242],
		name: 'Chesapeake',
		marketIds: [109],
	},
	'Newport News': {
		ids: [1243],
		name: 'Newport News',
		marketIds: [109],
	},
	Suffolk: {
		ids: [1244],
		name: 'Suffolk',
		marketIds: [109],
	},
	'Virginia Beach': {
		ids: [1245],
		name: 'Virginia Beach',
		marketIds: [109],
	},
	Portsmouth: {
		ids: [1246],
		name: 'Portsmouth',
		marketIds: [109],
	},
	Hampton: {
		ids: [1247],
		name: 'Hampton',
		marketIds: [109],
	},
	Norfolk: {
		ids: [1248],
		name: 'Norfolk',
		marketIds: [109],
	},
	Charleston: {
		ids: [997],
		name: 'Charleston',
		marketIds: [110],
	},
	'Pickens County': {
		ids: [1183],
		name: 'Pickens County',
		marketIds: [111],
	},
	'Greenville County': {
		ids: [1184],
		name: 'Greenville County',
		marketIds: [111],
	},
	'Anderson County': {
		ids: [1185, 1213],
		name: 'Anderson County',
		marketIds: [111, 121],
	},
	'Spartanburg County': {
		ids: [1186],
		name: 'Spartanburg County',
		marketIds: [111],
	},
	'DeSoto County': {
		ids: [1230],
		name: 'DeSoto County',
		marketIds: [112],
	},
	'East - Germantown - Collierville': {
		ids: [1231],
		name: 'East - Germantown - Collierville',
		marketIds: [112],
	},
	'Shelby County': {
		ids: [1233, 1196, 1226],
		name: 'Shelby County',
		marketIds: [112, 120, 121],
	},
	'Downtown Memphis': {
		ids: [1234],
		name: 'Downtown Memphis',
		marketIds: [112],
	},
	'Crittenden County': {
		ids: [1235],
		name: 'Crittenden County',
		marketIds: [112],
	},
	'St. Charles Parish': {
		ids: [1236],
		name: 'St. Charles Parish',
		marketIds: [113],
	},
	'Orleans Parish': {
		ids: [1237],
		name: 'Orleans Parish',
		marketIds: [113],
	},
	'Jefferson Parish': {
		ids: [1238],
		name: 'Jefferson Parish',
		marketIds: [113],
	},
	'St. John the Baptist Parish': {
		ids: [1239],
		name: 'St. John the Baptist Parish',
		marketIds: [113],
	},
	'Plaquemines Parish': {
		ids: [1240],
		name: 'Plaquemines Parish',
		marketIds: [113],
	},
	'Saint Bernard Parish': {
		ids: [1241],
		name: 'Saint Bernard Parish',
		marketIds: [113],
	},
	Providence: {
		ids: [1001],
		name: 'Providence',
		marketIds: [114],
	},
	Buffalo: {
		ids: [1002],
		name: 'Buffalo',
		marketIds: [115],
	},
	Knoxville: {
		ids: [1003],
		name: 'Knoxville',
		marketIds: [116],
	},
	'Midtown Birmingham': {
		ids: [1188],
		name: 'Midtown Birmingham',
		marketIds: [120],
	},
	'Vulcan/Oxmoor': {
		ids: [1189],
		name: 'Vulcan/Oxmoor',
		marketIds: [120],
	},
	'Jefferson County': {
		ids: [1190, 1221],
		name: 'Jefferson County',
		marketIds: [120, 121],
	},
	'Chilton County': {
		ids: [1191],
		name: 'Chilton County',
		marketIds: [120],
	},
	'St. Clair County': {
		ids: [1192],
		name: 'St. Clair County',
		marketIds: [120],
	},
	'Blount County': {
		ids: [1193],
		name: 'Blount County',
		marketIds: [120],
	},
	'Highway 280/Shelby County': {
		ids: [1194],
		name: 'Highway 280/Shelby County',
		marketIds: [120],
	},
	'Bibb County': {
		ids: [1195],
		name: 'Bibb County',
		marketIds: [120],
	},
	'Walker County': {
		ids: [1197],
		name: 'Walker County',
		marketIds: [120],
	},
	'Downtown Birmingham': {
		ids: [1198],
		name: 'Downtown Birmingham',
		marketIds: [120],
	},
	'Tuscaloosa County': {
		ids: [1199],
		name: 'Tuscaloosa County',
		marketIds: [120],
	},
	'Hardin County': {
		ids: [1208],
		name: 'Hardin County',
		marketIds: [121],
	},
	'Downtown Lexington': {
		ids: [1209],
		name: 'Downtown Lexington',
		marketIds: [121],
	},
	'Clark County': {
		ids: [1210],
		name: 'Clark County',
		marketIds: [121],
	},
	'Franklin County': {
		ids: [1211],
		name: 'Franklin County',
		marketIds: [121],
	},
	'Henry County': {
		ids: [1212],
		name: 'Henry County',
		marketIds: [121],
	},
	'Harrison County': {
		ids: [1214, 1249],
		name: 'Harrison County',
		marketIds: [121, 125],
	},
	'Fayette County': {
		ids: [1215],
		name: 'Fayette County',
		marketIds: [121],
	},
	'Scott County': {
		ids: [1216],
		name: 'Scott County',
		marketIds: [121],
	},
	'Jessamine County': {
		ids: [1217],
		name: 'Jessamine County',
		marketIds: [121],
	},
	'Bullitt County': {
		ids: [1218],
		name: 'Bullitt County',
		marketIds: [121],
	},
	'Central Louisville': {
		ids: [1219],
		name: 'Central Louisville',
		marketIds: [121],
	},
	'Floyd County': {
		ids: [1220],
		name: 'Floyd County',
		marketIds: [121],
	},
	'Meade County': {
		ids: [1222],
		name: 'Meade County',
		marketIds: [121],
	},
	'Washington County': {
		ids: [1223, 1252],
		name: 'Washington County',
		marketIds: [121, 125],
	},
	'Spencer County': {
		ids: [1224],
		name: 'Spencer County',
		marketIds: [121],
	},
	'Nelson County': {
		ids: [1225],
		name: 'Nelson County',
		marketIds: [121],
	},
	'Woodford County': {
		ids: [1227],
		name: 'Woodford County',
		marketIds: [121],
	},
	'Trimble County': {
		ids: [1228],
		name: 'Trimble County',
		marketIds: [121],
	},
	'Oldham County': {
		ids: [1229],
		name: 'Oldham County',
		marketIds: [121],
	},
	'Saunders County': {
		ids: [1250],
		name: 'Saunders County',
		marketIds: [125],
	},
	'Pottawattamie County': {
		ids: [1251],
		name: 'Pottawattamie County',
		marketIds: [125],
	},
	'Douglas County': {
		ids: [1253],
		name: 'Douglas County',
		marketIds: [125],
	},
	'Cass County': {
		ids: [1254],
		name: 'Cass County',
		marketIds: [125],
	},
	'Sarpy County': {
		ids: [1255],
		name: 'Sarpy County',
		marketIds: [125],
	},
	'Mills County': {
		ids: [1256],
		name: 'Mills County',
		marketIds: [125],
	},
	'El Paso CBD': {
		ids: [1179],
		name: 'El Paso CBD',
		marketIds: [126],
	},
	'Doña Ana County': {
		ids: [1180],
		name: 'Doña Ana County',
		marketIds: [126],
	},
	'Central El Paso': {
		ids: [1181],
		name: 'Central El Paso',
		marketIds: [126],
	},
	'El Paso County': {
		ids: [1182],
		name: 'El Paso County',
		marketIds: [126],
	},
};
