import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PortfolioItem } from '../../types';
import { QUERY_KEYS } from '../constants';
import { composeEnabled, createFetchFn } from '../utils';

const fetchPropertySales = createFetchFn<
	PropertySalesParams,
	PropertySalesResponse
>({
	method: 'get',
	getURL: ({ propertyId }) => `/properties/${propertyId}/sales`,
});

export const usePropertySalesQuery = (
	params: PropertySalesParams,
	options?: UseQueryOptions<PropertySalesResponse>
) =>
	useQuery({
		queryKey: createPropertySalesQueryKey(params),
		queryFn: fetchPropertySales(params),
		enabled: composeEnabled(params.propertyId != null, options?.enabled),
		...options,
	});

export const createPropertySalesQueryKey = (params: PropertySalesParams) => [
	QUERY_KEYS.PROPERTY_SALES,
	params,
];

export type PropertySalesParams = {
	propertyId?: number;
};

export type PropertySalesResponse = {
	comps: PropertySale[];
};

export type PropertySale = {
	id: number;
	saleDate?: string;
	isPortfolio?: boolean;
	portfolio: PortfolioItem[];
	totalSalePrice?: number;
	dataSource?: string;
	salePricePsf?: number | null;
	buyer?: string[] | null;
	buyerRepCompanies?: string[] | null;
	seller?: string[] | null;
	sellerRepCompanies?: string[] | null;
	capRate?: number | null;
	percentOccupied?: number | null;
	interestPercentage?: number | null;
	documentNumber?: string | null;
	own?: boolean;
	cost: number;
};
