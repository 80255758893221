import { QUERY_KEYS } from 'api/constants';
import {
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query';
import { API, buildQuery, formatRequestString } from '@compstak/common';
import {
	APIDateRequestParams,
	TimeGranularity,
} from '../../Pages/PropertyPageV2_1/api/interfaces';
import { SuggestedSpan } from '../../Pages/PropertyPageV2_1/type';

type Key =
	| 'adminExpensePerUnit'
	| 'marketingExpensePerUnit'
	| 'taxesInsuranceExpensePerUnit'
	| 'operatingMaintenanceExpensePerUnit'
	| 'utilitiesPerUnit'
	| 'payrollRelatedExpensePerUnit';

type HistogramItem = Record<Key, number> & {
	date: string;
};

export type ExpensesPerUnitDTO = {
	histogram: Array<HistogramItem>;
	suggestedSpan?: SuggestedSpan;
};

export interface FetchExpensesPerUnitApiParams extends APIDateRequestParams {
	id: string | number;
	period: TimeGranularity;
}

export type UseExpensesPerUnitResponse = UseQueryResult<ExpensesPerUnitDTO>;

const fetchExpensesPerUnit = async ({
	id,
	...rest
}: FetchExpensesPerUnitApiParams): Promise<ExpensesPerUnitDTO> => {
	const query = buildQuery({ ...rest });

	const response = await API.get<ExpensesPerUnitDTO>(
		formatRequestString({
			entityUrl: `/mufa/properties/${id}/rpSubmarket/expensesPerUnit?${query}`,
		})
	);

	return response.data;
};

export const useExpensesPerUnitData = (
	params: FetchExpensesPerUnitApiParams,
	queryConfig?: Pick<UseQueryOptions, 'enabled'>
): UseExpensesPerUnitResponse =>
	useQuery(
		[QUERY_KEYS.MUFA_PROPERTY_EXPENSES, params],
		() => fetchExpensesPerUnit(params),
		queryConfig
	);
