import dayjs, { Dayjs } from 'dayjs';

import {
	CREATE_PROJECT,
	UPDATE_PROJECT,
	START_LOADING_CHART_REPO,
	SET_CHART_REPO,
	CHART_REPO_LOAD_ERROR,
	CREATE_CHART,
	SAVE_CHART,
	DELETE_CHART,
	DELETE_PROJECT,
	ChartRepositoryAction,
} from './actions';

import { Project } from 'Pages/Analytics/analytics';
import { LOGOUT, LogoutAction } from 'Pages/Login/actions';

type State = {
	projects: Project[] | null;
	loading: boolean;
	error: any;
	lastUpdated: Dayjs | null;
};

const initialState: State = {
	projects: null,
	loading: false,
	error: null,
	lastUpdated: null,
};

export default (
	state = initialState,
	action: ChartRepositoryAction | LogoutAction
) => {
	switch (action.type) {
		case START_LOADING_CHART_REPO:
			state = {
				...state,
				loading: true,
			};
			break;

		case SET_CHART_REPO:
			state = {
				...state,
				loading: false,
				projects: action.payload,
				lastUpdated: dayjs(),
			};
			break;

		case CHART_REPO_LOAD_ERROR:
			state = {
				...state,
				loading: false,
				error: action.payload,
			};
			break;

		case CREATE_PROJECT:
			const projects = state.projects
				? [action.payload, ...state.projects]
				: [action.payload];
			state = {
				...state,
				projects,
				lastUpdated: dayjs(),
			};
			break;

		case UPDATE_PROJECT:
			state = {
				...state,
				projects: state.projects
					? state.projects.map((project) => {
							if (project.id === action.payload.id) {
								return action.payload;
							} else {
								return project;
							}
						})
					: null,
				lastUpdated: dayjs(),
			};
			break;

		case CREATE_CHART:
			state = {
				...state,
				projects: state.projects
					? state.projects.map((project) => ({
							...project,
							charts:
								project.id === action.payload.projectId
									? [action.payload, ...project.charts]
									: project.charts,
						}))
					: null,
				lastUpdated: dayjs(),
			};
			break;

		case SAVE_CHART:
			state = {
				...state,
				projects: state.projects
					? state.projects.map((project) => {
							return {
								...project,
								charts: project.charts.map((chart) => {
									if (chart.id === action.payload.id) {
										return action.payload;
									} else {
										return chart;
									}
								}),
							};
						})
					: null,
				lastUpdated: dayjs(),
			};
			break;

		case DELETE_PROJECT:
			state = {
				...state,
				projects: state.projects
					? state.projects.filter((project) => project.id !== action.project.id)
					: null,
				lastUpdated: dayjs(),
			};
			break;

		case DELETE_CHART:
			state = {
				...state,
				projects: state.projects
					? state.projects.map((project) => {
							return {
								...project,
								charts: project.charts.filter(
									(chart) => chart.id !== action.chartId
								),
							};
						})
					: null,
				lastUpdated: dayjs(),
			};
			break;
		case LOGOUT:
			state = initialState;
	}
	return state;
};
