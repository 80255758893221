export const CREATE_FILE_UPLOAD = 'CREATE_FILE_UPLOAD';
export const UPDATE_FILE_UPLOAD = 'UPDATE_FILE_UPLOAD';
export const REMOVE_FILE_UPLOAD = 'REMOVE_FILE_UPLOAD';
export const RESET_FILE_UPLOAD = 'RESET_FILE_UPLOAD';
export const SHOW_UPLOAD_FORM = 'SHOW_UPLOAD_FORM';
export const HIDE_UPLOAD_FORM = 'HIDE_UPLOAD_FORM';
export const SET_COMPS_AWARDED = 'SET_COMPS_AWARDED';
export const UPDATE_SUBMISSION = 'UPDATE_SUBMISSION';
export const SEND_SUBMISSION = 'SEND_SUBMISSION';
export const SEND_SUBMISSION_PENDING = 'SEND_SUBMISSION_PENDING';
export const SEND_SUBMISSION_REJECTED = 'SEND_SUBMISSION_REJECTED';
export const SEND_SUBMISSION_FULFILLED = 'SEND_SUBMISSION_FULFILLED';

import { loadUser } from 'Pages/Login/actions';
import { Dispatch } from 'redux';
import leaseService from 'services/lease';
import { LeaseSubmission, SaleSubmission } from './types';
import { csLogger } from 'util/log/logger';

export const UPLOAD_STATUS_MAP = {
	SUCCESS: 200,
	BAD_REQUEST: 400,
	ERROR: 500,
};

export function createFileUpload(file: File) {
	return {
		type: CREATE_FILE_UPLOAD,
		payload: {
			file,
		},
	};
}

export function updateFileUpload(file: File, property: string, value: number) {
	return {
		type: UPDATE_FILE_UPLOAD,
		payload: {
			file,
			property,
			value,
		},
	};
}

// @ts-expect-error TS7006: Parameter 'file' implicitly ha...
export function removeFileUpload(file) {
	return {
		type: REMOVE_FILE_UPLOAD,
		payload: {
			file,
		},
	};
}

export function resetFileUpload() {
	return {
		type: RESET_FILE_UPLOAD,
	};
}

export function showUploadForm() {
	return {
		type: SHOW_UPLOAD_FORM,
	};
}

export function hideUploadForm() {
	return {
		type: HIDE_UPLOAD_FORM,
	};
}

export function setCompsAwarded(count: number) {
	return {
		type: SET_COMPS_AWARDED,
		payload: {
			compsAwarded: count,
		},
	};
}

export function updateLeaseSubmissionFormState<T extends keyof LeaseSubmission>(
	property: T,
	value: LeaseSubmission[T]
) {
	return {
		type: UPDATE_SUBMISSION,
		payload: {
			property,
			value,
			compType: 'lease',
		},
	};
}

export function updateSaleSubmissionFormState<T extends keyof SaleSubmission>(
	property: T,
	value: SaleSubmission[T]
) {
	return {
		type: UPDATE_SUBMISSION,
		payload: {
			property,
			value,
			compType: 'sale',
		},
	};
}

export function sendSubmission(props: {
	submission: any;
	onSuccess?: (compsAwarded: number) => void;
}) {
	return (dispatch: Dispatch) => {
		dispatch({
			type: SEND_SUBMISSION,
			meta: {
				feedback: {
					id: 'sendSubmission',
					className: 'center',
					pending: 'Submitting Comp...',
					// @ts-expect-error TS7006: Parameter 'response' implicitl...
					rejected: function (response) {
						console.error('Error: ' + response);
						//TODO get a more helpful error message from the server here
						return 'Sorry there was an error submitting your comp, please try again later.';
					},
				},
			},
			payload: {
				promise: leaseService.sendComp(props.submission).then((res: any) => {
					dispatch(loadUser());
					if (typeof res?.comps?.awarded === 'number') {
						props.onSuccess?.(res.comps.awarded);
					} else {
						csLogger.warn({
							event: 'No comps awarded in response',
							extraInfo: { res },
						});
					}
					// The promise middleware will pass res as SEND_SUBMISSION_FULFILLED action payload.
					return res;
				}),
			},
		});
	};
}
