import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { AveragesParams, LeaseAndSaleAveragesResponse } from '../types';
import { composeEnabled, createFetchFn } from '../utils';

const createFetchAverages = createFetchFn<
	AveragesParams,
	LeaseAndSaleAveragesResponse
>({
	method: 'post',
	getURL: () => `/api/salesComps/search/averages`,
	getBody: ({ ids }) => ({ ids }),
});

export const useSaleAveragesQuery = (
	params: AveragesParams,
	options?: UseQueryOptions<LeaseAndSaleAveragesResponse>
) => {
	return useQuery({
		queryKey: [QUERY_KEYS.SALE_AVERAGES, params],
		queryFn: createFetchAverages(params),
		enabled: composeEnabled(params.ids != null, options?.enabled),
		...options,
	});
};
