import {
	SEARCH_MAX_LIMIT,
	useCompsetAddresses,
	useCompsetData,
	useSearchLeasesQuery,
} from 'api';
import { ServerFilterItem } from 'types/serverFilters';

type Props = {
	propertyId?: number;
};

export const useLeasesInCompset = ({ propertyId }: Props) => {
	const {
		data: compset,
		isFetching: isFetchingCompset,
		isLoading: isLoadingCompset,
	} = useCompsetData({
		propertyId,
	});

	const addresses = useCompsetAddresses({ propertyId });

	const serverFilters: ServerFilterItem[] = [
		{
			property: 'address',
			value: addresses,
		},
		{ property: 'hidden', value: false },
	];

	if (compset?.subjectProperty?.market) {
		serverFilters.push({
			property: 'marketName',
			value: compset.subjectProperty.market,
		});
	}

	const {
		data: leases,
		isFetching: isLeasesFetching,
		isLoading: isLoadingSearchLeases,
	} = useSearchLeasesQuery(
		{
			filter: serverFilters,
			limit: SEARCH_MAX_LIMIT,
			offset: 0,
			sort: 'executionQuarter',
			order: 'desc',
		},
		{
			enabled: !!compset,
		}
	);

	return {
		data: leases,
		isFetching: isFetchingCompset || isLeasesFetching,
		isLoading: isLoadingCompset || isLoadingSearchLeases,
	};
};
