import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { LeaseComp } from 'types/comp';
import { composeEnabled, createFetchFn } from '../utils';

const fetchPropertyLeases = createFetchFn<
	PropertyLeasesParams,
	PropertyLeasesResponse
>({
	method: 'get',
	getURL: ({ propertyId }) => `/properties/${propertyId}/leases`,
});

export const usePropertyLeasesQuery = (
	params: PropertyLeasesParams,
	options?: UseQueryOptions<PropertyLeasesResponse>
) =>
	useQuery({
		queryKey: createPropertyLeasesQueryKey(params),
		queryFn: fetchPropertyLeases(params),
		...options,
		enabled: composeEnabled(params.propertyId != null, options?.enabled),
	});

export const createPropertyLeasesQueryKey = (params: PropertyLeasesParams) => [
	QUERY_KEYS.PROPERTY_LEASES,
	params,
];

export type PropertyLeasesParams = {
	propertyId?: number;
};

export type PropertyLeasesResponse = {
	comps: PropertyLease[];
};

export type PropertyLease = Pick<
	LeaseComp,
	| 'id'
	| 'spaceType'
	| 'executionQuarter'
	| 'tenantName'
	| 'transactionSize'
	| 'suite'
	| 'startingRent'
	| 'effectiveRent'
	| 'avgRent'
	| 'blendedRent'
	| 'market'
	| 'leaseEscalations'
	| 'rentBumpsPercent'
	| 'rentBumpsDollar'
	| 'rentBumpYears'
	| 'freeMonths'
	| 'workValue'
	| 'leaseTerm'
	| 'leaseType'
	| 'buildingAddress'
	| 'city'
	| 'zip'
	| 'own'
	| 'cost'
	| 'expirationDate'
	| 'floorOccupancies'
	| 'pastExpirationDate'
	| 'missingExpirationDate'
>;
