import factory from './factory';

// @ts-expect-error TS7006: Parameter 'propertyId' implici...
const createUrl = (propertyId) => '/api/stackingPlans/' + propertyId;

const serviceController = factory.create({
	createUrl,
});

const exportable = serviceController.exportable({
	// @ts-expect-error TS7006: Parameter 'newData' implicitly...
	save: (newData) => {
		const url = createUrl(newData.propertyId);

		const promise = factory.post(url, newData);
		exportable.add(newData.propertyId, promise);
		return promise;
	},

	// @ts-expect-error TS7006: Parameter 'id' implicitly has ...
	del: (id) => {
		exportable.clear(id);
		return factory.del(createUrl(id));
	},
});

export default exportable;
