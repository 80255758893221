import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { createFetchFn, useIsMufaProperty } from 'api/utils';

const createFetchFloorPlans = createFetchFn<
	MufaFloorPlansParams,
	MufaFloorPlansResponse
>({
	method: 'get',
	getURL: ({ propertyId }) => `/mufa/properties/${propertyId}/floorPlans`,
});

export const useMufaFloorPlansQuery = (
	params: MufaFloorPlansParams,
	queryConfig?: Omit<UseQueryOptions<MufaFloorPlansResponse>, 'enabled'>
) => {
	const { data: isMufa } = useIsMufaProperty({
		propertyId: params.propertyId,
	});

	return useQuery({
		queryKey: [QUERY_KEYS.MUFA_FLOOR_PLANS, params],
		queryFn: createFetchFloorPlans(params),
		enabled: isMufa,
		...queryConfig,
	});
};

type MufaFloorPlansParams = {
	propertyId: number;
};

type MufaFloorPlansResponse = {
	floorPlans: MufaFloorPlan[];
};

export type MufaFloorPlan = {
	id: number;
	unitType: string;
	bedrooms: number;
	bathrooms: number;
	unitCount: number;
	unitSqFt: number;
	askingRentPerUnit: number;
	askingRentPerSqFt: number;
	effectiveRentPerUnit: number;
	effectiveRentPerSqFt: number;
	concessionValue: number;
	concessionsPercentage: number;
	occupancyPercentage: number;
};
