import { usePropertyByIdQuery } from '../propertyById';
import { useMarketByNameOrId } from 'Pages/Login/reducers';

type Props = {
	propertyId: number;
};

export const usePropertyMarket = ({ propertyId }: Props) => {
	const { data: property } = usePropertyByIdQuery({ id: propertyId });

	const market = useMarketByNameOrId(property?.marketId);

	return {
		data: market,
	};
};
