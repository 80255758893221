import { loadUser } from 'Pages/Login/actions';
import { HTTP_ERROR } from 'util/httpError';
import { refreshAccessToken } from 'auth/auth';

let isRetrying = false;
const requestsToRepeat = new Map();

export default function userUpdateMiddleware() {
	// @ts-expect-error TS7006: Parameter 'store' implicitly h...
	return (store) => (next) => (action) => {
		if (
			action.meta &&
			action.meta.updateUser &&
			action.type.indexOf('_PENDING') === -1
		) {
			store.dispatch(loadUser());
		}

		if (action.type === HTTP_ERROR && action.payload.xhr.status === 401) {
			// we retry request only if it's part of legacy ServiceFactory,
			// we assume it's monkey-patched only from there, and it will be removed after migrating to new auth-flow
			// otherwise react-query will do this instead
			if (
				action.payload.xhr.onRetryError ||
				action.payload.xhr.onRetrySuccess
			) {
				requestsToRepeat.set(
					action.payload.xhr.responseURL,
					action.payload.xhr
				);
			}
			if (
				// prevents repeated "start-refresh" requests
				!isRetrying
			) {
				isRetrying = true;
				refreshAccessToken()
					.then((accessToken) => {
						if (accessToken) {
							requestsToRepeat.forEach((xhr) => {
								if (typeof xhr.onRetrySuccess === 'function')
									xhr.onRetrySuccess();
							});
						}
					})
					.finally(() => {
						requestsToRepeat.clear();
						isRetrying = false;
					});
			}
		}

		return next(action);
	};
}
