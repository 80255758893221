import React from 'react';
import { connect } from 'react-redux';

import Popup from 'Components/Popup';
import { initialState, TooltipState } from './reducer';
import './tooltip.nomodule.less';
import { AppState } from 'reducers/root';
import { isEqual } from 'lodash';

export type Position = 'above' | 'below' | 'onleft' | 'onright' | 'manual';

type Props = TooltipState & { position: Position };

const Tooltip = React.memo<Props>((props) => {
	const getInnerHTML = React.useMemo(() => {
		return {
			__html: props.text,
		};
	}, [props.text]);

	if (!props.text) {
		return <div className="tooltip-container not-showing" />;
	}
	return (
		<div className="tooltip-container showing">
			<Popup
				position={props.position}
				target={props.target}
				className={props.className}
				// @ts-expect-error TS2769: No overload matches this call....
				offsetTop={props.offsetTop}
			>
				{/* @ts-expect-error TS2322: Type '{ __html: string | null;... */}
				<span dangerouslySetInnerHTML={getInnerHTML} />
			</Popup>
		</div>
	);
});

let currentState = initialState;
function mapStoreToProps(store: AppState) {
	currentState = store.tooltip;
	return store.tooltip;
}

// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
function mapDispatchToProps(dispatch) {
	// @ts-expect-error TS7034: Variable 'currentEl' implicitl...
	let currentEl;
	// @ts-expect-error TS7034: Variable 'timeout' implicitly ...
	let timeout;

	function dispatchHide() {
		// @ts-expect-error TS7005: Variable 'timeout' implicitly ...
		if (timeout) {
			clearTimeout(timeout);
			timeout = null;
		}
		// @ts-expect-error TS7005: Variable 'currentEl' implicitl...
		if (currentEl) {
			currentEl.removeEventListener('mouseout', dispatchHide);
			currentEl = null;
		}
		if (!isEqual(currentState, initialState)) {
			return dispatch({
				type: 'TOOLTIP_HIDE',
			});
		}
	}

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	function watchForMouseover(event) {
		let el = event.target;
		while (el.parentNode) {
			if (el.dataset && el.dataset.tooltip) {
				break;
			}
			el = el.parentNode;
		}

		// @ts-expect-error TS7005: Variable 'currentEl' implicitl...
		if (el.dataset && el.dataset.tooltip && el !== currentEl) {
			const delay =
				el.dataset.tooltipdelay !== undefined
					? parseFloat(el.dataset.tooltipdelay)
					: 400;
			timeout = setTimeout(function () {
				timeout = null;
				dispatch({
					type: 'TOOLTIP_SHOW',
					payload: {
						text: el.dataset.tooltip,
						className: el.dataset.tooltipClass || '',
						position: el.dataset.tooltipPosition || 'above',
						target: el,
						offsetTop: el.dataset.tooltipOffsetTop ?? 0,
					},
				});
			}, delay);

			currentEl = el;

			el.addEventListener('mouseout', dispatchHide);
		// @ts-expect-error TS7005: Variable 'currentEl' implicitl...
		} else if (currentEl && el !== currentEl) {
			dispatchHide();
		}
	}
	document.body.addEventListener('mouseover', watchForMouseover);

	return {
		hide: dispatchHide,
	};
}

// @ts-expect-error TS2345: Argument of type 'NamedExoticC...
export default connect(mapStoreToProps, mapDispatchToProps)(Tooltip);
