import { createFilters } from 'models/filters';
import { SaleFilters } from 'models/filters/sales';
import { filtersToQueryString } from 'models/filters/util';
import { routes } from 'router';

export const createSearchSalesUrl = (filtersArgs: Partial<SaleFilters>) => {
	return `${routes.searchSalesByView.toHref({ view: 'list' })}?${filtersToQueryString(
		createFilters('sale', filtersArgs)
	)}`;
};
