import { QUERY_KEYS } from 'api/constants';
import {
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query';
import { API, buildQuery, formatRequestString } from '@compstak/common';
import {
	APIDateRequestParams,
	Indicator,
	Metric,
} from '../../Pages/PropertyPageV2_1/api/interfaces';
import { addEmptyData, Timeframes } from '@compstak/common';
import { SuggestedSpan } from '../../Pages/PropertyPageV2_1/type';

type DTOKey = 'property' | 'compSet' | 'submarket';

const trendlineTypes: Array<DTOKey> = ['property', 'compSet', 'submarket'];

export type SubmarketMarketTrendDTO = Record<DTOKey, Indicator> & {
	suggestedSpan?: SuggestedSpan;
};

export interface FetchParams extends APIDateRequestParams {
	id: string | number;
	metric: Metric;
}

export type UseSubmarketMarketTrendResponse =
	UseQueryResult<SubmarketMarketTrendDTO>;

export const fetchMarketTrend = ({
	id,
	metric,
	...rest
}: FetchParams): Promise<SubmarketMarketTrendDTO> => {
	const query = buildQuery({ ...rest });

	return API.get<SubmarketMarketTrendDTO>(
		formatRequestString({
			entityUrl: `/mufa/properties/${id}/marketTrend/${metric}?${query}`,
		})
	)
		.then((response) => response.data)
		.then(addEmptyData(trendlineTypes, Timeframes.MoM));
};

export const useSubmarketMarketTrendData = (
	params: FetchParams,
	queryConfig?: Pick<UseQueryOptions, 'enabled'>
): UseSubmarketMarketTrendResponse => {
	return useQuery(
		[QUERY_KEYS.MUFA_PROPERTY_MARKET_TREND, params],
		() => fetchMarketTrend(params),
		queryConfig
	);
};
