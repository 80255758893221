import { LeaseEscalation } from 'types';
import { formatCsv } from './formatCsv';
import { formatMoney } from './formatMoney';
import { formatMonths } from './formatMonths';

// The wanted format "$0.00/4m, $52.35/1y 3m, $40.00/5y"
export const formatLeaseEscalations = (
	leaseEscalations: LeaseEscalation[],
	isMonthly?: boolean
) => {
	return formatCsv(
		leaseEscalations.map((leaseEscalation) => {
			const dollars = isMonthly
				? leaseEscalation.dollars / 12
				: leaseEscalation.dollars;

			return `${formatMoney(dollars)}/${formatMonths(leaseEscalation.months)}`;
		})
	);
};
