import { SalesComp } from 'types/comp';
import { fields, RowProps } from './Table';
import { Cell } from './TableCell';
import { defaultTheme } from '@compstak/ui-kit';
import styled from 'styled-components';

const SaleCompRow = ({
	comps,
	hiddenFields,
	compsAddresses,
	exportActions,
	editable,
	sectionMap,
}: RowProps & {
	comps: SalesComp[];
}) => {
	return (
		<div>
			{comps.map((comp, rowIndex) => {
				if (comp.isPortfolio)
					return (
						<div key={rowIndex} className="table-row">
							<RowHeader>
								Multi-property sale ({comp.portfolio.length})
							</RowHeader>
							{comp.portfolio.map((property, propertyIndex) => {
								return (
									<div
										key={`${rowIndex}_${propertyIndex}`}
										className="table-sub-row"
									>
										{fields.sale.map((field, index) => {
											if (fields.sale.includes(field))
												return (
													<Cell
														hiddenFields={hiddenFields}
														index={index}
														pinLabel={compsAddresses
															.get(property.buildingAddress)
															?.pinLabel.toString()}
														compId={comp.id}
														exportActions={exportActions}
														editable={editable}
														key={index}
														value={
															field === 'transactionSize'
																? property.buildingSize
																: field === 'buildingAddress'
																? property.buildingAddress
																// @ts-expect-error TS7053: Element implicitly has an 'any...
																: comp[field]
														}
														field={field}
														section={sectionMap[field]}
													/>
												);
											return (
												<div className={'table-cell ' + field} key={index} />
											);
										})}
									</div>
								);
							})}
						</div>
					);

				return (
					<div key={rowIndex} className="table-row">
						<RowHeader>Single Property Sale</RowHeader>
						{fields.sale.map((field, index) => {
							return (
								<Cell
									hiddenFields={hiddenFields}
									index={index}
									pinLabel={compsAddresses
										// @ts-expect-error TS2339: Property 'buildingAddress' doe...
										.get(comp.buildingAddress)
										?.pinLabel.toString()}
									compId={comp.id}
									exportActions={exportActions}
									editable={editable}
									key={index}
									// @ts-expect-error TS7053: Element implicitly has an 'any...
									value={comp[field]}
									field={field}
									section={sectionMap[field]}
								/>
							);
						})}
					</div>
				);
			})}
		</div>
	);
};

export default SaleCompRow;

const RowHeader = styled.div`
	font-size: 12px;
	line-height: 16px;
	font-weight: 500;
	line-height: 16px;
	padding: 8px 4px;
	background-color: ${defaultTheme.colors.neutral.n30};
	color: ${defaultTheme.colors.blue.blue500};
`;
