import {
	addFeedback,
	updateFeedback,
	removeFeedback,
	ADD_ALERT,
	REMOVE_ALERT,
	UPDATE_ALERT,
} from './actions';

const ALERT_TIME = 6000;

export function promiseFeedbackMiddleware() {
	// @ts-expect-error TS7006: Parameter 'store' implicitly h...
	return (store) => (next) => (action) => {
		switch (true) {
			case !action.meta:
			case !action.meta.feedback:
			case !action.payload:
			case !action.payload.promise:
				next(action);
				return;
		}

		let className;

		if (typeof action.meta.feedback.className !== 'function') {
			className = action.meta.feedback.className || '';
		} else {
			className = action.meta.feedback.className();
		}

		const duration =
			action.meta.feedback.duration !== undefined
				? action.meta.feedback.duration
				: ALERT_TIME;
		const id = action.meta.feedback.id || Math.random().toString();

		if (action.meta.feedback.pending) {
			let message = action.meta.feedback.pending;
			const pendingDuration =
				action.meta.feedback.pending.duration !== undefined
					? action.meta.feedback.pending.duration
					: duration;

			if (typeof message === 'function') {
				message = message();
			}
			if (typeof message !== 'string') {
				message = message.message;
			}
			if (message) {
				store.dispatch(
					addFeedback(
						message,
						className + ' action-pending',
						id,
						pendingDuration
					)
				);
			}
		}

		if (action.meta.feedback.fulfilled !== undefined) {
			let fulfilled = action.meta.feedback.fulfilled;
			let buttons = action.meta.feedback.buttons;
			const fulfilledDuration =
				action.meta.feedback.fulfilled.duration !== undefined
					? action.meta.feedback.fulfilled.duration
					: duration;

			if (typeof fulfilled !== 'function') {
				fulfilled = () => action.meta.feedback.fulfilled;
			}

			// @ts-expect-error TS7006: Parameter 'payload' implicitly...
			action.payload.promise.then((payload) => {
				let message = fulfilled(payload);
				if (message) {
					if (typeof message !== 'string') {
						buttons = message.buttons;
						message = message.message;
					}
					store.dispatch(
						updateFeedback(
							message,
							className + ' action-fulfilled',
							id,
							fulfilledDuration,
							buttons,
							// @ts-expect-error ts-migrate(2554) FIXME: Expected 5 arguments, but got 6.
							payload
						)
					);
				} else if (message === false || fulfilledDuration === 0) {
					store.dispatch(removeFeedback(id));
				}
			});
		}

		if (action.meta.feedback.rejected !== undefined) {
			let rejected = action.meta.feedback.rejected;
			let buttons = action.meta.feedback.buttons;
			const rejectedDuration =
				action.meta.feedback.rejected.duration !== undefined
					? action.meta.feedback.rejected.duration
					: duration;

			if (typeof rejected !== 'function') {
				rejected = () => action.meta.feedback.rejected;
			}
			// @ts-expect-error TS7006: Parameter 'payload' implicitly...
			action.payload.promise.then(undefined, (payload) => {
				let message = rejected(payload);
				if (message) {
					if (typeof message !== 'string') {
						buttons = message.buttons;
						message = message.message;
					}
					if (typeof message === 'function') {
						message = message(payload);
					}
					store.dispatch(
						updateFeedback(
							message,
							className + ' action-rejected',
							id,
							rejectedDuration,
							buttons
						)
					);
				} else if (message === false || rejectedDuration === 0) {
					store.dispatch(removeFeedback(id));
				}
			});
		}
		next(action);
	};
}

export function feedbackMiddleware() {
	const timeouts = {};

	// @ts-expect-error TS7006: Parameter 'store' implicitly h...
	return (store) => (next) => (action) => {
		next(action);

		switch (action.type) {
			case REMOVE_ALERT:
				if (action.payload.id) {
					// @ts-expect-error TS7053: Element implicitly has an 'any...
					clearTimeout(timeouts[action.payload.id]);
					// @ts-expect-error TS7053: Element implicitly has an 'any...
					delete timeouts[action.payload.id];
				}
				break;

			case ADD_ALERT:
			case UPDATE_ALERT: {
				const duration =
					action.payload.duration !== undefined
						? action.payload.duration
						: ALERT_TIME;

				if (action.payload.id) {
					// @ts-expect-error TS7053: Element implicitly has an 'any...
					clearTimeout(timeouts[action.payload.id]);
					if (duration !== 0) {
						// @ts-expect-error TS7053: Element implicitly has an 'any...
						timeouts[action.payload.id] = setTimeout(() => {
							store.dispatch(removeFeedback(action.payload.id));
							// @ts-expect-error TS7053: Element implicitly has an 'any...
							delete timeouts[action.payload.id];
						}, duration);
					}
				}
			}
		}
	};
}
