export const HTTP_ERROR = 'HTTP_ERROR';

// @ts-expect-error TS7006: Parameter 'xhr' implicitly has...
export function httpError(xhr, openParams, sendParams) {
	return {
		type: HTTP_ERROR,
		payload: {
			xhr,
			openParams,
			sendParams,
		},
	};
}

export function httpErrorMiddleware() {
	// @ts-expect-error TS7006: Parameter 'store' implicitly h...
	return (store) => {
		const OriginalXHR = window.XMLHttpRequest;

		function AuthorizedXHR() {
			// @ts-expect-error TS7034: Variable 'openParams' implicit...
			let openParams, sendParams;
			const realXHR = new OriginalXHR();
			const { open, send } = realXHR;

			// @ts-expect-error TS2322: Type '(args_0: string, args_1:...
			realXHR.open = function (...args) {
				openParams = args;
				// @ts-expect-error ts-migrate(2345) FIXME: Type 'any[]' is missing the following properties f... Remove this comment to see the full error message
				open.apply(realXHR, args);
			};

			realXHR.send = function (...args) {
				sendParams = args;

				send.apply(realXHR, args);
			};

			realXHR.addEventListener(
				'readystatechange',
				function () {
					if (realXHR.readyState === 4 && realXHR.status >= 400) {
						// @ts-expect-error TS7005: Variable 'openParams' implicit...
						store.dispatch(httpError(realXHR, openParams, sendParams));
					}
				},
				true
			);

			return realXHR;
		}

		// @ts-expect-error ts-migrate(2739) FIXME: Type '() => XMLHttpRequest' is missing the followi... Remove this comment to see the full error message
		window.XMLHttpRequest = AuthorizedXHR;

		// @ts-expect-error TS7006: Parameter 'next' implicitly ha...
		return (next) => (action) => next(action);
	};
}
