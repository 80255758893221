import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { composeEnabled, createFetchFn } from '../utils';

const fetchSimilarSales = createFetchFn<
	SimilarSalesParams,
	SimilarSalesResponse
>({
	method: 'get',
	getURL: ({ propertyId }) => `/properties/${propertyId}/similarSales`,
});

export const useSimilarSalesQuery = (
	params: SimilarSalesParams,
	options?: UseQueryOptions<SimilarSalesResponse>
) =>
	useQuery({
		queryFn: fetchSimilarSales(params),
		queryKey: [QUERY_KEYS.SIMILAR_SALES, params],
		enabled: composeEnabled(params.propertyId != null, options?.enabled),
		...options,
	});

type SimilarSalesParams = {
	propertyId?: string | number;
};

export type SimilarSalesResponse = {
	comps: SimilarSalesSaleComp[];
};

export type SimilarSalesSaleComp = {
	buyer: string[];
	buyerRepCompanies: string[];
	capRate: number;
	cost: number;
	dataSource: string;
	id: number;
	isPortfolio: boolean;
	own: boolean;
	portfolio: SimilarSalesPortfolioItem[];
	buildingAddress: string;
	buildingAddressAndCity: string;
	submarket: string;
	saleDate: string;
	salePricePsf: number;
	seller: string[];
	sellerRepCompanies: string[];
	totalSalePrice: number;
};

export type SimilarSalesPortfolioItem = {
	buildingAddress: string;
	buildingAddressAndCity: string;
	submarket: string;
};
