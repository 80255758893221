import { API } from '@compstak/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants';
import { Portfolio } from '../../types';

const copyPortfolio = async (params: CopyPortfolioParams) => {
	const res = await API.post<CopyPorfolioResponse>(
		`/pa/v2/api/portfolios/copy`,
		params.body
	);
	return res.data;
};

export const useCopyPortfolioMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (params: CopyPortfolioParams) => copyPortfolio(params),
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.ALL_PORTFOLIOS]);
		},
	});
};

export type CopyPortfolioParams = {
	body: { portfolioId: number; name: string; description: string | undefined };
};

export type CopyPorfolioResponse = Portfolio;
