import { MufaPropertyComp } from 'types';
import { QUERY_KEYS } from '../constants';
import { createFetchFn, createQueryHook } from '../utils';

const fetchMufaCompset = createFetchFn<MufaCompsetParams, MufaCompsetResponse>({
	method: 'get',
	getURL: ({ propertyId }) => `/mufa/properties/${propertyId}/compSet`,
});

export const useMufaCompsetQuery = createQueryHook({
	queryKey: QUERY_KEYS.MUFA_COMPSET,
	fetchFn: fetchMufaCompset,
	enabled: ({ propertyId }) => propertyId != null,
});

type MufaCompsetParams = {
	propertyId?: number | string;
};

export type MufaCompsetDTO = {
	property: MufaPropertyComp;
	custom: boolean;
};

export type MufaCompsetResponse = {
	subjectProperty: MufaPropertyComp;
	compSet: MufaCompsetDTO[];
	summary: {
		isCustom: boolean;
		lastEdited?: string;
	};
};
