import React, { Component } from 'react';

// @ts-expect-error TS7016: Could not find a declaration f...
import geoViewport from 'geo-viewport';
import {
	point as turfPoint,
	featureCollection as turfFeatureCollection,
} from '@turf/helpers';
import bbox from '@turf/bbox';

import { ExportPDFToolbar } from './ExportPDFToolbar';
import ExportPDFPage from './ExportPDFPage';
import LogoPicker from './LogoPicker';

import SingleCompLandscape, {
	PortfolioSalesComp,
	SinglePageComp,
} from 'ui/Components/Export/SingleCompLandscape';
import LineItemLandscape from 'ui/Components/Export/LineItemLandscape';
import '../styles/exportPDF.nomodule.less';
import { ExportPDFConnectedProps } from '../';
import { CompType, LeaseComp, SalesComp } from 'types';
import { formatComp } from 'util/comp-format/src/format';
import { isPortfolioSaleComp } from 'utils/compHelpers';
import { LegacyRouter, LegacyRouterLocation } from 'router/migration/types';

const pdfToolbarTitle = 'Edit any field by clicking it below.';

export type ExportPDFProps = ExportPDFConnectedProps & {
	route: { compType: CompType };
	location: LegacyRouterLocation;
	router: LegacyRouter;
	routeParams: {
		compIds: string;
	};
	compsAddresses: Map<string, LeaseCompAddress> | Map<string, SaleCompAddress>;
	goBack: () => void;
};

type ExportPDFState = {
	showLineItemComps: boolean;
	showIndividualComps: boolean;
};

export default class ExportPDF extends Component<
	ExportPDFProps,
	ExportPDFState
> {
	state = {
		showLineItemComps: true,
		showIndividualComps: true,
		compsAddresses: new Map(),
	};

	componentDidMount() {
		if (this.props.routeParams?.compIds) {
			if (
				this.props.routeParams.compIds !==
				this.props.export.exportData.leases.map((l) => l.id).join(',')
			) {
				const compIds = this.props.routeParams.compIds
					.split(',')
					.map((id) => parseInt(id));
				this.props.exportActions.prepareCustomExport({
					compIds,
					user: this.props.user!,
					compType: this.props.route.compType,
				});
			}
		}

		if (this.props.export?.exportData.formattedLeases.length)
			this.setCompsAddresses(this.props.export.exportData.formattedLeases);
	}

	// @ts-expect-error TS7006: Parameter 'prevProps' implicit...
	componentDidUpdate(prevProps) {
		const formattedComps = this.props.export?.exportData.formattedLeases ?? [];
		const prevFormattedComps =
			prevProps.export?.exportData.formattedLeases ?? [];
		if (
			prevFormattedComps.length !== formattedComps.length ||
			JSON.stringify(prevFormattedComps) !== JSON.stringify(formattedComps)
		) {
			this.setCompsAddresses(formattedComps);
		}
	}

	componentWillUnmount() {
		this.props.exportActions.clearCustomData();
	}

	toggleShowLineItemComps = () => {
		const showLineItemComps = this.state.showLineItemComps;
		if (!showLineItemComps || this.state.showIndividualComps) {
			this.setState({
				showLineItemComps: !showLineItemComps,
			});
		}
	};

	toggleShowIndivudualComps = () => {
		const showIndividualComps = this.state.showIndividualComps;
		if (!showIndividualComps || this.state.showLineItemComps) {
			this.setState({
				showIndividualComps: !showIndividualComps,
			});
		}
	};

	// @ts-expect-error TS7006: Parameter 'formattedComps' imp...
	setCompsAddresses = (formattedComps) => {
		const compsAddresses =
			this.props.route.compType === 'sale'
				? getCompsAddresses(formattedComps)
				: getLeaseCompsAddresses(formattedComps);
		this.setState({
			// @ts-expect-error TS2345: Argument of type '{ compsAddre...
			compsAddresses,
		});
		this.setMapViewport(
			compsAddresses,
			MAIN_MAP_WIDTH - MAIN_MAP_PADDING,
			MAIN_MAP_HEIGHT - MAIN_MAP_PADDING
		);
	};

	// @ts-expect-error TS7006: Parameter 'compsByAddress' imp...
	setMapViewport = (compsByAddress, mapWidth, mapHeight) => {
		// @ts-expect-error TS7034: Variable 'coords' implicitly h...
		const coords = [];

		// @ts-expect-error TS7006: Parameter 'addressGroup' impli...
		compsByAddress.forEach((addressGroup) => {
			coords.push(turfPoint([addressGroup.lon, addressGroup.lat]));
		});

		// @ts-expect-error TS7005: Variable 'coords' implicitly h...
		const bounds = bbox(turfFeatureCollection(coords));
		const viewport = geoViewport.viewport(bounds, [
			mapWidth,
			Math.max(240, mapHeight),
		]);
		this.props.exportActions.updateCustomExportMetaData('viewport', viewport);
	};

	render() {
		const comps = this.props.export.exportData.leases;
		const compsAddresses = this.state.compsAddresses;

		if (comps.length === 0 || compsAddresses.size === 0) {
			return <div className="spinner large" />;
		}

		const formattedComps = this.props.export.exportData.formattedLeases;
		const sectionedComps = this.props.export.exportData.sectionedLeases;
		const compType = this.props.route.compType;

		// portfolio sale with n properties => n comps (portfolio = [property[i]]) , i=(0,n)
		// this is "hack" to get SingleCompLandscape to work for now
		// TODO: remove this logic when the SingleCompLandscape is updated
		const compsByOneProperty = (
			compType === 'sale'
				? (comps as SalesComp[]).flatMap<SalesComp | PortfolioSalesComp>(
						(comp) => {
							return isPortfolioSaleComp(comp)
								? comp.portfolio.map<PortfolioSalesComp>(
										(portfolioItem, index) => ({
											...comp,
											...portfolioItem,
											portfolio: [portfolioItem],
											indexInPortfolio: index + 1,
											oneOfProperties: comp.portfolio.length,
										})
									)
								: comp;
						}
					)
				: comps
		) as SinglePageComp[];

		const disclaimerText =
			'All information is not guaranteed and should be verified by personal inspection by and/or with the appropriate professional(s).';

		return (
			<div className="export-pdf-container">
				<div className="export-pdf-toolbar-container">
					<ExportPDFToolbar
						title={pdfToolbarTitle}
						exportData={this.props.export.exportData}
						exportActions={this.props.exportActions}
						showLineItemComps={this.state.showLineItemComps}
						showIndividualComps={this.state.showIndividualComps}
						toggleShowLineItemComps={this.toggleShowLineItemComps}
						toggleShowIndivudualComps={this.toggleShowIndivudualComps}
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'modalActions' does not exist on type 'Re... Remove this comment to see the full error message
						modalActions={this.props.modalActions}
						onComplete={this.props.goBack}
						compType={compType}
					/>
				</div>
				<div className="export-pdf-page-container">
					{this.state.showLineItemComps ? (
						<ExportPDFPage className="editable-page">
							<LineItemLandscape
								logoPickers={{
									companyLogo: (
										<LogoPicker
											// @ts-expect-error ts-migrate(2322) FIXME: Property 'attribute' does not exist on type 'Intri... Remove this comment to see the full error message
											attribute="companyLogo"
											placeholder="+ Add your logo"
											logo={this.props.export.exportData.companyLogo}
											setLogo={
												this.props.exportActions.updateCustomExportMetaData
											}
										/>
									),
									clientLogo: (
										<LogoPicker
											// @ts-expect-error ts-migrate(2322) FIXME: Property 'attribute' does not exist on type 'Intri... Remove this comment to see the full error message
											attribute="clientLogo"
											placeholder="+ Add your client's logo"
											logo={this.props.export.exportData.clientLogo}
											setLogo={
												this.props.exportActions.updateCustomExportMetaData
											}
										/>
									),
								}}
								raw={comps}
								exportData={this.props.export.exportData}
								exportActions={this.props.exportActions}
								compsAddresses={compsAddresses}
								editable={true}
								user={this.props.user}
								token={this.props.appConfig.mapbox.accessToken}
								disclaimerText={disclaimerText}
								compType={this.props.route.compType}
								// @ts-expect-error TS2322: Type 'Comp[]' is not assignabl...
								formattedComps={formattedComps}
								mapWidth={MAIN_MAP_WIDTH}
								mapHeight={MAIN_MAP_HEIGHT}
							/>
						</ExportPDFPage>
					) : (
						false
					)}
					{this.state.showIndividualComps
						? compsByOneProperty.map((comp, index) => (
								<ExportPDFPage key={index} className="editable-page">
									{/* TODO: update SingleCompLandscape so that is clear that it shows lease and sale comp details */}
									<SingleCompLandscape
										logoPicker={
											<LogoPicker
												// @ts-expect-error ts-migrate(2322) FIXME: Property 'setLogo' does not exist on type 'Intrins... Remove this comment to see the full error message
												setLogo={
													this.props.exportActions.updateCustomExportMetaData
												}
												attribute="companyLogo"
												logo={this.props.export.exportData.companyLogo}
											/>
										}
										exportData={this.props.export.exportData}
										exportActions={this.props.exportActions}
										editable={true}
										lease={formatComp(comp, compType)}
										sectioned={(sectionedComps || [])[index]}
										raw={comp}
										pinLabel={
											compsAddresses.get(comp.buildingAddress)!.pinLabel
										}
										token={this.props.appConfig.mapbox.accessToken}
										disclaimerText={disclaimerText}
										compType={this.props.route.compType}
									/>
								</ExportPDFPage>
							))
						: false}
				</div>
			</div>
		);
	}
}

type CompAddress = {
	propertyAddress: string;
	pinLabel: number;
	lat: number;
	lon: number;
};
export type SaleCompAddress = CompAddress;
export type LeaseCompAddress = CompAddress & {
	leaseComps: LeaseComp[];
};

// pins for addresses are unique,
const getCompsAddresses: (
	comps: LeaseComp[] | SalesComp[]
) => Map<string, SaleCompAddress> = (comps) => {
	const result = new Map();

	comps.forEach((comp) => {
		if (isPortfolioSaleComp(comp)) {
			comp.portfolio.forEach((property) => {
				const { buildingAddress, geoPoint } = property;
				if (!result.has(buildingAddress)) {
					result.set(buildingAddress, {
						propertyAddress: buildingAddress,
						pinLabel: result.size + 1,
						lat: geoPoint.lat,
						lon: geoPoint.lon,
					});
				}
			});
		} else {
			// @ts-expect-error TS2339: Property 'buildingAddress' doe...
			const { buildingAddress, geoPoint } = comp;
			if (!result.has(buildingAddress)) {
				result.set(buildingAddress, {
					propertyAddress: buildingAddress,
					pinLabel: result.size + 1,
					lat: geoPoint.lat,
					lon: geoPoint.lon,
				});
			}
		}
	});

	return result;
};

// lease comps are grouped by address
const getLeaseCompsAddresses: (
	comps: LeaseComp[]
) => Map<string, LeaseCompAddress> = (comps) => {
	const result = new Map();

	comps.forEach((comp) => {
		const { buildingAddress, geoPoint } = comp;
		if (!result.has(buildingAddress)) {
			result.set(buildingAddress, {
				propertyAddress: buildingAddress,
				pinLabel: result.size + 1,
				lat: geoPoint.lat,
				lon: geoPoint.lon,
				leaseComps: [],
			});
		}
		result.get(buildingAddress).leaseComps.push(comp);
	});

	return result;
};

const MAIN_MAP_WIDTH = 516;
const MAIN_MAP_HEIGHT = 240;
const MAIN_MAP_PADDING = 70;
