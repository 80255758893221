// @deprecated
// It was introduced during the transition period of migrating filters from immutable to PO
// Use plain object spread or `mergeFilters` if obsolete filters are expected
export const setFilters = <T extends object, K extends keyof T>(
	filters: T,
	key: K,
	value: T[K]
) => {
	if (!(key in filters)) {
		return filters;
	}

	return { ...filters, [key]: value };
};
