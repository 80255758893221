import { LeaseFilters } from '../lease';
import {
	apnFilterStub,
	compIdFilterStub,
	filterDateIntervalStub,
	filterNumberIntervalStub,
	filterStringIntervalStub,
	marketFilterStub,
	multiPolygonFilterStub,
	polygonFilterStub,
	radiusFilterStub,
	submarketsFilterStub,
} from './filterStubs';

export const leaseFiltersStub: {
	[K in keyof LeaseFilters]: NonNullable<LeaseFilters[K]>;
} = {
	portfolioId: 1,
	polygon: polygonFilterStub,
	multiPolygon: multiPolygonFilterStub,
	radius: radiusFilterStub,
	address: [],
	buildingClassId: [],
	buildingFloorsCount: filterNumberIntervalStub,
	buildingName: '',
	buildingPropertyTypeId: [],
	buildingPropertySubtype: [],
	buildingSize: filterNumberIntervalStub,
	buildingYearBuilt: filterNumberIntervalStub,
	buildingYearRenovated: filterNumberIntervalStub,
	dateCreated: filterDateIntervalStub,
	effectiveRent: filterNumberIntervalStub,
	executionDate: filterDateIntervalStub,
	expirationDate: filterDateIntervalStub,
	floors: filterNumberIntervalStub,
	landlordNames: [],
	landlordRealtyCompanies: '',
	leaseTerm: filterStringIntervalStub,
	leaseTypeId: [],
	market: marketFilterStub,
	markets: [marketFilterStub],
	owns: 'own',
	spaceSubtypeId: [],
	spaceTypeId: [],
	startingRent: filterNumberIntervalStub,
	sublease: 'true',
	submarkets: submarketsFilterStub,
	opportunityZoneId: [],
	tenantNames: [],
	tenantRealtyCompanies: '',
	transactionSize: filterNumberIntervalStub,
	transactionTypeId: [],
	tenantIndustry: [],
	insideviewRevenue: filterNumberIntervalStub,
	insideviewEmployees: filterNumberIntervalStub,
	insideviewOwnershipId: 1,
	insideviewTickers: '',
	insideviewStatusId: [],
	partial: 'true',
	hidden: 'hidden',
	city: [],
	zip: [],
	sortField: 'executionQuarter',
	sortDirection: 'desc',
	propertyId: [],
	lastAttributeUpdate: filterDateIntervalStub,
	recencyDate: filterDateIntervalStub,
	fips: [],
	apn: apnFilterStub,
	compId: compIdFilterStub,
};
