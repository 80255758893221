import { UseQueryOptions } from '@tanstack/react-query';
import {
	createFetchFn,
	createFiltersParamWithMarketId,
	useQueryHook,
} from 'api/utils';
import { FiltersObject } from 'models/filters/types';
import { useMemo } from 'react';
import { QUERY_KEYS } from '../constants';
import { TenantIndustryDataParams, TenantIndustryResponse } from '../types';

const fetchTenantIndustryDashboardData = createFetchFn<
	TenantIndustryDataParams,
	TenantIndustryResponse
>({
	method: 'post',
	getURL: () => `/api/dashboard/tenantIndustry`,
	getBody: (params) => params,
});

type TenantIndustryDataParamsArg = Omit<TenantIndustryDataParams, 'filters'> & {
	filters: FiltersObject;
};

export type UseTenantIndustryDashboardQueryOptions =
	UseQueryOptions<TenantIndustryResponse>;

export const useTenantIndustryDashboardQuery = (
	params: TenantIndustryDataParamsArg,
	options?: UseTenantIndustryDashboardQueryOptions
) => {
	const { filters: paramsFilters, ...otherParams } = params;

	const filters = useMemo(() => {
		return createFiltersParamWithMarketId(paramsFilters);
	}, [paramsFilters]);

	return useQueryHook({
		queryKey: [
			QUERY_KEYS.TENANT_INDUSTRY_ANALYTICS_DASHBOARD,
			filters,
			otherParams,
		],
		queryFn: fetchTenantIndustryDashboardData({ ...otherParams, filters }),
		...options,
	});
};
