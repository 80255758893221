export function pick<
	K extends string,
	T extends Record<K, unknown> | Record<string, unknown>
>(obj: T, ...keys: K[]): Pick<T, K> {
	// https://stackoverflow.com/questions/47232518/write-a-typesafe-pick-function-in-typescript
	// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-1.html#partial-readonly-record-and-pick
	const result = {} as Pick<T, K>;
	keys.forEach((key) => {
		result[key] = obj[key];
	});
	return result;
}

export function omit<
	K extends string,
	T extends Record<K, unknown> | Record<string, unknown>
>(obj: T, ...keys: K[]): Omit<T, K> {
	// https://stackoverflow.com/questions/53966509/typescript-type-safe-omit-function
	// https://www.typescriptlang.org/docs/handbook/utility-types.html#omittype-keys
	const result = { ...obj };
	keys.forEach((key) => delete result[key]);
	return result;
}
