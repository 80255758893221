import { COLUMN_CONFIG } from 'Components/SearchTable/columnConfigs';
import { BASE_PROPERTY_SECTIONS } from 'Components/SearchTable/columnSectionsConfig';
import { PropertyComp } from 'types';

export const PORTFOLIO_LIMIT = 60_000;

const MAP_VIEW_INCLUDE_FIELDS: (keyof PropertyComp)[] = [
	'id',
	'marketId',
	'buildingAddressAndCity',
	'geoPoint',
	// PortfolioPopup
	'buildingAddress',
	'buildingClass',
	'buildingFloorsCount',
	'buildingPropertyType',
	'buildingName',
	'buildingSize',
	'city',
	'lastSalePrice',
	'propertyAverageTransactionSize',
	'propertySqFtExpiringInTwelveMonths',
	'state',
	'zip',
];

const LIST_VIEW_INCLUDE_FIELDS = BASE_PROPERTY_SECTIONS.flatMap(
	({ ids }) => ids
)
	.map((columnId) => COLUMN_CONFIG[columnId]?.name)
	.filter(Boolean) as (keyof PropertyComp)[];

const DASHBOARD_VIEW_SIDEBAR_INCLUDE_FIELDS: (keyof PropertyComp)[] = [
	'id',
	'buildingAddressAndCity',
];

export const PORTFOLIO_PROPERTIES_INCLUDE_FIELDS = [
	...new Set([
		...MAP_VIEW_INCLUDE_FIELDS,
		...LIST_VIEW_INCLUDE_FIELDS,
		...DASHBOARD_VIEW_SIDEBAR_INCLUDE_FIELDS,
	]),
];
