import { Market, MarketPermissions, User, UserState } from '@compstak/common';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers/root';
import { DEFAULT_FEATURE_FLAG_VALUES } from 'api/featureFlags/useFeatureFlagsQuery';
import { FeatureFlags } from 'api/featureFlags/types';
import { useAppSelector } from 'util/useAppSelector';
import {
	BOOTSTRAP,
	FORGOT_PASSWORD,
	LOGOUT,
	SET_GB_COMPANY,
	TRIAL_EXPIRED,
	USER_ADD_CREDITS,
	USER_LOAD,
	USER_UPDATE,
} from './actions';
import { useMarkets } from 'hooks/useMarkets';

type ReduxAction<T = unknown> = { type?: string; payload?: T };

export function user(
	state: UserState = null,
	action: ReduxAction = {}
): UserState {
	switch (action.type) {
		case LOGOUT:
			state = null;
			break;

		case BOOTSTRAP + '_FULFILLED':
			state = (action.payload as { user: User }).user;
			break;

		case BOOTSTRAP + '_REJECTED':
			state = null;
			break;

		case USER_UPDATE:
		case USER_LOAD + '_FULFILLED':
			state = action.payload as UserState;
			break;

		case USER_ADD_CREDITS:
			if (state) {
				state = {
					...state,
					pointsAvailable: state.pointsAvailable + (action.payload as number),
				};
			}
			break;
	}
	return state;
}

export const useUser = () => {
	return useAppSelector((s) => s.user!);
};

export const useAppConfig = () => {
	return useAppSelector((s) => s.appConfig);
};

export function featureFlagsReducer(
	state = DEFAULT_FEATURE_FLAG_VALUES,
	action: ReduxAction<{ featureFlags: FeatureFlags }> = {}
) {
	if (!action.type) {
		return state;
	}
	if ([LOGOUT, BOOTSTRAP + '_REJECTED'].includes(action.type)) {
		return DEFAULT_FEATURE_FLAG_VALUES;
	}
	if (action.type === BOOTSTRAP + '_FULFILLED') {
		return action.payload?.featureFlags as FeatureFlags;
	}
	return state;
}

export type MarketsState = {
	list: Market[];
} & {
	[marketIdOrName: string]: Market;
};

const marketsInitialState = {
	list: [],
} as unknown as MarketsState;

// @ts-expect-error TS7006: Parameter 'action' implicitly ...
export function markets(state = marketsInitialState, action) {
	switch (action.type) {
		case BOOTSTRAP + '_FULFILLED':
			state = action.payload.markets;
			break;

		case LOGOUT:
		case BOOTSTRAP + '_REJECTED':
			state = marketsInitialState;
			break;
	}
	return state;
}

export const useMarketByNameOrId = (nameOrId: string | number | undefined) => {
	const markets = useMarkets();

	return useMemo(() => {
		if (nameOrId) {
			return markets[nameOrId];
		}
	}, [markets, nameOrId]);
};

export const useReferenceData = () => {
	return useAppSelector((state) => state.referenceData!);
};

export interface MufaPropertyStatus {
	name: string;
}

export interface MufaAssetClassMarket {
	name: string;
}

export interface MufaReferenceData {
	propertyStatuses: MufaPropertyStatus[];
	assetClassMarkets: MufaAssetClassMarket[];
}

export function mufaReferenceData(
	state: MufaReferenceData | null = null,
	// @ts-expect-error TS7006: Parameter 'action' implicitly ...
	action
): MufaReferenceData | null {
	switch (action.type) {
		case BOOTSTRAP + '_FULFILLED': {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const { mufaReferenceData } = action.payload;
			state = mufaReferenceData && {
				// @ts-expect-error TS7006: Parameter 'name' implicitly ha...
				propertyStatuses: mufaReferenceData.propertyStatuses.map((name) => ({
					name,
				})),
				// @ts-expect-error TS7006: Parameter 'name' implicitly ha...
				assetClassMarkets: mufaReferenceData.assetClassMarkets.map((name) => ({
					name,
				})),
			};
			break;
		}

		case LOGOUT:
		case BOOTSTRAP + '_REJECTED':
			state = null;
			break;
	}
	return state;
}

export type PermissionsState = Record<string, MarketPermissions>;

const DEFAULT_STATE = {} as PermissionsState;

export function permissions(
	state: PermissionsState = DEFAULT_STATE,
	// @ts-expect-error TS7006: Parameter 'action' implicitly ...
	action
): PermissionsState {
	switch (action.type) {
		case BOOTSTRAP + '_FULFILLED':
			state = action.payload.permissions;
			break;

		case LOGOUT:
		case BOOTSTRAP + '_REJECTED':
			state = { ...DEFAULT_STATE };
			break;
	}
	return state;
}

export const usePermissions = () => {
	return useSelector<AppState, PermissionsState>((state) => state.permissions);
};

export type AuthStateType = {
	checking: boolean;
	checked: boolean;
	succeeded: boolean;
	termsNeeded: boolean;
	trialExpired: boolean;
	privacyPolicyNeeded: boolean;
	displayGBCompany: boolean;
	forgotPasswordPending: boolean;
	forgotPasswordSucceeded: boolean | null;
	usernameUsedToSignIn: string | null;
};

const defaultAuthState: AuthStateType = {
	checking: false,
	checked: false,
	succeeded: false,
	termsNeeded: false,
	trialExpired: false,
	privacyPolicyNeeded: false,
	displayGBCompany: false,
	forgotPasswordPending: false,
	forgotPasswordSucceeded: null,
	usernameUsedToSignIn: null,
};

export function authState(
	prevState: AuthStateType = defaultAuthState,
	action: any = {}
): AuthStateType {
	let state = prevState;

	if (state.forgotPasswordSucceeded) {
		state = {
			...state,
			forgotPasswordSucceeded: null,
			forgotPasswordPending: false,
		};
	}

	switch (action.type) {
		case LOGOUT:
			return {
				...defaultAuthState,
				checked: true,
			};

		case SET_GB_COMPANY:
			return {
				...state,
				displayGBCompany: action.payload,
			};

		case FORGOT_PASSWORD + '_PENDING':
			return {
				...state,
				forgotPasswordPending: true,
				forgotPasswordSucceeded: null,
			};

		case FORGOT_PASSWORD + '_FULFILLED':
			return {
				...state,
				forgotPasswordPending: false,
				forgotPasswordSucceeded: true,
			};

		case FORGOT_PASSWORD + '_REJECTED':
			return {
				...state,
				forgotPasswordPending: false,
				forgotPasswordSucceeded: true,
			};

		case BOOTSTRAP + '_REJECTED': {
			return {
				...defaultAuthState,
				checked: true,
			};
		}

		case BOOTSTRAP + '_FULFILLED':
			return {
				...state,
				checking: false,
				checked: true,
				succeeded: true,
			};

		case BOOTSTRAP + '_PENDING':
			return {
				...state,
				checking: true,
			};

		case TRIAL_EXPIRED: {
			return {
				...state,
				trialExpired: true,
				usernameUsedToSignIn: action.payload.username,
			};
		}

		default:
			return state;
	}
}
