import { QUERY_KEYS } from 'api/constants';
import { createFetchFn, createQueryHook } from 'api/utils';
import { SearchPropertiesPayload, SearchPropertiesResponse } from './types';

const fetchProperties = createFetchFn<
	SearchPropertiesPayload,
	SearchPropertiesResponse
>({
	method: 'post',
	getURL: () => `/api/properties/actions/search`,
	getBody: (params) => params,
});

export const useSearchPropertiesQuery = createQueryHook({
	queryKey: QUERY_KEYS.SEARCH_PROPERTIES,
	fetchFn: fetchProperties,
});
