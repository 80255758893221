import factory from './factory';
import permissionsService from './permissions';
import { API, User, UserUpdateRequest } from '@compstak/common';

import { getCastleTokens } from './impl/Castle';
import { login } from 'auth/auth';

export type SessionsCreds = {
	password: string;
	rememberMe: boolean;
	username: string;
	totpCode?: string;
};

const serviceController = factory.create<string | void, User>({
	timeout: 500,
	createUrl: function (id) {
		if (id) {
			return '/api/users/' + id;
		} else {
			return '/api/user';
		}
	},
});

const exportable = serviceController.exportable({
	acceptTerms(user: User, asCompany?: boolean) {
		let url = '/api/users/' + user.id + '/actions/acceptTerms';
		if (asCompany) {
			url = url + '?asCompany=true';
		}

		return factory.put(url).then(function () {
			serviceController.service.clearAll();
			return exportable.load();
		});
	},

	declineTerms(user: User) {
		return factory
			.put('/api/users/' + user.id + '/actions/declineTerms')
			.then(function () {
				serviceController.service.clearAll();
				return exportable.load();
			});
	},

	sendForgotPassword(email: string) {
		return factory.post('/api/users/forgotPassword', { email: email });
	},

	async resetPassword(uuid: string, newPassword: string) {
		const castleTokens = await getCastleTokens();
		factory.setCastleTokens(castleTokens);
		return factory.post('/api/users/password/reset', {
			uuid,
			newPassword,
			newPasswordConfirmation: newPassword,
		});
	},

	isAdmin(user: User) {
		// @ts-expect-error TS2367: This comparison appears to be ...
		return user.userType === 'ADMIN';
	},

	isTeamAdmin(user: User) {
		return user.isTeamAdmin;
	},

	savePreferences(user: User) {
		exportable.add(undefined, user);
		return factory.put('/api/preferences/' + user.id, user.preferences);
	},

	// @ts-expect-error TS7006: Parameter 'property' implicitl...
	applyPreset(user: User, property, preset) {
		exportable.add(undefined, user);
		const data = { property: property, preset: preset };
		return factory
			.put('/api/preferences/' + user.id + '/applyPreset', data)
			.then(function (updatedPreferences) {
				// @ts-expect-error TS2322: Type 'unknown' is not assignab...
				user.preferences = updatedPreferences;
				exportable.add(undefined, user);
				return user;
			});
	},

	async createAccount(
		registrationToken: string,
		password: string,
		touAsCompany: boolean,
		utmParams?: Record<string, string[]>
	) {
		const res = await API.post<{ user: User }>('/api/users/createAccount', {
			password: password,
			confirmPassword: password,
			registrationToken: registrationToken,
			touAcceptedAsCompany: !!touAsCompany,
			...utmParams,
		});
		return res.data;
	},

	async activateAccountAndLogin(
		registrationToken: string,
		password: string,
		touAsCompany: boolean,
		utmParams?: Record<string, string[]>
	) {
		const { user } = await this.createAccount(
			registrationToken,
			password,
			touAsCompany,
			utmParams
		);
		await login({
			username: user.email,
			password,
			rememberMe: true,
		});
		window.appConfig.isExchange && this.initialInsertTags(user.id);
		return user;
	},

	initialInsertTags(userId: number) {
		return factory.post(`/api/user/${userId}/userflow/tags`);
	},

	setTrialMarket(marketId: number) {
		return factory
			.put('/api/preferences/trialMarket/' + marketId)
			.then(function (data) {
				permissionsService.clearAll();
				return data;
			});
	},

	// @ts-expect-error TS7006: Parameter 'origin' implicitly ...
	requestDemo(usernameOrEmailOrId: string | number, origin, uuid: string) {
		return factory.post('/api/users/requestDemo', {
			usernameOrEmail: usernameOrEmailOrId,
			origin: origin,
			uuid: uuid,
		});
	},

	async changePassword(user: User, oldPassword: string, newPassword: string) {
		const castleTokens = await getCastleTokens();
		factory.setCastleTokens(castleTokens);

		return factory
			.put(`/api/users/${user.id}/actions/changePassword`, {
				oldPassword,
				newPassword,
				confirmPassword: newPassword,
			})
			.then((userUpdate) => {
				exportable.add(undefined, user);
				return userUpdate;
			});
	},

	async updateUserInfo(
		updatedUser: User,
		userUpdateRequest: UserUpdateRequest
	) {
		const castleTokens = await getCastleTokens();
		factory.setCastleTokens(castleTokens);

		return factory
			.put(`/api/users/${updatedUser.id}`, userUpdateRequest)
			.then((user) => {
				exportable.add(undefined, user);
				return user;
			});
	},
});

export default exportable;
