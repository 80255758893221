import React from 'react';
import styled from 'styled-components';

type Props = {
	className?: string;
	placeholder?: string;
	disabled?: boolean;
	onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
	onChange?: (newValue: string) => void;
	onFocus?: (event: React.FocusEvent<HTMLDivElement>) => void;
	spellCheck?: string;
	value: string;
};

export const ContentEditableV2 = React.forwardRef((props: Props, ref) => {
	const defaultValue = React.useRef(props.value);
	const [focus, setFocus] = React.useState(false);

	const handleInput: React.FormEventHandler<HTMLDivElement> = React.useCallback(
		(event) => {
			if (props.onChange) {
				// @ts-expect-error TS2339: Property 'innerHTML' does not ...
				const newValue = event?.target?.innerHTML ?? '';
				props.onChange(newValue);
			}
		},
		[]
	);

	const handleFocus: React.FocusEventHandler<HTMLDivElement> = React.useCallback(
		(event) => {
			if (props.onFocus) {
				props.onFocus(event);
			}
			setFocus(true);
		},
		[]
	);

	const handleBlur: React.FocusEventHandler<HTMLDivElement> = React.useCallback(
		(event) => {
			if (props.onBlur) {
				props.onBlur(event);
			}
			setFocus(false);
		},
		[]
	);

	return (
		<EditableContainer
			// @ts-expect-error TS2769: No overload matches this call....
			ref={ref}
			className={props.className}
			contentEditable={!props.disabled}
			placeholder={props.placeholder}
			onBlur={handleBlur}
			onFocus={handleFocus}
			onInput={handleInput}
			dangerouslySetInnerHTML={{
				__html: defaultValue.current,
			}}
		/>
	);
});

const EditableContainer = styled.div.attrs((props) => ({
	placeholder: props.placeholder,
	spellCheck: props.spellCheck,
}))`
	&[contenteditable][placeholder]:empty:before {
		color: #aaa;
		content: attr(placeholder);
		position: absolute;
	}
`;

export default class ContentEditable extends React.Component {
	constructor() {
		// @ts-expect-error ts-migrate(2554) FIXME: Expected 1-2 arguments, but got 0.
		super();

		this.state = {
			hasFocus: false,
		};
	}

	// @ts-expect-error TS7006: Parameter 'nextProps' implicit...
	shouldComponentUpdate(nextProps, nextState) {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'hasFocus' does not exist on type 'Readon... Remove this comment to see the full error message
		if (this.state.hasFocus !== nextState.hasFocus) {
			return true;
		}
		return (
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'htmlEl' does not exist on type 'ContentE... Remove this comment to see the full error message
			!this.htmlEl ||
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'htmlEl' does not exist on type 'ContentE... Remove this comment to see the full error message
			(nextProps.html !== this.htmlEl?.innerHTML &&
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'html' does not exist on type 'Readonly<{... Remove this comment to see the full error message
				nextProps.html !== this.props.html) ||
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'disabled' does not exist on type 'Readon... Remove this comment to see the full error message
			this.props.disabled !== nextProps.disabled
		);
	}

	componentDidUpdate() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'htmlEl' does not exist on type 'ContentE... Remove this comment to see the full error message
		if (this.htmlEl && this.props.html !== this.htmlEl?.innerHTML) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'htmlEl' does not exist on type 'ContentE... Remove this comment to see the full error message
			this.htmlEl.innerHTML = this.props.html;
		}
	}

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	onFocus = (event) => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'htmlEl' does not exist on type 'ContentE... Remove this comment to see the full error message
		if (this.htmlEl && this.htmlEl?.innerHTML === this.props.placeholder) {
			setTimeout(() => {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'htmlEl' does not exist on type 'ContentE... Remove this comment to see the full error message
				this.htmlEl.innerHTML = '';
			}, 32);
		}
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'onFocus' does not exist on type 'Readonl... Remove this comment to see the full error message
		if (this.props.onFocus) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'onFocus' does not exist on type 'Readonl... Remove this comment to see the full error message
			this.props.onFocus(event);
		}
		this.setState({
			hasFocus: true,
		});
	};

	// @ts-expect-error TS7006: Parameter 'event' implicitly h...
	onBlur = (event) => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'onBlur' does not exist on type 'Readonly... Remove this comment to see the full error message
		if (this.props.onBlur) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'onBlur' does not exist on type 'Readonly... Remove this comment to see the full error message
			this.props.onBlur(event);
		}
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'htmlEl' does not exist on type 'ContentE... Remove this comment to see the full error message
		if (!this.htmlEl.innerHTML) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'htmlEl' does not exist on type 'ContentE... Remove this comment to see the full error message
			this.htmlEl.innerHTML = this.props.placeholder;
		}
		this.setState({
			hasFocus: false,
		});
	};

	emitChange = () => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'htmlEl' does not exist on type 'ContentE... Remove this comment to see the full error message
		if (!this.htmlEl) return;
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'htmlEl' does not exist on type 'ContentE... Remove this comment to see the full error message
		const html = this.htmlEl.innerHTML;
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
		if (this.props.onChange && html !== this.lastHtml) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
			this.props.onChange(html);
			//evt.target = { value: html };
			//this.props.onChange(evt);
		}
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'lastHtml' does not exist on type 'Conten... Remove this comment to see the full error message
		this.lastHtml = html;
	};

	render() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'tagName' does not exist on type 'Readonl... Remove this comment to see the full error message
		let { tagName, html, className, ...props } = this.props;

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'lastHtml' does not exist on type 'Conten... Remove this comment to see the full error message
		html = this.lastHtml || html;
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'placeholder' does not exist on type 'Rea... Remove this comment to see the full error message
		if ((!html || html === this.props.placeholder) && !this.state.hasFocus) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'placeholder' does not exist on type 'Rea... Remove this comment to see the full error message
			html = this.props.placeholder;
			// if you can do this better, please do. I couldn't get the placeholder to reappear.
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'htmlEl' does not exist on type 'ContentE... Remove this comment to see the full error message
			if (this.htmlEl) {
				setTimeout(() => {
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'htmlEl' does not exist on type 'ContentE... Remove this comment to see the full error message
					this.htmlEl.innerHTML = this.props.placeholder;
				}, 16);
			}
			className += ' showing-placeholder';
		}
		// @ts-expect-error TS2339: Property 'children' does not e...
		const { children, ...rest } = props;

		return React.createElement(tagName || 'div', {
			...rest,
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'htmlEl' does not exist on type 'ContentE... Remove this comment to see the full error message
			ref: (e) => (this.htmlEl = e),
			className,
			onKeyUp: this.emitChange,
			onFocus: this.onFocus,
			onBlur: this.onBlur,
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'disabled' does not exist on type 'Readon... Remove this comment to see the full error message
			contentEditable: !this.props.disabled,
			dangerouslySetInnerHTML: { __html: html },
		});
	}
}
