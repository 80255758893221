import dayjs, { Dayjs } from 'dayjs';
import { LOGOUT, LogoutAction } from 'Pages/Login/actions';
import { DELETE_PROJECT, DELETE_CHART } from '../Repository/actions';
import {
	CHART_DRAFT_LOADING,
	CHART_DRAFT_LOADED,
	CHART_DRAFT_UPDATED,
	CHART_DRAFT_DELETED,
	EXPAND_DATA_SET,
	RESET_CHART_BUILDER,
	ChartBuilderAction,
	CHART_DRAFT_ERROR,
} from './actions';

import {
	UPDATE_PROJECT,
	CREATE_PROJECT,
	SET_CHART_REPO,
	ChartRepositoryAction,
} from '../Repository/actions';

import { Chart } from 'Pages/Analytics/analytics';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers/root';

export type ChartBuilderState = {
	loading: boolean;
	chartDraft: Chart | null;
	expandedDataSetIndex: number | null;
	lastUpdated: Dayjs;
	lastSuccessfulSave: Dayjs | null;
	error: {
		statusCode: number;
		message: string;
	} | null;
};

const initialState: ChartBuilderState = {
	loading: false,
	chartDraft: null,
	expandedDataSetIndex: null,
	lastUpdated: dayjs(),
	lastSuccessfulSave: null,
	error: null,
};

export const useChartBuilder = () => {
	const chartBuilder = useSelector<AppState, ChartBuilderState>(
		(state) => state.chartBuilder
	);
	return chartBuilder;
};

export default (
	state = initialState,
	action: ChartBuilderAction | ChartRepositoryAction | LogoutAction
) => {
	const now = dayjs();
	switch (action.type) {
		case CHART_DRAFT_LOADING:
			state = {
				...initialState,
				loading: true,
				lastUpdated: now,
			};
			break;

		case CHART_DRAFT_LOADED:
		case CHART_DRAFT_UPDATED:
			state = {
				...state,
				loading: false,
				chartDraft: action.payload,
				lastUpdated: now,
			};
			break;

		case CHART_DRAFT_ERROR:
			state = {
				...state,
				error: {
					statusCode: action.payload.statusCode,
					message: action.payload.message,
				},
				loading: false,
			};
			break;

		case EXPAND_DATA_SET:
			state = {
				...state,
				expandedDataSetIndex: action.dataSetIndex,
			};
			break;

		case CHART_DRAFT_DELETED:
			state = {
				...initialState,
				lastUpdated: now,
			};
			break;

		case LOGOUT:
			state = initialState;
			break;

		case CREATE_PROJECT:
		case UPDATE_PROJECT:
		case SET_CHART_REPO:
			state = {
				...state,
				lastSuccessfulSave: now,
				lastUpdated: now,
			};
			break;

		case RESET_CHART_BUILDER:
			state = {
				...state,
				chartDraft: action.chartDraft,
			};
			break;

		case DELETE_CHART:
			if (
				state.chartDraft &&
				action.chartId === state.chartDraft.originalChartId
			) {
				state = {
					...state,
					chartDraft: {
						...state.chartDraft,
						originalChartId: null,
					},
				};
			}
			break;

		case DELETE_PROJECT:
			if (
				state.chartDraft &&
				action.project.charts.some(
					(chart: Chart) =>
						state.chartDraft && chart.id === state.chartDraft.originalChartId
				)
			) {
				state = {
					...state,
					chartDraft: {
						...state.chartDraft,
						originalChartId: null,
					},
				};
			}
			break;
	}

	return state;
};
