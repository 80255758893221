import { UseQueryOptions } from '@tanstack/react-query';
import { ServerFilterItem } from 'types/serverFilters';
import { QUERY_KEYS } from '../constants';
import { createFetchFn, useQueryHook } from '../utils';

const fetchSpaceTypeAnalytics = createFetchFn<
	SpaceTypeAnalyticsParams,
	SpaceTypeAnalyticsResponse
>({
	method: 'post',
	getURL: () => `/api/comps/analytics/spaceType`,
	getBody: (params) => params,
});

export const useSpaceTypeAnalyticsQuery = (
	params: SpaceTypeAnalyticsParams,
	options?: UseQueryOptions<SpaceTypeAnalyticsResponse>
) =>
	useQueryHook({
		queryKey: [QUERY_KEYS.SPACE_TYPE_ANALYTICS, params],
		queryFn: fetchSpaceTypeAnalytics(params),
		...options,
	});

export type SpaceTypeAnalyticsParams = {
	filters: ServerFilterItem<any>[];
	maxBySqft: boolean;
};

export type SpaceTypeDataPoint = {
	propertyId: number;
	aggregateValue: number;
	latitude: number;
	longitude: number;
	spaceType: string;
};

export type SpaceTypeAnalyticsResponse = SpaceTypeDataPoint[];
