import { Market } from '@compstak/common';
import { usePermissions } from 'Pages/Login/reducers';
import { useMemo } from 'react';
import { isMultifamily } from 'util/isMultifamily';
import { useIsExchange } from './useIsExchange';

type UseIsMultifamilyProps = {
	markets?: Market[] | number[];
};

export const useIsMultifamily = ({ markets }: UseIsMultifamilyProps) => {
	const isExchange = useIsExchange();
	const permissions = usePermissions();

	return useMemo(() => {
		if (!markets) return false;

		return isMultifamily({
			isExchange,
			markets,
			permissions,
		});
	}, [markets, isExchange, permissions]);
};
