import { API } from '@compstak/common';
import {
	QueryClient,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';
import { loadUser } from 'Pages/Login/actions';
import * as saleActions from 'actions/sale';
import { useDispatch } from 'react-redux';
import { SalesComp } from 'types';
import { MUTATION_KEYS, QUERY_KEYS } from '../constants';
import { updateCachePropertyComps } from '../utils';
import { isSaleByIdQuery, updateCacheSaleById } from './updateCacheSaleById';
import { invalidateUserQuery } from 'hooks/userHooks';

const unlockSales = async ({ ids }: UnlockSalesParams) => {
	const response = await API.put<UnlockSalesResponse>(
		`/api/salesComps/unlock`,
		{
			ids,
		}
	);

	return response.data;
};

export const useUnlockSalesMutation = () => {
	const queryClient = useQueryClient();
	const dispatch = useDispatch();

	return useMutation({
		mutationKey: [MUTATION_KEYS.UNLOCK_SALES],
		mutationFn: unlockSales,
		onSuccess: (data) => {
			// redux legacy
			const ids = data.comps.map((sale) => sale.id);
			dispatch(saleActions.unlockSales(ids));
			dispatch(loadUser());

			// new
			updateCacheSaleById(queryClient, data);
			updateCachePropertyComps('sale', queryClient, data);
			invalidateSalesQueries(queryClient, data);
			invalidateUserQuery(queryClient);
		},
	});
};

export const invalidateSalesQueries = (
	queryClient: QueryClient,
	data: UnlockSalesResponse
) => {
	queryClient.invalidateQueries({
		predicate: (query) => {
			const [staticKey] = query.queryKey;
			const isPluralQuery = [
				QUERY_KEYS.SEARCH_SALES,
				QUERY_KEYS.SEARCH_SALES_INFINITE,
				QUERY_KEYS.PROPERTY_SALES,
				QUERY_KEYS.SIMILAR_SALES,
			].includes(staticKey as QUERY_KEYS);

			return isPluralQuery || isSaleByIdQuery(query, data);
		},
	});
};

export type UnlockSalesParams = {
	ids: number[];
};

export type UnlockSalesResponse = {
	points: number;
	freeComps: number;
	comps: SalesComp[];
};
