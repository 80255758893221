import sortBy from 'lodash/sortBy';

import stackingPlansService from 'services/stackingPlans';
import propertyService from 'services/property';

import overflowModal from './Modals';
import { MODAL_SHOW } from 'Singletons/Modal/actions';

import {
	createFloorsIfNeeded,
	insertFloorIfNeeded,
	getActualLabel,
	getFloorsSizeChangeShouldAffect,
} from './util';

export const UPDATE_STACKING_PLAN = 'UPDATE_STACKING_PLAN';
export const SERVER_UPDATE_STACKING_PLAN = 'SERVER_UPDATE_STACKING_PLAN';
export const LOAD_STACKING_PLAN = 'LOAD_STACKING_PLAN';
export const STACKING_PLAN_UNDO = 'STACKING_PLAN_UNDO';
export const STACKING_PLAN_REDO = 'STACKING_PLAN_REDO';
export const STACKING_PLAN_EDIT_SPACE = 'STACKING_PLAN_EDIT_SPACE';
export const STACKING_PLAN_EDIT_FLOOR = 'STACKING_PLAN_EDIT_FLOOR';
export const STACKING_PLAN_CLOSE_EDITOR = 'STACKING_PLAN_CLOSE_EDITOR';

export function loadStackingPlan(propertyId: string) {
	const promise = Promise.all([
		stackingPlansService.load(propertyId).then(createFloorsIfNeeded),
		propertyService.load(parseInt(propertyId)),
	]).then(function ([stackingPlan, property]) {
		return {
			stackingPlan,
			property,
		};
	});

	return {
		type: LOAD_STACKING_PLAN,
		meta: {
			propertyId: parseInt(propertyId),
		},
		payload: {
			promise: promise,
		},
	};
}

// @ts-expect-error TS7006: Parameter 'inputObject' implic...
function populateFloorConfig(inputObject, props) {
	// @ts-expect-error TS7006: Parameter 'floor' implicitly h...
	props.floors.forEach((floor) => {
		const actualLabel = getActualLabel(floor.label);

		inputObject[actualLabel] = {};
		inputObject[actualLabel].size = floor.size;
		inputObject[actualLabel].availableSpace = floor.size;
		inputObject[actualLabel].overflowing = false;
	});

	// @ts-expect-error TS7006: Parameter 'space' implicitly h...
	props.spaces.forEach((space) => {
		if (space.placed) {
			const actualLabel = getActualLabel(space.floor);

			inputObject[actualLabel].availableSpace -= space.size;
			if (
				inputObject[actualLabel].availableSpace <
				inputObject[actualLabel].size * -0.15
			) {
				inputObject[actualLabel].overflowing = true;
			}
		}
	});

	return inputObject;
}

type Floor = {
	label: string;
};
type Space = {};
type OverflowProps = {
	floors?: Floor[];
	spaces?: Space[];
};
function checkForOverflow(
	// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
	dispatch,
	oldProps: OverflowProps,
	newProps: OverflowProps
) {
	if (
		!oldProps.floors ||
		!oldProps.spaces ||
		!newProps.floors ||
		!newProps.spaces
	) {
		return;
	}

	let oldFloorConfig = {};
	oldFloorConfig = populateFloorConfig(oldFloorConfig, oldProps);

	let newFloorConfig = {};
	newFloorConfig = populateFloorConfig(newFloorConfig, newProps);

	const resultSet: string[] = [];
	newProps.floors.forEach((floor) => {
		if (
			floor.label in oldFloorConfig &&
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			typeof oldFloorConfig[floor.label] !== 'undefined'
		) {
			if (
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				!oldFloorConfig[floor.label].overflowing &&
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				newFloorConfig[floor.label].overflowing
			) {
				resultSet.push(floor.label);
			}
		}
	});

	if (resultSet.length > 0) {
		const newOverflowingFloors = { resultSet: resultSet, ...newProps };

		dispatch({
			type: MODAL_SHOW,
			payload: {
				stack: true,
				component: overflowModal,
				data: newOverflowingFloors,
			},
		});
	}
}

// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
function updateFrontEndOnly(dispatch, newData, requiresSpinner = false) {
	dispatch({
		type: UPDATE_STACKING_PLAN,
		meta: {
			requiresSpinner,
		},
		payload: newData,
	});
}

// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
function updateAndDispatch(dispatch, newData, requiresSpinner = false) {
	const promise = stackingPlansService.save(newData);

	dispatch({
		type: UPDATE_STACKING_PLAN,
		meta: {
			requiresSpinner,
		},
		payload: newData,
	});

	dispatch({
		type: SERVER_UPDATE_STACKING_PLAN,
		meta: {
			updateUser: true,
			requiresSpinner,
		},
		payload: {
			promise,
		},
	});

	return promise;
}

// @ts-expect-error TS7006: Parameter 'space' implicitly h...
export function openSpaceEditor(space) {
	return {
		type: STACKING_PLAN_EDIT_SPACE,
		payload: space,
	};
}

// @ts-expect-error TS7006: Parameter 'floor' implicitly h...
export function openFloorEditor(floor) {
	return {
		type: STACKING_PLAN_EDIT_FLOOR,
		payload: floor,
	};
}

export function saveUpdatedPlan(
	// @ts-expect-error TS7006: Parameter 'stackingPlan' impli...
	stackingPlan,
	requiresSpinner = false,
	oldStackingPlan: OverflowProps | null = null
) {
	// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
	return (dispatch) => {
		if (oldStackingPlan != null) {
			checkForOverflow(dispatch, oldStackingPlan, stackingPlan);
		}
		updateAndDispatch(dispatch, stackingPlan, requiresSpinner);
	};
}

// @ts-expect-error TS7006: Parameter 'stackingPlan' impli...
export const updateSpace = function (stackingPlan, updatedSpace) {
	// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
	return function (dispatch) {
		const oldStackingPlan = stackingPlan;

		if (!updatedSpace.floor || !updatedSpace.size) {
			updatedSpace.placed = false;
		}

		// @ts-expect-error TS7006: Parameter 's' implicitly has a...
		const oldSpace = stackingPlan.spaces.find((s) => s.id === updatedSpace.id);

		let newSpaces;

		if (oldSpace.floor === updatedSpace.floor) {
			// @ts-expect-error TS7006: Parameter 's' implicitly has a...
			newSpaces = stackingPlan.spaces.map((s) =>
				s.id === updatedSpace.id ? updatedSpace : s
			);
		} else {
			// @ts-expect-error TS7006: Parameter 's' implicitly has a...
			newSpaces = stackingPlan.spaces.filter((s) => s !== oldSpace);
			newSpaces.push(updatedSpace);
			newSpaces = sortBy(newSpaces, ['placed', 'floor']);
		}

		stackingPlan = insertFloorIfNeeded(stackingPlan, updatedSpace);

		stackingPlan = {
			...stackingPlan,
			spaces: newSpaces,
		};

		checkForOverflow(dispatch, oldStackingPlan, stackingPlan);
		updateAndDispatch(dispatch, stackingPlan);
	};
};

// @ts-expect-error TS7006: Parameter 'stackingPlan' impli...
export const duplicateSpace = function (stackingPlan, toDupe) {
	// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
	return function (dispatch) {
		const oldStackingPlan = stackingPlan;

		const duped = {
			...toDupe,
			id: null,
		};

		stackingPlan = {
			...stackingPlan,
			spaces: [...stackingPlan.spaces, duped],
		};

		checkForOverflow(dispatch, oldStackingPlan, stackingPlan);
		updateAndDispatch(dispatch, stackingPlan, true);
	};
};

// @ts-expect-error TS7006: Parameter 'stackingPlan' impli...
export const removeSpace = function (stackingPlan, toRemove) {
	// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
	return function (dispatch) {
		stackingPlan = {
			...stackingPlan,
			// @ts-expect-error TS7006: Parameter 's' implicitly has a...
			spaces: stackingPlan.spaces.filter((s) => s.id !== toRemove.id),
		};

		updateAndDispatch(dispatch, stackingPlan);
	};
};

// @ts-expect-error TS7006: Parameter 'stackingPlan' impli...
export const addSpace = function (stackingPlan, newSpace) {
	// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
	return function (dispatch) {
		stackingPlan = insertFloorIfNeeded(stackingPlan, newSpace);

		newSpace.placed = !!newSpace.floor && !!newSpace.size;

		stackingPlan = {
			...stackingPlan,
			spaces: [...stackingPlan.spaces, newSpace],
		};

		updateAndDispatch(dispatch, stackingPlan, true);
	};
};

export const updateNumberOfFloors = function (
	// @ts-expect-error TS7006: Parameter 'stackingPlan' impli...
	stackingPlan,
	// @ts-expect-error TS7006: Parameter 'numberOfFloors' imp...
	numberOfFloors,
	// @ts-expect-error TS7006: Parameter 'squareFootage' impl...
	squareFootage
) {
	// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
	return function (dispatch) {
		const floors = new Array(numberOfFloors).fill(1).map((a, i) => {
			if (stackingPlan.floors && stackingPlan.floors[i]) {
				return stackingPlan.floors[i];
			}

			return {
				label: (i + 1).toString(),
				size: squareFootage,
				position: 'left',
				spaces: [],
			};
		});

		stackingPlan = {
			...stackingPlan,
			floors,
		};

		updateAndDispatch(dispatch, stackingPlan);
	};
};

export const updateFloor = function (
	// @ts-expect-error TS7006: Parameter 'stackingPlan' impli...
	stackingPlan,
	// @ts-expect-error TS7006: Parameter 'floorIndex' implici...
	floorIndex,
	// @ts-expect-error TS7006: Parameter 'size' implicitly ha...
	size,
	// @ts-expect-error TS7006: Parameter 'label' implicitly h...
	label,
	// @ts-expect-error TS7006: Parameter 'position' implicitl...
	position?
) {
	// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
	return function (dispatch) {
		const newFloor = {
			size,
			label,
		};

		const oldFloor = stackingPlan.floors[floorIndex];
		const [lowest, highest] = getFloorsSizeChangeShouldAffect(
			floorIndex,
			size,
			stackingPlan.floors
		);

		// @ts-expect-error TS7006: Parameter 'floor' implicitly h...
		const floors = stackingPlan.floors.map((floor, i) => {
			if (i === floorIndex) {
				return newFloor;
			}
			if (i >= lowest && i <= highest) {
				return {
					label: floor.label,
					size,
				};
			}
			return floor;
		});

		let spaces = stackingPlan.spaces;

		// allow user to have specially-cased floor names
		if (oldFloor.label !== newFloor.label) {
			// @ts-expect-error TS7006: Parameter 'space' implicitly h...
			spaces = spaces.map((space) => {
				// when comparing labels, they should both be checked against the "actual" label
				if (getActualLabel(space.floor) === getActualLabel(oldFloor.label)) {
					return {
						...space,
						floor: newFloor.label,
					};
				}
				return space;
			});
		}

		const newData = {
			...stackingPlan,
			spaces,
			floors,
			position,
		};

		updateAndDispatch(dispatch, newData);
	};
};

// @ts-expect-error TS7006: Parameter 'stackingPlan' impli...
export const automaticallyPlaceSpaces = function (stackingPlan) {
	// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
	return function (dispatch) {
		let placedOne = false;
		// @ts-expect-error TS7006: Parameter 'space' implicitly h...
		function placeSpace(space) {
			if (space.floor && !space.placed && space.size) {
				placedOne = true;
				return {
					...space,
					placed: true,
				};
			}
			return space;
		}

		// @ts-expect-error TS7006: Parameter 'space' implicitly h...
		stackingPlan.spaces.forEach((space) => {
			stackingPlan = insertFloorIfNeeded(stackingPlan, space);
		});

		const spaces = sortBy(stackingPlan.spaces.map(placeSpace), [
			'placed',
			'floor',
		]);

		stackingPlan = {
			...stackingPlan,
			spaces,
		};

		if (!placedOne) {
			return;
		}

		updateAndDispatch(dispatch, stackingPlan);
	};
};

// @ts-expect-error TS7006: Parameter 'stackingPlan' impli...
export const placeSingleSpace = function (stackingPlan, space) {
	space = {
		...space,
		placed: true,
	};

	return updateSpace(stackingPlan, space);
};

// @ts-expect-error TS7006: Parameter 'stackingPlan' impli...
export const unplaceSingleSpace = function (stackingPlan, space) {
	space = {
		...space,
		placed: false,
	};

	return updateSpace(stackingPlan, space);
};

// @ts-expect-error TS7006: Parameter 'stackingPlan' impli...
export const changeSpaceFloor = function (stackingPlan, space, newFloorLabel) {
	space = {
		...space,
		floor: newFloorLabel,
		placed: true,
	};

	return updateSpace(stackingPlan, space);
};

// @ts-expect-error TS7006: Parameter 'stackingPlan' impli...
export const insertFloorAbove = function (stackingPlan, referenceFloor) {
	// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
	return function (dispatch) {
		let addedFloor;
		const floors = stackingPlan.floors.reduce(
			// @ts-expect-error TS7006: Parameter 'acc' implicitly has...
			(acc, floor, index, floorsList) => {
				acc.push(floor);
				if (floor === referenceFloor) {
					addedFloor = {
						size: floor.size,
						spaces: [],
						label: 'New Floor ' + (floorsList.length + 1),
						initialIndex: index + 1,
					};
					acc.push(addedFloor);
				}
				return acc;
			},
			[]
		);

		stackingPlan = {
			...stackingPlan,
			floors,
		};

		updateFrontEndOnly(dispatch, stackingPlan);

		dispatch(openFloorEditor(addedFloor));
	};
};

// @ts-expect-error TS7006: Parameter 'stackingPlan' impli...
export const insertFloorBelow = function (stackingPlan, referenceFloor) {
	// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
	return function (dispatch) {
		let addedFloor;
		const floors = stackingPlan.floors.reduce(
			// @ts-expect-error TS7006: Parameter 'acc' implicitly has...
			(acc, floor, index, floorsList) => {
				if (floor === referenceFloor) {
					addedFloor = {
						size: floor.size,
						spaces: [],
						label: 'New Floor ' + (floorsList.length + 1),
						initialIndex: index,
					};
					acc.push(addedFloor);
				}
				acc.push(floor);
				return acc;
			},
			[]
		);

		stackingPlan = {
			...stackingPlan,
			floors,
		};

		updateFrontEndOnly(dispatch, stackingPlan);

		dispatch(openFloorEditor(addedFloor));
	};
};

// @ts-expect-error TS7006: Parameter 'stackingPlan' impli...
export const removeFloor = function (stackingPlan, floor) {
	// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
	return function (dispatch) {
		// @ts-expect-error TS7006: Parameter 'f' implicitly has a...
		const floors = stackingPlan.floors.filter((f) => f !== floor);
		const actualLabel = getActualLabel(floor.label);

		// @ts-expect-error TS7006: Parameter 's' implicitly has a...
		const spaces = stackingPlan.spaces.map((s) => {
			if (getActualLabel(s.floor) === actualLabel) {
				return {
					...s,
					placed: false,
				};
			}
			return s;
		});

		stackingPlan = {
			...stackingPlan,
			spaces,
			floors,
		};

		updateAndDispatch(dispatch, stackingPlan);
	};
};

export function undo() {
	return {
		type: STACKING_PLAN_UNDO,
	};
}

export function redo() {
	return {
		type: STACKING_PLAN_REDO,
	};
}

export function closeEditor() {
	return {
		type: STACKING_PLAN_CLOSE_EDITOR,
	};
}
