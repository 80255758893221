import { Spinner } from '@compstak/ui-kit';
import styled from 'styled-components';

export const FilterSpinner = () => {
	return (
		<SpinnerContainer>
			<Spinner size="s" />
		</SpinnerContainer>
	);
};

const SpinnerContainer = styled.span`
	position: absolute;
	top: calc(50% - 10px);
	right: 16px;
`;
