import moneyHelper, { Currency } from 'ui/util/money';
import number from 'ui/util/number';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import { nullValueText } from '@compstak/common';
import { Locale } from 'util/comp-format/attributes';
import { formatMonths, formatPercent } from 'format';

function maybe<T extends (...args: any[]) => ReturnType<T>>(cb: T) {
	return function (...arg: Parameters<T>) {
		if (arg && typeof arg[0] !== 'number') {
			return nullValueText;
		}
		// @ts-expect-error TS2345: Argument of type 'IArguments' ...
		return cb.apply(null, arguments);
	};
}

const months = maybe((value: number) => {
	return value.toFixed(1) + 'm';
});

const squareFeet = maybe((value: number) => {
	return abbreviateNumber(Math.round(value)) + ' SF';
});

const money = maybe(
	(value: number, currency: Currency, useMonthlyMetrics?: boolean) => {
		if (useMonthlyMetrics) {
			value = value / 12;
		}
		return moneyHelper(value, currency);
	}
);

const price = maybe((value: number, currency: Currency) =>
	moneyHelper(value, currency, 2, true)
);

function ensureNumber(a: unknown) {
	if (typeof a !== 'number') {
		return 0;
	}
	return a;
}

function leaseTermToComparable(value: number) {
	return Math.round(value);
}

const COUNT = {
	combinator: maybe(number),
	toComparator: ensureNumber,
};
const FIXED_COUNT = {
	combinator: maybe((value: number) => value.toFixed().toString()),
	toComparator: ensureNumber,
};
const MONEY = {
	combinator: money,
	toComparator: ensureNumber,
	canBeMonthly: true,
};
const PERCENTAGE = {
	combinator: (input: number) => formatPercent(input),
	toComparator: ensureNumber,
};
const PRICE = {
	combinator: price,
	toComparator: ensureNumber,
};

export const displayOptions = {
	effectiveRent: MONEY,
	startingRent: MONEY,
	freeMonths: {
		combinator: months,
		toComparator: ensureNumber,
	},
	leaseTerm: {
		combinator: (input: number) => formatMonths(input, { omitDays: true }),
		toComparator: leaseTermToComparable,
	},
	ti: {
		combinator: function (
			input: number,
			currency: Currency,
			useMonthlyMetrics?: boolean
		) {
			return money(input ? input : 0, currency, useMonthlyMetrics);
		},
		toComparator: function (input: number) {
			return input ? input : 0;
		},
	},
	transactionSize: {
		combinator: squareFeet,
		toComparator: ensureNumber,
	},
	startingRentMedian: MONEY,
	concessionsPercentage: PERCENTAGE,
	propertyCount: COUNT,
	leaseCount: COUNT,
	officeLeaseCount: COUNT,
	withLeaseDataCount: COUNT,
	withRpDataCount: COUNT,
	leases: COUNT,
	sales: COUNT,
	averageMarketStartingRent: MONEY,
	averageMarketEffectiveRent: MONEY,
	totalSalePrice: PRICE,
	lastSalePrice: PRICE,
	buildingFloorsCount: FIXED_COUNT,
	buildingSize: {
		combinator: squareFeet,
		toComparator: ensureNumber,
	},
	propertyAverageTransactionSize: {
		combinator: squareFeet,
		toComparator: ensureNumber,
	},
	propertyMarketStartingRent: MONEY,
	propertySqFtExpiringInTwelveMonths: {
		combinator: squareFeet,
		toComparator: ensureNumber,
	},
	'12': PERCENTAGE,
	'36': PERCENTAGE,
	'60': PERCENTAGE,
	'84': PERCENTAGE,
	'120': PERCENTAGE,
} as const;

export const createDisplayObject = function <
	T extends keyof typeof displayOptions,
>(
	key: T,
	value: number,
	useMonthlyMetrics?: boolean,
	locale: Locale = 'en_US'
) {
	const currency = locale === 'en_GB' ? 'GBP' : 'USD';
	const displayOption = displayOptions[key];

	if (!displayOption) {
		console.error('Got an unexpected field!?', key, displayOption);
		return {
			property: key,
			value: 'ERROR',
			comparator: 0,
		};
	}

	return {
		property: key,
		value: displayOption.combinator(value, currency, useMonthlyMetrics),
		comparator: displayOption.toComparator(value),
	};
};
