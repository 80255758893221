import { buildQuery } from '@compstak/common';
import { UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { createFetchFn, useQueryHook } from '../utils';

const fetchMarketRentAnalyticsProperties = createFetchFn<
	MarketRentPropertiesParams,
	MarketRentPropertiesResponse
>({
	method: 'get',
	getURL: ({ hexagonId, marketId }) => {
		const query = buildQuery({ h3: hexagonId, marketId });
		return `/api/comps/analytics/marketRent/properties?${query}`;
	},
});

export const useMarketRentAnalyticsPropertiesQuery = (
	params: MarketRentPropertiesParams,
	options?: UseQueryOptions<MarketRentPropertiesResponse>
) =>
	useQueryHook({
		queryKey: [QUERY_KEYS.MARKET_RENT_ANALYTICS_PROPERTIES, params],
		queryFn: fetchMarketRentAnalyticsProperties(params),
		enabled: !!params.hexagonId,
		...options,
	});

export type MarketRentPropertiesParams = {
	marketId: number;
	hexagonId: string;
};

export type MarketRentPropertiesResponse = string[];
