import { SavedSearch, SavedSearchType } from 'reducers/savedSearches';
import basicServiceFactory from './factory';

function createUrl() {
	return '/api/savedSearches';
}

const serviceController = basicServiceFactory.create<number, SavedSearch[]>({
	createUrl: createUrl,
});

const exportable = serviceController.exportable({
	async create(data: CreateSavedSearchPayload) {
		const savedSearches = await basicServiceFactory.post<
			CreateSavedSearchPayload,
			SavedSearch[]
		>('/api/savedSearches', data);
		updateCachedSavedSearches(savedSearches);
		return savedSearches;
	},

	async update(savedSearch: UpdateSavedSearchPayload) {
		const savedSearches = await basicServiceFactory.put<
			UpdateSavedSearchPayload,
			SavedSearch[]
		>('/api/savedSearches/' + savedSearch.id, savedSearch);
		updateCachedSavedSearches(savedSearches);
		return savedSearches;
	},

	async delete(removedSavedSearch: UpdateSavedSearchPayload) {
		const savedSearches = await basicServiceFactory.del<
			UpdateSavedSearchPayload,
			SavedSearch[]
		>('/api/savedSearches/' + removedSavedSearch.id);
		updateCachedSavedSearches(savedSearches);
		return savedSearches;
	},

	async view(savedSearch: { id: string }) {
		basicServiceFactory.put<void>(
			'/api/savedSearches/' + savedSearch.id + '/actions/viewed'
		);
	},

	async sendSearch(searchId: string, emails: string[]) {
		basicServiceFactory.post<SendSearchPayload, void>(
			'/api/savedSearches/sendSearch',
			{
				searchId,
				recipientEmails: emails,
			}
		);
	},

	clear() {
		serviceController.service.clearAll();
	},
});

// @ts-expect-error TS7006: Parameter 'savedSearches' impl...
function updateCachedSavedSearches(savedSearches) {
	serviceController.service.clearAll();
	exportable.add(createUrl(), savedSearches);
}

export default exportable;

export type CreateSavedSearchPayload<
	T extends SavedSearchType =
		| SavedSearchType.LEASES
		| SavedSearchType.SALES
		| SavedSearchType.PROPERTIES,
> = Pick<
	SavedSearch<T>,
	| 'name'
	| 'notifyByEmail'
	| 'marketId'
	| 'sortAsc'
	| 'sortProperty'
	| 'filters'
	| 'searchType'
>;

type UpdateSavedSearchPayload<
	T extends SavedSearchType =
		| SavedSearchType.LEASES
		| SavedSearchType.SALES
		| SavedSearchType.PROPERTIES,
> = CreateSavedSearchPayload<T> & {
	id: string;
};

type SendSearchPayload = {
	searchId: string;
	recipientEmails: string[];
};
