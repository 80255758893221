import { QUERY_KEYS } from 'api/constants';
import {
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query';
import { API, buildQuery, formatRequestString } from '@compstak/common';
import {
	APIDateRequestParams,
	Metric,
	TimeGranularity,
} from '../../Pages/PropertyPageV2_1/api/interfaces';
import { SuggestedSpan } from '../../Pages/PropertyPageV2_1/type';

type HistogramItem = {
	year: number;
	supplyValue: number;
	quarter?: number;
};

export type SubmarketSupplyDTO = {
	supplyHistogram: {
		type: TimeGranularity;
		histogram: Array<HistogramItem>;
	};
	suggestedSpan?: SuggestedSpan;
};

export interface FetchParams extends APIDateRequestParams {
	id: string | number;
	metric: Metric;
}

export type UseSubmarketSupplyResponse = UseQueryResult<SubmarketSupplyDTO>;

const fetchSubmarketSupply = async ({
	id,
	metric,
	...rest
}: FetchParams): Promise<SubmarketSupplyDTO> => {
	const query = buildQuery({ ...rest });

	const response = await API.get<SubmarketSupplyDTO>(
		formatRequestString({
			entityUrl: `/mufa/properties/${id}/rpSubmarket/supply/${metric}?${query}`,
		})
	);

	return response.data;
};

export const useSubmarketSupplyData = (
	params: FetchParams,
	queryConfig?: Pick<UseQueryOptions, 'enabled'>
): UseSubmarketSupplyResponse =>
	useQuery(
		[QUERY_KEYS.MUFA_PROPERTY_SUPPLY, params],
		() => fetchSubmarketSupply(params),
		queryConfig
	);
