import { useQueryClient } from '@tanstack/react-query';
import { useUserId } from 'hooks/userHooks';
import { memo, useEffect } from 'react';
import factory from 'services/factory';

export const LogoutEffects = memo(() => {
	const queryClient = useQueryClient();
	const userId = useUserId();

	useEffect(() => {
		if (!userId) {
			queryClient.removeQueries();
			factory.clearAll();
		}
	}, [userId, queryClient]);

	return null;
});

LogoutEffects.displayName = 'LogoutEffects';
