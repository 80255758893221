import { User } from '@compstak/common';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS, useUserQuery } from 'api';
import { addCreditsToUser } from 'Pages/Login/actions';
import { useAppDispatch } from 'util/useAppDispatch';
import { useAppSelector } from 'util/useAppSelector';

export function useUserId(): number | undefined {
	return useAppSelector((state) => state.user?.id);
}

export function useAddCreditsToUser() {
	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();

	return (credits: number) => {
		// At the moment user data is both in redux and in react-query cache.
		// For this reason we update user credits in both places.
		// In the future user state will be migrated to just be in react-query cache.
		dispatch(addCreditsToUser(credits));
		queryClient.setQueryData<User>(
			[QUERY_KEYS.USER],
			(user) =>
				user && {
					...user,
					pointsAvailable: user.pointsAvailable + credits,
				}
		);
	};
}

export function useUserIsSalesTrader(): boolean {
	const user = useUserQuery().data;
	return !!user?.salesTrader;
}

export const invalidateUserQuery = (queryClient: QueryClient) => {
	queryClient.invalidateQueries([QUERY_KEYS.USER]);
};
