import factory from './factory';
import pointService from './salePoints';
import marketService from 'services/markets';
import { SalesComp } from 'types';
import { UnlockSalesResponse } from 'api';
// @ts-expect-error TS7034: Variable 'callbacks' implicitl...
const callbacks = [];

const serviceController = factory.create<number, SalesComp>({
	createUrl: (id) => '/api/salesComps/' + id,
	timeout: 1000 * 60 * 5,
	// cache: {
	//     storage: sessionStorage,
	//     ttl: 1000 * 60 * 60, // 1 hour
	//     namespace: 'sales',
	//     maxSize: 1024 * 1024 * 5 // 2.5 MB
	// }
});

const service = serviceController.exportable({
	onUnlock(cb: Function) {
		callbacks.push(cb);
	},

	// @ts-expect-error TS7006: Parameter 'id' implicitly has ...
	sendSuggestion(id, suggestion, market) {
		return factory
			.post('/api/salesComps/feedback', {
				compId: id,
				suggestion: suggestion,
				market: market,
			})
			.then(function (data) {
				return data;
			});
	},

	// @ts-expect-error TS7006: Parameter 'id' implicitly has ...
	share(id, email) {
		return factory
			.post('/api/salesComps/share', { compId: id, email: email })
			.then(function (data) {
				return data;
			});
	},
	// @ts-expect-error TS7006: Parameter 'ids' implicitly has...
	unlock(ids): Promise<UnlockSalesResponse> {
		return factory
			.put('/api/salesComps/unlock', { ids: ids })
			.then(function (data) {
				pointService.clearAll();
				// @ts-expect-error TS7005: Variable 'callbacks' implicitl...
				callbacks.forEach(function (cb: Function) {
					cb();
				});
				// @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
				data.comps.forEach(function (sale) {
					serviceController.service.clear(sale.id);
					service.add(sale.id, sale);
				});
				// @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
				return data.comps;
			});
	},
});

// @ts-expect-error TS2322: Type '(idsOrPromises: number[]...
service.loadMany = function (idsOrPromises) {
	if (idsOrPromises.length === 1) {
		return Promise.all([service.load(idsOrPromises[0])]);
	}

	// Should it be this complicated? It seems like it shouldn't.
	// The reasons it is:
	// • We can't do an ID search without knowing the market.
	// • We can't get the market off the comp, because we get the display name.
	// • We don't want to load comps we don't have to load.
	// • The cache should be filled immediately in case this is called twice

	return Promise.all(idsOrPromises).then(function (ids) {
		const resolves = {};
		const rejects = {};

		// to avoid loading comps that are aleady loaded, and to
		// hopefully speed up loading all comps by already having
		// the market, the comps are split into loaded and unloaded groups.
		const idsLoaded = ids.filter(function (id) {
			return service.has(id);
		});
		const idsToLoad = ids.filter(function (id) {
			return !service.has(id);
		});

		// This is the array of promises that will be returned.
		const promises = ids.map(function (id) {
			if (service.has(id)) {
				return service.load(id);
			}
			// Since we're loading the comps ourselves, we need to
			// create the promises and fill the cache ourselves.
			const promise = new Promise(function (resolve, reject) {
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				resolves[id] = resolve;

				// @ts-expect-error TS7053: Element implicitly has an 'any...
				rejects[id] = reject;
			});
			service.add(id, promise);
			return promise;
		});

		// If everything has been loaded already, short circuit.
		if (idsToLoad.length === 0) {
			return Promise.all(promises);
		}

		// Loading one or more sale will be faster if we can get
		// the market off of one that's already loaded. If only one
		// sale needs to be loaded, use the ID endpoint instead of search.
		let saleForMarket;
		if (idsLoaded.length && idsToLoad.length !== 1) {
			saleForMarket = service.load(idsLoaded[0]);
		} else {
			service.clear(idsToLoad[0]);
			// @ts-expect-error TS2345: Argument of type 'number | und...
			saleForMarket = promises[0] = service.load(idsToLoad.shift());

			if (idsToLoad.length === 0) {
				return Promise.all(promises);
			}
		}

		// The name we need for the market (not the display name) is
		// only in the market service. So we pluck it off
		saleForMarket
			.then(function (sale) {
				return marketService.load().then(function (markets) {
					for (let i = 0; i < markets.length; i++) {
						if (markets[i].id === sale.marketId) {
							return markets[i].name;
						}
					}
					throw new Error(
						'Unable to to loadMany sales because there is no market with display name ' +
							sale.market
					);
				});
			})
			.then(function (marketName) {
				// this performs and ID based search.
				const filters = [
					{ property: 'marketName', value: marketName },
					{ property: 'id', value: idsToLoad },
				];
				const loader = factory.post(
					'/api/salesComps/search',
					{
						filter: filters,
						offset: 0,
						limit: idsToLoad.length,
						order: 'desc',
						sort: 'id',
					},
					true
				);
				loader.then(function (searchResults: any) {
					// @ts-expect-error TS7006: Parameter 'sale' implicitly ha...
					searchResults.comps.forEach(function (sale) {
						// @ts-expect-error TS7053: Element implicitly has an 'any...
						resolves[sale.id](sale);
					});
				});
			});

		return Promise.all(promises);
	});
};

export default service;
