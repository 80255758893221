import Modal from 'Singletons/Modal';
import modalStyles from 'Singletons/Modal/modal.less';

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as stackingPlanActions from '../actions';

import button from 'ui/styles/button.less';

class OverflowingFloorModalComponent extends React.Component {
	constructor() {
		// @ts-expect-error ts-migrate(2554) FIXME: Expected 1-2 arguments, but got 0.
		super();
	}

	undo = () => {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'stackingPlanActions' does not exist on t... Remove this comment to see the full error message
		this.props.stackingPlanActions.undo();
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'modalActions' does not exist on type 'Re... Remove this comment to see the full error message
		this.props.modalActions.hideModal();
	};

	render() {
		return (
			<div className={modalStyles.popupContent}>
				<h1>Overflowing Floor</h1>
				<p className={modalStyles.lg + ' ' + modalStyles.grey}>
					The lease you just added extends beyond the floor plate of floor{' '}
					{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'resultSet' does not exist on type 'Reado... Remove this comment to see the full error message */}
					{this.props.resultSet[0]}. Please confirm that you want to make this
					add or undo the change.
				</p>

				<div className={button.large} onClick={this.undo}>
					Undo
				</div>
				<div
					className={button.large}
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'modalActions' does not exist on type 'Re... Remove this comment to see the full error message
					onClick={this.props.modalActions.hideModal}
				>
					Confirm
				</div>
			</div>
		);
	}
}

// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
function mapDispatchToProps(dispatch) {
	return {
		// @ts-expect-error ts-migrate(2769) FIXME: Type '"UPDATE_STACKING_PLAN"' is not assignable to... Remove this comment to see the full error message
		stackingPlanActions: bindActionCreators(stackingPlanActions, dispatch),
	};
}

const OverflowingFloorModal = connect(
	null,
	mapDispatchToProps
)(OverflowingFloorModalComponent);

const modalId = 'overflowing-floor-modal';

Modal.addComponent({
	id: modalId,
	Component: OverflowingFloorModal,
	className: modalStyles.centeredModal,
});

export default modalId;
