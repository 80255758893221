import { PortfolioItem } from '../types';

export const getUniqueDefinedValuesInPortfolio = (
	portfolio: PortfolioItem[],
	fieldName: string
) => {
	return Array.from(
		new Set(
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			portfolio.filter((p) => p[fieldName] != null).map((p) => p[fieldName])
		)
	);
};
