import { SearchPropertiesAverages } from 'api';
import { AveragesPayload } from 'api/types/averages';
import factory from '../factory';
import { formatAverages } from '../util/formatAverages';

const serviceController = factory.create<
	AveragesPayload,
	SearchPropertiesAverages
>({
	createUrl: function () {
		return '/api/properties/actions/search/averages';
	},
	createData: function (ids) {
		return { ids: ids };
	},
	createKey: function (ids) {
		return JSON.stringify(ids.sort());
	},
	timeout: 1000 * 60 * 10, // 10 minutes
	mappings: {
		display: formatAverages,
	},
});

const exportable = serviceController.exportable({});

export default exportable;
