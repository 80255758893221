import { Button } from '@compstak/ui-kit';
import styled, { css, keyframes } from 'styled-components';
import { CHECKBOX_CELL_SIZE } from './constants';

export const baseCellCss = css`
	display: flex;
	align-items: center;
	padding: 0.75rem 0.625rem;
	font-family: system-ui;
	font-size: 12px;
	background-color: ${(p) => p.theme.colors.white.white};
	color: ${(p) => p.theme.colors.neutral.n300};
`;

export const averagesCellCss = css`
	background-color: ${(p) => p.theme.colors.neutral.n40};
`;

export const CheckboxCell = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${CHECKBOX_CELL_SIZE}px;
	flex-shrink: 0;
`;

export const cellBorders = css`
	border-right: 1px solid ${(p) => p.theme.colors.neutral.n40};
	border-bottom: 1px solid ${(p) => p.theme.colors.neutral.n40};
`;

// cheap way to prevent mouse interactions
export const TableOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	background-color: transparent;
	width: 100%;
	height: 100%;
`;

export const HeaderScrollPadding = styled.div`
	background-color: #fafafa;
	border-right: 1px solid #e9e9e9;
	flex-shrink: 0;
`;

export const Root = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	background-color: #fafafa;
	* {
		box-sizing: border-box;
	}
`;

export const TableSectionContainer = styled.div`
	display: flex;
	align-items: stretch;
	overflow: hidden;
	position: relative;
`;

export const TextOverflow = styled.div`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const SortTriangle = styled.div`
	border-bottom: 4px solid ${(p) => p.theme.colors.gray.gray700};
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
`;

// @ts-expect-error TS7006: Parameter 'p' implicitly has a...
const getCellLoadingAnimation = (p) => keyframes`
	50% {
    background-color:${p.theme.colors.neutral.n20};
  }
`;

export const LoadingIndicator = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${(p) => p.theme.colors.neutral.n30};
	animation: ${(p) => getCellLoadingAnimation(p)} 750ms ease-in-out infinite;
`;

export const ActionButton = styled(Button)`
	width: 100%;
	height: 30px;
	font-size: 12px;
	font-weight: 400;
	gap: 0.5rem;
`;

export const UnlockActionButton = styled(ActionButton)<{ isDisabled: boolean }>`
	${(p) =>
		p.isDisabled &&
		css`
			cursor: not-allowed;
			color: ${p.theme.colors.white.white};
			background-color: ${p.theme.colors.neutral.n40};
			border-color: ${p.theme.colors.neutral.n40};
			&&:hover {
				background-color: ${p.theme.colors.neutral.n40};
				border-color: ${p.theme.colors.neutral.n40};
			}
		`}
`;
