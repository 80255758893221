import { FormatNumberOptions, formatNumber } from './formatNumber';

type Options = Pick<FormatNumberOptions, 'shorten'>;

export const formatInteger = (value: number, options?: Options) => {
	const shorten = options?.shorten ?? true;

	return formatNumber(value, {
		shorten,
		minDecimals: 0,
		maxDecimals: shorten ? 1 : 0,
	});
};
