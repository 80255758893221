import { Pinpoint } from '@compstak/maps';
import Editable from '../Editable';
import Hideable from '../Hideable';
// @ts-expect-error TS2307: Cannot find module './TableRow...
import { RowProps } from './TableRow';

type CellProps = Pick<
	RowProps,
	'editable' | 'hiddenFields' | 'exportActions'
> & {
	field: string;
	value?: string | number;
	index: number;
	pinLabel?: string;
	section: string;
	compId: number;
	hideAddress?: boolean;
};

export const Cell = ({
	editable,
	field,
	value,
	hiddenFields,
	exportActions,
	index,
	pinLabel,
	section,
	compId,
	hideAddress,
}: CellProps) => {
	if (editable) {
		if (hideAddress && field === 'buildingAddress')
			return <div className={'table-cell ' + field} key={index} />;

		return (
			<div className={'table-cell ' + field}>
				{index === 0 && pinLabel ? (
					<div className="letter">
						<Pinpoint text={pinLabel} textScale={0.6} />
					</div>
				) : null}
				{/* @ts-expect-error ts-migrate(2769) FIXME: Property 'hideableKey' does not exist on type 'Int... Remove this comment to see the full error message */}
				<Hideable hideableKey={field} hiddenFields={hiddenFields}>
					{/* @ts-expect-error TS2322: Type '{ children: string | num... */}
					<Editable
						className="data block"
						name={field}
						section={section}
						leaseId={compId}
						updateValue={exportActions.updateCustomExportValue}
						placeholder="&mdash;"
					>
						{value}
					</Editable>
				</Hideable>
			</div>
		);
	} else if (hiddenFields && hiddenFields.includes(field)) {
		return null;
	} else {
		if (hideAddress && field === 'buildingAddress')
			return <div className={'table-cell ' + field} key={index} />;

		return (
			<div className={'table-cell ' + field}>
				{index === 0 && pinLabel ? (
					<div className="letter">
						<Pinpoint text={pinLabel} textScale={0.6} />
					</div>
				) : (
					false
				)}
				<div className="data">{value}</div>
			</div>
		);
	}
};
