import { QUERY_KEYS } from '../constants';
import { createFetchFn, createQueryHook } from '../utils';
import { CompsetResponse, PropertyByIdParams } from './types';

const fetchCompSet = createFetchFn<PropertyByIdParams, CompsetResponse>({
	method: 'get',
	getURL: ({ id }) => `/api/properties/${id}`,
});

export const usePropertyCompsetQuery = createQueryHook({
	queryKey: QUERY_KEYS.PROPERTY_COMPSET,
	fetchFn: fetchCompSet,
	enabled: ({ id }) => id != null,
});
