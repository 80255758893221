// @ts-expect-error TS7016: Could not find a declaration f...
import isMobile from 'is-mobile';
import React, { cloneElement } from 'react';

import CrossIcon from '../../ui/svg_icons/cross.svg';

import { useAppSelector } from 'util/useAppSelector';
import styles from './modal.less';
import { defaultTheme } from '@compstak/ui-kit';
import styled from 'styled-components';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { LegacyNavigateFn } from 'router/migration/types';
import { useHistoryState } from 'router/HistoryProvider';

type Props = {
	headerNotificationShowing?: boolean;
	key?: string;
	close?: (ev?: KeyboardEvent) => void;
	locationPathname?: string;
	className?: string;
	allowHeaderClicks: boolean;
	containerClass?: string;
	hideCloseIcon: boolean;
	style?: React.CSSProperties;
	children: React.ReactElement;
	push?: LegacyNavigateFn;
};

export enum KeyboardButtons {
	ESCAPE = 'Escape',
}

const Modal = (props: Props) => {
	const locationBeforeTransitions = useAppSelector((s) => s.router.location);

	const { LeaseCompUX23Q2 } = useFeatureFlags();
	const showDarkerBackground =
		LeaseCompUX23Q2 && props.locationPathname?.includes('comps/leases');

	const isProperty = React.useMemo(() => {
		return props.locationPathname?.includes('property');
	}, [props.locationPathname]);

	// @ts-expect-error state
	const isFromNewSalePage = !!locationBeforeTransitions?.state?.fromNewSalePage;

	// meaning just close the modal without rerouting
	const shouldGoBack =
		(isProperty || isFromNewSalePage) && locationBeforeTransitions?.key;

	function getContainerClassName() {
		return props.headerNotificationShowing
			? `${styles.componentContainer} ${styles.withHeaderNotification}`
			: styles.componentContainer;
	}

	const { goBack } = useHistoryState();

	const close = props.close !== undefined ? props.close : goBack;

	const [
		preventModalClosingOnClickOutside,
		setPreventModalClosingOnClickOutside,
	] = React.useState(false);

	React.useEffect(() => {
		const handleCloseModal = (ev: KeyboardEvent) => {
			if (ev.key === KeyboardButtons.ESCAPE) {
				if (shouldGoBack) {
					goBack();
				} else {
					close();
				}
			}
		};

		window.addEventListener('keydown', handleCloseModal);

		return () => {
			window.removeEventListener('keydown', handleCloseModal);
		};
	}, [shouldGoBack]);

	const closeIconRef = React.useRef<HTMLDivElement>(null);
	const closeOnCloseButtonClick = (event: HTMLElementEventMap['click']) => {
		const clickedNode = event.target as HTMLElement;

		if (closeIconRef.current && closeIconRef.current.contains(clickedNode)) {
			event.preventDefault();
			if (shouldGoBack) {
				goBack();
			} else {
				close();
			}
			return;
		}

		if (preventModalClosingOnClickOutside) return;

		if (clickedNode.hasAttribute('data-close-on-direct-click')) {
			if (shouldGoBack) {
				goBack();
			} else {
				close();
			}
		}
	};

	const className = `${props.className}` || '';
	const mobileClassName = isMobile() ? styles.mobile : '';

	return (
		<div
			key={'modal'}
			className={getContainerClassName()}
			// @ts-expect-error TS2322: Type '(event: HTMLElementEvent...
			onClick={closeOnCloseButtonClick}
		>
			<div
				className={`${styles.container} ${props.containerClass} ${mobileClassName}`}
			>
				{props.hideCloseIcon ? null : (
					<div className={styles.close} data-close-button ref={closeIconRef}>
						{showDarkerBackground ? (
							<WhiteCrossIcon height="30px" width="30px" data-close-button />
						) : (
							<GreyCrossIcon height="30px" width="30px" data-close-button />
						)}
					</div>
				)}

				<div className={styles.centerModal}>
					<div
						data-close-on-direct-click
						className={styles.scroller}
						style={{
							backgroundColor: `${
								showDarkerBackground
									? '#00000085'
									: ' rgba(226, 231, 243, 0.85)'
							}`,
						}}
					>
						<div
							className={`${styles.modal} ${className}`}
							data-region="modal_body"
							style={props.style}
						>
							{cloneElement(props.children, {
								setPreventModalClosingOnClickOutside,
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Modal;

const GreyCrossIcon = styled(CrossIcon)`
	opacity: 0.6;
	transition: opacity 0.25s ease-in-out;
	fill: hsl(226, 15%, 72%);
	&:hover {
		opacity: 1;
	}
`;

const WhiteCrossIcon = styled(CrossIcon)`
	opacity: 1;
	transition: opacity 0.25s ease-in-out;
	fill: ${defaultTheme.colors.white.white};
	&:hover {
		opacity: 0.8;
	}
`;
