import { ADD_ALERT, REMOVE_ALERT, UPDATE_ALERT, CLEAR_ALERTS } from './actions';

// @ts-expect-error TS7034: Variable 'initialState' implic...
const initialState = [];

// @ts-expect-error TS7005: Variable 'initialState' implic...
export default (state = initialState, action = {}) => {
	// @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type '{}'.
	switch (action.type) {
		case ADD_ALERT:
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'payload' does not exist on type '{}'.
			if (action.payload.id) {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
				state = state.filter((item) => item.id !== action.payload.id);
			}
			// @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
			state = [...state, action.payload];
			break;

		case REMOVE_ALERT:
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'payload' does not exist on type '{}'.
			if (action.payload.id) {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
				state = state.filter((item) => item.id !== action.payload.id);
			}
			break;

		case UPDATE_ALERT:
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
			var index = state.findIndex((item) => item.id === action.payload.id);
			if (index !== -1) {

				state = state.map((item) =>
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
					item.id === action.payload.id ? action.payload : item
				);
			} else {
				// @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
				state = [...state, action.payload];
			}
			break;

		case CLEAR_ALERTS:
			// @ts-expect-error TS7005: Variable 'initialState' implic...
			state = initialState;
			break;
	}
	return state;
};
