import { API } from '@compstak/common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { composeEnabled } from 'api/utils/composeEnabled';
import { QUERY_KEYS } from '../../constants';

export const useClearbitCompanySuggestions = (
	params: UseClearbitCompanySuggestionsParams,
	options?: UseClearbitCompanySuggestionsOptions
) => {
	const { query } = params;
	return useQuery({
		queryKey: [QUERY_KEYS.CLEARBIT_COMPANY_SUGGESTIONS, params],
		queryFn: async () => {
			const response = await API.get<ClearbitCompanySuggestionsResponse>(
				`https://autocomplete.clearbit.com/v1/companies/suggest?query=${encodeURIComponent(
					query
				)}`,
				{
					includeAuthHeader: false,
					credentials: 'omit',
				}
			);

			return response.data;
		},
		...options,
		enabled: composeEnabled(query.length > 2, options?.enabled),
	});
};

type UseClearbitCompanySuggestionsOptions =
	UseQueryOptions<ClearbitCompanySuggestionsResponse>;

type UseClearbitCompanySuggestionsParams = { query: string };

export type ClearbitCompanySuggestion = {
	domain: string;
	logo: string;
	name: string;
};

type ClearbitCompanySuggestionsResponse = ClearbitCompanySuggestion[];
