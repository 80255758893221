import React, { Component } from 'react';

import SubtractIcon from '../../svg_icons/subtract.svg';
import AddIcon from '../../svg_icons/add.svg';

import './hideable.nomodule.css';

class HideableControl extends Component {
	className() {
		let className = 'hideable-control';
		if (
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'hiddenFields' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.hiddenFields &&
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'hiddenFields' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.hiddenFields.indexOf(this.props.target) !== -1
		) {
			className += ' field-hidden';
		}
		return className;
	}

	render() {
		const hidden =
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'hiddenFields' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.hiddenFields &&
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'hiddenFields' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.hiddenFields.indexOf(this.props.target) !== -1;

		return (
			<div className={this.className()}>
				{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'html' does not exist on type 'Readonly<{... Remove this comment to see the full error message */}
				<span className="hideable">{this.props.html}</span>
				{hidden ? (
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'showItem' does not exist on type 'Readon... Remove this comment to see the full error message
					<div onClick={this.props.showItem} className="toggle-item">
						<AddIcon />
					</div>
				) : (
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'hideItem' does not exist on type 'Readon... Remove this comment to see the full error message
					<div onClick={this.props.hideItem} className="toggle-item">
						<SubtractIcon />
					</div>
				)}
			</div>
		);
	}
}

class HideableHeaderControl extends Component {
	className() {
		let className = 'hideable-header-control';
		if (
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'hiddenFields' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.hiddenFields &&
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'hiddenFields' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.hiddenFields.indexOf(this.props.target) !== -1
		) {
			className += '-hidden';
		}
		return className;
	}

	render() {
		const hidden =
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'hiddenFields' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.hiddenFields &&
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'hiddenFields' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.hiddenFields.indexOf(this.props.target) !== -1;

		return (
			<div
				className={this.className()}
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'showItem' does not exist on type 'Readon... Remove this comment to see the full error message
				onClick={hidden ? this.props.showItem : this.props.hideItem}
			>
				{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'html' does not exist on type 'Readonly<{... Remove this comment to see the full error message */}
				<div className="content">{this.props.html}</div>
				<div className="hide">{hidden ? 'SHOW' : 'HIDE'}</div>
			</div>
		);
	}
}

// @ts-expect-error TS7006: Parameter 'WrappedComponent' i...
const WithHandlers = (WrappedComponent) => {
	return class HOC extends Component {
		hideItem = () => {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'hideCustomExportField' does not exist on... Remove this comment to see the full error message
			this.props.hideCustomExportField(this.props.target);
		};

		showItem = () => {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'showCustomExportField' does not exist on... Remove this comment to see the full error message
			this.props.showCustomExportField(this.props.target);
		};

		render() {
			return (
				<WrappedComponent
					showItem={this.showItem}
					hideItem={this.hideItem}
					{...this.props}
				/>
			);
		}
	};
};

const HideableControlWithHandlers = WithHandlers(HideableControl);
export default HideableControlWithHandlers;
export const HideableHeaderControlWithHandlers = WithHandlers(
	HideableHeaderControl
);
