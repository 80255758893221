import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { createFetchFn, useIsMufaProperty } from 'api/utils';

const createFetchKeyMetrics = createFetchFn<
	MufaKeyMetricsParams,
	MufaKeyMetricsResponse
>({
	method: 'get',
	getURL: ({ propertyId }) => `/mufa/properties/${propertyId}/keyMetrics`,
});

export const useMufaKeyMetricsQuery = (
	params: MufaKeyMetricsParams,
	queryConfig?: Omit<UseQueryOptions<MufaKeyMetricsResponse>, 'enabled'>
) => {
	const { data: isMufa } = useIsMufaProperty({
		propertyId: params.propertyId,
	});

	return useQuery({
		queryKey: [QUERY_KEYS.MUFA_KEY_METRICS, params],
		queryFn: createFetchKeyMetrics(params),
		enabled: isMufa,
		...queryConfig,
	});
};

type MufaKeyMetricsParams = {
	propertyId: number;
};

export type MufaKeyMetricsResponse = {
	askingRentPerUnit: number;
	askingRentPerSqFt: number;
	effectiveRentPerUnit: number;
	effectiveRentPerSqFt: number;
	concessionValue: number;
	concessionsPercentage: number;
	unitsWithConcessionsPercentage: number;
	occupancyPercentage: number;
};
