import { QueryClient } from '@tanstack/react-query';
import { ONE_HOUR_MS, ONE_SECOND_MS } from './constants';
import { qcRetryDefault } from './utils/queryRetries';

const retry = qcRetryDefault;

export const QUERY_CLIENT = new QueryClient({
	defaultOptions: {
		queries: {
			cacheTime: ONE_HOUR_MS,
			staleTime: ONE_HOUR_MS,
			retry,
			retryDelay: ONE_SECOND_MS,
		},
		mutations: {
			retry,
			retryDelay: ONE_SECOND_MS,
		},
	},
});
