import SaveSearchMenu from 'Pages/SavedSearches/Components/SaveSearchMenu';

import savedLeaseSearchService, {
	CreateSavedSearchPayload,
} from 'services/savedSearches';

import popupStyles from 'Components/Popup/popup.less';
import pluralizeCompType from '../../util/pluralizeCompType';
import { FiltersObject } from 'models/filters/types';
import { SavedSearch } from 'reducers/savedSearches';
import { User } from '@compstak/common';
import { CompType } from 'types';
import { ReduxPromiseAction } from 'types/redux-promise-middleware';
import { MENU_SHOW } from 'Singletons/Menu/actions';
import { filtersToServerJSON } from 'models/filters/util/serverJson';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';

export const EDITING_SEARCH = 'EDITING_SEARCH' as const;
export const NOT_EDITING_SEARCH = 'NOT_EDITING_SEARCH' as const;
export const DELETING_SEARCH = 'DELETING_SEARCH' as const;
export const NOT_DELETING_SEARCH = 'NOT_DELETING_SEARCH' as const;
export const LOAD_SAVED_SEARCHES = 'LOAD_SAVED_SEARCHES' as const;
export const SAVE_SEARCH = 'SAVE_SEARCH' as const;
export const DUPLICATE_SEARCH = 'DUPLICATE_SEARCH' as const;
export const UPDATE_SAVED_SEARCH = 'UPDATE_SAVED_SEARCH' as const;
export const DELETE_SAVED_SEARCH = 'DELETE_SAVED_SEARCH' as const;
export const SET_CURRENT_SAVED_SEARCH = 'SET_CURRENT_SAVED_SEARCH' as const;
export const CLEAR_CURRENT_SAVED_SEARCH = 'CLEAR_CURRENT_SAVED_SEARCH' as const;

export function setCurrentSavedSearch(savedSearch: SavedSearch) {
	return {
		type: SET_CURRENT_SAVED_SEARCH,
		payload: {
			savedSearch,
		},
	};
}

export function clearCurrentSavedSearch() {
	return {
		type: CLEAR_CURRENT_SAVED_SEARCH,
	};
}

export function loadSavedSearches(user: User) {
	return {
		type: LOAD_SAVED_SEARCHES,
		payload: {
			promise: savedLeaseSearchService.load(user.id),
		},
	};
}

export function saveSearch({
	filters,
	search,
	compType,
	onShare,
}: {
	filters: FiltersObject;
	search: { savedSearchName: string; receiveEmailUpdates: boolean };
	compType: CompType;
	onShare?: (createdSearch: SavedSearch) => void;
}) {
	const searchableFilters = filtersToServerJSON(filters);

	const data: CreateSavedSearchPayload = {
		name: search.savedSearchName,
		notifyByEmail: search.receiveEmailUpdates,
		// marketId field required by BE
		marketId: getFiltersMarkets(filters)[0].id,
		sortAsc: filters.sortDirection === 'asc',
		sortProperty: filters.sortField,
		filters: searchableFilters,
		searchType: pluralizeCompType(compType, false),
	};

	const promise = savedLeaseSearchService.create(data);

	let buttons;
	if (onShare) {
		buttons = [
			{
				action: () => () => {
					promise.then((payload) => {
						const createdSearch = payload.find(
							(s) => s.name === search.savedSearchName
						);
						createdSearch && onShare(createdSearch);
					});
				},
				text: 'SHARE',
			},
		];
	} else {
		buttons = false;
	}

	return {
		type: SAVE_SEARCH,
		meta: {
			feedback: {
				pending: 'Saving ' + data.name,
				fulfilled: {
					message: 'Successfully created ' + data.name,
					buttons,
				},
				// @ts-expect-error TS7006: Parameter 'response' implicitl...
				rejected: function (response) {
					if (response.responseText.includes('Duplicate entry')) {
						return (
							'A saved search with the name "' + data.name + '" Already Exists'
						);
					}
				},
			},
		},
		payload: {
			promise,
		},
	};
}

//TODO ADD ALERTS TO THESE FUNCTIONS
export function updateSavedSearch(
	savedSearch: SavedSearch,
	newValues: Partial<SavedSearch>
) {
	const newSavedSearch = { ...savedSearch, ...newValues };
	return {
		type: UPDATE_SAVED_SEARCH,
		meta: {
			feedback: {
				className: 'center',
				pending: 'Updating ' + newSavedSearch.name,
				fulfilled: 'Successfully updated ' + newSavedSearch.name,
				// @ts-expect-error TS7006: Parameter 'response' implicitl...
				rejected: function (response) {
					if (response.responseText.includes('Duplicate entry')) {
						return (
							'A saved search with the name "' +
							newSavedSearch.name +
							'" already exists'
						);
					} else {
						return false;
					}
				},
			},
		},
		payload: {
			promise: savedLeaseSearchService.update(newSavedSearch),
		},
	};
}

export function deleteSavedSearch(savedSearch: SavedSearch) {
	return {
		type: DELETE_SAVED_SEARCH,
		meta: {
			feedback: {
				className: 'center',
				pending: 'Deleting ' + savedSearch.name,
				fulfilled: 'Successfully deleted ' + savedSearch.name,
				rejected: 'An error occurred, please try again later',
			},
		},
		payload: {
			promise: savedLeaseSearchService.delete(savedSearch),
		},
	};
}

export function showSaveSearchMenu(
	filters: FiltersObject,
	targetButton: EventTarget,
	hasSidebar: boolean,
	compType: CompType
) {
	return {
		type: MENU_SHOW,
		payload: {
			component: SaveSearchMenu,
			target: targetButton,
			position: 'manual',
			className:
				'save-search-menu ' +
				popupStyles.snackbar +
				(hasSidebar ? ' has-sidebar' : ''),
			data: {
				filters,
				compType,
			},
		},
	};
}

export function duplicateSavedSearch(
	savedSearch: SavedSearch,
	changes: Partial<SavedSearch>
) {
	const data: CreateSavedSearchPayload = { ...savedSearch, ...changes };

	const promise = savedLeaseSearchService.create(data);

	return {
		type: DUPLICATE_SEARCH,
		meta: {
			feedback: {
				className: function () {
					return 'center';
				},
				pending: 'Saving ' + data.name,
				fulfilled: {
					message: 'Successfully created ' + data.name,
				},
				// @ts-expect-error TS7006: Parameter 'response' implicitl...
				rejected: function (response) {
					if (response.responseText.includes('Duplicate entry')) {
						return (
							'A saved search with the name "' + data.name + '" Already Exists'
						);
					}
				},
			},
		},
		payload: {
			promise,
		},
	};
}

export type SavedSearchAction =
	| ReturnType<typeof setCurrentSavedSearch>
	| ReturnType<typeof clearCurrentSavedSearch>
	| ReduxPromiseAction<ReturnType<typeof loadSavedSearches>>
	| ReduxPromiseAction<ReturnType<typeof saveSearch>>
	| ReduxPromiseAction<ReturnType<typeof updateSavedSearch>>
	| ReduxPromiseAction<ReturnType<typeof deleteSavedSearch>>
	| ReturnType<typeof showSaveSearchMenu>
	| ReduxPromiseAction<ReturnType<typeof duplicateSavedSearch>>;

export const savedSearchActions = {
	setCurrentSavedSearch,
	clearCurrentSavedSearch,
	loadSavedSearches,
	saveSearch,
	updateSavedSearch,
	deleteSavedSearch,
	showSaveSearchMenu,
	duplicateSavedSearch,
};

export type SavedSearchesActions = typeof savedSearchActions;
