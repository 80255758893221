import { getCompset, useCompsetData } from 'api';
import { uniq } from 'lodash';
import { useMemo } from 'react';

type Props = {
	propertyId: number | undefined;
};

export const useCompsetAddresses = ({ propertyId }: Props) => {
	const { data: compset } = useCompsetData({ propertyId });

	return useMemo(() => {
		if (!compset) return [];

		let addresses = [compset.subjectProperty.buildingAddressAndCity];

		addresses = addresses.concat(
			getCompset(compset).map((p) => {
				return p.property.buildingAddressAndCity;
			})
		);

		return uniq(addresses);
	}, [compset]);
};
