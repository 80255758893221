import factory from './factory';

function createUrl(userId: string) {
	return '/api/users/' + userId + '/features';
}

const serviceController = factory.create({
	createUrl: createUrl,
});

const exportable = serviceController.exportable({});

export default exportable;
