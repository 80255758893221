// Used to get paths of ALL routes typesafely.

import { route } from 'router/route';

const leaseByIdRoute = route<{ id: number }>({
	path: '/comps/leases/:id',
	toHref: ({ id }) => `/comps/leases/${id}`,
	isModal: true,
});

const saleByIdRoute = route<{ id: number }>({
	path: '/comps/sales/:id',
	toHref: ({ id }) => `/comps/sales/${id}`,
});

const exportPdfLeasesRoute = route<{ compIds: number[] }>({
	path: '/export/leases/:compIds',
	toHref: ({ compIds }) => `/export/leases/${compIds.join(',')}`,
	isModal: true,
	data: {
		compType: 'lease',
	},
});

const exportPdfSalesRoute = route<{ compIds: number[] }>({
	path: '/export/sales/:compIds',
	toHref: ({ compIds }) => `/export/sales/${compIds.join(',')}`,
	isModal: true,
	data: {
		compType: 'sale',
	},
});

const stackingPlanRoute = route<{ propertyId: number }>({
	path: '/stacking-plan/:propertyId',
	toHref: ({ propertyId }) => `/stacking-plan/${propertyId}`,
	data: {
		inOverlay: true,
	},
});

// Rendering of routes is separate ( to avoid circular reference in TS ).
export const routes = {
	login: route({
		path: '/login',
	}),
	forgotPassword: route({
		path: '/forgotPassword',
	}),
	resetPassword: route<{ uuid: string }>({
		path: '/resetPassword/:uuid',
		toHref: ({ uuid }) => `/resetPassword/${uuid}`,
	}),
	createAccount: route<{ uuid: string }>({
		path: '/createAccount/:uuid',
		toHref: ({ uuid }) => `/createAccount/${uuid}`,
	}),
	home: route({
		path: '/home',
		data: {
			compType: 'lease',
			isHome: true,
		},
	}),
	searchLeasesHome: route({
		path: '/search/leases/home',
		data: {
			compType: 'lease',
			isHome: true,
		},
	}),
	searchSalesHome: route({
		path: '/search/sales/home',
		data: {
			compType: 'sale',
			isHome: true,
		},
	}),
	searchPropertiesHome: route({
		path: '/search/properties/home',
		data: {
			compType: 'property',
			isHome: true,
		},
	}),
	leaseById: leaseByIdRoute,
	saleById: saleByIdRoute,
	propertyById: route<{ propertyId: number }>({
		path: '/property/:propertyId',
		toHref: ({ propertyId }) => `/property/${propertyId}`,
		data: {
			inOverlay: true,
		},
	}),
	propertyLeaseById: route<{ id: number }>({
		path: '/property/comps/leases/:id',
		toHref: ({ id }) => `/property${leaseByIdRoute.toHref({ id })}`,
		isModal: true,
	}),
	settingsPage: route<{ page: SettingsPage }>({
		path: '/settings/:page',
		toHref: ({ page }) => `/settings/${page}`,
	}),
	searchLeases: route<{ savedSearchId?: string }>({
		path: '/search/leases',
		data: {
			compType: 'lease',
		},
		toHref: ({ savedSearchId }) =>
			`/search/leases${savedSearchId ? `?savedSearchId=${savedSearchId}` : ''}`,
	}),
	searchSales: route<{ savedSearchId?: string }>({
		path: '/search/sales',
		data: {
			compType: 'sale',
		},
		toHref: ({ savedSearchId }) =>
			`/search/sales${savedSearchId ? `?savedSearchId=${savedSearchId}` : ''}`,
	}),
	searchProperties: route<{ savedSearchId?: string }>({
		path: '/search/properties',
		data: {
			compType: 'property',
		},
		toHref: ({ savedSearchId }) =>
			`/search/properties${savedSearchId ? `?savedSearchId=${savedSearchId}` : ''}`,
	}),
	searchLeasesByView: route<{ view: SearchPageView }>({
		path: '/search/leases/:visualization',
		toHref: ({ view: view }) => `/search/leases/${view}`,
		data: {
			compType: 'lease',
			isSearch: true,
		},
	}),
	searchSalesByView: route<{ view: SearchPageView }>({
		path: '/search/sales/:visualization',
		toHref: ({ view }) => `/search/sales/${view}`,
		data: {
			compType: 'sale',
			isSearch: true,
		},
	}),
	searchPropertiesByView: route<{ view: SearchPageView }>({
		path: '/search/properties/:visualization',
		toHref: ({ view }) => `/search/properties/${view}`,
		data: {
			compType: 'property',
			isSearch: true,
		},
	}),
	savedSearches: route({
		path: '/saved-searches',
		isModal: true,
	}),
	propertyByMarketAndAddress: route<{
		marketId: number;
		propertyAddress: string;
	}>({
		path: '/property/market/:marketId/:propertyAddress',
		toHref: ({ marketId, propertyAddress }) =>
			`/property/market/${marketId}/${propertyAddress}`,
		data: {
			inOverlay: true,
		},
	}),
	exportPdfLeases: exportPdfLeasesRoute,
	exportPdfSales: exportPdfSalesRoute,
	propertyExportPdfLeases: route<{ compIds: number[] }>({
		path: '/property/export/leases/:compIds',
		toHref: ({ compIds }) =>
			`/property/${exportPdfLeasesRoute.toHref({ compIds })}}`,
		isModal: true,
		data: {
			compType: 'lease',
		},
	}),
	propertyExportPdfSales: route<{ compIds: number[] }>({
		path: '/property/export/sales/:compIds',
		toHref: ({ compIds }) =>
			`/property/${exportPdfSalesRoute.toHref({ compIds })}}`,
		isModal: true,
		data: {
			compType: 'sale',
		},
	}),
	upload: route({
		path: '/upload',
		data: {
			inOverlay: true,
		},
	}),
	uploadSection: route<{ section: UploadSection }>({
		path: '/upload/:section',
		toHref: ({ section }) => `/upload/${section}`,
		data: {
			inOverlay: true,
		},
	}),
	premierList: route({
		path: '/premierList',
	}),
	analytics: route({
		path: '/analytics',
	}),
	analyticsDashboard: route({
		path: '/analytics/dashboard',
	}),
	analyticsProjects: route({
		path: '/analytics/projects',
	}),
	analyticsProjectById: route<{ projectId: number }>({
		path: '/analytics/projects/:projectId',
		toHref: ({ projectId }) => `/analytics/projects/${projectId}`,
	}),
	analyticsImport: route({
		path: '/analytics/import',
		isModal: true,
	}),
	portfolios: route({
		path: '/portfolios',
		data: {
			compType: 'portfolios',
		},
	}),
	portfolioByIdView: route<{
		portfolioId: number;
		viewType: PortfolioView;
	}>({
		path: '/portfolios/:portfolioId/:viewType',
		toHref: ({ portfolioId, viewType }) =>
			`/portfolios/${portfolioId}/${viewType}`,
		data: {
			compType: 'portfolios',
		},
	}),
	upgrade: route({
		path: '/upgrade',
	}),
	landingpagetoken: route<{ accessToken: string }>({
		path: '/landingpagetoken',
		toHref: ({ accessToken }) => `landingpagetoken?accessToken=${accessToken}`,
	}),
	firstComp: route({
		path: '/comps/leases/firstcomp',
		isModal: true,
		data: {
			order: 1,
		},
	}),
	promotions: route({
		path: '/promotions',
	}),
	promotionsReview: route({
		path: '/promotions/review',
	}),
	credits: route({
		path: '/credits',
	}),
	rewards: route({
		path: '/rewards',
	}),
	survey: route({
		path: '/survey',
	}),
	stackingPlan: stackingPlanRoute,
	stackingPlanByDisplayMode: route<{
		propertyId: number;
		displayMode: StackingPlanDisplayMode;
	}>({
		path: '/stacking-plan/:propertyId/:displayMode',
		toHref: ({ propertyId, displayMode }) =>
			`/${stackingPlanRoute.toHref({ propertyId })}/${displayMode}`,
		data: {
			inOverlay: true,
		},
	}),
} as const;

// convenient option to get routes by compType
export const SEARCH_BY_VIEW_ROUTES = {
	lease: routes.searchLeasesByView,
	leases: routes.searchLeasesByView,
	sale: routes.searchSalesByView,
	sales: routes.searchSalesByView,
	property: routes.searchPropertiesByView,
	properties: routes.searchPropertiesByView,
};

export const SEARCH_ROUTES = {
	lease: routes.searchLeases,
	leases: routes.searchLeases,
	sale: routes.searchSales,
	sales: routes.searchSales,
	property: routes.searchProperties,
	properties: routes.searchProperties,
};

export type RouteKey = keyof typeof routes;

export type SearchPageView = 'map' | 'list' | 'leagueTables';

export type UploadSection = 'lease' | 'sale';

export type PortfolioView = 'map' | 'list' | 'dashboard';

export type SettingsPage = 'account' | 'email' | 'password';

export type StackingPlanDisplayMode = 'directory' | 'plan';
