import * as ReduxHistory from 'redux-first-history';
import { LocationDescriptor } from './types';

// Redux migration stuff lives here

export const navigate = (location: LocationDescriptor, isReplace = false) => {
	if (isReplace) {
		// @ts-expect-error window
		window.store.dispatch(ReduxHistory.replace(location));
	} else {
		// @ts-expect-error window
		window.store.dispatch(ReduxHistory.push(location));
	}
};

export const push: GlobalNavigateFn = (location) => navigate(location);
export const replace: GlobalNavigateFn = (location) => navigate(location, true);
export const goBack = () => {
	// @ts-expect-error window
	window.store.dispatch(ReduxHistory.goBack());
};

export type GlobalNavigateFn = (location: LocationDescriptor) => void;
