import { FiltersObject } from 'models/filters/types';
import { mergeFilters } from 'models/filters/util/mergeFilters';
import { useRef, useState } from 'react';
import { Timer } from 'react-timeout';
import { TableField } from 'types/table';

type OnColumReorderEvent = {
	columnBefore: string;
	columnAfter: string;
	reorderColumn: string;
};

/**
 * If the user hold more than 40ms, we consider that they want to reorder the column, if the value is under 40ms, we consider that the user wants to do sort
 */
const HOLD_DURATION = 40;

export const useTableHeaderCell = () => {
	const [activeReorderColumn, setActiveReorderColumn] = useState('');

	const timerValueRef = useRef(0);
	const timerIntervalRef = useRef<Timer | undefined>();

	const sortByThisField = (
		// @ts-expect-error TS7006: Parameter 'field' implicitly h...
		field,
		filters: FiltersObject,
		onFilterChange?: (filters: Partial<FiltersObject>) => void
	) => {
		if (!field.backendSortName) {
			return;
		}

		let sortDirection = field.defaultSortOrder;
		if (filters.sortField === field.backendSortName) {
			sortDirection = filters.sortDirection === 'asc' ? 'desc' : 'asc';
		}

		if (typeof onFilterChange === 'function') {
			const newFilters = mergeFilters(filters, {
				sortDirection,
				sortField: field.backendSortName,
			});
			onFilterChange(newFilters);
		}
	};

	const startTimer = () => {
		timerValueRef.current = 0;
		timerIntervalRef.current = setInterval(() => {
			timerValueRef.current += 1;
		}, 1);
	};

	const onColumnReorderStart = (columnKey: string) => {
		startTimer();
		setActiveReorderColumn(columnKey);
	};
	const onColumReorderEnd = ({
		event,
		onColumnReorder,
		field,
		filters,
		onFilterChange,
	}: {
		event: OnColumReorderEvent;
		onColumnReorder: (event: OnColumReorderEvent) => void;
		filters: FiltersObject;
		onFilterChange?: (newFilters: Partial<FiltersObject>) => void;
		field: TableField[];
	}) => {
		clearInterval(timerIntervalRef.current);

		timerValueRef.current <= HOLD_DURATION
			? sortByThisField(field, filters, onFilterChange)
			: onColumnReorder(event);
		setActiveReorderColumn('');
	};

	return {
		activeReorderColumn,
		onColumnReorderStart,
		onColumReorderEnd,
	};
};
