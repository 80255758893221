import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { createFetchFn } from '../utils';
import { PropertyLoanParams, PropertyLoanResponse } from './types';

const fetchMufaPropertyLoan = createFetchFn<
	PropertyLoanParams,
	PropertyLoanResponse
>({
	method: 'get',
	getURL: ({ propertyId }) => `/api/mufa/properties/${propertyId}/loan`,
});

export const useMufaPropertyLoanQuery = (
	params: PropertyLoanParams,
	options?: UseQueryOptions<PropertyLoanResponse>
) => {
	return useQuery({
		queryKey: [QUERY_KEYS.MUFA_PROPERTY_LOAN, params],
		queryFn: fetchMufaPropertyLoan(params),
		...options,
	});
};
