import { Market } from '@compstak/common';
import { FiltersObject } from 'models/filters/types';
import { FiltersType } from 'reducers/filtersReducer';
import { AppDispatch, AppGetState } from 'store';
import { CompType } from 'types';

export const FILTERS_INIT = 'FILTERS_INIT' as const;
export const FILTERS_CHANGE = 'FILTERS_CHANGE' as const;
export const FILTERS_RESET = 'FILTERS_RESET' as const;
export const FILTERS_SHOW_UPGRADE_MODAL = 'FILTERS_SHOW_UPGRADE_MODAL' as const;
export const FILTERS_CLOSE_UPGRADE_MODAL =
	'FILTERS_CLOSE_UPGRADE_MODAL' as const;

export function init(initialFilters: Record<FiltersType, FiltersObject>) {
	return {
		type: FILTERS_INIT,
		payload: {
			initialFilters,
		},
	};
}

export function change(context: FiltersType, changes: Partial<FiltersObject>) {
	return {
		type: FILTERS_CHANGE,
		payload: {
			context,
			changes,
		},
	};
}

export function reset(context: FiltersType, defaults?: Partial<FiltersObject>) {
	return {
		type: FILTERS_RESET,
		payload: {
			context,
			defaults,
		},
	};
}

export function swapCache(compType: CompType) {
	return (dispatch: AppDispatch, getState: AppGetState) => {
		const state = getState();
		const newFilters = state.filtersV2.cache[compType];
		dispatch(reset('main', newFilters));
	};
}

export const showUpgradeModal = (
	context: FiltersType,
	nonAccessableMarkets: Market[]
) => {
	return {
		type: FILTERS_SHOW_UPGRADE_MODAL,
		payload: {
			context,
			nonAccessableMarkets,
		},
	};
};

export const closeUpgradeModal = (context: FiltersType) => {
	return {
		type: FILTERS_CLOSE_UPGRADE_MODAL,
		payload: {
			context,
		},
	};
};

export type FilterAction =
	| ReturnType<typeof init>
	| ReturnType<typeof change>
	| ReturnType<typeof reset>
	| ReturnType<typeof showUpgradeModal>
	| ReturnType<typeof closeUpgradeModal>;
