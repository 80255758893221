import {
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query';
import { API, formatRequestString } from '@compstak/common';
import { Metric } from '../../Pages/PropertyPageV2_1/api/interfaces';
import { SpaceTypeName, QUERY_KEYS } from 'api';
import { DistributionMonths } from 'types/propertyTrendCharts';

type DistributionHistogramItem = {
	value: number;
	transactionSizeSum: number;
};

export type DistributionDTO = {
	summaries: [
		{
			annualizedGrowth: number;
			histogram: Array<DistributionHistogramItem>;
			leases: number;
			months: DistributionMonths;
			properties: number;
			transactionSize: number;
		},
	];
};

export interface FetchDistributionApiParams {
	id: number;
	metric: Metric;
	months: DistributionMonths;
	spaceTypes: SpaceTypeName;
}

type QueryConfig = {
	enabled?: boolean;
};

export type UseDistributionByParamsResponse = UseQueryResult<DistributionDTO>;

const fetchDistribution = async ({
	id,
	metric,
	months,
	spaceTypes,
}: FetchDistributionApiParams): Promise<DistributionDTO> => {
	const response = await API.get<DistributionDTO>(
		formatRequestString({
			entityUrl: `/properties/${id}/submarket/distribution/${metric}?months=${months}&spaceTypes=${encodeURIComponent(
				spaceTypes
			)}`,
		})
	);

	return response.data;
};

const useDistributionByParams = (
	params: FetchDistributionApiParams,
	queryConfig?: QueryConfig
): UseDistributionByParamsResponse =>
	useQuery(
		[QUERY_KEYS.PROPERTY_SUBMARKET_DISTRIBUTION, params],
		() => fetchDistribution(params),
		queryConfig
	);

export const useDistributionData = (
	params: FetchDistributionApiParams,
	queryConfig?: Pick<UseQueryOptions, 'enabled'>
): UseDistributionByParamsResponse => {
	const result = useDistributionByParams(params, {
		enabled: Boolean(params.id) && (!queryConfig || queryConfig.enabled),
	});

	return result;
};
