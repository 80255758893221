const neitherNumberNorDot = /[^0-9.]/g;
const commaRegex = /(\d)(?=(\d{3})+(?!\d))/g;

export default function formatNumber(
	value: string | number | string[]
): string {
	if (typeof value === 'string') {
		value = value.replace(neitherNumberNorDot, '');
		value = parseFloat(value);
	}
	if (typeof value !== 'number') {
		throw new Error('Tried to number format a non number');
	}
	// make sure we don't put commas after the decimal point
	value = value.toString().split('.');
	value[0] = value[0].replace(commaRegex, '$1,');
	return value.join('.');
}
