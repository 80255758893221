import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Popup from 'Components/Popup';

import styles from 'Components/Popup/popup.less';

import * as actions from './actions';
import { Position } from 'Singletons/Tooltip';
import { MenuComponentNames } from './types';

const componentMap = {};

interface MenuProps {
	className?: string;
	component?: MenuComponentNames;
	clickOutsideToClose: boolean;
	data?: { onClose?: NoArgCallback };
	closeMenu: (componentName?: MenuComponentNames) => void;
	onTargetLeaveCallback?: NoArgCallback;
	position: Position;
	config: {
		className?: string;
		timeout?: number;
		offsetTop?: number;
	};
	target: Element;
	id: string;
}

class Menu extends React.Component<MenuProps> {
	static defaultProps = {
		clickOutsideToClose: true,
	};

	static addComponent = (
		stringName: MenuComponentNames,
		Component: React.ComponentType<any | string>,
		config = {}
	) => {
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		if (componentMap[stringName]) {
			throw new Error('Tried to register ' + stringName + ' component twice!');
		}

		// @ts-expect-error TS7053: Element implicitly has an 'any...
		componentMap[stringName] = {
			Component,
			config,
		};
	};

	UNSAFE_componentWillReceiveProps(newProps: MenuProps) {
		if (
			this.props.component !== newProps.component &&
			this.props.data &&
			this.props.data.onClose
		) {
			this.props.data.onClose();
		}
	}

	handleOnTargetLeave = () => {
		if (this.props.onTargetLeaveCallback) {
			this.props.onTargetLeaveCallback();
		} else {
			this.props.closeMenu(this.props.component);
		}
	};

	createMask() {
		if (this.props.clickOutsideToClose) {
			return (
				<div
					className={styles.mask}
					onClick={() => this.props.closeMenu(this.props.component)}
				/>
			);
		} else {
			return false;
		}
	}

	className() {
		return this.props.className ? this.props.className : '';
	}

	renderMenu() {
		return (
			<div className={styles.container + ' ' + this.className()}>
				{this.createMask()}
				{/* @ts-expect-error TS2769: No overload matches this call.... */}
				<Popup
					position={this.props.position}
					target={this.props.target}
					onTargetLeave={this.handleOnTargetLeave}
					data-mr-menu
					offsetTop={this.props.config.offsetTop}
					className={
						this.props.className + ' ' + (this.props.config.className || '')
					}
					timeout={this.props.config.timeout || 4000}
				>
					{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'Component' does not exist on type 'Reado... Remove this comment to see the full error message */}
					<this.props.Component {...this.props.data} {...this.props} />
				</Popup>
			</div>
		);
	}

	render() {
		if (!this.props.component) {
			return <span data-mr-menu />;
		}

		return (
			<TransitionGroup>
				<CSSTransition
					key={this.props.id}
					classNames="menu"
					timeout={350}
					appear
				>
					{this.renderMenu()}
				</CSSTransition>
			</TransitionGroup>
		);
	}
}

// @ts-expect-error TS7006: Parameter 'store' implicitly h...
function mapStoreToProps(store) {
	const props = store.menu;

	if (store.menu.component) {
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		if (!componentMap[props.component]) {
			throw new Error('Tried to show an unknown menu: ' + props.component);
		}

		return {
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			Component: componentMap[props.component].Component,
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			config: componentMap[props.component].config,
			...props,
		};
	}
	return props;
}

// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
function mapDispatchToProps(dispatch) {
	return {
		closeMenu: (componentName?: MenuComponentNames) =>
			dispatch(actions.hideMenu(componentName)),
	};
}

export default connect(mapStoreToProps, mapDispatchToProps)(Menu);
