import { useUserQuery } from 'api';
import { FeatureFlags } from 'api/featureFlags/types';
import { useFeatureFlagsQuery } from 'api/featureFlags/useFeatureFlagsQuery';

export const useFeatureFlags = (): FeatureFlags => {
	const { data: user } = useUserQuery();
	const { data } = useFeatureFlagsQuery(user, { enabled: !!user });

	return data;
};
