import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { UseNavHeaderContext } from './types';

/**
 * Handles the routing part of NavHeader and uses the nav data to do so
 */
export const useNavHeaderNavigation = <Item, Args>(
	useNavHeaderContext: UseNavHeaderContext<Item, Args>
) => {
	const { navData, getItemPath } = useNavHeaderContext();
	const { currentItem, requestPrevItem, requestNextItem } = navData;
	const navigate = useNavigate();
	const location = useLocation();

	const prevPath = useRef<string>();

	useEffect(() => {
		if (!currentItem) return;
		const newPath = getItemPath(currentItem);
		if (prevPath.current === newPath) {
			return;
		}
		prevPath.current = newPath;

		navigate(newPath, {
			replace: true,
			state: location.state,
		});
	}, [currentItem, getItemPath, navigate, location.state]);

	const goToPrevItem = requestPrevItem;
	const goToNextItem = requestNextItem;

	const goToList = () => {
		navigate(-1);
	};

	useEffect(() => {
		const handleKeyNavigation = (ev: KeyboardEvent) => {
			if (ev.key === 'ArrowLeft') goToPrevItem();
			if (ev.key === 'ArrowRight') goToNextItem();
		};
		window.addEventListener('keydown', handleKeyNavigation);
		return () => {
			window.removeEventListener('keydown', handleKeyNavigation);
		};
	}, [goToPrevItem, goToNextItem]);

	return {
		...navData,
		goToPrevItem,
		goToNextItem,
		goToList,
	};
};
