export type APIRequestParams<T> = {
	includeMetadata?: boolean;
} & T;

export type APIListRequestParams<T = {}> = {
	offset?: number;
	limit?: number;
} & T;

export type APIListResponse<T> = {
	count: number;
	next?: string;
	previous?: string;
} & T;

export type APIDateRequestParams = {
	/** YYYY-MM-DD */
	fromDate: string;

	/** YYYY-MM-DD */
	toDate: string;
};

export type Timeframe = 'YoY' | 'QoQ' | 'MoM';

//TODO: neex extract this type after @compstak/common types is ready
export enum Timeframes {
	YoY = 'YoY',
	QoQ = 'QoQ',
	MoM = 'MoM',
}

const metricListItems = [
	{ title: 'Starting Rent', value: 'startingRent' },
	{ title: 'Asking Rent $/SF', value: 'askingRentPerSqFt' },
	{ title: 'Effective Rent', value: 'effectiveRent' },
	{ title: 'Effective Rent $/SF', value: 'effectiveRentPerSqFt' },
	{ title: 'Free Months', value: 'freeMonths' },
	{ title: 'TI', value: 'ti' },
	{ title: 'Lease Term', value: 'leaseTerm' },
	{ title: 'Transaction Size', value: 'transactionSize' },
	{ title: 'Total Units', value: 'totalUnits' },
	{ title: 'New Supply', value: 'newSupply' },
	{ title: 'Units Under Construction', value: 'unitsUnderConstruction' },
] as const;

export type MetricListItem = (typeof metricListItems)[number];

export type Metric =
	| 'startingRent'
	| 'askingRentPerSqFt'
	| 'effectiveRent'
	| 'effectiveRentPerSqFt'
	| 'freeMonths'
	| 'ti'
	| 'leaseTerm'
	| 'transactionSize'
	| 'totalUnits'
	| 'newSupply'
	| 'unitsUnderConstruction';

const granularityListItems = [
	{ title: 'Annual', value: 'annual' },
	{ title: 'Quarterly', value: 'quarterly' },
	{ title: 'Monthly', value: 'monthly' },
] as const;

export type GranularityListItems = (typeof granularityListItems)[number];

export type TimeGranularity = 'annual' | 'quarterly' | 'monthly';

//TODO: neex extract this type after @compstak/common types is ready
export type TrendLineItem = {
	date: string;
	value?: number;
};

export type RequestStates = {
	isSuccess: boolean;
	isLoading: boolean;
	isError: boolean;
	error: unknown;
};

export type Indicator = {
	trendLine: Array<TrendLineItem>;
	name?: string;
};

export type DistributionMonths = 12 | 36 | 60 | 120 | 180;

/*
 * This is not the actual or real property type what you can see as a BE response in the overview/header section.
 * There is no agreement at BE side about how it should look properly and which values it should have in the future.
 * But definitely, there is a plan to change it.
 * At the moment, exactly these values regulates "existing of RealPage (data provider could be changed in the future) data" for the property.
 */
export enum PropertyMufaDataProviderType {
	NO_PROVIDER = 'commercial',
	HAS_PROVIDER_WITH_LEASES = 'mixedUse',
	HAS_PROVIDER = 'multifamily',
}

export enum DataProviderAccess {
	NO_ACCESS = 'NO_ACCESS',
	ACCESS_WITH_DATA = 'WITH_DATA',
	ACCESS_WITH_DATA_AND_LEASES = 'ACCESS_WITH_DATA_AND_LEASES',
}

export enum RentPeriod {
	MONTHLY = 'monthly',
	ANNUAL = 'annual',
}
