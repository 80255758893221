import { getCompset, useCompsetData } from 'api';
import { useMemo } from 'react';

type Props = {
	propertyId: number;
};

/** Splits compset into subjectProperty and ONLY compset properties */
export const useSplitCompset = ({ propertyId }: Props) => {
	const { data, isFetching, isLoading } = useCompsetData({ propertyId });

	return {
		data: useMemo(() => {
			if (!data) return;

			const compset = getCompset(data);

			const compsetWithoutSubjectProperty = compset.filter(
				(p) => p.property.id !== data.subjectProperty.id
			);

			return {
				subjectProperty: data.subjectProperty,
				compset: compsetWithoutSubjectProperty,
			};
		}, [data]),
		isFetching,
		isLoading,
	};
};
