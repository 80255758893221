import { useQueryClient } from '@tanstack/react-query';
import { ExportActions } from 'actions/export';
import { CompType } from 'types/comp';

interface Props {
	exportActions: Pick<ExportActions, 'exportCustomData'>;
	exportData: any;
	showLineItemComps: boolean;
	toggleShowLineItemComps: NoArgCallback;
	showIndividualComps: boolean;
	toggleShowIndivudualComps: NoArgCallback;
	compType: CompType;
	onComplete: NoArgCallback;
	title: string;
}
export const ExportPDFToolbar = (props: Props) => {
	const queryClient = useQueryClient();

	const exportPDF = () => {
		props.exportActions.exportCustomData(
			props.exportData,
			props.showLineItemComps,
			props.showIndividualComps,
			props.compType,
			queryClient
		);
		props.onComplete();
	};

	return (
		<div className="export-pdf-toolbar">
			<div className="export-pdf-controls">
				<div className="title">
					<h2 className="export-pdf-title">{props.title}</h2>
				</div>

				<div className="controls">
					<label className="control-label">Include in Report</label>
					<div className="control">
						<input
							data-qa-id="export-pdf-toggleShowLineItemComps"
							type="checkbox"
							className="checkbox"
							id="line-item-summary"
							checked={props.showLineItemComps}
							onChange={props.toggleShowLineItemComps.bind(this)}
						/>
						<label htmlFor="line-item-summary" className="checkbox">
							Cover Page
						</label>
					</div>
					<div className="control">
						<input
							data-qa-id="export-pdf-toggleShowIndivudualComps"
							type="checkbox"
							className="checkbox"
							id="individual-comps"
							checked={props.showIndividualComps}
							onChange={props.toggleShowIndivudualComps.bind(this)}
						/>
						<label htmlFor="individual-comps" className="checkbox">
							Individual Comps
						</label>
					</div>
					<div onClick={exportPDF} className="button_button button_blue">
						Export PDF
					</div>
				</div>
			</div>
		</div>
	);
};
