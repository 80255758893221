import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { usePermissions } from 'Pages/Login/reducers';
import { QUERY_KEYS } from '../constants';
import { useUserQuery } from '../user';
import { composeEnabled, createFetchFn } from '../utils';

const fetchMarketsFeatures = createFetchFn<
	MarketsFeaturesParams,
	MarketsFeaturesResponse
>({
	method: 'get',
	getURL: ({ userId }) => `/api/users/${userId}/features`,
});

export const useMarketsFeaturesQuery = (
	options?: UseQueryOptions<MarketsFeaturesResponse>
) => {
	const { data: user } = useUserQuery();
	const permissions = usePermissions();

	const params = {
		userId: user.id,
	};

	const { data, ...result } = useQuery({
		queryKey: [QUERY_KEYS.MARKETS_FEATURES, params],
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		queryFn: async (ctx) => fetchMarketsFeatures(params!)(ctx),
		...options,
		enabled: composeEnabled(!!params, options?.enabled),
		initialData: permissions as MarketsFeaturesResponse,
	});

	return {
		...result,
		data: data!,
	};
};

type MarketsFeaturesParams = {
	userId: number;
};

export type MarketsFeaturesResponse = {
	[marketId: number]: MarketFeatures;
};

export type MarketFeatures = {
	adminFilters: boolean;
	analytics: boolean;
	editableCompset: boolean;
	exports: boolean;
	extendedPropertyPage: boolean;
	insights: boolean;
	leaseAccess: boolean;
	multifamilyAccess: boolean;
	ownsAllLeaseComps: boolean;
	ownsAllSalesComps: boolean;
	salesAccess: boolean;
	premierList: boolean;
};

export type MarketFeatureKey = keyof MarketFeatures;
