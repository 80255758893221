import { QUERY_KEYS } from 'api/constants';
import { createFetchFn, createQueryHook } from '../utils';
import {
	SearchMufaPropertiesPayload,
	SearchMufaPropertiesResponse,
} from './types';

const fetchMufaProperties = createFetchFn<
	SearchMufaPropertiesPayload,
	SearchMufaPropertiesResponse
>({
	method: 'post',
	getURL: () => `/api/mufa/properties/actions/search`,
	getBody: (params) => params,
});

export const useSearchMufaPropertiesQuery = createQueryHook({
	queryKey: QUERY_KEYS.SEARCH_MUFA_PROPERTIES,
	fetchFn: fetchMufaProperties,
});
