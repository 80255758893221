import { MarketsState } from 'Pages/Login/reducers';
import { useMarketsQuery } from 'api';

export const useMarkets = (): MarketsState => {
	const { data } = useMarketsQuery();

	// @ts-expect-error Property 'list' is incompatible with index signature.
	return data.reduce(
		(acc, market) => {
			// @ts-expect-error Property 'list' is incompatible with index signature.
			acc[market.id] = market;
			// @ts-expect-error Property 'list' is incompatible with index signature.
			acc[market.name] = market;
			// @ts-expect-error Property 'list' is incompatible with index signature.
			acc[market.displayName] = market;
			return acc;
		},
		{ list: data }
	);
};
