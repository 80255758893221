import { API } from '@compstak/common';
import { useQuery } from '@tanstack/react-query';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { z } from 'zod';
import { CompType } from '../../types';
import { QUERY_KEYS } from '../constants';
import { filterColumnPreferences } from 'Components/SearchTable/filterColumnPreferences';

const ColumnPreference = z.object({
	compAttributeId: z.number(),
});

const ColumnPreset = z.array(ColumnPreference);

const AllPresetsResponse = z.record(ColumnPreset);

export type AllPresetsResponse = z.infer<typeof AllPresetsResponse>;

export function useAllPresetsQuery(compType: CompType) {
	const featureFlags = useFeatureFlags();

	return useQuery({
		queryKey: [QUERY_KEYS.ALL_COLUMN_PRESETS_QUERY_KEY, compType],
		queryFn: async (): Promise<AllPresetsResponse> => {
			const response = await API.get<AllPresetsResponse>(
				`/api/preferences/presets/${compType === 'sale' ? 'sales' : compType}`
			);

			const data = response.data;
			AllPresetsResponse.parse(data);

			// We need to filter out "hidden" fields.
			// There are troubles on BE side, that's why it's safer to filter it out here.
			for (const presetName in data) {
				data[presetName] = filterColumnPreferences(
					data[presetName],
					featureFlags
				);
			}

			// filter out unnecessary presets
			const excludePresets = ['all'];

			if (compType === 'property') {
				excludePresets.push('summary');
			}

			for (const presetName of excludePresets) {
				delete data[presetName];
			}

			return data;
		},
	});
}
