import { connect } from 'react-redux';
import actionWrapper from 'util/actionWrapper';

import * as exportActions from 'actions/export';
import * as modalActions from 'Singletons/Modal/actions';

import ExportPDFComponenent from './Components/ExportPDF';

import './styles/exportPDFModal.nomodule.less';
import { AppState } from 'reducers/root';
import { AppDispatch } from 'store';
import { push } from 'router';

export type ExportPDFConnectedProps = ReturnType<typeof mapStoreToProps> &
	ReturnType<typeof mapDispatchToProps>;

function mapStoreToProps(store: AppState) {
	return {
		user: store.user,
		export: store.export,
		appConfig: store.appConfig,
	};
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
	return {
		push,
		...actionWrapper(
			{
				exportActions,
				modalActions,
			},
			dispatch
		),
	};
};

const ExportPDF = connect(
	mapStoreToProps,
	mapDispatchToProps
)(ExportPDFComponenent);

export default ExportPDF;
