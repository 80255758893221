import React from 'react';
import { connect } from 'react-redux';

import Modal from 'Singletons/Modal';

import actionWrapper from 'util/actionWrapper';
import * as userActions from 'actions/user';

import modalStyles from 'Singletons/Modal/modal.less';
import button from 'ui/styles/button.less';

type State = any;

class ExportLimitModalComponent extends React.Component<{}, State> {
	constructor() {
		// @ts-expect-error ts-migrate(2554) FIXME: Expected 1-2 arguments, but got 0.
		super();
		this.state = {
			requestSent: false,
		};
	}

	requestDemo() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'userActions' does not exist on type 'Rea... Remove this comment to see the full error message
		this.props.userActions.requestDemo(
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
			this.props.user.username,
			'export',
			null
		);
		this.setState({
			requestSent: true,
		});
	}

	render() {
		let requestButton;
		if (this.state.requestSent) {
			requestButton = (
				<div className={button.disabled}>
					{"Thanks! We'll contact you shortly"}
				</div>
			);
		} else {
			requestButton = (
				<div className={button.large} onClick={this.requestDemo.bind(this)}>
					Get more exports
				</div>
			);
		}

		return (
			<div className={modalStyles.popupContent}>
				<h1>Comp Exports Exceeded</h1>
				<p className={modalStyles.lg + ' ' + modalStyles.grey}>
					During your trial, you have a limited number of exports.
				</p>

				<div>{requestButton}</div>
				<a
					href="#"
					className={modalStyles.negativeCta}
					data-close-button="true"
					// @ts-expect-error ts-migrate(2339) FIXME: Property 'closeModal' does not exist on type 'Read... Remove this comment to see the full error message
					onClick={this.props.closeModal}
				>
					I don't need to export more comps.
				</a>
			</div>
		);
	}
}

// @ts-expect-error TS7006: Parameter 'store' implicitly h...
function mapStoreToProps(store) {
	return {
		user: store.user,
	};
}

// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
function mapDispatchToProps(dispatch) {
	return actionWrapper(
		{
			userActions,
		},
		dispatch
	);
}

export const ExportLimitModal = connect(
	mapStoreToProps,
	mapDispatchToProps
)(ExportLimitModalComponent);

Modal.addComponent({
	id: 'export-limit-exceeded',
	Component: ExportLimitModal,
	className: modalStyles.centeredModal,
});

export default 'export-limit-exceeded';
