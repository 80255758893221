import { API, Preferences } from '@compstak/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { useUserQuery } from '../user';

const applyPreset = async ({ userId, property, preset }: ApplyPresetParams) => {
	const response = await API.put<Preferences>(
		`/api/preferences/${userId}/applyPreset`,
		{
			property,
			preset,
		}
	);
	return response.data;
};

export const useApplyPresetMutation = () => {
	const { data: user } = useUserQuery();

	const queryClient = useQueryClient();

	return useMutation<Preferences, unknown, ApplyPresetVariables>({
		mutationFn: ({ property, preset }) =>
			applyPreset({ userId: user.id, property, preset }),
		onSuccess: (preferences) => {
			queryClient.setQueryData([QUERY_KEYS.USER], (prevUser) => {
				if (!prevUser) return;
				return {
					...prevUser,
					preferences,
				};
			});
			queryClient.invalidateQueries([QUERY_KEYS.USER]);
		},
	});
};

type ApplyPresetVariables = {
	property: keyof Pick<
		Preferences,
		'columnPreferences' | 'salesColumnPreferences' | 'propertyColumnPreferences'
	>;
	preset: string;
};

type ApplyPresetParams = ApplyPresetVariables & {
	userId: number;
};
