import { CompatibleFilter } from 'types/serverFilters';
import { LeaseFilters } from './lease';
import { MufaPropertyFilters } from './mufaProperty';
import { PropertyFilters } from './property';
import { SaleFilters } from './sales';
import { mergeFilters } from './util/mergeFilters';

import {
	FiltersObject,
	leasesFilters,
	mufaFilters,
	propertiesFilters,
	salesFilters,
} from './types';

export type CreateFiltersArg = keyof CreateFiltersArgToFilters;

export const createFilters = <T extends CreateFiltersArg>(
	type: T,
	properties: Partial<CreateFiltersArgToFilters[T] & CompatibleFilter> = {}
): FiltersObject => {
	let filters: FiltersObject | undefined;
	switch (type) {
		case 'lease':
		case 'leases':
			// @ts-expect-error TS2740: Type '{ portfolioId: number | ...
			filters = { ...leasesFilters };
			break;
		case 'property':
		case 'properties':
			// @ts-expect-error TS2740: Type '{ portfolioId: number | ...
			filters = { ...propertiesFilters };
			break;

		case 'sale':
		case 'sales':
			// @ts-expect-error TS2740: Type '{ portfolioId: number | ...
			filters = { ...salesFilters };
			break;
		case 'mufa': {
			// @ts-expect-error TS2740: Type '{ portfolioId: number | ...
			filters = { ...mufaFilters };
			break;
		}
	}

	if (!filters) {
		throw new Error(`Unexpected filters creation via type: ${type}`);
	}

	return mergeFilters(filters, properties);
};

type CreateFiltersArgToFilters = {
	lease: LeaseFilters;
	leases: LeaseFilters;
	sale: SaleFilters;
	sales: SaleFilters;
	property: PropertyFilters;
	properties: PropertyFilters;
	mufa: MufaPropertyFilters;
};
