import { API, Preferences, User } from '@compstak/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { useUserQuery } from '../user';

const updatePreferences = async ({
	userId,
	preferences,
}: UpdatePreferencesParams) => {
	const response = await API.put<Preferences>(
		`/api/preferences/${userId}`,
		preferences
	);
	return response.data;
};

export const useUpdatePreferencesMutation = () => {
	const { data: user } = useUserQuery();
	const queryClient = useQueryClient();

	return useMutation<Preferences, unknown, UpdatePreferencesVariables>({
		mutationFn: (preferences) =>
			updatePreferences({
				userId: user.id,
				preferences: {
					...user.preferences,
					...preferences,
				},
			}),
		onSuccess: (newPreferences) => {
			queryClient.setQueryData<User>([QUERY_KEYS.USER], (prevUser) => {
				if (!prevUser) return;
				return {
					...prevUser,
					preferences: newPreferences,
				};
			});
			queryClient.invalidateQueries([QUERY_KEYS.USER]);
		},
	});
};

type UpdatePreferencesParams = {
	preferences: Preferences;
	userId: number;
	signal?: AbortSignal;
};

type UpdatePreferencesVariables = Partial<Preferences>;
