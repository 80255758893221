// @ts-expect-error TS7006: Parameter 'pattern' implicitly...
function _cloneRegExp(pattern) {
	return new RegExp(
		pattern.source,
		(pattern.global ? 'g' : '') +
			(pattern.ignoreCase ? 'i' : '') +
			(pattern.multiline ? 'm' : '') +
			(pattern.sticky ? 'y' : '') +
			(pattern.unicode ? 'u' : '')
	);
}

// @ts-expect-error TS7006: Parameter 'val' implicitly has...
function type(val) {
	return val === null
		? 'Null'
		: val === undefined
		? 'Undefined'
		: Object.prototype.toString.call(val).slice(8, -1);
}

// @ts-expect-error TS7006: Parameter 'value' implicitly h...
function _clone(value, refFrom, refTo) {
	// @ts-expect-error TS7006: Parameter 'copiedValue' implic...
	const copy = function copy(copiedValue) {
		const len = refFrom.length;
		let idx = 0;
		while (idx < len) {
			if (value === refFrom[idx]) {
				return refTo[idx];
			}
			idx += 1;
		}
		refFrom[idx + 1] = value;
		refTo[idx + 1] = copiedValue;
		for (const key in value) {
			copiedValue[key] = _clone(value[key], refFrom, refTo);
		}
		return copiedValue;
	};
	switch (type(value)) {
		case 'Object':
			return copy({});
		case 'Array':
			return copy([]);
		case 'Date':
			return new Date(value);
		case 'RegExp':
			return _cloneRegExp(value);
		default:
			return value;
	}
}

// @ts-expect-error TS7006: Parameter 'value' implicitly h...
export function clone(value) {
	return value != null && typeof value.clone === 'function'
		? value.clone()
		: _clone(value, [], []);
}
