import { UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { TenantIndustryDataParams, TenantIndustryResponse } from '../types';
import { createFetchFn, useQueryHook } from '../utils';

const fetchTenantIndustryData = createFetchFn<
	TenantIndustryDataParams,
	TenantIndustryResponse
>({
	method: 'post',
	getURL: () => `/api/comps/analytics/tenantIndustry`,
	getBody: (params) => params,
});

export const useTenantIndustryAnalyticsQuery = (
	params: TenantIndustryDataParams,
	options?: UseQueryOptions<TenantIndustryResponse>
) =>
	useQueryHook({
		queryKey: [QUERY_KEYS.TENANT_INDUSTRY_ANALYTICS, params],
		queryFn: fetchTenantIndustryData(params),
		...options,
	});
