export const SHOW_HEADER_NOTIFICATION = 'SHOW_HEADER_NOTIFICATION' as const;
export const HIDE_HEADER_NOTIFICATION = 'HIDE_HEADER_NOTIFICATION' as const;

export function showHeaderNotification() {
	return {
		type: SHOW_HEADER_NOTIFICATION,
	};
}

export function hideHeaderNotification() {
	return {
		type: HIDE_HEADER_NOTIFICATION,
	};
}

export type HeaderNotficationAction =
	| ReturnType<typeof showHeaderNotification>
	| ReturnType<typeof hideHeaderNotification>;
