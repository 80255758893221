export type TooltipState = {
	target: Element | null;
	text: string | null;
	position: string | null;
	className: string | null;
	offsetTop: number | null;
};

export const initialState: TooltipState = {
	target: null,
	text: null,
	position: null,
	className: null,
	offsetTop: null,
};

export default (state = initialState, action = {}): TooltipState => {
	// @ts-expect-error TS2339: Property 'type' does not exist...
	switch (action.type) {
		case 'TOOLTIP_SHOW':
			return {
				...state,
				// @ts-expect-error TS2339: Property 'payload' does not ex...
				...action.payload,
			};

		//case 'TOOLTIP_HIDE':
		//case '@@router/CALL_HISTORY_METHOD':
		default:
			return initialState;
	}
};
