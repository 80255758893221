import { SearchSalesResponse } from 'api';
import averagesService from './averages/saleAverages';
import factory from './factory';
import saleService from './sale';

const searchService = {};

// @ts-expect-error ts-migrate(2339) FIXME: Property 'create' does not exist on type '{}'.
searchService.create = function (
	// @ts-expect-error TS7006: Parameter 'filters' implicitly...
	filters,
	// @ts-expect-error TS7006: Parameter 'initialNumber' impl...
	initialNumber,
	// @ts-expect-error TS7006: Parameter 'order' implicitly h...
	order,
	// @ts-expect-error TS7006: Parameter 'sortField' implicit...
	sortField,
	// @ts-expect-error TS7006: Parameter 'suggestionId' impli...
	suggestionId
) {
	order = order || 'desc';
	sortField = sortField || '-saleDate';

	const results = {};
	const saleIdPromises = {};
	let isFirstLoad = true;

	// @ts-expect-error TS7006: Parameter 'loader' implicitly ...
	function createSaleIdPromise(loader, loadStart, i) {
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		saleIdPromises[i + loadStart] = new Promise(function (resolve, reject) {
			loader.then(
				// @ts-expect-error TS7006: Parameter 'data' implicitly ha...
				function (data) {
					if (i >= data.comps.length) {
						// @ts-expect-error TS7053: Element implicitly has an 'any...
						delete saleIdPromises[i + loadStart]; // fewer than initalNumber of first fetch
						// @ts-expect-error TS2794: Expected 1 arguments, but got ...
						resolve();
					} else {
						const rawSale = data.comps[i];
						saleService.add(rawSale.id, rawSale);
						resolve(rawSale.id);
					}
				},
				function () {
					// @ts-expect-error TS7053: Element implicitly has an 'any...
					delete saleIdPromises[i + loadStart];
					reject();
				}
			);
		});
	}

	// @ts-expect-error TS7006: Parameter 'start' implicitly h...
	function loadRange(start, end) {
		let loader;

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'total' does not exist on type '{}'.
		if (!isFirstLoad && end > results.total) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'total' does not exist on type '{}'.
			end = results.total;
		}

		let loadStart = start;
		let loadEnd = end;

		// @ts-expect-error TS7053: Element implicitly has an 'any...
		while (saleIdPromises[loadStart + 1] && loadStart < loadEnd) {
			loadStart += 1;
		}

		// @ts-expect-error TS7053: Element implicitly has an 'any...
		while (saleIdPromises[loadEnd - 1] && loadStart < loadEnd) {
			loadEnd -= 1;
		}

		const loadTotal = loadEnd - loadStart;
		const suggestionIdParam = suggestionId
			? `?suggestionId=${suggestionId}`
			: '';

		if (loadTotal > 0 || isFirstLoad) {
			loader = factory.post(
				`/api/salesComps/search${suggestionIdParam}`,
				{
					filter: filters,
					offset: loadStart,
					limit: loadTotal,
					order,
					sort: sortField,
				},
				true
			);

			for (let i = 0; i < loadTotal; i += 1) {
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				if (!saleIdPromises[i + loadStart]) {
					createSaleIdPromise(loader, loadStart, i);
				}
			}
		}

		const promises = [];
		for (let i = start; i < end; i += 1) {
			// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
			promises.push(saleIdPromises[i]);
		}

		if (isFirstLoad) {
			isFirstLoad = false;
			// @ts-expect-error TS18048: 'loader' is possibly 'undefine...
			return loader.then(function (data: SearchSalesResponse) {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'averages' does not exist on type '{}'.
				results.averages = averagesService.of(data.averages || {}); // comes back as null if zero results
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'aggregations' does not exist on type '{}... Remove this comment to see the full error message
				results.aggregations = data.aggregations;
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'total' does not exist on type '{}'.
				results.total = data.totalCount;
				// @ts-expect-error TS2339: Property 'sales' does not exis...
				results.sales = data.comps;
				return results;
			});
		} else {
			return promises;
		}
	}

	// @ts-expect-error TS7006: Parameter 'page' implicitly ha...
	function loadPage(page, numberPerPage) {
		numberPerPage = Math.max(numberPerPage || 0, 1);
		const start = page * numberPerPage;
		const end = start + numberPerPage;
		return loadRange(start, end);
	}

	// @ts-expect-error ts-migrate(2339) FIXME: Property 'load' does not exist on type '{}'.
	results.load = function (page, numberPerPage) {
		// @ts-expect-error TS2345: Argument of type 'any[] | Prom...
		return saleService.loadMany(loadPage(page, numberPerPage));
	};

	// @ts-expect-error ts-migrate(2339) FIXME: Property 'loadRange' does not exist on type '{}'.
	results.loadRange = function (start, end) {
		// @ts-expect-error TS2345: Argument of type 'any[] | Prom...
		return saleService.loadMany(loadRange(start, end));
	};

	// @ts-expect-error ts-migrate(2339) FIXME: Property 'loadIndividualPromises' does not exist o... Remove this comment to see the full error message
	results.loadIndividualPromises = function (page, numberPerPage) {
		// @ts-expect-error TS2339: Property 'map' does not exist ...
		return loadPage(page, numberPerPage).map(saleService.load);
	};

	// @ts-expect-error ts-migrate(2339) FIXME: Property 'loadRangeIndividualPromises' does not ex... Remove this comment to see the full error message
	results.loadRangeIndividualPromises = function (start, end) {
		// @ts-expect-error TS2339: Property 'map' does not exist ...
		return loadRange(start, end).map(saleService.load);
	};

	// @ts-expect-error ts-migrate(2339) FIXME: Property 'getFilters' does not exist on type '{}'.
	results.getFilters = function () {
		// yes, this actually is one of the fastest ways to copy an object, and definitely the safest.
		return JSON.parse(JSON.stringify(filters));
	};

	return loadPage(0, initialNumber);
};

export default searchService;
