import { fields, TableProps, RowProps } from './Table';
import { Cell } from './TableCell';

const LeaseCompRow = ({
	hiddenFields,
	compsAddresses,
	exportActions,
	editable,
	sectionMap,
}: RowProps & Pick<TableProps, 'compsAddresses'>) => {
	return (
		<div>
			{Array.from(compsAddresses.values()).map((address) => {
				return (
					// @ts-expect-error TS2339: Property 'address' does not ex...
					<div key={address.address} className="table-row">
						{/* @ts-expect-error TS2339: Property 'leaseComps' does not... */}
						{address.leaseComps?.map((comp, rowIndex) => {
							return (
								<div key={rowIndex} className="table-sub-row">
									{fields.lease.map((field, index) => {
										return (
											<Cell
												hiddenFields={hiddenFields}
												index={index}
												pinLabel={address.pinLabel.toString()}
												compId={comp.id}
												exportActions={exportActions}
												editable={editable}
												key={index}
												value={comp[field]}
												field={field}
												section={sectionMap[field]}
												hideAddress={rowIndex !== 0}
											/>
										);
									})}
								</div>
							);
						})}
					</div>
				);
			})}
		</div>
	);
};

export default LeaseCompRow;
