import React from 'react';

import isEqual from 'lodash/isEqual';

import popupStyles from './popup.less';
import { Position } from 'Singletons/Tooltip';

function curriedGetLocFromDOM() {
	let previousLoc = {};
	// @ts-expect-error TS7034: Variable 'loc' implicitly has ...
	let loc;
	return function getLocFromDomImpl(el: Element | null) {
		// get the location of this element
		if (el == null) {
			// @ts-expect-error TS7005: Variable 'loc' implicitly has ...
			return loc;
		}

		const rect = el.getBoundingClientRect();
		const newLoc = {
			left: rect.left,
			right: rect.right,
			top: rect.top,
			bottom: rect.bottom,
		};
		if (isEqual(newLoc, previousLoc)) {
			// @ts-expect-error TS7005: Variable 'loc' implicitly has ...
			return loc;
		}
		previousLoc = {
			...newLoc,
		};
		loc = newLoc;

		// crop to any elements that will affect overflow
		// @ts-expect-error TS18047: 'el' is possibly 'null'....
		while (el.parentNode && el.parentNode.nodeName !== 'BODY') {
			// @ts-expect-error TS2740: Type 'ParentNode' is missing t...
			el = el.parentNode;
			// @ts-expect-error TS2345: Argument of type 'Element | nu...
			const overflow = window.getComputedStyle(el).overflow;
			if (
				overflow !== 'visible' &&
				overflow !== 'scroll' &&
				overflow !== 'auto'
			) {
				// @ts-expect-error TS18047: 'el' is possibly 'null'....
				const crop = el.getBoundingClientRect();
				loc.left = Math.max(loc.left, crop.left);
				loc.right = Math.min(loc.right, crop.right);
				loc.top = Math.max(loc.top, crop.top);
				loc.bottom = Math.min(loc.bottom, crop.bottom);
			}
		}

		// crop to the window
		loc.left = Math.max(0, loc.left);
		loc.right = Math.min(loc.right, window.innerWidth);
		loc.top = Math.max(0, loc.top);
		loc.bottom = Math.min(loc.bottom, window.innerHeight);

		// @ts-expect-error TS2339: Property 'width' does not exis...
		loc.width = loc.right - loc.left;
		// @ts-expect-error TS2339: Property 'height' does not exi...
		loc.height = loc.bottom - loc.top;

		return loc;
	};
}

const getLocFromDOM = curriedGetLocFromDOM();

type OwnState = {
	position: Position;
	inside: boolean;
	outside: number;
	rect?: { right: number };
	styles?: {
		left?: number;
		right?: number;
		bottom: number;
		top: number;
	};
};

type State = OwnState & typeof Popup.defaultProps;

type Props = {
	position: Position;
	className: string | null;
	target: Element | null;
	onTargetLeave: () => void;
	offsetTop?: number;
	timeout: number;
};

export default class Popup extends React.Component<Props, State> {
	// @ts-expect-error TS2416: Property 'state' in type 'Popu...
	state = { inside: true, outside: 0 };
	keepChecking = true;

	static defaultProps = {
		offsetTop: 0,
		position: 'below',
		className: '',
	};

	componentDidMount = () => {
		this.setState({
			position: this.props.position,
			// @ts-expect-error TS2322: Type '{ display: string; top?:...
			styles: this.getLocation(this.props.position),
		});
		if (this.props.position === 'manual') {
			return;
		}

		this.keepChecking = true;
		const checkAgain = () => {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'rect' does not exist on type '{ inside: ... Remove this comment to see the full error message
			const { rect } = this.state;
			if (rect?.right > window.innerWidth) {
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'position' does not exist on type '{ insi... Remove this comment to see the full error message
				let newLocation = this.getLocation(this.state.position, rect);
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'styles' does not exist on type '{ inside... Remove this comment to see the full error message
				const previousLocation = this.state.styles;
				const overflow = rect.right - window.innerWidth + 10;

				// @ts-expect-error TS18049: 'newLocation' is possibly 'nul...
				if (newLocation.left) {
					newLocation = {
						...newLocation,
						// @ts-expect-error TS2322: Type 'string' is not assignabl...
						left: parseInt(newLocation.left) - overflow + 'px',
					};
				// @ts-expect-error TS18049: 'newLocation' is possibly 'nul...
				} else if (newLocation.right) {
					// @ts-expect-error TS2322: Type '{ right: string; } | { r...
					newLocation = {
						...newLocation,
						// @ts-expect-error TS18049: 'newLocation' is possibly 'nul...
						right: parseInt(newLocation.right) + overflow + 'px',
					};
				}

				if (isEqual(newLocation, previousLocation) === false) {
					this.setState({
						// @ts-expect-error TS2322: Type '{ display: string; top?:...
						styles: newLocation,
					});

					if (

						this.props.onTargetLeave &&
						// @ts-expect-error TS18049: 'newLocation' is possibly 'nul...
						newLocation.display === 'none' &&
						previousLocation.display !== 'none'
					) {
						this.props.onTargetLeave();
					}
				}
			}
			if (this.keepChecking) {
				requestAnimationFrame(checkAgain);
			}
		};

		requestAnimationFrame(checkAgain);
	};

	componentWillUnmount() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'timeout' does not exist on type 'Popup'.
		clearTimeout(this.timeout);
		this.keepChecking = false;
	}

	previousLoc = null;
	previousStyles = null;
	// @ts-expect-error TS7006: Parameter 'position' implicitl...
	getLocation(position) {
		let loc;

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'loc' does not exist on type 'Readonly<{}... Remove this comment to see the full error message
		if (this.props.loc) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'loc' does not exist on type 'Readonly<{}... Remove this comment to see the full error message
			loc = this.props.loc;
		} else {
			loc = getLocFromDOM(this.props.target);
		}

		if (isEqual(loc, this.previousLoc)) {
			return this.previousStyles;
		}

		this.previousLoc = loc;

		let styles;
		switch (true) {
			case loc.top < 0:
			case loc.bottom < 0:
			case loc.left < 0:
			case loc.right < 0:
			case loc.width < 0:
			case loc.height < 0:
				styles = {
					display: 'none',
				};
				// @ts-expect-error TS2322: Type '{ display: string; }' is...
				this.previousStyles = styles;
				return styles;
		}

		// @ts-expect-error ts-migrate(2339) FIXME: Property 'relative' does not exist on type 'Readon... Remove this comment to see the full error message
		if (this.props.relative) {
			styles = {
				top: loc.top,
				left: loc.left,
				right: loc.right,
				bottom: loc.bottom,
				position: loc.position,
			};
			// @ts-expect-error TS2322: Type '{ top: any; left: any; r...
			this.previousStyles = styles;
			return styles;
		}
		const { offsetTop } = this.props;

		switch (position) {
			case 'above':
				styles = {
					bottom: Math.round(window.innerHeight - loc.top) + 'px',
					left: Math.round(loc.left + loc.width / 2) + 'px',
				};
				break;

			case 'center': {
				// @ts-expect-error TS18048: 'offsetTop' is possibly 'undef...
				const center = loc.top + loc.height / 2 - offsetTop;
				styles = {
					bottom: Math.round(window.innerHeight - center) + 'px',
					left: Math.round(loc.left + loc.width / 2) + 'px',
				};
				break;
			}
			case 'below':
				if (offsetTop) {
					styles = {
						top: Math.round(loc.bottom + offsetTop) + 'px',
						left: Math.round(loc.left + loc.width / 2) + 'px',
					};
				} else {
					styles = {
						top: Math.round(loc.bottom) + 'px',
						left: Math.round(loc.left + loc.width / 2) + 'px',
					};
				}
				break;

			case 'onleft':
				styles = {
					top: Math.round(loc.top + loc.height / 2) + 'px',
					right: Math.round(window.innerWidth - loc.left) + 'px',
				};
				break;

			case 'onright':
				styles = {
					top: Math.round(loc.top + loc.height / 2) + 'px',
					left: Math.round(loc.left + loc.width) + 'px',
				};
				break;

			case 'below-onleft':
				styles = {
					top: Math.round(loc.bottom) + 'px',
					right:
						Math.round(window.innerWidth - loc.right + loc.width / 2) + 'px',
				};
				break;

			case 'below-onright':
				styles = {
					top: Math.round(loc.bottom) + 'px',
					left: Math.round(loc.left) + 'px',
				};
				break;

			case 'above-onright':
				styles = {
					bottom: Math.round(window.innerHeight - loc.top) + 'px',
					left: Math.round(loc.left) + 'px',
				};
				break;

			case 'above-onleft':
				styles = {
					bottom: Math.round(window.innerHeight - loc.top) + 'px',
					right: Math.round(window.innerWidth - loc.right) + 'px',
				};
				break;
		}
		// @ts-expect-error TS2322: Type '{ bottom: string; left: ...
		this.previousStyles = styles;
		return styles;
	}

	// @ts-expect-error TS7006: Parameter 'el' implicitly has ...
	adjustIfOffscreen = (el) => {
		if (!el) {
			return;
		}
		const rect = el.getBoundingClientRect();
		this.setState({
			rect,
		});
	};

	checkLeave = () => {
		if (!this.state.inside && this.props.onTargetLeave) {
			this.props.onTargetLeave();
		}
	};

	onMouseLeave = () => {
		this.setState(
			{
				inside: false,
			},

			() => setTimeout(this.checkLeave, this.props.timeout)
		);
	};

	onMouseEnter = () => {
		this.setState({
			inside: true,
		});
	};

	render() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'position' does not exist on type '{ insi... Remove this comment to see the full error message
		const positionClass = popupStyles[this.state.position] || '';
		return (
			<div
				id="popup"
				onMouseLeave={this.onMouseLeave}
				onMouseEnter={this.onMouseEnter}
				key="popup"
				className={
					popupStyles.popup + ' ' + positionClass + ' ' + this.props.className
				}
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'styles' does not exist on type '{ inside... Remove this comment to see the full error message
				style={this.state.styles}
			>
				<div className={popupStyles.content} ref={this.adjustIfOffscreen}>
					{/* @ts-expect-error TS2339: Property 'children' does not e... */}
					{this.props.children}
				</div>
			</div>
		);
	}
}
