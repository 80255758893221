import { GrayPostfix } from './UI';

export const renderEstimatedPostfix = (
	isEstimated: boolean,
	shorten = true
) => {
	return (
		isEstimated && (
			<GrayPostfix>{shorten ? '(Est.)' : '(Estimated)'}</GrayPostfix>
		)
	);
};
