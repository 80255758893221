'use strict';
import abbreviateNumber from './abbreviateNumber';

const moneyCharacters = {
	USD: '$',
	GBP: '£',
} as const;

const prefixToCurrencyName = {
	$: 'USD',
	'£': 'GBP',
} as const;

export type Currency = keyof typeof moneyCharacters;

const decimalPlaces = {
	USD: 2,
	GBP: 2,
};

const neitherNumberNorDot = /[^0-9.]/g;
const commaRegex = /(\d)(?=(\d{3})+(?!\d))/g;

function format(
	value: string | number,
	currency: Currency,
	decimalPrecision?: number | string,
	abbreviate?: boolean
) {
	if (typeof value === 'string') {
		value = value.replace(neitherNumberNorDot, '');
		// @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
		value = parseFloat(value, 10);
	}
	if (typeof value !== 'number') {
		throw new Error('Tried to convert a non number to currency');
	}
	if (typeof currency !== 'string') {
		currency = 'USD';
	// @ts-expect-error TS7053: Element implicitly has an 'any...
	} else if (typeof prefixToCurrencyName[currency] !== 'undefined') {
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		currency = prefixToCurrencyName[currency];
	}

	if (!decimalPrecision) {
		decimalPrecision = decimalPlaces[currency];
	} else if (
		typeof decimalPrecision !== 'number' ||
		Math.floor(decimalPrecision) !== decimalPrecision
	) {
		throw new Error(
			'Trying to format money using decimal precision "' +
				decimalPrecision +
				'" that is not an integer, but of type ' +
				typeof decimalPrecision
		);
	}

	if (abbreviate) {
		return (
			moneyCharacters[currency] +
			abbreviateNumber(value.toFixed(decimalPrecision))
		);
	} else {
		return (
			moneyCharacters[currency] +
			value.toFixed(decimalPrecision).replace(commaRegex, '$1,')
		);
	}
}

export default format;
