import { nullValueText } from '@compstak/common';

export const formatBuildingCeilingHeight = (v: string) => {
	if (!v || v === '0' || v === "0'") return nullValueText;

	if (v.includes('-')) {
		const [start, end] = v.split('-');

		const startValue = start.includes("'") ? start : `${start}'`;
		const endValue = end.includes("'") ? end : `${end}'`;
		return `${startValue}-${endValue}`;
	} else {
		return v.includes("'") ? v : `${v}'`;
	}
};
