import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { API, formatRequestString } from '@compstak/common';
import { TransitScreenDto } from './dto';
import { QUERY_KEYS } from 'api/constants';

type FetchTransitScreenApiParams = { id: string };

export type UseTransitScreenParams = UseQueryResult<TransitScreenDto>;

const fetch = async ({
	id,
}: FetchTransitScreenApiParams): Promise<TransitScreenDto> => {
	const response = await API.get<TransitScreenDto>(
		formatRequestString({
			entityUrl: `/properties/${id}/transitScreen`,
		})
	);

	return response.data;
};

export const useTransitScreenQuery = (
	params: FetchTransitScreenApiParams
): UseTransitScreenParams =>
	useQuery([QUERY_KEYS.TRANSIT_SCREEN, params], () => fetch(params), {
		enabled: Boolean(params.id),
	});
