import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { createFetchFn } from 'api/utils';
import { PropertyComp } from 'types';

const createFetchProperty = createFetchFn<PropertyByIdParams, PropertyComp>({
	method: 'get',
	getURL: ({ id }) => `/properties/${id}`,
});

export const usePropertyByIdQuery = (params: PropertyByIdParams) => {
	return useQuery({
		queryKey: [QUERY_KEYS.PROPERTY_BY_ID, params],
		queryFn: createFetchProperty(params),
		enabled: params.id != null,
	});
};

type PropertyByIdParams = {
	id: number | undefined;
};
