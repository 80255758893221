import {
	SHOW_HEADER_NOTIFICATION,
	HIDE_HEADER_NOTIFICATION,
} from 'Components/CookiesNotification/actions';

const initialState = false;

const headerNotification = (state = initialState, action = {}) => {
	// @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type '{}'.
	switch (action.type) {
		case SHOW_HEADER_NOTIFICATION:
			state = true;
			break;

		case HIDE_HEADER_NOTIFICATION:
			state = false;
			break;
	}
	return state;
};

export default headerNotification;
