import {
	marketIdToMarketNameAndRentPeriod,
	RentPeriod,
} from '@compstak/common';

export const getIsMonthlyMarket = (marketId: number) => {
	// @ts-expect-error TS7053: Element implicitly has an 'any...
	return !!marketIdToMarketNameAndRentPeriod[marketId];
};

export const getIsMonthlyMarketFromMarketName = (marketName: string) => {
	const marketConfig = Object.values(marketIdToMarketNameAndRentPeriod).find(
		(c) => c.marketName === marketName
	);
	return marketConfig?.rentPeriod === RentPeriod.MONTHLY;
};
