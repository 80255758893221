import { CompatibleFilterKey } from 'types/serverFilters';
import { allFiltersStub } from '../stubs/allFiltersStub';
import {
	DateIntervalFiltersKeys,
	FiltersTypesKeys,
	IntervalFiltersKeys,
	NumberIntervalFiltersKeys,
	StringIntervalFiltersKeys,
} from '../types';
import {
	isDateIntervalFilterValue,
	isIntervalFilterValue,
	isNumberIntervalFilterValue,
	isStringIntervalFilterValue,
} from './filterHelpers';

export const isIntervalFilterKey = (
	filterKey: FiltersTypesKeys | CompatibleFilterKey
): filterKey is IntervalFiltersKeys => {
	// @ts-expect-error TS2551: Property 'submarket' does not ...
	const valueType = allFiltersStub[filterKey];
	return isIntervalFilterValue(valueType);
};

export const isDateIntervalFilterKey = (
	filterKey: FiltersTypesKeys | CompatibleFilterKey
): filterKey is DateIntervalFiltersKeys => {
	// @ts-expect-error TS2551: Property 'submarket' does not ...
	const valueType = allFiltersStub[filterKey];
	return isDateIntervalFilterValue(valueType);
};

export const isNumberIntervalFilterKey = (
	filterKey: FiltersTypesKeys | CompatibleFilterKey
): filterKey is NumberIntervalFiltersKeys => {
	// @ts-expect-error TS2551: Property 'submarket' does not ...
	const valueType = allFiltersStub[filterKey];
	return isNumberIntervalFilterValue(valueType);
};

export const isStringIntervalFilterKey = (
	filterKey: FiltersTypesKeys | CompatibleFilterKey
): filterKey is StringIntervalFiltersKeys => {
	// @ts-expect-error TS2551: Property 'submarket' does not ...
	const valueType = allFiltersStub[filterKey];
	return isStringIntervalFilterValue(valueType);
};
