import { API } from '@compstak/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { CompsetResponse, UpdatePropertyCompsetParams } from '../propertyById';

const removeProperty = async ({ id, ids }: UpdatePropertyCompsetParams) => {
	const res = await API.put<CompsetResponse>(`/api/properties/${id}/remove`, {
		ids,
	});
	return res.data;
};

export const useRemovePropertyMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (params: UpdatePropertyCompsetParams) => removeProperty(params),
		onSuccess: (data) => {
			queryClient.invalidateQueries([
				QUERY_KEYS.PROPERTY_COMPSET,
				{ id: data.subjectProperty.id },
			]);
		},
	});
};
