import factory from './factory';
import marketService from 'services/markets';

// @ts-expect-error TS7006: Parameter 'id' implicitly has ...
function createUrl(id) {
	return '/api/properties/' + id;
}

const serviceController = factory.create({
	createUrl: createUrl,
	timeout: 1000 * 60 * 5,
});

const service = serviceController.exportable({});

// @ts-expect-error TS2322: Type '(idsOrPromises: any, use...
service.loadMany = function (idsOrPromises, useMultifamily) {
	if (idsOrPromises.length === 1) {
		return Promise.all([service.load(idsOrPromises[0])]);
	}

	// Should it be this complicated? It seems like it shouldn't.
	// The reasons it is:
	// • We can't do an ID search without knowing the market.
	// • We can't get the market off the comp, because we get the display name.
	// • We don't want to load comps we don't have to load.
	// • The cache should be filled immediately in case this is called twice

	return Promise.all(idsOrPromises).then(function (ids) {
		const resolves = {};
		const rejects = {};

		// to avoid loading comps that are aleady loaded, and to
		// hopefully speed up loading all comps by already having
		// the market, the comps are split into loaded and unloaded groups.
		const idsLoaded = ids.filter(function (id) {
			return service.has(id);
		});
		const idsToLoad = ids.filter(function (id) {
			return !service.has(id);
		});

		// This is the array of promises that will be returned.
		const promises = ids.map(function (id) {
			if (service.has(id)) {
				return service.load(id);
			}
			// Since we're loading the comps ourselves, we need to
			// create the promises and fill the cache ourselves.
			const promise = new Promise(function (resolve, reject) {
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				resolves[id] = resolve;

				// @ts-expect-error TS7053: Element implicitly has an 'any...
				rejects[id] = reject;
			});
			//service.add(id, promise);
			return promise;
		});

		// If everything has been loaded already, short circuit.
		if (idsToLoad.length === 0) {
			return Promise.all(promises);
		}

		// Loading one or more property will be faster if we can get
		// the market off of one that's already loaded. If only one
		// property needs to be loaded, use the ID endpoint instead of search.
		let propertyForMarket;
		if (idsLoaded.length && idsToLoad.length !== 1) {
			propertyForMarket = service.load(idsLoaded[0]);
		} else {
			service.clear(idsToLoad[0]);
			propertyForMarket = promises[0] = service.load(idsToLoad.shift());

			if (idsToLoad.length === 0) {
				return Promise.all(promises);
			}
		}

		// The name we need for the market (not the display name) is
		// only in the market service. So we pluck it off
		propertyForMarket
			.then(function (property) {
				return marketService.load().then(function (markets) {
					for (let i = 0; i < markets.length; i++) {
						// @ts-expect-error TS18046: 'markets' is of type 'unknown'...
						if (markets[i].displayName === property.market) {
							return markets[i].name;
						}
					}
					throw new Error(
						'Unable to to loadMany properties because there is no market with display name ' +
							// @ts-expect-error TS18046: 'property' is of type 'unknown...
							property.market
					);
				});
			})
			.then(function (marketName) {
				// this performs and ID based search.
				const filters = [
					{ property: 'marketName', comparison: 'eq', value: marketName },
					{ property: 'id', comparison: 'in', value: idsToLoad },
				];

				const url =
					'/api' +
					(useMultifamily ? '/mufa' : '') +
					'/properties/actions/search';

				const loader = factory.post(
					url,
					{
						filter: filters,
						offset: 0,
						limit: idsToLoad.length,
						order: 'desc',
						sort: 'id',
					},
					true
				);

				loader.then(function (searchResults) {
					// @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
					searchResults.comps.forEach(function (property) {
						// @ts-expect-error TS7053: Element implicitly has an 'any...
						resolves[property.id](property);
					});
				});
			});

		return Promise.all(promises);
	});
};

export default service;
