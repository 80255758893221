import { Link as LinkOriginal } from 'react-router-dom';
import styled from 'styled-components';

export const Link = styled(LinkOriginal)<{ isDisabled?: boolean }>`
	cursor: pointer;

	${({ isDisabled, theme }) =>
		isDisabled &&
		`
        pointer-events: none;
        color: ${theme.colors.gray.gray300};
    `}
`;
