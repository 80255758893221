import { requestDemo } from 'actions/user';
import { useUser } from 'Pages/Login/reducers';
import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import button from 'ui/styles/button.less';
import modalStyles from 'Singletons/Modal/modal.less';
import { ContactUsParagraph } from './ContactUsParagraph';

type ExportModalProps = {
	closeModal?: NoArgCallback;
};

export const ExportModal = memo((props: ExportModalProps) => {
	const [requestSent, setRequestSent] = useState(false);
	const dispatch = useDispatch();
	const user = useUser()!;

	const requestDemoHandler = () => {
		dispatch(requestDemo(user.username, 'export', null));
		setRequestSent(true);
	};

	return (
		<div className={modalStyles.popupContent}>
			<h1>CompStak Enterprise includes Excel and PDF exports</h1>
			<p className={modalStyles.lg + ' ' + modalStyles.grey}>
				Ask us about turning on this feature.
			</p>

			{requestSent ? (
				<div className={button.disabled}>Thanks! We'll contact you shortly</div>
			) : (
				<div className={button.large} onClick={requestDemoHandler}>
					Upgrade to Export Your Leases
				</div>
			)}
			<a
				href="#"
				className={modalStyles.negativeCta}
				data-close-button="true"
				onClick={props.closeModal}
			>
				I’m not ready to turn on exports yet.
			</a>
			<ContactUsParagraph />
		</div>
	);
});

ExportModal.displayName = 'ExportModal';
