import { nullValueText } from '@compstak/common';
import { ReactNode } from 'react';

export const dashWhenNoValue = <Value>(
	value: Value,
	format?: (value: NonNullable<Value>) => ReactNode
) => {
	if (value == null) return nullValueText;
	if (format) return format(value);
	return value;
};
