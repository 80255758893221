import { isObject } from 'lodash';
import {
	ServerDateIntervalFilter,
	ServerFilterItem,
	ServerFilterItemValue,
	ServerNumberIntervalFilter,
	ServerStringIntervalFilter,
} from 'types/serverFilters';
import {
	BooleanStringFiltersKeys,
	DateIntervalFiltersKeys,
	FiltersTypesKeys,
	HiddenFiltersKeys,
	MarketFiltersKeys,
	MarketsFiltersKeys,
	MultiPolygonFiltersKeys,
	NumberIntervalFiltersKeys,
	PolygonFiltersKeys,
	RadiusFiltersKeys,
	StringIntervalFiltersKeys,
	SubmarketsFilterKeys,
} from '../types';
import { booleanFiltersList } from './filterHelpers';
import {
	isDateIntervalFilterKey,
	isNumberIntervalFilterKey,
	isStringIntervalFilterKey,
} from './isIntervalFilterKey';

export const isServerMarketFilter = (
	serverFilterItem: ServerFilterItem
): serverFilterItem is ServerFilterItem<MarketFiltersKeys> => {
	return serverFilterItem.property === 'marketName';
};

export const isServerMarketsFilter = (
	serverFilterItem: ServerFilterItem
): serverFilterItem is ServerFilterItem<MarketsFiltersKeys> => {
	return serverFilterItem.property === 'marketNames';
};

export const isServerSubmarketsFilter = (
	serverFilterItem: ServerFilterItem
): serverFilterItem is ServerFilterItem<SubmarketsFilterKeys> => {
	return serverFilterItem.property === 'submarketId';
};

export const isServerBooleanFilter = (
	serverFilterItem: ServerFilterItem
): serverFilterItem is ServerFilterItem<BooleanStringFiltersKeys> => {
	return booleanFiltersList.includes(
		serverFilterItem.property as BooleanStringFiltersKeys
	);
};

export const isServerHiddenFilter = (
	serverFilterItem: ServerFilterItem
): serverFilterItem is ServerFilterItem<HiddenFiltersKeys> => {
	return serverFilterItem.property === 'hidden';
};

export const isServerRadiusFilter = (
	serverFilterItem: ServerFilterItem
): serverFilterItem is ServerFilterItem<RadiusFiltersKeys> => {
	// legacy backwards compatibility
	if (
		'comparison' in serverFilterItem &&
		serverFilterItem.comparison === 'circle'
	) {
		return true;
	}

	const value = serverFilterItem.value;

	return isObject(value) && 'shape' in value && value.shape === 'circle';
};

export const isServerPolygonFilter = (
	serverFilterItem: ServerFilterItem
): serverFilterItem is ServerFilterItem<PolygonFiltersKeys> => {
	// legacy backwards compatibility
	if (
		'comparison' in serverFilterItem &&
		serverFilterItem.comparison === 'polygon'
	) {
		return true;
	}

	const value = serverFilterItem.value;

	return isObject(value) && 'shape' in value && value.shape === 'polygon';
};

export const isServerMultiPolygonFilter = (
	serverFilterItem: ServerFilterItem
): serverFilterItem is ServerFilterItem<MultiPolygonFiltersKeys> => {
	const value = serverFilterItem.value;

	return isObject(value) && 'shape' in value && value.shape === 'multiPolygon';
};

export const isServerAPNFilter = (
	serverFilterItem: ServerFilterItem
): serverFilterItem is ServerFilterItem<'apn'> => {
	return (
		isServerMultiPolygonFilter(serverFilterItem) &&
		'fips' in serverFilterItem.value!
	);
};

export const isServerStringIntervalFilter = (
	serverFilterItem: ServerFilterItem
): serverFilterItem is ServerFilterItem<StringIntervalFiltersKeys> => {
	const { property, value } = serverFilterItem;

	return (
		isServerIntervalFilter(value as ServerFilterItemValue) &&
		isStringIntervalFilterKey(property as FiltersTypesKeys)
	);
};

export const isServerNumberIntervalFilter = (
	serverFilterItem: ServerFilterItem
): serverFilterItem is ServerFilterItem<NumberIntervalFiltersKeys> => {
	const { property, value } = serverFilterItem;

	return (
		isServerIntervalFilter(value as ServerFilterItemValue) &&
		isNumberIntervalFilterKey(property as FiltersTypesKeys)
	);
};

export const isServerDateIntervalFilter = (
	serverFilterItem: ServerFilterItem
): serverFilterItem is ServerFilterItem<DateIntervalFiltersKeys> => {
	const { property, value } = serverFilterItem;

	return (
		isServerIntervalFilter(value as ServerFilterItemValue) &&
		isDateIntervalFilterKey(property as FiltersTypesKeys)
	);
};

const isServerIntervalFilter = (
	value: ServerFilterItemValue
): value is
	| ServerNumberIntervalFilter
	| ServerStringIntervalFilter
	| ServerDateIntervalFilter => {
	return (
		isObject(value) &&
		('to' in value || 'from' in value || 'allowFallback' in value)
	);
};
