// All the possible columns that can show in any of the search tables

import { SortDirection } from 'api';
import { FeatureFlags } from 'api/featureFlags/types';
import { COMP_FIELD_TOOLTIPS } from '../../constants/tooltips';
import { TableCompKeys } from 'types/table';
import { AnyComp } from 'types';
import { ReactNode } from 'react';

// Some are shown in more than one table
export const COLUMN_CONFIG: ColumnConfig = {
	0: {
		id: 0,
		name: 'tenantName',
		label: 'Tenant Name',
	},
	1: {
		id: 1,
		name: 'transactionType',
		label: 'Transaction Type',
		sortName: 'transactionTypeName',
	},
	2: {
		id: 2,
		name: 'spaceType',
		label: 'Space Type',
		sortName: 'spaceTypeName',
	},
	3: {
		id: 3,
		name: 'executionQuarter',
		label: 'Transaction Quarter',
		initialSortDir: 'desc',
	},
	4: {
		id: 4,
		name: 'transactionSize',
		label: 'Transaction SQFT',
		initialSortDir: 'desc',
	},
	6: {
		id: 6,
		name: 'buildingSellingBasement',
		label: 'Selling Basement',
	},
	7: {
		id: 7,
		name: 'buildingVentedSpace',
		label: 'Vented Space',
	},
	8: {
		id: 8,
		name: 'buildingCornerUnit',
		label: 'Corner Unit',
	},
	9: {
		id: 9,
		name: 'buildingStreetFrontage',
		label: 'Street Frontage',
		initialSortDir: 'desc',
	},
	10: {
		id: 10,
		name: 'executionDate',
		label: 'Execution Date',
		initialSortDir: 'desc',
	},
	11: {
		id: 11,
		name: 'commencementDate',
		label: 'Commencement Date',
		initialSortDir: 'desc',
	},
	12: {
		id: 12,
		name: 'expirationDate',
		label: 'Expiration Date',
		initialSortDir: 'desc',
		showWarningIfNoValue: true,
	},
	13: {
		id: 13,
		name: 'startingRent',
		label: 'Starting Rent',
		initialSortDir: 'desc',
		tooltip: COMP_FIELD_TOOLTIPS.startingRent,
	},
	14: {
		id: 14,
		name: 'effectiveRent',
		label: 'Net Effective Rent',
		initialSortDir: 'desc',
		tooltip: COMP_FIELD_TOOLTIPS.effectiveRent,
	},
	15: {
		id: 15,
		name: 'currentRent',
		label: 'Current Rent',
		initialSortDir: 'desc',
		tooltip: COMP_FIELD_TOOLTIPS.currentRent,
	},
	16: {
		id: 16,
		name: 'askingRent',
		label: 'Asking Rent',
		initialSortDir: 'desc',
	},
	17: {
		id: 17,
		name: 'floorOccupancies',
		label: 'Floor(s) Occupied',
		sortName: 'floors',
	},
	18: {
		id: 18,
		name: 'suite',
		label: 'Suite',
	},
	20: {
		id: 20,
		name: 'rentBumpYears',
		label: 'Rent Bump Year',
	},
	21: {
		id: 21,
		name: 'rentBumpsDollar',
		label: 'Rent Bump Dollar',
		isSortable: false,
	},
	22: {
		id: 22,
		name: 'rentBumpsPercent',
		label: 'Rent Bump Percent',
		isSortable: false,
	},
	23: {
		id: 23,
		name: 'leaseEscalations',
		label: 'Rent Schedule',
		isSortable: false,
	},
	24: {
		id: 24,
		name: 'freeMonths',
		label: 'Free Rent',
		initialSortDir: 'desc',
	},
	26: {
		id: 26,
		name: 'workType',
		label: 'Work Type',
	},
	27: {
		id: 27,
		name: 'workValue',
		label: 'TI Value / Work Value',
		initialSortDir: 'desc',
	},
	28: {
		id: 28,
		name: 'concessionsNotes',
		label: 'Concessions Notes',
		isSortable: false,
	},
	29: {
		id: 29,
		name: 'sublease',
		label: 'Sublease',
		initialSortDir: 'desc',
	},
	30: {
		id: 30,
		name: 'sublessorName',
		label: 'Sublessor',
	},
	31: {
		id: 31,
		name: 'renewalOptions',
		label: 'Renewal Options',
	},
	32: {
		id: 32,
		name: 'buildingCrossStreets',
		label: 'Cross Streets',
	},
	33: {
		id: 33,
		name: 'leaseTerm',
		label: 'Lease Term',
		initialSortDir: 'desc',
		showWarningIfNoValue: true,
	},
	34: {
		id: 34,
		name: 'leaseType',
		label: 'Lease Type',
	},
	37: {
		id: 37,
		name: 'operatingExpensesType',
		label: 'Operating Expenses Type',
	},
	38: {
		id: 38,
		name: 'operatingExpensesValue',
		label: 'Operating Expense (PSF)',
	},
	39: {
		id: 39,
		name: 'operatingExpensesNotes',
		label: 'Operating Expense Notes',
		isSortable: false,
	},
	40: {
		id: 40,
		name: 'proRataPercent',
		label: 'Pro Rata Percent',
	},
	41: {
		id: 41,
		name: 'annualTaxesNotes',
		label: 'Annual Taxes',
		isSortable: false,
	},
	42: {
		id: 42,
		name: 'percentageRent',
		label: 'Percentage Rent',
		isSortable: false,
	},
	43: {
		id: 43,
		name: 'buildingAddress',
		label: 'Street Address',
		sortName: 'address',
	},
	44: {
		id: 44,
		name: 'city',
		label: 'City',
	},
	45: {
		id: 45,
		name: 'buildingName',
		label: 'Building Name',
	},
	46: {
		id: 46,
		name: 'buildingClass',
		label: 'Building Class',
	},
	47: {
		id: 47,
		name: 'buildingYear',
		label: 'Year Built',
		sortName: 'buildingYearBuilt',
	},
	48: {
		id: 48,
		name: 'buildingYearRenovated',
		label: 'Year Renovated',
		initialSortDir: 'desc',
	},
	49: {
		id: 49,
		name: 'buildingPropertyType',
		label: 'Property Type',
	},
	50: {
		id: 50,
		name: 'buildingSize',
		label: 'Building Size',
		initialSortDir: 'desc',
	},
	51: {
		id: 51,
		name: 'buildingFloorsCount',
		label: 'Number of Floors',
	},
	52: {
		id: 52,
		name: 'buildingParkingRatio',
		label: 'Parking Ratio',
	},
	55: {
		id: 55,
		name: 'buildingParkingType',
		label: 'Parking Lot Type',
	},
	56: {
		id: 56,
		name: 'buildingLoadFactor',
		label: 'Load Factor',
	},
	57: {
		id: 57,
		name: 'buildingSprinkler',
		label: 'Sprinkler',
	},
	58: {
		id: 58,
		name: 'buildingCeilingHeight',
		label: 'Clear Height',
	},
	59: {
		id: 59,
		name: 'buildingGroundLvlDoorsCount',
		label: 'Doors',
	},
	60: {
		id: 60,
		name: 'buildingLoadingDocksCount',
		label: 'Loading Docks',
	},
	61: {
		id: 61,
		name: 'buildingRail',
		label: 'Rail Access',
	},
	62: {
		id: 62,
		name: 'buildingOfficePortion',
		label: 'Office Portion',
		isSortable: false,
	},
	63: {
		id: 63,
		name: 'landlordName',
		label: 'Current Landlord',
	},
	66: {
		id: 66,
		name: 'tenantIndustry',
		label: 'Tenant Industry',
	},
	70: {
		id: 70,
		name: 'comments',
		label: 'Comments',
		isSortable: false,
	},
	71: {
		id: 71,
		name: 'freeRentType',
		label: 'Free Rent Type',
	},
	72: {
		id: 72,
		name: 'buildingPropertySubtype',
		label: 'Property Subtype',
	},
	73: {
		id: 73,
		name: 'state',
		label: 'State',
	},
	74: {
		id: 74,
		name: 'zip',
		label: 'Zip Code',
	},
	75: {
		id: 75,
		name: 'submarket',
		label: 'Submarket',
	},
	76: {
		id: 76,
		name: 'tenantRealtyBrokers',
		label: 'Tenant Brokers',
	},
	77: {
		id: 77,
		name: 'tenantRealtyCompanies',
		label: 'Tenant Brokerage Firms',
	},
	80: {
		id: 80,
		name: 'grossAnnualAskingRent',
		label: 'Asking Rent (Gross Annual)',
		tooltip:
			'The rent amount advertised by the landlord for a property when it is available for rent during the first year of the lease.',
	},
	81: {
		id: 81,
		name: 'grossAnnualTakingRent',
		label: 'Starting Rent (Gross Annual)',
		tooltip:
			'The rent amount that the tenant pays during the first year of the lease (initial free rent not included).',
	},
	82: {
		id: 82,
		name: 'retailAnchor',
		label: 'Retail Anchor',
	},
	84: {
		id: 84,
		name: 'landlordRealtyBrokers',
		label: 'Landlord Brokers',
	},
	85: {
		id: 85,
		name: 'landlordRealtyCompanies',
		label: 'Landlord Brokerage Firms',
	},
	88: {
		id: 88,
		name: 'geoPoint',
		label: 'Geo Point',
		isSortable: false,
	},
	90: {
		id: 90,
		name: 'parkingNotes',
		label: 'Parking Notes',
		isSortable: false,
	},
	93: {
		id: 93,
		name: 'market',
		label: 'Market',
	},
	109: {
		id: 109,
		name: 'lastUpdated',
		label: 'Date Updated',
		initialSortDir: 'desc',
	},
	110: {
		id: 110,
		name: 'blendedRent',
		label: 'Blended Rent',
	},
	111: {
		id: 111,
		name: 'totalTransactionSize',
		label: 'Total Transaction SQFT',
		initialSortDir: 'desc',
	},
	112: {
		id: 112,
		name: 'retailNotes',
		label: 'Retail Notes',
		isSortable: false,
	},
	124: {
		id: 124,
		name: 'dateCreated',
		label: 'Date Created',
		initialSortDir: 'desc',
	},
	127: {
		id: 127,
		name: 'adjStartingRent',
		label: 'Adjusted Starting Rent',
		initialSortDir: 'desc',
		tooltip:
			'Starting Rent grossed up for office; net for industrial; unadjusted for all other space types.',
	},
	128: {
		id: 128,
		name: 'adjEffectiveRent',
		label: 'Adjusted Effective Rent',
		initialSortDir: 'desc',
		tooltip:
			'Effective Rent grossed up for office; net for industrial; unadjusted for all other space types.',
	},
	133: {
		id: 133,
		name: 'officePortionRent',
		label: 'Office Portion Rent',
	},
	138: {
		id: 138,
		name: 'breakOptionType',
		label: 'Break Option Type',
	},
	141: {
		id: 141,
		name: 'rentReviewDates',
		label: 'Rent Review Dates',
		initialSortDir: 'desc',
	},
	142: {
		id: 142,
		name: 'breakOptionDates',
		label: 'Break Option Dates',
		initialSortDir: 'desc',
	},
	143: {
		id: 143,
		name: 'additionalFreeMonths',
		label: 'Additional Rent Free',
		initialSortDir: 'desc',
	},
	152: {
		id: 152,
		name: 'constructionType',
		label: 'Construction Type',
	},
	173: {
		id: 173,
		name: 'zoning',
		label: 'Zoning',
	},
	174: {
		id: 174,
		name: 'saleDate',
		label: 'Sale Date',
	},
	175: {
		id: 175,
		name: 'recordDate',
		label: 'Record Date',
	},
	178: {
		id: 178,
		name: 'recordedBuyer',
		label: 'Recorded Buyer',
	},
	179: {
		id: 179,
		name: 'recordedSeller',
		label: 'Recorded Seller',
	},
	181: {
		id: 181,
		name: 'lotSize',
		label: 'Lot Size',
	},
	182: {
		id: 182,
		name: 'floorAreaRatio',
		label: 'FAR',
	},
	189: {
		id: 189,
		name: 'interestType',
		label: 'Interest Type',
		sortName: 'interestTypeName',
	},
	190: {
		id: 190,
		name: 'saleType',
		label: 'Sale Type',
		sortName: 'saleTypeName',
	},
	191: {
		id: 191,
		name: 'interestPercentage',
		label: 'Interest',
	},
	192: {
		id: 192,
		name: 'totalSalePrice',
		label: 'Total Sale Price',
	},
	193: {
		id: 193,
		name: 'salePricePsf',
		label: 'Sales Price ($/SF)',
	},
	194: {
		id: 194,
		name: 'saleQuarter',
		label: 'Sale Quarter',
	},
	195: {
		id: 195,
		name: 'daysOnMarket',
		label: 'Days on Market',
	},
	196: {
		id: 196,
		name: 'capRate',
		label: 'Cap Rate',
	},
	197: {
		id: 197,
		name: 'percentOccupied',
		label: 'Occupancy Rate',
	},
	198: {
		id: 198,
		name: 'percentImproved',
		label: 'Percent Improved',
	},
	199: {
		id: 199,
		name: 'amps',
		label: 'Amps',
	},
	200: {
		id: 200,
		name: 'volts',
		label: 'Volts',
	},
	201: {
		id: 201,
		name: 'totalNetOperatingIncome',
		label: 'Total NOI',
	},
	202: {
		id: 202,
		name: 'netOperatingIncomePsf',
		label: 'NOI (PSF)',
	},
	203: {
		id: 203,
		name: 'totalOperatingExpenses',
		label: 'Total Operating Expense',
	},
	204: {
		id: 204,
		name: 'totalAskingSalePrice',
		label: 'Total Asking Price',
	},
	205: {
		id: 205,
		name: 'askingSalePricePsf',
		label: 'Asking Price',
	},
	206: {
		id: 206,
		name: 'includeBusinesses',
		label: 'Include Businesses',
	},
	209: {
		id: 209,
		name: 'lenderName',
		label: 'Lender',
	},
	210: {
		id: 210,
		name: 'sellerRepBrokers',
		label: 'Seller Rep',
		isSortable: false,
	},
	211: {
		id: 211,
		name: 'buyerRepBrokers',
		label: 'Buyer Rep',
		isSortable: false,
	},
	212: {
		id: 212,
		name: 'sellerRepCompanies',
		label: 'Seller Rep Companies',
	},
	213: {
		id: 213,
		name: 'buyerRepCompanies',
		label: 'Buyer Rep Companies',
	},
	216: {
		id: 216,
		name: 'elevators',
		label: 'Elevators',
		isSortable: false,
	},
	217: {
		id: 217,
		name: 'documentNumber',
		label: 'Deed Doc #',
		isSortable: false,
	},
	218: {
		id: 218,
		name: 'documentType',
		label: 'Document Type',
		isSortable: false,
	},
	222: {
		id: 222,
		name: 'county',
		label: 'County',
	},
	223: {
		id: 223,
		name: 'propertyRights',
		label: 'Property Rights',
		isSortable: false,
	},
	224: {
		id: 224,
		name: 'numberOfBuildings',
		label: 'Number Of Buildings',
	},
	225: {
		id: 225,
		name: 'buyer',
		label: 'Buyer',
	},
	226: {
		id: 226,
		name: 'seller',
		label: 'Seller',
	},
	243: {
		id: 243,
		name: 'capRateNotes',
		label: 'Cap Rate Notes',
	},
	247: {
		id: 247,
		name: 'dataSource',
		label: 'Data Source',
	},
	249: {
		id: 249,
		name: 'numberOfUnits',
		label: 'Number Of Units',
	},
	260: {
		id: 260,
		name: 'propertyMarketStartingRent',
		label: 'Property Market Starting Rent',
		tooltip:
			'Simple average of time adjusted annualized starting rent in $/SF. Gross for Office; Net for Industrial; unadjusted for all other space types.',
	},
	261: {
		id: 261,
		name: 'propertyMarketEffectiveRent',
		label: 'Property Market Effective Rent',
		tooltip:
			'Simple average of time adjusted annualized effective rent in $/SF. Gross for Office; Net for Industrial; unadjusted for all other space types.',
	},
	262: {
		id: 262,
		name: 'propertyAverageTransactionSize',
		label: 'Property Average Transaction SQFT',
	},
	272: {
		id: 272,
		name: 'lastSalePrice',
		label: 'Last Sale Price',
	},
	274: {
		id: 274,
		name: 'propertySqFtExpiringInTwelveMonths',
		label: 'SQ FT Expiring In Twelve Months',
	},
	284: {
		id: 284,
		name: 'insideviewEmployees',
		label: 'Tenant Employees',
	},
	285: {
		id: 285,
		name: 'insideviewRevenue',
		label: 'Tenant Revenue (USD)',
	},
	286: {
		id: 286,
		name: 'insideviewPhoneNumber',
		label: 'Tenant Phone Number',
	},
	287: {
		id: 287,
		name: 'insideviewWebsites',
		label: 'Tenant Website(s)',
	},
	288: {
		id: 288,
		name: 'insideviewStatus',
		label: 'Tenant Status',
	},
	289: {
		id: 289,
		name: 'insideviewParentCompany',
		label: 'Tenant Parent Name',
	},
	290: {
		id: 290,
		name: 'insideviewOwnership',
		label: 'Tenant Ownership',
	},
	291: {
		id: 291,
		name: 'insideviewTickers',
		label: 'Tenant Ticker Symbol',
	},
	292: {
		id: 292,
		name: 'insideviewNaics',
		label: 'Tenant Primary NAICS',
	},
	293: {
		id: 293,
		name: 'insideviewSic',
		label: 'Tenant SIC',
	},
	308: {
		id: 308,
		name: 'insideviewHeadquarters',
		label: 'Tenant HQ Address',
	},
	342: {
		id: 342,
		name: 'propertyStatus',
		label: 'Property Status',
	},
	343: {
		id: 343,
		name: 'totalUnits',
		label: 'Total Units',
	},
	344: {
		id: 344,
		name: 'occupancy',
		label: 'Current Occupancy Rate',
	},
	345: {
		id: 345,
		name: 'askingRpsf',
		label: 'Starting Rent, Monthly ($/SF)',
	},
	346: {
		id: 346,
		name: 'effectiveRpsf',
		label: 'Effective Rent, Monthly ($/SF)',
	},
	347: {
		id: 347,
		name: 'assetClassMarket',
		label: 'Multifamily Asset Class',
	},
	348: {
		id: 348,
		name: 'managementCompany',
		label: 'Management Company',
	},
	350: {
		id: 350,
		name: 'marketDisplayName',
		label: 'Market',
	},
	352: {
		id: 352,
		name: 'loanOriginator',
		label: 'Lender',
	},
	353: {
		id: 353,
		name: 'loanAmount',
		label: 'Loan Amount',
	},
	356: {
		id: 356,
		name: 'loanOriginationDate',
		label: 'Origination Date',
	},
	357: {
		id: 357,
		name: 'loanTerm',
		label: 'Original Term',
		initialSortDir: 'desc',
	},
	358: {
		id: 358,
		name: 'loanToValue',
		label: 'Loan-to-Value (LTV)',
	},
	362: {
		id: 362,
		name: 'loanMaturityDate',
		label: 'Loan Maturity Date',
		initialSortDir: 'desc',
	},
	364: {
		id: 364,
		name: 'currentLoanRate',
		label: 'Current Loan Rate',
	},
	366: {
		id: 366,
		name: 'loanInterestType',
		label: 'Interest Type',
	},
	368: {
		id: 368,
		name: 'loanSource',
		label: 'Loan Source',
	},
	370: {
		id: 370,
		name: 'apn',
		label: 'APN',
	},
	373: {
		id: 373,
		name: 'ceilingHeight',
		label: 'Clear Height',
	},
	374: {
		id: 374,
		name: 'groundLvlDoorsCount',
		label: 'Doors',
	},
	375: {
		id: 375,
		name: 'loadingDocksCount',
		label: 'Loading Docks',
	},
	376: {
		id: 376,
		name: 'sprinkler',
		label: 'Sprinkler',
	},
	377: {
		id: 377,
		name: 'parkingRatio',
		label: 'Parking Ratio',
	},
	378: {
		id: 378,
		name: 'spaceSubtype',
		label: 'Space Subtype',
		cellTooltip: ({ value }) => value,
	},
};

type ColumnId =
	| 0
	| 1
	| 2
	| 3
	| 4
	| 6
	| 7
	| 8
	| 9
	| 10
	| 11
	| 12
	| 13
	| 14
	| 15
	| 16
	| 17
	| 18
	| 20
	| 21
	| 22
	| 23
	| 24
	| 26
	| 27
	| 28
	| 29
	| 30
	| 31
	| 32
	| 33
	| 34
	| 37
	| 38
	| 39
	| 40
	| 41
	| 42
	| 43
	| 44
	| 45
	| 46
	| 47
	| 48
	| 49
	| 50
	| 51
	| 52
	| 55
	| 56
	| 57
	| 58
	| 59
	| 60
	| 61
	| 62
	| 63
	| 66
	| 70
	| 71
	| 72
	| 73
	| 74
	| 75
	| 76
	| 77
	| 80
	| 81
	| 82
	| 84
	| 85
	| 88
	| 90
	| 93
	| 109
	| 110
	| 111
	| 112
	| 124
	| 127
	| 128
	| 133
	| 138
	| 141
	| 142
	| 143
	| 152
	| 173
	| 174
	| 175
	| 178
	| 179
	| 181
	| 182
	| 189
	| 190
	| 191
	| 192
	| 193
	| 194
	| 195
	| 196
	| 197
	| 198
	| 199
	| 200
	| 201
	| 202
	| 203
	| 204
	| 205
	| 206
	| 209
	| 210
	| 211
	| 212
	| 213
	| 216
	| 217
	| 218
	| 222
	| 223
	| 224
	| 225
	| 226
	| 243
	| 247
	| 249
	| 260
	| 261
	| 262
	| 272
	| 274
	| 284
	| 285
	| 286
	| 287
	| 288
	| 289
	| 290
	| 291
	| 292
	| 293
	| 308
	| 342
	| 343
	| 344
	| 345
	| 346
	| 347
	| 348
	| 350
	| 352
	| 353
	| 356
	| 357
	| 358
	| 362
	| 364
	| 366
	| 368
	| 370
	| 373
	| 374
	| 375
	| 376
	| 377
	| 378;

export type ColumnConfig = Record<ColumnId, ColumnConfigValues>;

export type ColumnConfigValues = {
	id: ColumnId;
	/** Any possible key (nested included) from any Comp */
	name: TableCompKeys;
	label: string;
	cellTooltip?: (args: {
		row: AnyComp;
		value: ReactNode;
		config: ColumnConfigValues;
	}) => ReactNode;
	tooltip?: ReactNode;
	/** If not defined, config.name will be used. Use it for exceptional cases */
	sortName?: string;
	/** @default true */
	isSortable?: boolean;
	/** @default "asc" */
	initialSortDir?: SortDirection;
	/** @default false */
	showWarningIfNoValue?: boolean;
	featureFlag?: keyof FeatureFlags;
	featureFlagOff?: keyof FeatureFlags;
};
