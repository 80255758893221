import { FiltersObject } from 'models/filters/types';
import { filtersToServerJSON } from 'models/filters/util';
import { useMemo } from 'react';

type Props = {
	filters: FiltersObject;
};

export const useSearchInfiniteParams = ({ filters }: Props) => {
	return useMemo(
		() => ({
			order: filters.sortDirection,
			sort: filters.sortField,
			filter: filtersToServerJSON(filters),
		}),
		[filters]
	);
};
