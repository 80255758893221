import { useLayoutEffect, useMemo, useState } from 'react';

export const useRect = (el: HTMLElement | null) => {
	const [rect, setRect] = useState<DOMRect | null>(null);

	const observer = useMemo(() => {
		return new ResizeObserver(() => {
			if (!el) return;
			setRect(el.getBoundingClientRect());
		});
	}, [el]);

	useLayoutEffect(() => {
		if (!el) return;
		observer.observe(el);
	}, [observer, el]);

	return rect;
};
