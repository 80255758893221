import React, { Component } from 'react';

import './hideable.nomodule.css';

export default class Hideable extends Component {
	className() {
		let className = 'hideable';
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
		if (this.props.className) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
			className += ' ' + this.props.className;
		}

		if (
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'hiddenFields' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.hiddenFields &&
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'hiddenFields' does not exist on type 'Re... Remove this comment to see the full error message
			this.props.hiddenFields.indexOf(this.props.hideableKey) !== -1
		) {
			className += ' field-hidden';
		}
		return className;
	}

	render() {
		// @ts-expect-error TS2339: Property 'children' does not e...
		return <span className={this.className()}>{this.props.children}</span>;
	}
}
