import { MODAL_SHOW } from 'Singletons/Modal/actions';

import { MENU_SHOW, MENU_HIDE } from './actions';
import { MenuAction, MenuState } from './types';

import { LOCATION_CHANGE } from 'redux-first-history';
import { LocationChangeRouterAction } from 'middleware/filtersFromRoutes';

const initialState: MenuState = {
	data: null,
	// @ts-expect-error TS2322: Type 'null' is not assignable ...
	component: null,
	// @ts-expect-error TS2322: Type 'null' is not assignable ...
	target: null,
	id: new Date().valueOf(),
	clickOutsideToClose: true,
	// @ts-expect-error TS2322: Type 'null' is not assignable ...
	className: null,
	onTargetLeaveCallback: null,
	// @ts-expect-error TS2322: Type 'null' is not assignable ...
	position: null,
};

export default (
	state = initialState,
	action: MenuAction | LocationChangeRouterAction
) => {
	switch (action.type) {
		case MENU_SHOW:
			return {
				...action.payload,
				id: new Date().valueOf(),
			};

		case MODAL_SHOW:
		case MENU_HIDE:
		case LOCATION_CHANGE:
			return initialState;
	}
	return state;
};
