import { memo } from 'react';
import { emailConstants, phoneNumberConstants } from '@compstak/common';
import styled from 'styled-components';

export const ContactUsParagraph = memo(
	({ contactEmail = emailConstants.sales }: { contactEmail?: string }) => {
		const contactPhoneDashed = phoneNumberConstants.phone1.dashed;
		const contactPhoneDotted = phoneNumberConstants.phone1.dotted;
		return (
			<Paragraph>
				Contact us at{' '}
				<a
					data-qa-id={`contact-us-modal-mail-${contactEmail}`}
					href={`mailto:${contactEmail}`}
				>
					{contactEmail}
				</a>{' '}
				or call{' '}
				<a
					data-qa-id={`contact-us-modal-phone-number-${contactPhoneDashed}`}
					href={`tel:${contactPhoneDashed}`}
				>
					{contactPhoneDotted}
				</a>
			</Paragraph>
		);
	}
);

ContactUsParagraph.displayName = 'ContactUsParagraph';

const Paragraph = styled.p`
	color: ${({ theme }) => theme.colors.gray.n70};
	margin-top: 18px;

	a {
		color: inherit;
		text-decoration: underline;
	}
`;
