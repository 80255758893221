import { QueryClient, useQueryClient } from '@tanstack/react-query';

/** HOC to pass the QueryClient to class components */
export const withQueryClient = <Props extends WithQueryClientProps>(
	Component: React.ComponentType<Props>
) => {
	const WithQueryClient = (props: WithQueryClientComponentProps<Props>) => {
		const queryClient = useQueryClient();

		return (
			// @ts-expect-error TS2322: Type '{ queryClient: QueryClie...
			<Component
				queryClient={queryClient}
				{...(props as WithQueryClientComponentProps<Props>)}
			/>
		);
	};

	return WithQueryClient;
};

type WithQueryClientComponentProps<Props extends WithQueryClientProps> = Omit<
	Props,
	keyof WithQueryClientProps
>;

export type WithQueryClientProps = {
	queryClient: QueryClient;
};
