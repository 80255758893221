import { CellWarning } from 'Components/SearchTable/CellWarning';
import { LeaseComp } from 'types';
import { formatRentWithPostfix } from './formatRentWithPostfix';

export const formatStartingRent = (
	row: LeaseComp,
	value: number,
	isMonthly?: boolean
) => {
	if (
		'presentationRentName' in row &&
		row.presentationRentName != null &&
		row.presentationRentName !== 'startingRent'
	) {
		let content = row.presentationRentName.replace('Rent', '');
		if (content === 'avg') {
			content = 'Average';
		} else {
			content = content[0].toUpperCase() + content.slice(1);
		}
		return <CellWarning>{content} Rent Only</CellWarning>;
	}
	return formatRentWithPostfix(value, isMonthly);
};
