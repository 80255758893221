import { useMemo } from 'react';
import { LeaseComp } from 'types';
import { ServerFilterItem } from 'types/serverFilters';
import { useAllSearchLeasesForMap } from './useAllSearchLeasesForMap';

type Props = {
	filters: ServerFilterItem[];
};

export const useAllMapSearchLeasesByPropertyId = ({ filters }: Props) => {
	const { data } = useAllSearchLeasesForMap({
		filters,
	});

	return useMemo(() => {
		if (!data) return {};

		const byPropertyId: Record<number, LeaseComp[]> = {};

		for (const lease of data.comps) {
			if (byPropertyId[lease.propertyId]) {
				byPropertyId[lease.propertyId].push(lease);
			} else {
				byPropertyId[lease.propertyId] = [lease];
			}
		}

		return byPropertyId;
	}, [data]);
};
