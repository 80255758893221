import styled from 'styled-components';

export const GrayPostfix = styled.span`
	color: ${(p) => p.theme.colors.neutral.n50};
`;

export const Gapper = styled.div`
	display: flex;
	gap: 0.25rem;
`;
