import { APIClientNotOkResponseError } from '@compstak/common';
import { QueryOptions } from '@tanstack/react-query';
import { isObject } from 'lodash';

export const noRetryFor404: QueryOptions['retry'] = (failureCount, error) => {
	if (isAPIClientNotOkResponseError(error) && error.response.status === 404)
		return false;
	return failureCount < 3;
};

export function isAPIClientNotOkResponseError<T = unknown>(
	error: unknown
): error is APIClientNotOkResponseError<T> {
	return (
		isObject(error) &&
		'response' in error &&
		isObject(error.response) &&
		'ok' in error.response &&
		!error.response.ok
	);
}
