import { useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { createFiltersParamWithMarketId } from './createFiltersParamWithMarketId';

export const useFiltersParamWithMarketId = () => {
	const [filters] = useFilters();

	return useMemo(() => {
		return createFiltersParamWithMarketId(filters);
	}, [filters]);
};
