const months = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'June',
	'July',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

// @ts-expect-error TS7006: Parameter 'value' implicitly h...
function dateFormat(value, fullYear, locale) {
	if (value === undefined || value === null) {
		throw new Error('Tried to format an undefined or null date.');
	}

	const date = new Date(value);

	if (!date.valueOf()) {
		throw new Error('Tried to format an invalid date.');
	}

	let output;

	//UK - "28 Jan 2016"
	//US - "Jan 28, 2016"

	switch (locale) {
		case 'en_GB':
			output =
				date.getUTCDate() +
				' ' +
				months[date.getUTCMonth()] +
				' ' +
				date.getUTCFullYear();
			break;
		// TODO: en-US should be removed once BE is fixed
		case 'en_US':
		case 'en-US':
			output =
				months[date.getUTCMonth()] +
				' ' +
				date.getUTCDate() +
				', ' +
				date.getUTCFullYear();
			break;

		default:
			throw new Error(
				'Tried to format a date with an unknown locale: ' + locale + '.'
			);
	}

	return output;
}

export default dateFormat;
