import { QUERY_KEYS } from 'api/constants';
import {
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query';
import { API, buildQuery, formatRequestString } from '@compstak/common';
import { APIDateRequestParams } from '../../Pages/PropertyPageV2_1/api/interfaces';
import { SuggestedSpan } from '../../Pages/PropertyPageV2_1/type';

export type NOIMetric = 'perUnit' | 'percentageOfRevenue';

export type NOIDTO = {
	trendLine: Array<{ date: string; value: number }>;
	suggestedSpan?: SuggestedSpan;
};

export interface FetchNOIApiParams extends APIDateRequestParams {
	id: string | number;
	metric: NOIMetric;
}

type QueryConfig = {
	enabled?: boolean;
};

export type UseNOIByParamsResponse = UseQueryResult<NOIDTO>;

const fetchNOI = async ({
	id,
	metric,
	...rest
}: FetchNOIApiParams): Promise<NOIDTO> => {
	const query = buildQuery({ ...rest });

	const response = await API.get<NOIDTO>(
		formatRequestString({
			entityUrl: `/mufa/properties/${id}/rpSubmarket/noi/${metric}?${query}`,
		})
	);

	return response.data;
};

const useNOIByParams = (
	params: FetchNOIApiParams,
	queryConfig?: QueryConfig
): UseNOIByParamsResponse =>
	useQuery(
		[QUERY_KEYS.MUFA_PROPERTY_NOI, params],
		() => fetchNOI(params),
		queryConfig
	);

export const useNOIData = (
	params: FetchNOIApiParams,
	queryConfig?: Pick<UseQueryOptions, 'enabled'>
): UseNOIByParamsResponse => {
	const result = useNOIByParams(params, queryConfig);

	return result;
};
