// @ts-expect-error TS7016: Could not find a declaration f...
import rollingStorage from 'rolling-storage';

export type ServiceURL<Payload = unknown> = (id: Payload) => string;

export type Options<Payload = unknown> = {
	cache?: any;
	createKey?: (payload: Payload) => string;
	createUrl: ServiceURL<Payload>;
	createData?: (payload: Payload) => any;
	timeout?: number;
	tryAgainOnFailure?: boolean;
	mappings?: any;
	errors?: any;
};

export interface Service<Payload = unknown, Response = unknown> {
	load: (payload: Payload) => Promise<Response>;
	put: Function;
	loadMany: (payload: Payload[]) => Promise<Response[]>;
	post: Function;
	of: Function;
	add: Function;
	has: Function;
	clear: Function;
	clearAll: Function;
	keyOf: NonNullable<Options<Payload>['createKey']>;
}

export default class ServiceController<Payload = unknown, Response = unknown> {
	createKey: NonNullable<Options<Payload>['createKey']>;
	createUrl: Options<Payload>['createUrl'];
	createData: Options<Payload>['createData'];
	timeout: NonNullable<Options<Payload>['timeout']>;
	service: Service<Payload, Response>;
	options: Options<Payload>;
	promises: any;
	identifiers: any;
	cache: Options['cache'];
	cacheClearTimeouts: any;

	constructor(options: Options<Payload>, makeConnection: Function) {
		// @ts-expect-error TS2322: Type '((payload: Payload) => s...
		this.createKey = options.createKey;
		this.createUrl = options.createUrl;
		this.createData = options.createData;
		this.timeout = options.timeout || 0;
		this.options = options;

		const tryAgainOnFailure = options.tryAgainOnFailure ?? !this.createData;

		if (!this.createKey) {
			if (this.createData) {
				this.createKey = (identifier) => {
					return JSON.stringify(this.createData!(identifier));
				};
			} else {
				this.createKey = this.createUrl;
			}
		}

		this.cache;
		if (options.cache) {
			this.cache = rollingStorage(options.cache);
			this.timeout = 5000; // so many rapid calls don't hit localStorage.
		}

		this.promises = {};
		this.identifiers = {};
		this.cacheClearTimeouts = {};

		this.service = {
			// @ts-expect-error TS2322: Type '(identifier: string | Pr...
			load: (identifier: string | Promise<any>) => {
				if (identifier instanceof Promise) {
					return this.extendPromise(
						identifier.then((resolvedIdentifier) => {
							return this.service.load(resolvedIdentifier);
						})
					);
				}

				// @ts-expect-error TS2345: Argument of type 'string' is n...
				const key = this.createKey(identifier);

				if (this.promises[key]) {
					return this.promises[key];
				}

				if (this.cache && this.cache.has(key)) {
					return this.service.of(this.cache.get(key));
				}

				// @ts-expect-error TS2345: Argument of type 'string' is n...
				const url = this.createUrl(identifier);
				let promise;

				if (this.createData) {
					promise = makeConnection(
						'POST',
						url,
						// @ts-expect-error TS2345: Argument of type 'string' is n...
						this.createData(identifier),
						tryAgainOnFailure
					).promise;
				} else {
					promise = makeConnection(
						'GET',
						url,
						undefined,
						tryAgainOnFailure
					).promise;
				}

				this.addToCache(identifier, promise);

				// if (onload) {
				//  promise.then(function (data) {
				//      onload(identifier, data);
				//  });
				// }

				return this.extendPromise(promise);
			},

			loadMany: (identifiersToLoad: Payload[]) => {
				const loadingPromises = identifiersToLoad.map(this.service.load);

				const allPromise = Promise.all(loadingPromises);

				if (options.mappings) {
					Object.keys(options.mappings).forEach((mapping) => {
						// @ts-expect-error TS7053: Element implicitly has an 'any...
						allPromise[mapping] = function () {
							const mappedPromises = loadingPromises.map((promise) => {
								// @ts-expect-error TS7053: Element implicitly has an 'any...
								return promise[mapping]();
							});
							return Promise.all(mappedPromises);
						};
						// @ts-expect-error TS7053: Element implicitly has an 'any...
						allPromise[mapping].original = allPromise;
					});
				}

				return allPromise;
			},
			// @ts-expect-error TS7006: Parameter 'identifier' implici...
			put: (identifier, data, tryThisConnectionAgainOnFailure) => {
				this.clearCacheForIdentifier(identifier, true);
				const url = this.createUrl(identifier);
				const promise = makeConnection(
					'PUT',
					url,
					data,
					tryThisConnectionAgainOnFailure
				).promise;
				this.addToCache(identifier, promise);
				return promise;
			},
			// @ts-expect-error TS7006: Parameter 'identifier' implici...
			post: (identifier, data, tryThisConnectionAgainOnFailure) => {
				this.clearCacheForIdentifier(identifier, true);
				const url = this.createUrl(identifier);
				const promise = makeConnection(
					'POST',
					url,
					data,
					tryThisConnectionAgainOnFailure
				).promise;
				this.addToCache(identifier, promise);
				return promise;
			},
			// @ts-expect-error TS7006: Parameter 'data' implicitly ha...
			of: (data) => {
				return this.extendPromise(Promise.resolve(data));
			},
			// @ts-expect-error TS7006: Parameter 'identifier' implici...
			add: (identifier, data) => {
				const promise = this.service.of(data);

				if (this.cache) {
					// @ts-expect-error TS7006: Parameter 'dataResult' implici...
					promise.then((dataResult) => {
						this.cache.set(this.createKey(identifier), dataResult);
					});
				}

				this.addToCache(identifier, promise);

				return promise;
			},
			// @ts-expect-error TS7006: Parameter 'identifier' implici...
			has: (identifier) => {
				const key = this.createKey(identifier);
				if (this.promises[key] || (this.cache && this.cache.has(key))) {
					return true;
				}
				return false;
			},
			keyOf: this.createKey,
			// @ts-expect-error TS7006: Parameter 'identifier' implici...
			clear: (identifier) => {
				this.clearCacheForIdentifier(identifier, true);
			},
			clearAll: () => {
				Object.keys(this.identifiers).forEach((key) => {
					this.clearCacheForIdentifier(this.identifiers[key], true);
				});

				if (this.cache) {
					this.cache.flush();
				}
			},
		};
	}

	// @ts-expect-error TS7006: Parameter 'identifier' implici...
	clearCacheForIdentifier = (identifier, storageToo = false) => {
		const key = this.createKey(identifier);
		clearTimeout(this.cacheClearTimeouts[key]);

		if (this.cache && storageToo) {
			this.cache.remove(key);
		}

		delete this.promises[key];
		delete this.cacheClearTimeouts[key];
	};

	// @ts-expect-error TS7006: Parameter 'promise' implicitly...
	extendPromise = (promise) => {
		if (this.options.mappings) {
			Object.keys(this.options.mappings).forEach((mapping) => {
				promise[mapping] = function () {
					return promise.then(this.options.mappings[mapping]);
				};
				promise[mapping].original = promise;
			});
		}
		return promise;
	};

	// @ts-expect-error TS7006: Parameter 'identifier' implici...
	addToCache = (identifier, promise) => {
		const key = this.createKey(identifier);
		this.promises[key] = promise;
		this.identifiers[key] = identifier;

		promise.then(
			// @ts-expect-error TS7006: Parameter 'data' implicitly ha...
			(data) => {
				if (key && this.cache) {
					this.cache.set(key, data);
				}
			},
			(xhr: XMLHttpRequest) => {
				if (key && this.promises) {
					delete this.promises[key];
				}

				if (this.options.errors && this.options.errors[xhr.status]) {
					return this.options.errors[xhr.status](xhr.responseText, xhr.status);
				}
			}
		);

		if (this.timeout) {
			this.cacheClearTimeouts[identifier] = setTimeout(() => {
				this.clearCacheForIdentifier(identifier);
			}, this.timeout);
		}
	};

	exportable = <T = {}>(target: T): T & Service<Payload, Response> => {
		return {
			...this.service,
			...target,
		};
	};
}
