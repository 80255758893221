/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ChangeEvent, useEffect, useState } from 'react';

import Menu from 'Singletons/Menu';
import {
	saveSearch,
	updateSavedSearch,
	clearCurrentSavedSearch,
} from 'Pages/SavedSearches/actions';

import '../styles/saveSearchMenu.nomodule.less';
import { FiltersObject } from 'models/filters/types';
import { CompType } from 'types';
import { SavedSearch, useSavedSearches } from 'reducers/savedSearches';
import { filtersToServerJSON } from 'models/filters/util/serverJson';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import pluralizeCompType from 'util/pluralizeCompType';
import { useDispatch } from 'react-redux';
import { useUser } from 'Pages/Login/reducers';
import { hideMenu } from 'Singletons/Menu/actions';
import { useModal } from 'providers/ModalProvider';
import { ShareSearchModal } from 'Components/Modals/ShareSearchModal/ShareSearchModal';

export type SaveSearchMenuProps = {
	compType: CompType;
	filters: FiltersObject;
};

const SaveSearchMenu = (props: SaveSearchMenuProps) => {
	const { currentSavedSearch } = useSavedSearches();
	const needToReset =
		!!currentSavedSearch &&
		currentSavedSearch.searchType !== pluralizeCompType(props.compType, false);

	const [formState, setFormState] = useState<{
		savedSearchName: string;
		receiveEmailUpdates: boolean;
	}>({
		savedSearchName: needToReset ? '' : currentSavedSearch?.name ?? '',
		receiveEmailUpdates: needToReset
			? true
			: currentSavedSearch?.notifyByEmail ?? true,
	});

	const user = useUser()!;
	const dispatch = useDispatch();
	const { openModal } = useModal();

	useEffect(() => {
		if (needToReset) {
			dispatch(clearCurrentSavedSearch());
		}
	}, [needToReset, currentSavedSearch, dispatch]);

	const cancelSaveSearchAndCloseMenu = () => {
		dispatch(clearCurrentSavedSearch());
		dispatch(hideMenu('save-search-menu'));
	};

	const onSaveSearch = () => {
		dispatch(
			saveSearch({
				filters: props.filters,
				search: formState,
				compType: props.compType,
				onShare:
					user.userType !== 'exchange'
						? (savedSearch) =>
								openModal({
									modalContent: <ShareSearchModal search={savedSearch} />,
								})
						: undefined,
			})
		);
		cancelSaveSearchAndCloseMenu();
	};

	const onUpdateSavedSearch = (savedSearch: SavedSearch) => {
		dispatch(
			updateSavedSearch(savedSearch, {
				filters: filtersToServerJSON(props.filters),
				sortAsc: props.filters.sortDirection === 'asc',
				sortProperty: props.filters.sortField,
				name: formState.savedSearchName,
				notifyByEmail: formState.receiveEmailUpdates,
				marketId: getFiltersMarkets(props.filters)[0].id,
			})
		);
		cancelSaveSearchAndCloseMenu();
	};

	const handleSavedSearchNameInput = (event: ChangeEvent<HTMLInputElement>) => {
		setFormState({
			...formState,
			savedSearchName: event.target.value,
		});
	};

	const handleReceiveEmailUpdatesChange = (
		event: ChangeEvent<HTMLInputElement>
	) => {
		setFormState({
			...formState,
			receiveEmailUpdates: event.target.checked,
		});
	};

	const onSave = () => {
		if (currentSavedSearch) {
			onUpdateSavedSearch(currentSavedSearch);
		} else {
			onSaveSearch();
		}
	};

	const isSaveDisabled = !formState.savedSearchName.trim();

	return (
		<div className="save-search-popup">
			<div className="form">
				<input
					data-qa-id="save-search-popup-name"
					type="text"
					onChange={handleSavedSearchNameInput}
					value={formState.savedSearchName}
					placeholder="Save Search As"
				/>
			</div>
			<div className="email-update-checkbox">
				<input
					data-qa-id="save-search-popup-receive-email-updates"
					type="checkbox"
					onChange={handleReceiveEmailUpdatesChange}
					checked={formState.receiveEmailUpdates}
					className="checkbox"
					id="receive-email-updates"
				/>
				<label htmlFor="receive-email-updates" className="checkbox">
					Receive email updates
				</label>
			</div>
			<div className="save-search-actions">
				<div
					onClick={() => dispatch(hideMenu('save-search-menu'))}
					className="button_button button_dark_blue save-search-action safari-flexbox-bugged"
				>
					Cancel
				</div>
				<div
					onClick={isSaveDisabled ? undefined : onSave}
					className="button_button button_blue save-search-action safari-flexbox-bugged"
					// @ts-expect-error TS2322: Type '{ children: string; onCl...
					disabled={isSaveDisabled}
					data-tooltip={isSaveDisabled ? 'Enter Name To Save Search' : null}
				>
					Save
				</div>
			</div>
		</div>
	);
};

Menu.addComponent('save-search-menu', SaveSearchMenu);

export default 'save-search-menu';
