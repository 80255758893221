/* eslint-disable prefer-rest-params */
import { AppDispatch } from 'store';

type ExtractFunctions<T> = {
	[K in keyof T as T[K] extends Function | object
		? K
		: never]: T[K] extends Function
		? T[K]
		: T[K] extends object
		? ExtractFunctions<T[K]>
		: never;
};

export default function wrapActions<T extends object>(
	actions: T,
	dispatch: AppDispatch
) {
	return Object.keys(actions).reduce((acc, key) => {
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		if (typeof actions[key] === 'function') {
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			acc[key] = function () {
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				return dispatch(actions[key].apply(null, arguments));
			};
		} else {
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			acc[key] = Object.keys(
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				actions[key] as Record<string, Record<string, Function>>
			).reduce((actionAcc, actionKey) => {
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				if (typeof actions[key][actionKey] === 'function') {
					// @ts-expect-error TS7053: Element implicitly has an 'any...
					actionAcc[actionKey] = function () {
						// @ts-expect-error TS7053: Element implicitly has an 'any...
						return dispatch(actions[key][actionKey].apply(null, arguments));
					};
				}
				return actionAcc;
			}, {});
		}

		return acc;
	}, {} as ExtractFunctions<T>);
}
