function addGwPrefix(url: string) {
	if (typeof url !== 'string') {
		throw Error('addGwPrefix: url is not a string!');
	}
	const gwHost = window._env_.REACT_APP_GATEWAY_HOST;
	if (typeof gwHost !== 'string') {
		throw Error('addGwPrefix: gwHost is not a string!');
	}
	return gwHost + url;
}

export function mapUrlToGateway(url: string) {
	if (url.startsWith('/user-service')) {
		// User service swagger:
		// https://user-service.dev.cs-int-592.com/docs/swagger/
		return (
			addGwPrefix(window._env_.REACT_APP_GATEWAY_USER_SERVICE_PREFIX) +
			url.replace('/user-service', '')
		);
	}

	if (url.startsWith('/ppm-property')) {
		// PPM property service swagger:
		// https://ppm-property.dev.cs-int-592.com/docs/swagger/
		return (
			addGwPrefix(window._env_.REACT_APP_GATEWAY_PPM_PROPERTY_PREFIX) +
			url.replace('/ppm-property', '')
		);
	}
	return url;
}
