import { PropertyLoanResponse } from './types';

export const getHasLoanData = (loans: PropertyLoanResponse) => {
	const hasPublicLoans = loans.publicLoans && loans.publicLoans.length > 0;
	const hasTreppLoans = loans.treppLoans && loans?.treppLoans.length > 0;

	return {
		hasPublicLoans,
		hasTreppLoans,
		hasLoans: hasPublicLoans || hasTreppLoans,
	};
};
