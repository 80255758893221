import React, { useEffect, useRef, useState } from 'react';
import Autosuggest, {
	InputProps,
	RenderSuggestion,
	SuggestionsFetchRequested,
} from 'react-autosuggest';

import './typeahead.less';
import { Market } from '@compstak/common';
import { CompType } from 'types/comp';
import {
	DEFAULT_SUGGESTIONS_LIMIT,
	SuggestionField,
	SuggestionItemV1,
} from 'api/suggestions/suggestions';
import { FILTERS_DEBOUNCE_TIMEOUT } from 'util/formConstants';
import { useDebouncedCallback } from 'use-debounce/lib';
import { FilterSpinner } from 'Components/Filters/Base/Filter/FilterSpinner';
import { FiltersObject } from 'models/filters/types';
import { useSuggestionsV1Query } from 'api/suggestions/suggestionsV1/useSuggestionsV1Query';
import {
	ClearbitCompanySuggestion,
	useClearbitCompanySuggestions,
} from 'api/suggestions/clearbit/useClearbitCompanySuggestions';

type SharedProps<
	TSuggestion extends ClearbitCompanySuggestion | SuggestionItemV1,
> = {
	className?: string;
	displaySelectedValue?: boolean;
	getSuggestionValue?: (suggestion: TSuggestion) => string;
	keepValueOfSelection?: boolean;
	onBlur?: NoArgCallback;
	onChange?: InputProps<TSuggestion>['onChange'];
	onSelection: (value: string) => void;
	placeholder?: string;
	renderSuggestion?: RenderSuggestion<TSuggestion>;
	defaultValue?: string | null;
};

type ClearbitProps = SharedProps<ClearbitCompanySuggestion> & {
	source: 'clearbit';
	compType?: undefined;
	typeaheadAttribute?: undefined;
	filters?: undefined;
	markets?: undefined;
	omittedSuggestions?: undefined;
};

type CommonProps = SharedProps<SuggestionItemV1> & {
	source?: undefined;
	compType: CompType;
	typeaheadAttribute: SuggestionField;
	filters?: FiltersObject;
	markets: Market[];
	omittedSuggestions?: SuggestionItemV1[];
};

type Props = ClearbitProps | CommonProps;

export const Typeahead = (props: Props) => {
	const [query, setQuery] = useState('');
	const [value, setValue] = useState(props.defaultValue ?? '');
	const ref = useRef<HTMLDivElement>(null);
	const compType = props.compType ?? 'lease';
	const markets = props.markets ?? [];
	const omittedSuggestions = props.omittedSuggestions ?? [];

	const { data: suggestionsV1, isFetching: isFetchingSuggestionsV1 } =
		useSuggestionsV1Query(
			{
				query,
				compType: compType,
				marketId: markets.map(({ id }) => id),
				field: props.typeaheadAttribute,
				filters: props.filters,
				limit: omittedSuggestions.length + DEFAULT_SUGGESTIONS_LIMIT,
			},
			{ enabled: props.source !== 'clearbit' }
		);

	const {
		data: clearbitCompanySuggestions,
		isFetching: isFetchingClearbitCompanySuggestions,
	} = useClearbitCompanySuggestions(
		{
			query,
		},
		{ enabled: props.source === 'clearbit' }
	);

	const isFetching =
		isFetchingSuggestionsV1 || isFetchingClearbitCompanySuggestions;

	const suggestions = (() => {
		if (props.source === 'clearbit') {
			if (!clearbitCompanySuggestions?.length) {
				return [];
			}

			return clearbitCompanySuggestions;
		}

		if (!suggestionsV1?.length) {
			return [];
		}

		const field = suggestionsV1.find(
			(f) => f.field === props.typeaheadAttribute
		);
		if (field) {
			return field.suggestions
				.filter((sug) => !omittedSuggestions.includes(sug))
				.slice(0, DEFAULT_SUGGESTIONS_LIMIT);
		}
		return [];
	})();

	const renderSuggestion: RenderSuggestion<
		SuggestionItemV1 | ClearbitCompanySuggestion
	> = (suggestion, params) => {
		if (props.renderSuggestion) {
			return props.renderSuggestion?.(
				suggestion as SuggestionItemV1 & ClearbitCompanySuggestion,
				params
			);
		}

		return <span>{suggestion as string}</span>;
	};

	useEffect(() => {
		setValue(props.defaultValue ?? '');
	}, [props.defaultValue]);

	useEffect(() => {
		const input = ref.current?.querySelector('input');
		if (input && !input.value) {
			input.focus();
		}
	}, []);

	// when suggestion selected, this function tells what should be the value of the input
	const getSuggestionValue = (
		suggestion: SuggestionItemV1 | ClearbitCompanySuggestion
	) => {
		if (props.getSuggestionValue) {
			return props.getSuggestionValue(
				suggestion as SuggestionItemV1 & ClearbitCompanySuggestion
			);
		}

		if (typeof suggestion === 'string') {
			setValue(suggestion);
			return suggestion;
		}

		return '';
	};

	const triggerSelected = (value: string) => {
		props.onSelection(value);
		if (props.displaySelectedValue) {
			setValue(value);
		} else if (!props.keepValueOfSelection) {
			setValue('');
		}
	};

	const onChange: InputProps<
		SuggestionItemV1 | ClearbitCompanySuggestion
	>['onChange'] = (event, { newValue, method }) => {
		setQuery('');
		if (props.onChange) {
			props.onChange(event, { newValue, method });
		}
		switch (method) {
			case 'enter':
			case 'click':
				triggerSelected(newValue);
				break;

			default:
				setValue(newValue);
		}
	};

	const requestSuggestions: SuggestionsFetchRequested = ({ value }) => {
		setQuery(value);
	};

	const [debouncedRequestSuggestions] = useDebouncedCallback(
		requestSuggestions,
		FILTERS_DEBOUNCE_TIMEOUT
	);

	const inputProps: InputProps<SuggestionItemV1 | ClearbitCompanySuggestion> = {
		placeholder: props.placeholder || 'Add',
		value,
		onChange,
		onBlur: props.onBlur,
	};

	return (
		<>
			<div className={`typeahead-view ${props.className ?? ''}`} ref={ref}>
				<Autosuggest<SuggestionItemV1 | ClearbitCompanySuggestion>
					suggestions={suggestions}
					onSuggestionsFetchRequested={debouncedRequestSuggestions}
					onSuggestionsClearRequested={() => []}
					getSuggestionValue={getSuggestionValue}
					onSuggestionSelected={(_event, { suggestionValue }) =>
						triggerSelected(suggestionValue)
					}
					renderSuggestion={renderSuggestion}
					inputProps={inputProps}
					highlightFirstSuggestion={true}
				/>
				{isFetching && <FilterSpinner />}
			</div>
		</>
	);
};

export default Typeahead;
