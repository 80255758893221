import { MarketResponse } from 'api/markets/useMarketsQuery';
import factory from './factory';
import { Market } from '@compstak/common';

// @ts-expect-error TS7006: Parameter 'markets' implicitly...
function byId(markets) {
	// @ts-expect-error TS7006: Parameter 'acc' implicitly has...
	return markets.reduce(function (acc, market) {
		acc[market.id] = market;
		return acc;
	}, {});
}

function byName(markets: Market[]) {
	return markets.reduce(function (acc, market) {
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		acc[market.name] = market;
		return acc;
	}, {});
}

const serviceController = factory.create<number | void, MarketResponse[]>({
	createUrl: function (id) {
		if (id) {
			return '/api/markets/' + id;
		} else {
			return '/api/markets';
		}
	},
	mappings: {
		byId: byId,
		byName: byName,
	},
});

const exportable = serviceController.exportable({
	activate(id: number) {
		return factory.post('/api/admin/markets/' + id + '/sendActivation');
	},

	getTopSubmarkets(marketId: number): Promise<string[]> {
		return factory.get('/api/markets/' + marketId + '/topSubmarkets');
	},

	byId,
	byName,
});

export default exportable;
