import { FeatureCollection } from '@turf/helpers';
import basicFactory from './factory';
import { SubmarketPolygon } from 'api/submarketPolygons/useSubmarketPolygons';

// Real response type
type SubmarketsResponse = FeatureCollection<
	SubmarketPolygon['geometry'],
	SubmarketPolygon['properties']
>;

type SubmarketsPayload = {
	marketId: number;
};

// list of markets which submarket geometries are not simplified
const SIMPLIFIED_MARKETS_BLACK_LIST = [52]; // 52 - Hawaii

const serviceController = basicFactory.create<
	SubmarketsPayload,
	// mapped from response type, not real response
	SubmarketPolygon[]
>({
	createUrl: ({ marketId }) => {
		const isSimplified = !SIMPLIFIED_MARKETS_BLACK_LIST.includes(marketId);

		return `/api/markets/${marketId}/submarketPolygons${
			isSimplified ? '/simplified' : ''
		}`;
	},
});

const exportable = serviceController.exportable({});
const load = exportable.load;
const loadMany = exportable.loadMany;
// mapping real response to a mapped type
exportable.load = async ({ marketId }) => {
	const response = (await load({
		marketId,
	})) as unknown as SubmarketsResponse;
	return response.features;
};
exportable.loadMany = async (payloads) => {
	const response = (await loadMany(
		payloads
	)) as unknown as SubmarketsResponse[];
	return response.map(({ features }) => features);
};

export default exportable;
