import { QUERY_KEYS } from 'api/constants';
import {
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query';
import { API, buildQuery, formatRequestString } from '@compstak/common';
import {
	APIDateRequestParams,
	Indicator,
} from '../../Pages/PropertyPageV2_1/api/interfaces';
import { Timeframes } from '../../Pages/PropertyPageV2_1/api/interfaces/common';
import { addEmptyData } from '@compstak/common';
import { SuggestedSpan } from '../../Pages/PropertyPageV2_1/type';

export interface LeaseTradeOutDTO {
	new: Indicator;
	renewal: Indicator;
	all: Indicator;
	rpSubmarketName: string;
	suggestedSpan?: SuggestedSpan;
}

export interface FetchLeaseTradeOutApiParams extends APIDateRequestParams {
	id: string | number;
}

export type UseLeaseTradeOutResponse = UseQueryResult<LeaseTradeOutDTO>;
export type QueryArguments = Omit<FetchLeaseTradeOutApiParams, 'id' | 'metric'>;

export const trendlineTypes = ['all', 'new', 'renewal'];

export const fetchLeaseTradeOut = ({
	id,
	...rest
}: FetchLeaseTradeOutApiParams): Promise<LeaseTradeOutDTO> => {
	const query = buildQuery({ ...rest });

	return API.get<LeaseTradeOutDTO>(
		formatRequestString({
			entityUrl: `/mufa/properties/${id}/rpSubmarket/leaseTradeOutTrend?${query}`,
		})
	)
		.then((response) => response.data)
		.then(addEmptyData(trendlineTypes, Timeframes.MoM));
};

export const useLeaseTradeOutData = (
	params: FetchLeaseTradeOutApiParams,
	queryConfig?: Pick<UseQueryOptions, 'enabled'>
): UseLeaseTradeOutResponse => {
	return useQuery(
		[QUERY_KEYS.MUFA_PROPERTY_LEASE_TRADE_OUT, params],
		() => fetchLeaseTradeOut(params),
		queryConfig
	);
};
