import { FiltersObject } from 'models/filters/types';
import { filtersToServerJSON } from 'models/filters/util';
import { mergeFilters } from 'models/filters/util/mergeFilters';
import { useMemo } from 'react';

type Props = {
	filters: FiltersObject;
};

export const useServerFiltersForSearchMap = ({ filters }: Props) => {
	return useMemo(() => {
		return filtersToServerJSON(
			mergeFilters(filters, {
				polygon: null,
				radius: null,
				submarket: undefined,
				submarkets: null,
				opportunityZoneId: null,
				fips: null,
				apn: null,
			})
		);
	}, [filters]);
};
