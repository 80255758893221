import Modal from 'Singletons/Modal';

import modalStyles from 'Singletons/Modal/modal.less';

import ExportPDF from '../../Pages/Export';
import { ExportModal } from './UpgradeModal/ExportModal';
import { MarketUpgradeModal } from './UpgradeModal/MarketUpgradeModal';

Modal.addComponent({
	id: 'market-upgrade-modal',
	Component: MarketUpgradeModal,
});

Modal.addComponent({
	id: 'export-upgrade-modal',
	Component: ExportModal,
	className: modalStyles.centeredModal,
});

Modal.addComponent({
	id: 'export-pdf',
	Component: ExportPDF,
});
