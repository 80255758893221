import { QueryClient } from '@tanstack/react-query';
import { LeaseComp, SalesComp } from 'types';
import { QUERY_KEYS } from '../constants';
import {
	PropertyLease,
	PropertyLeasesParams,
	PropertyLeasesResponse,
} from '../propertyLeases';
import { PropertySalesParams } from '../propertySales';
import { UnlockLeasesResponse } from '../unlockLeases';
import { UnlockSalesResponse } from '../unlockSales/useUnlockSalesMutation';

export const updateCachePropertyComps = (
	type: 'lease' | 'sale',
	queryClient: QueryClient,
	data: UnlockSalesResponse | UnlockLeasesResponse
) => {
	queryClient.setQueriesData<PropertyLeasesResponse>(
		{
			predicate: (query) => {
				const [staticKey, params] = query.queryKey;
				const propertyQueryKey =
					type === 'lease'
						? QUERY_KEYS.PROPERTY_LEASES
						: QUERY_KEYS.PROPERTY_SALES;

				if (staticKey !== propertyQueryKey) return false;

				return data.comps.some((c) => {
					if (type === 'lease') {
						return (
							(c as LeaseComp).propertyId ===
							(params as PropertyLeasesParams).propertyId
						);
					} else {
						return (c as SalesComp).portfolio.find(
							(p) => p.propertyId === (params as PropertySalesParams).propertyId
						);
					}
				});
			},
		},
		(prevData) => {
			if (!prevData) return;
			return {
				...prevData,
				comps: prevData.comps.map((c) => {
					const unlockedComp = data.comps.find((uc) => uc.id === c.id);
					if (unlockedComp) {
						return unlockedComp as PropertyLease;
					}
					return c;
				}),
			};
		}
	);
};
