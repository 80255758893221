import { TrendLineItem } from '../../Pages/PropertyPageV2_1/api/interfaces';
import { SuggestedSpan } from '../../Pages/PropertyPageV2_1/type';

type OccupancyDTOValue = {
	trendLine: Array<TrendLineItem>;
};

export enum OccupancyTypes {
	PROPERTY = 'property',
	SUBMARKET = 'submarket',
}

export type OccupancyDTO = Record<OccupancyTypes, OccupancyDTOValue> & {
	suggestedSpan?: SuggestedSpan;
};

export enum OccupancyMetrics {
	OCCUPANCY = 'occupancy',
	NET_OCCUPIED_UNITS = 'netOccupiedUnits',
}
