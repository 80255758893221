import { FiltersObject } from 'models/filters/types';
import { filtersToServerJSON } from 'models/filters/util/serverJson';
import { setFilters } from 'models/filters/util/setFilters';
import { CompType } from 'types';
import { SEARCH_MAX_LIMIT } from '../constants';
import { fetchSearchLeases } from '../searchLeases/useSearchLeasesQuery';
import { fetchSearchSales } from '../searchSales/useSearchSalesQuery';

type Props = {
	compType: CompType;
	latitude: number;
	longitude: number;
	filters: FiltersObject;
	minNumberOfLeases?: number;
	minNumberOfProperties?: number;
};

// Uses the search leases/sales endpoints to find the radius
// in which we get the desired number of comps and properties
// and returns the filters with that radius
export const getNearbyCompsFilters = async ({
	compType,
	latitude,
	longitude,
	filters,
	minNumberOfLeases = DEFAULT_MIN_NUMBER_OF_LEASES,
	minNumberOfProperties = DEFAULT_MIN_NUMBER_OF_PROPERTIES,
}: Props) => {
	const fetchFn = fetchFnMap[compType === 'sale' ? 'sales' : 'leases'];
	let radius = INITIAL_RADIUS;

	const createFiltersWithRadius = () => {
		const radiusFilter = {
			center: { lat: latitude, lng: longitude },
			distance: radius,
		};
		return setFilters(filters, 'radius', radiusFilter);
	};

	for (let i = 0; i < MAX_REQUESTS; i++) {
		const filtersWithRadius = createFiltersWithRadius();

		const { aggregations, totalCount } = await fetchFn({
			filter: filtersToServerJSON(filtersWithRadius),
			offset: 0,
			limit: SEARCH_MAX_LIMIT,
			sort: compType === 'lease' ? 'tenantName' : 'saleDate',
			order: 'asc',
			//@ts-expect-error will be fixed with test maps merging
		})({ signal: undefined });

		const isPropertyCountOk =
			compType === 'lease'
				? aggregations.properties >= minNumberOfProperties
				: true;
		const isLeaseCountOk = totalCount >= minNumberOfLeases;

		if (isPropertyCountOk && isLeaseCountOk) return filtersWithRadius;

		radius *= 2;
	}

	return createFiltersWithRadius();
};

const MAX_REQUESTS = 5;

// in km
const INITIAL_RADIUS = 0.1;

const DEFAULT_MIN_NUMBER_OF_LEASES = 12;
const DEFAULT_MIN_NUMBER_OF_PROPERTIES = 1;

const fetchFnMap = {
	leases: fetchSearchLeases,
	sales: fetchSearchSales,
};
