import { Service } from 'services/impl/ServiceController';

export const RETAIN_DATA = 'RETAIN_DATA';
export const RELEASE_DATA = 'RELEASE_DATA';

export const LOAD_DATA = 'LOAD_DATA';
export const RECEIVE_DATA = 'RECEIVE_DATA';

// @ts-expect-error TS7006: Parameter 'itemKeys' implicitl...
export function retainItems(namespace: string, itemKeys) {
	return {
		type: RETAIN_DATA,
		payload: {
			namespace,
			itemKeys,
		},
	};
}

// @ts-expect-error TS7006: Parameter 'itemKeys' implicitl...
export function releaseItems(namespace: string, itemKeys) {
	return {
		type: RELEASE_DATA,
		payload: {
			namespace,
			itemKeys,
		},
	};
}

export const loadItems =
	<Payload, Response>(
		service: Service<Payload, Response>,
		namespace: string,
		payloads: Payload[]
	) =>
	// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
	(dispatch) => {
		const itemKeys = payloads.map(service.keyOf);

		dispatch({
			type: LOAD_DATA,
			payload: {
				namespace,
				itemKeys,
			},
		});

		const itemsPromise = service.loadMany(payloads);

		itemsPromise.then((datas) => {
			dispatch({
				type: RECEIVE_DATA,
				payload: {
					namespace,
					itemKeys,
					datas,
				},
			});
		});
	};

export const dataActions = {
	retainItems,
	releaseItems,
	loadItems,
};
