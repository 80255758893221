import { QUERY_KEYS } from '../constants';
import { createFetchFn, createQueryHook } from '../utils';

const fetchMufaPropertyById = createFetchFn<
	MufaPropertyByIdParams,
	MufaPropertyByIdResponse
>({
	method: 'get',
	getURL: ({ propertyId }) => `/mufa/properties/${propertyId}`,
});

export const useMufaPropertyByIdQuery = createQueryHook({
	queryKey: QUERY_KEYS.MUFA_PROPERTY_BY_ID,
	fetchFn: fetchMufaPropertyById,
	enabled: ({ propertyId }) => propertyId != null,
});

type MufaPropertyByIdParams = {
	propertyId?: number | string;
};

export type MufaPropertyByIdResponse = {
	rpMarketId: number;
	rpMarketName: string;
	rpSubmarketCode: string;
	rpSubmarketName: string;
	propertyType: string;
	propertyStatus: string;
	assetClassMarket: string;
	assetClassSubmarket: string;
	buildingYear: number;
	buildingYearRenovated: number;
	dailyPricing: boolean;
	survey: string;
	totalUnits: number;
	marketRateUnits: number;
	areaPerUnit: number;
	unitMixType: string;
	buildingFloorsCount: number;
	propertySubtype: string;
	buildingSize: number;
};
