import { useQuery } from '@tanstack/react-query';
import { InsightData } from 'Pages/Analytics/analytics';
import { QUERY_KEYS } from '../constants';
import { createFetchFn } from '../utils';

type InsightsParams = {
	properties: string[];
	fullDataThreshold: number;
	quartileThreshold: number;
	trendMonths: number;
	filter: {
		property: string;
		value: any;
	}[];
};

export const fetchInsights = createFetchFn<InsightsParams, InsightsResponse>({
	method: 'post',
	getURL: () => `/api/comps/insights`,
	getBody: (params) => params,
});

export const useInsightsQuery = (params: InsightsParams) =>
	useQuery({
		queryKey: [QUERY_KEYS.PORTFOLIO_MARKET_TREND, params],
		queryFn: fetchInsights(params),
		enabled: !!params.filter.find(
			(f) => f.property === 'marketId' && f.value.length === 1
		),
	});

export type InsightsResponse = InsightData;
