import { API, APIClientNotOkResponseError } from '@compstak/common';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { useUserQuery } from './useUserQuery';
import { mapUrlToGateway } from 'utils/gatewayUtil';

export const useUserW9Query = () => {
	const { data: user } = useUserQuery();

	return useQuery({
		queryKey: [QUERY_KEYS.USER_W9, user.id],
		queryFn: async () => {
			try {
				const res = await API.get<UserW9ResponseOk>(
					mapUrlToGateway(`/user-service/users/${user.id}/w9`)
				);
				return res.data;
			} catch (err) {
				if ((err as APIClientNotOkResponseError).response.status === 404) {
					return false;
				} else {
					throw err;
				}
			}
		},
	});
};

type UserW9ResponseOk = {
	userId: number;
	submittedAt: string;
};

export type UserW9Response = UserW9ResponseOk | false;
