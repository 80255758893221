export const encodeQuery = (query: object) => {
	const queryData = Object.keys(query)
		.filter((key) =>
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			Array.isArray(query[key])
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				? query[key]?.length
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				: query[key] !== undefined && query[key] !== null
		)
		.map((key) =>
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			Array.isArray(query[key])
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				? query[key].map((item) => `${key}=${item}`).join('&')
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				: `${key}=${query[key]}`
		);

	return queryData.length ? `?${queryData.join('&')}` : '';
};
