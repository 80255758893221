import { CompsetType, useCompsetType } from '../utils';
import { useAddPropertyMutation } from './useAddPropertyMutation';
import { useMufaAddPropertyMutation } from './useMufaAddPropertyMutation';

type Props = {
	propertyId: number;
};

export const useAddProperty = ({ propertyId }: Props) => {
	const compsetType = useCompsetType({ propertyId });

	const mutation = useAddPropertyMutation();

	const mufaMutation = useMufaAddPropertyMutation();

	return compsetType === CompsetType.COMMERCIAL
		? mutation
		: (mufaMutation as unknown as typeof mutation);
};
