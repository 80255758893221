import { ExchangeDashboardServerFilterItemKeys } from 'api/types/shared';
import { FiltersObject } from 'models/filters/types';
import { filtersToServerJSON } from 'models/filters/util';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { ServerFilterItem } from 'types/serverFilters';

export const createFiltersParamWithMarketId = (
	filters: Partial<FiltersObject> &
		(Pick<FiltersObject, 'market'> | Pick<FiltersObject, 'markets'>)
) => {
	const filtersWithoutMarkets = filtersToServerJSON(filters).filter(
		(f) => f.property !== 'marketName' && f.property !== 'marketNames'
	) as ServerFilterItem<ExchangeDashboardServerFilterItemKeys>[];

	return [
		{ property: 'marketId' as const, value: getFiltersMarkets(filters)[0].id },
		...filtersWithoutMarkets,
	];
};
