import { isLeaseDateEstimated, LeaseEstimatedFieldName } from 'api';
import { LeaseComp } from 'types';
import { formatDate } from './formatDate';
import { renderEstimatedPostfix } from './renderEstimatedPostfix';
import { Gapper } from './UI';

type Options = {
	shortenEstimatedPostfix?: boolean;
};

export const formatLeaseEstimatedField = (
	name: string,
	value: any,
	lease: LeaseComp,
	options?: Options
) => {
	return (
		<Gapper>
			{formatDate(value)}
			{renderEstimatedPostfix(
				isLeaseDateEstimated(lease, name as LeaseEstimatedFieldName),
				options?.shortenEstimatedPostfix
			)}
		</Gapper>
	);
};
