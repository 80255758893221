import { PortfolioItem } from 'types';
import {
	PORTFOLIO_PROPERTY_ALL_FIELDS_OBJECT,
	PORTFOLIO_PROPERTY_FIELDS_UNIQUE_VALUES,
	PORTOFOLIO_PROPERTY_FIELDS_DEFINED_VALUES_OBJECT,
} from './config';

export const getIsPortfolioField = (
	fieldName: string
): fieldName is keyof PortfolioItem => {
	return !!PORTFOLIO_PROPERTY_ALL_FIELDS_OBJECT[
		fieldName as keyof PortfolioItem
	];
};

export const getShouldShowUniqueValuesForPortfolioField = (
	fieldName: string
): fieldName is keyof PortfolioItem => {
	return PORTFOLIO_PROPERTY_FIELDS_UNIQUE_VALUES.includes(
		fieldName as keyof PortfolioItem
	);
};

export const getShouldShowDefinedValuesForPortfolioField = (
	fieldName: string
): fieldName is keyof PortfolioItem => {
	return !!PORTOFOLIO_PROPERTY_FIELDS_DEFINED_VALUES_OBJECT[
		fieldName as keyof PortfolioItem
	];
};
