import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { createFetchFn, useIsMufaProperty } from 'api/utils';

const createFetchMix = createFetchFn<
	MufaUnitMixSummaryParams,
	MufaUnitMixSummaryResponse
>({
	method: 'get',
	getURL: ({ propertyId }) => `/mufa/properties/${propertyId}/unitMixSummary`,
});

export const useMufaUnitMixSummaryQuery = (
	params: MufaUnitMixSummaryParams,
	queryConfig?: Omit<UseQueryOptions<MufaUnitMixSummaryResponse>, 'enabled'>
) => {
	const { data: isMufa } = useIsMufaProperty({
		propertyId: params.propertyId,
	});

	return useQuery({
		queryKey: [QUERY_KEYS.MUFA_UNIT_MIX_SUMMARY, params],
		queryFn: createFetchMix(params),
		enabled: isMufa,
		...queryConfig,
	});
};

type MufaUnitMixSummaryParams = {
	propertyId: number;
};

export type MufaUnitMixSummaryResponse = {
	totalUnits?: number | null;
	marketRateUnits?: number | null;
	averageUnitSqFt?: number | null;
	occupancy?: number | null;
	grossLivingArea?: number | null;
};
