import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { parseHref } from './parseHref';

/**
 * Handles 3 things:
 * - anchor elements which should behave like react-router Links ( href starts with / and not external links).
 * - elements used as back buttons ( data-back-button )
 * - anchor elements used for scrolling ( href starts with # e.g. #some-section ).
 */
export const useLinkInterceptor = () => {
	const navigate = useNavigate();

	useEffect(() => {
		const onClick = (e: MouseEvent) => {
			if (!(e.target instanceof HTMLElement || e.target instanceof SVGElement))
				return;
			if (e.defaultPrevented || e.metaKey) return;

			const linkEl = findLinkElement(e.target);

			if (!linkEl) return;

			if (linkEl.dataset.backButton) {
				return navigate(-1);
			}

			if (linkEl instanceof HTMLAnchorElement) {
				// external and download links
				if (linkEl.getAttribute('target') || linkEl.getAttribute('download'))
					return;

				const href = linkEl.getAttribute('href');

				if (href == null) return;

				e.preventDefault();

				// normal links assumed to start with /
				// and links that start with // are external
				if (href[0] === '/' && href[1] !== '/') {
					navigate(parseHref(linkEl.href), {
						replace: !!linkEl.dataset.replace,
						state: getLocationStateFromElement(linkEl),
					});
				}
				// scroll links
				else if (href[0] === '#') {
					const target = document.getElementById(href.slice(1));
					if (target) {
						target.scrollIntoView({
							behavior: 'smooth',
						});
					}
				}
			}
		};

		document.addEventListener('click', onClick);
		return () => {
			document.removeEventListener('click', onClick);
		};
	}, [navigate]);
};

// anchor element or any element that has data-back-button
const findLinkElement = (startEl: HTMLElement | SVGElement) => {
	let current = startEl;

	while (current && current.parentElement) {
		if (current.nodeName === 'A' || current.dataset.backButton) {
			return current;
		}
		current = current.parentElement;
	}
	return current;
};

const getLocationStateFromElement = (el: HTMLElement) => {
	let state: Record<string, unknown> = {};

	Object.keys(el.dataset).forEach((key) => {
		state[key] = el.dataset[key];
	});

	// goes recursively up the tree and merges parents' datasets
	// not sure why we would need this
	if (el.parentElement && Object.keys(el.parentElement.dataset).length > 0) {
		state = {
			...state,
			...getLocationStateFromElement(el.parentElement),
		};
	}

	return state;
};
