import {
	PropertySubtypeId,
	PropertyTypeId,
	SpaceSubtypeId,
	SpaceTypeId,
} from 'api/referenceData';
import { LeaseComp, MufaPropertyComp, PropertyComp, SalesComp } from 'types';

export const DEFAULT_SUGGESTIONS_LIMIT = 3;

export type SuggestionItemV1 = string;

export type SuggestionItemV2 = {
	title: string;
	marketId: number;
};

export type PlaceSuggestionItem = SuggestionItemV2 & {
	lat: number;
	lon: number;
	description: string;
};

export type PropertyTypeOrSubtypeSuggestionItem = SuggestionItemV2 & {
	propertyTypeId?: PropertyTypeId;
	propertySubtypeId?: PropertySubtypeId;
};

export type SpaceTypeOrPropertySubtypeSuggestionItem = SuggestionItemV2 & {
	spaceTypeId?: SpaceTypeId;
	propertySubtypeId?: PropertySubtypeId;
};

export type SpaceTypeOrSubtypeSuggestionItem = SuggestionItemV2 & {
	spaceTypeId?: SpaceTypeId;
	spaceSubtypeId?: SpaceSubtypeId;
};

export type SuggestionItem = Suggestion['suggestions'][number];

export type SuggestionField =
	| keyof LeaseComp
	| keyof SalesComp
	| keyof Omit<PropertyComp, 'loan'>
	| keyof Required<PropertyComp>['loan']
	| keyof Omit<MufaPropertyComp, 'loan'>
	| keyof Required<MufaPropertyComp>['loan']
	| 'places'
	| 'propertyTypeOrSubtype'
	| 'spaceTypeOrPropertySubtype'
	| 'spaceTypeOrSpaceSubtype';

type SuggestionFieldToSuggestionItemV2<T extends SuggestionField> =
	T extends 'places'
		? PlaceSuggestionItem
		: T extends 'propertyTypeOrSubtype'
			? PropertyTypeOrSubtypeSuggestionItem
			: T extends 'spaceTypeOrPropertySubtype'
				? SpaceTypeOrPropertySubtypeSuggestionItem
				: T extends 'spaceTypeOrSpaceSubtype'
					? SpaceTypeOrSubtypeSuggestionItem
					: SuggestionItemV2;

export interface Suggestion<
	V extends 'v1' | 'v2' = 'v2',
	T extends SuggestionField = SuggestionField,
> {
	title: string;
	field: T;
	suggestions: V extends 'v2'
		? SuggestionFieldToSuggestionItemV2<T>[]
		: SuggestionItemV1[];
}
