import { encodeQuery } from 'util/encodeQuery';
import { QUERY_KEYS } from '../../constants';
import {
	PortfolioFilterQuery,
	PortfolioLeaseExpirationsByIndustriesByYears,
	PortfolioLeaseExpirationsBySpaceTypesByYears,
	PortfolioMarket,
	PortfolioTenantIndustry,
} from '../../types';
import { useQueryHook, createFetchFn } from '../../utils';

type PortfolioMarketsSummaryParams = PortfolioFilterQuery;

export const fetchPortfolioMarketsSummary = createFetchFn<
	PortfolioFilterQuery,
	PortfolioMarketsSummaryResponse
>({
	method: 'get',
	getURL: (queryArgs) => {
		return `/pa/v2/api/portfolios/marketsSummary${encodeQuery(queryArgs)}`;
	},
});

export const usePortfolioMarketsSummaryQuery = (
	params: PortfolioMarketsSummaryParams
) =>
	useQueryHook({
		queryKey: [QUERY_KEYS.PORTFOLIO_MARKETS_SUMMARY, params],
		queryFn: fetchPortfolioMarketsSummary(params),
	});

export type PortfolioMarketsSummaryResponse = {
	markets: PortfolioMarket[];
	tenantIndustries: PortfolioTenantIndustry[];
	leaseExpirationsBySpaceTypesByYears: PortfolioLeaseExpirationsBySpaceTypesByYears[];
	leaseExpirationsByIndustriesByYears: PortfolioLeaseExpirationsByIndustriesByYears[];
};
