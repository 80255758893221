import { useMemo } from 'react';
import { useUserQuery } from './useUserQuery';

type Props = {
	comps: QuasiComp[];
};

type QuasiComp = {
	cost: number;
};

/** Should handle all bussiness conditions / derived values around unlocking comps.
 * The logic assumes freeComps are applied.
 */
export const useUnlockConditions = ({ comps }: Props) => {
	const { data: user, isFetching } = useUserQuery();

	const freeCompsCount = user.freeComps;

	const totalCost = useMemo(() => {
		return comps
			.toSorted((a, b) => b.cost - a.cost)
			.slice(freeCompsCount)
			.reduce((sum, c) => sum + c.cost, 0);
	}, [freeCompsCount, comps]);

	const hasEnoughPoints = user.points >= totalCost;

	const canUnlockForFree = freeCompsCount > 0 && totalCost === 0;

	return {
		totalCost,
		hasEnoughPoints,
		freeCompsCount,
		isUserFetching: isFetching,
		canUnlockForFree,
	};
};
