import { QUERY_KEYS } from 'api/constants';
import {
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query';
import { addEmptyData, Timeframes } from '@compstak/common';
import { API, formatRequestString, buildQuery } from '@compstak/common';
import { OccupancyDTO, OccupancyMetrics, OccupancyTypes } from './types';
import { APIDateRequestParams } from '../../Pages/PropertyPageV2_1/api/interfaces';

interface QueryConfig {
	enabled?: boolean;
}

export type UseOccupancyByParamsResponse = UseQueryResult<OccupancyDTO>;

export const useOccupancyByParams = (
	params: FetchOccupancyApiParams,
	queryConfig?: QueryConfig
): UseOccupancyByParamsResponse =>
	useQuery(
		[QUERY_KEYS.MUFA_PROPERTY_OCCUPANCY, params],
		() => fetchOccupancy(params),
		queryConfig
	);

export interface FetchOccupancyApiParams extends APIDateRequestParams {
	propertyId: string | number;
	metric: OccupancyMetrics;
}

export const fetchOccupancy = ({
	propertyId,
	metric,
	...rest
}: FetchOccupancyApiParams): Promise<OccupancyDTO> => {
	const query = buildQuery({ ...rest });

	return API.get<OccupancyDTO>(
		formatRequestString({
			entityUrl: `/mufa/properties/${propertyId}/rpSubmarket/trend/${metric}?${query}`,
		})
	)
		.then((response) => response.data)
		.then(addEmptyData(Object.values(OccupancyTypes), Timeframes.QoQ));
};

export type QueryArguments = Omit<
	FetchOccupancyApiParams,
	'propertyId' | 'metric'
>;

export type UseOccupancyParams = FetchOccupancyApiParams;

export const useOccupancyData = (
	params: UseOccupancyParams,
	queryConfig?: Pick<UseQueryOptions, 'enabled'>
): UseOccupancyByParamsResponse => {
	const result = useOccupancyByParams(params, queryConfig);

	return result;
};
