// The "as const" is needed for later pattern matching,
// to make sure the "type" field on the return type of actions later is a string literal,
// and not just "string".
export const MODAL_SHOW = 'MODAL_SHOW' as const;
export const MODAL_HIDE = 'MODAL_HIDE' as const;

export function showModal<T>(componentName: string, data?: T) {
	return {
		type: MODAL_SHOW,
		payload: {
			component: componentName,
			stack: false,
			data,
		},
	};
}

export function hideModal(componentName?: string) {
	return {
		type: MODAL_HIDE,
		payload: {
			component: componentName,
			stack: false,
		},
	};
}

export function pushModal<T>(componentName: string, data?: T) {
	return {
		type: MODAL_SHOW,
		payload: {
			component: componentName,
			stack: true,
			data,
		},
	};
}

export function popModal(componentName?: string) {
	return {
		type: MODAL_HIDE,
		payload: {
			stack: true,
			component: componentName,
		},
	};
}

export const modalActions = {
	showModal,
	hideModal,
	pushModal,
	popModal,
};

export type ModalActions = typeof modalActions;
export type ModalAction = ReturnType<ModalActions[keyof ModalActions]>;
