import { UseQueryOptions } from '@tanstack/react-query';
import { ExchangeDashboardServerFilterItem } from 'api/types/shared';
import {
	createFetchFn,
	createFiltersParamWithMarketId,
	useQueryHook,
} from 'api/utils';
import { FiltersObject } from 'models/filters/types';
import { useMemo } from 'react';
import { QUERY_KEYS } from '../constants';

const fetchTopTenantIndustries = createFetchFn<
	TopTenantIndustriesDataParams,
	TopTenantIndustriesResponse
>({
	method: 'post',
	getURL: () => `/api/comps/topTenantIndustries`,
	getBody: ({ filters }) => {
		return {
			filters,
			maxBySqft: false,
		};
	},
});

type TopTenantIndustriesDataParamsArg = Omit<
	TopTenantIndustriesDataParams,
	'filters'
> & {
	filters: Partial<FiltersObject> &
		(Pick<FiltersObject, 'market'> | Pick<FiltersObject, 'markets'>);
};

export const useTopTenantIndustriesQuery = (
	params: TopTenantIndustriesDataParamsArg,
	options?: UseTopTenantIndustriesOptions
) => {
	const { filters: paramsFilters, ...otherParams } = params;

	const filters = useMemo(() => {
		return createFiltersParamWithMarketId(paramsFilters);
	}, [paramsFilters]);

	return useQueryHook({
		queryKey: [QUERY_KEYS.TOP_TENANT_IDUSTRIES, filters, otherParams],
		queryFn: fetchTopTenantIndustries({ ...otherParams, filters }),
		...options,
	});
};

export type TopTenantIndustriesDataParams = {
	filters: ExchangeDashboardServerFilterItem[];
};

export type TopTenantIndustry = {
	industry: string;
	leaseCount: number;
	propertyCount: number;
	sqft: number;
	sqftOrLeaseCount: number;
};

export type TopTenantIndustriesResponse = TopTenantIndustry[];

export type UseTopTenantIndustriesOptions = UseQueryOptions<TopTenantIndustriesResponse>;
