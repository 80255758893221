import factory from './factory';

const serviceController = factory.create({
	timeout: 10 * 60 * 1000,
	createUrl: () => '/api/analytics',
});

const exportable = serviceController.exportable({
	loadAllProjects() {
		return factory.get('/api/analytics');
	},

	// @ts-expect-error TS7006: Parameter 'project' implicitly...
	updateProject(project) {
		return factory.post('/api/analytics/projects', project);
	},

	// @ts-expect-error TS7006: Parameter 'chart' implicitly h...
	updateChart(chart) {
		return factory.post('/api/analytics/charts', chart);
	},

	// @ts-expect-error TS7006: Parameter 'projectId' implicit...
	deleteProject(projectId) {
		return factory.del(`/api/analytics/projects/${projectId}`);
	},

	// @ts-expect-error TS7006: Parameter 'chartId' implicitly...
	deleteChart(chartId) {
		return factory.del(`/api/analytics/charts/${chartId}`);
	},

	// @ts-expect-error TS7006: Parameter 'chartId' implicitly...
	sendChart(chartId, recipientEmail) {
		return factory.post('/api/analytics/charts/share', {
			chartId,
			recipientEmail,
		});
	},

	// @ts-expect-error TS7006: Parameter 'projectId' implicit...
	sendProject(projectId, recipientEmail) {
		return factory.post('/api/analytics/projects/share', {
			projectId,
			recipientEmail,
		});
	},

	// @ts-expect-error TS7006: Parameter 'userId' implicitly ...
	requestDemo(userId) {
		return factory.post('/api/analytics/requestDemo', {
			userId,
		});
	},
});

export default exportable;
