import { leaseFiltersStub } from './leaseStub';
import { mufaFiltersStub } from './mufaStub';
import { propertyFiltersStub } from './propertyStub';
import { saleFiltersStub } from './saleStub';

// filter key to non-nullable value to be able to determine filter value type by key
export const allFiltersStub = {
	...leaseFiltersStub,
	...saleFiltersStub,
	...propertyFiltersStub,
	...mufaFiltersStub,
};
