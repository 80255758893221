import { Market } from '@compstak/common';
import { PermissionsState } from 'Pages/Login/reducers';

export const isMultifamily = ({
	isExchange,
	markets,
	permissions,
}: {
	isExchange?: boolean;
	markets: Market[] | number[];
	permissions: PermissionsState;
}) => {
	if (isExchange) {
		return false;
	}

	const marketIds = markets.map((market: Market | number) =>
		typeof market === 'object' ? market.id : market
	);

	const hasPermission = marketIds.every(
		(id) => !!permissions?.[id]?.multifamilyAccess
	);

	return hasPermission;
};
