import * as Dialog from '@radix-ui/react-dialog';
import { Button, ButtonProps } from '@compstak/ui-kit';
import { MQB } from 'constants/mediaQueryBreakpoints';
import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { IconCross } from '@compstak/ui-kit';
import { APP_HEADER_HEIGHT } from 'constants/sizes';
import { APP_WRAPPER_ID } from 'ui/UI';

export type ModalProps = PropsWithChildren<{
	onClose: () => void;
	fullScreen?: boolean;
}>;

export const Modal = ({ children, fullScreen, onClose }: ModalProps) => {
	return (
		<Dialog.Root open onOpenChange={(open) => !open && onClose()}>
			<Dialog.Portal container={document.getElementById(APP_WRAPPER_ID)}>
				<ModalOverlay>
					<ModalCloseButton>
						<ModalCloseIcon />
					</ModalCloseButton>
					<ModalContent fullScreen={fullScreen}>{children}</ModalContent>
				</ModalOverlay>
			</Dialog.Portal>
		</Dialog.Root>
	);
};

export const ModalOverlay = styled(Dialog.Overlay)`
	display: flex;
	top: ${APP_HEADER_HEIGHT}px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	overflow-y: auto;
	z-index: ${({ theme }) => theme.zIndex.overlay + theme.zIndex.modal};
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: ${({ theme }) => theme.colors.gray.gray400TransparentCC};
	backdrop-filter: blur(5px);
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
`;

export const ModalButtons = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-top: 1rem;
	gap: 1rem;
`;

const fullScreenCss = css`
	display: flex;
	flex-direction: column;
	margin: 0 0 ${APP_HEADER_HEIGHT}px;
	height: 100%;
	overflow-y: auto;
	max-width: unset;
`;

export const ModalContent = styled(Dialog.Content)<{ fullScreen?: boolean }>`
	width: 100%;
	min-width: 300px;
	max-width: 560px;
	margin: 5rem;
	padding: 2.5rem;
	background-color: ${({ theme }) => theme.colors.white.white};
	border-radius: 3px;
	/* to mimic full screen */
	@media (max-width: ${MQB.M_640}px) {
		${fullScreenCss};
	}
	${({ fullScreen }) => fullScreen && fullScreenCss};
`;

export const ModalCloseButton = styled(Dialog.Close)`
	all: unset;
	position: absolute;
	top: 26px;
	right: 30px;
	width: 30px;
	height: 30px;
	z-index: 10;
	cursor: pointer;
	opacity: 0.6;
	&:hover {
		opacity: 1;
	}
`;

export const ModalCloseIcon = styled(IconCross)`
	width: 30px;
	height: 30px;
	fill: hsl(226, 15%, 72%);
`;

export const ModalTitle = styled.h3(
	({ theme }) => css`
		margin-bottom: 3rem;

		font-family: ${theme.typography.fontFamily.gotham};
		text-align: center;
		font-size: 2rem;
		line-height: 1.6;
		font-weight: 400;
		color: ${theme.colors.gray.gray700};
	`
);

export const ModalParagraph = styled.p`
	margin-bottom: 0.75rem;
	font-size: 1rem;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
`;

export const ModalInputLabel = styled.label`
	display: block;
	color: ${({ theme }) => theme.colors.blue.blue400};
`;

export const ModalCenteredContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const ModalScrollableInnerContent = styled.div`
	overflow-y: auto;

	font-size: 1rem;
	line-height: 24px;

	max-height: 25rem;
	@media (max-width: ${MQB.M_640}px) {
		max-height: 31rem;
	}
`;

export const ModalButton = (props: ButtonProps) => {
	return <Button size="l" {...props} />;
};

export const onInteractOutsideModal: (
	event: Parameters<
		NonNullable<Dialog.DialogContentProps['onInteractOutside']>
	>[0],
	defaultAction?: () => void
) => void = (event, defaultAction) => {
	const target = event.target as HTMLElement;
	if (
		target.className.match('feedback') ||
		target.parentElement?.className.match('feedback')
	) {
		event.preventDefault();
	} else if (defaultAction) {
		defaultAction();
	}
};
