export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const UPDATE_ALERT = 'UPDATE_ALERT';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';

export function addFeedback(
	message: string,
	className = '',
	id: string | null = null,
	duration?: number,
	buttons?: any
) {
	if (id === null) {
		id = Math.random().toString();
	}
	return {
		type: ADD_ALERT,
		payload: {
			message,
			className,
			id,
			duration,
			buttons,
		},
	};
}

export function removeFeedback(id: string | number | null) {
	if (id == null) {
		throw new Error('Tried to remove an alert without providing an ID.');
	}

	return {
		type: REMOVE_ALERT,
		payload: {
			id,
		},
	};
}

export function updateFeedback(
	message: string,
	className = '',
	id: string | number | null = null,
	// @ts-expect-error TS7006: Parameter 'duration' implicitl...
	duration,
	// @ts-expect-error TS7006: Parameter 'buttons' implicitly...
	buttons
) {
	if (id == null) {
		throw new Error('Tried to update an alert without providing an ID.');
	}

	return {
		type: UPDATE_ALERT,
		payload: {
			message,
			className,
			id,
			duration,
			buttons,
		},
	};
}

export function clearFeedback() {
	return {
		type: CLEAR_ALERTS,
	};
}

export const feedbackActions = {
	addFeedback,
	removeFeedback,
	updateFeedback,
	clearFeedback,
};

export type FeedbackActions = typeof feedbackActions;
