type Props<T> = {
	list: T[];
	sortByProperty: keyof T;
};

export const alphabeticalOrder = <T>({ list, sortByProperty }: Props<T>) => {
	return list.sort((a, b) => {
		if (
			typeof a[sortByProperty] === 'string' ||
			typeof a[sortByProperty] === 'number'
		) {
			return String(a[sortByProperty]).localeCompare(String(b[sortByProperty]));
		}

		return 0;
	});
};
