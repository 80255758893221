import {
	CompatibleFilter,
	CompatibleFilterKey,
	CompatibleFilterValue,
	ServerFilterItem,
	ServerFilterItemProperties,
} from 'types/serverFilters';
import { FiltersObject } from '../types';
import { COMPATIBLE_FILTERS } from './serverJson';

const mapCompatibleFilterToActual = <K extends CompatibleFilterKey>({
	compatibleFilterKey,
	compatibleFilterValue,
	toQueryParam = false,
}: {
	compatibleFilterKey: K;
	compatibleFilterValue: CompatibleFilterValue<K>;
	toQueryParam?: boolean;
}) => {
	const compatibleFilterMapper = COMPATIBLE_FILTERS[compatibleFilterKey];
	return {
		key: toQueryParam
			? compatibleFilterMapper.serializedProperty
			: compatibleFilterMapper.filterProperty,
		value: toQueryParam
			? // @ts-expect-error TS2345: Argument of type 'string | str...
				compatibleFilterMapper.queryParamMapping?.(compatibleFilterValue) ??
				compatibleFilterValue
			: // @ts-expect-error TS2345: Argument of type 'string | str...
				compatibleFilterMapper.valueMapping?.(compatibleFilterValue) ??
				compatibleFilterValue,
	};
};

const findCompatibleFilterKeys = (filters: object) => {
	const filtersKeys = Object.keys(filters);
	return filtersKeys.filter((filterKey) =>
		Object.keys(COMPATIBLE_FILTERS).includes(filterKey)
	) as CompatibleFilterKey[];
};

/**
 * Removes compatible filters if found and substitutes them with actual filters key and value
 * @param filters - object to process filters
 */
export const processFilters = <ToQueryParam extends boolean>(
	filters: ToQueryParam extends true
		? Record<string, string>
		: Partial<FiltersObject & CompatibleFilter>,
	toQueryParam: ToQueryParam
) => {
	const compatibleFilterKeys = findCompatibleFilterKeys(filters);
	compatibleFilterKeys.forEach((compatibleFilterKey) => {
		const compatibleFilterValue = filters[compatibleFilterKey];
		if (compatibleFilterValue) {
			const actualFilter = mapCompatibleFilterToActual({
				compatibleFilterKey,
				compatibleFilterValue,
				toQueryParam,
			});
			// @ts-expect-error TS2551: Property 'submarketId' does no...
			filters[actualFilter.key] = actualFilter.value;
		}
		delete filters[compatibleFilterKey];
	});

	return filters;
};

/**
 * Removes compatible filters if found and substitutes them with actual filters key and query param value
 * @param filters - object to process for old filters
 */
export const processCompatibleFiltersQueryParams = (
	filters: Record<string, string>
) => {
	return processFilters(filters, true);
};

/**
 * Substitutes old filter key & value with actual one from ServerFilterItem
 * @param filters - object to process for old filters
 */
export const processCompatibleServerFilterItem = (
	serverItem: ServerFilterItem,
	filters: FiltersObject
): ServerFilterItem => {
	const compatibleFilter = COMPATIBLE_FILTERS[
		serverItem.property as CompatibleFilterKey
	] as (typeof COMPATIBLE_FILTERS)[CompatibleFilterKey] | undefined;
	const serverItemProperty: Exclude<
		ServerFilterItemProperties,
		CompatibleFilterKey
	> = compatibleFilter?.serializedProperty ?? serverItem.property;
	const serverItemValue =
		compatibleFilter?.serializedValueMapping?.(
			// @ts-expect-error TS2345: Argument of type 'string | num...
			serverItem.value,
			filters
		) ?? serverItem.value;

	return {
		...serverItem,
		property: serverItemProperty,
		value: serverItemValue,
	} as ServerFilterItem;
};
