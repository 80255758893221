import { ComponentType } from 'react';
import { FallbackProps, withErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';
import { csLogger } from 'util/log/logger';
import { stringifyErrorOrNull } from 'utils/error';

export function withErrorBoundaryDefault<T extends object>(
	Component: ComponentType<T>
) {
	return withErrorBoundary(Component, {
		FallbackComponent: ErrorDisplay,
		onError: (error, info) => {
			csLogger.error({
				event: 'withErrorBoundaryDefault error',
				extraInfo: { error: stringifyErrorOrNull(error), info },
			});
		},
	});
}

function ErrorDisplay(props: Partial<FallbackProps>) {
	let errorMessage = 'Failed to format the error message.';
	try {
		// https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify
		errorMessage = JSON.stringify(
			props.error ?? 'Error is falsy?!',
			Object.getOwnPropertyNames(props.error)
		);
	} catch (err) {
		// Failed to stringify. Catch block is empty on purpose.
	}
	return (
		<ErrorContainer>
			<ErrorTitle>Something went wrong...</ErrorTitle>
			<p>
				Please try to {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
				<a
					href="#"
					data-qa-id="refresh-page-link"
					onClick={() => document.location.reload()}
				>
					refresh the page
				</a>{' '}
				or {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
				<a
					href="#"
					data-qa-id="go-back-link"
					onClick={() => {
						history.back();
					}}
				>
					go back
				</a>{' '}
				or{' '}
				<a href="/" data-qa-id="home-page-link">
					go to the Home page
				</a>
			</p>
			<p>ERROR: {errorMessage}</p>
		</ErrorContainer>
	);
}

const ErrorContainer = styled.div`
	max-height: 400px;
	max-width: 800px;
	background-color: #f8d7da;
	color: #721c24;
	margin: 8px;
	padding: 16px;
	border: 2px solid #f5c6cb;
	overflow: auto;
	display: grid;
	gap: 16px;
`;

const ErrorTitle = styled.h1`
	font-size: 24px;
`;
