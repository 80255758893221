import { API } from '@compstak/common';
import { useMutation } from '@tanstack/react-query';

const sendCompsFeedback = async (params: CompsFeedbackParams) => {
	const res = await API.post<CompsFeedbackResponse>(
		'/api/comps/feedback',
		params
	);
	return res.data;
};

export const useCompFeedbackMutation = () =>
	useMutation<CompsFeedbackResponse, unknown, CompsFeedbackParams>({
		mutationFn: (params) => sendCompsFeedback(params),
	});

type CompsFeedbackParams = {
	compId: number;
	suggestion: string;
	market: string;
	dataType: 'sale' | 'lease';
};

type CompsFeedbackResponse = {
	freeComps: string;
	result: string;
};
