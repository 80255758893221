import { nullValueText } from '@compstak/common';
import { numberUtils } from '@compstak/ui-kit';
import { formatDate } from 'format';
import { Comp, CompType, PropertyComp, SalesComp } from 'types';
import { isLoanField } from 'utils/compHelpers';

export default function (
	comp: Comp,
	compType: CompType,
	fieldName: string
): string | number | null | undefined {
	// @ts-expect-error TS7053: Element implicitly has an 'any...
	let value = comp[fieldName];
	if (
		compType === 'sale' &&
		value === undefined &&
		(comp as SalesComp).portfolio &&
		(comp as SalesComp).portfolio[0]
	) {
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		value = (comp as SalesComp).portfolio[0][fieldName];
	}

	if (
		compType === 'property' &&
		(comp as PropertyComp).loan &&
		isLoanField(fieldName)
	) {
		value = (comp as PropertyComp).loan?.[fieldName] ?? null;

		if (value === null) return nullValueText;

		switch (fieldName) {
			case 'loanAmount': {
				value = `${numberUtils.formatCurrency(value, 'currencyInteger')}`;
				break;
			}
			case 'loanMaturityDate':
			case 'loanOriginationDate': {
				value = formatDate(value);
				break;
			}
			case 'loanTerm': {
				value = `${value} Months`;
				break;
			}
			case 'loanToValue':
			case 'currentLoanRate': {
				value = numberUtils.formatPercent(value / 100, 'percentTwoDecimals');
				break;
			}
			default:
				return value;
		}
	}

	return value;
}
