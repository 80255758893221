export enum PropertyPageTab {
	COMPSET = 'COMPSET',
	SUBMARKET_TRENDS = 'SUBMARKET_TRENDS',
	MULTIFAMILY = 'MULTIFAMILY',
	LEASES = 'LEASES',
	SALES = 'SALES',
	LOANS = 'LOANS',
	TRANSIT = 'TRANSIT',
	STACKING_PLAN = 'STACKING_PLAN',
}
