import { useParams } from './migration';

export const route = <Params = void>({
	path,
	toHref = () => path,
	isModal = false,
	data = {},
}: RouteConfigOptions<Params>) => {
	return {
		path,
		toHref,
		isModal,
		data,
		useParams: () => {
			const params = useParams();
			const result = { ...params };

			// convert numeric params to numbers automatically
			Object.entries(result).forEach(([key, value]) => {
				if (isNumeric(value ?? '')) {
					// @ts-expect-error please shut up
					result[key] = Number(value);
				}
			});
			return result as Params;
		},
	};
};

const isNumeric = (str: string) => {
	if (typeof str != 'string') return false;
	return !isNaN(+str) && !isNaN(parseFloat(str));
};

type RouteConfigOptions<Params> = {
	path: string;
	toHref?: (params: Params) => string;
	/** @default false */
	isModal?: boolean;
	data?: Record<string, any>;
};
