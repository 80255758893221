import { useMemo } from 'react';
import { SpaceTypeName, useReferenceDataQuery } from './useReferenceDataQuery';

export const useSpaceTypeReferencesByName = (
	spaceTypeNames: SpaceTypeName[]
) => {
	const { data } = useReferenceDataQuery();

	return useMemo(() => {
		if (!data) return [];

		return data.spaceTypes.filter((s) => spaceTypeNames.includes(s.name));
	}, [data, spaceTypeNames]);
};
