import React from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import styles from './feedback.less';
import buttonStyles from 'ui/styles/button.less';
import { removeFeedback } from './actions';

const FIVE_SECONDS = 5000;

class FeedbackItem extends React.PureComponent {
	// @ts-expect-error TS7006: Parameter 'action' implicitly ...
	func(action) {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'buttonAction' does not exist on type 'Re... Remove this comment to see the full error message
		this.props.buttonAction(action(this.props.id));
	}

	componentDidMount(): void {
		setTimeout(() => this.func(removeFeedback), FIVE_SECONDS);
	}

	render() {
		let className = styles.item;
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
		if (this.props.className) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
			className += ' ' + styles[this.props.className];
		}

		let buttons = [];
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'buttons' does not exist on type 'Readonl... Remove this comment to see the full error message
		if (this.props.buttons) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'buttons' does not exist on type 'Readonl... Remove this comment to see the full error message
			buttons = this.props.buttons.map((button, i) => {
				if (button.link) {
					return (
						<a
							key={i}
							className={buttonStyles.button}
							onClick={this.func.bind(this, button.action)}
							{...button.link}
						>
							{button.text}
						</a>
					);
				}
				return (
					<span
						key={i}
						className={buttonStyles.button}
						onClick={this.func.bind(this, button.action)}
					>
						{button.text}
					</span>
				);
			});
		}

		return (
			<div>
				<div className={className}>
					{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'message' does not exist on type 'Readonl... Remove this comment to see the full error message */}
					<span className={styles.message}>{this.props.message}</span> {buttons}
				</div>
			</div>
		);
	}
}

class AlertsList extends React.Component {
	shouldComponentUpdate(nextProps: any) {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'feedback' does not exist on type 'Readon... Remove this comment to see the full error message
		if (this.props.feedback.length === 0 && nextProps.feedback.length === 0) {
			return false;
		}
		return true;
	}

	render() {
		return (
			<div className={styles.container}>
				<TransitionGroup>
					{/* @ts-expect-error ts-migrate(2339) FIXME: Property 'feedback' does not exist on type 'Readon... Remove this comment to see the full error message */}
					{this.props.feedback.map((item) => {
						return (
							<CSSTransition
								key={item.id}
								classNames="feedback-transition"
								timeout={300}
							>
								<FeedbackItem
									// @ts-expect-error ts-migrate(2339) FIXME: Property 'buttonAction' does not exist on type 'Re... Remove this comment to see the full error message
									buttonAction={this.props.buttonAction}
									{...item}
								/>
							</CSSTransition>
						);
					})}
				</TransitionGroup>
			</div>
		);
	}
}

// @ts-expect-error TS7006: Parameter 'store' implicitly h...
function mapStoreToProps(store) {
	return {
		feedback: store.feedback,
	};
}

// @ts-expect-error TS7006: Parameter 'dispatch' implicitl...
function mapDispatchToProps(dispatch) {
	return {
		// @ts-expect-error TS7006: Parameter 'action' implicitly ...
		buttonAction: (action) => dispatch(action),
	};
}

const AlertsComponent = connect(
	mapStoreToProps,
	mapDispatchToProps
)(AlertsList);

export default AlertsComponent;
