import { Query, QueryClient } from '@tanstack/react-query';
import { SalesComp } from 'types';
import { QUERY_KEYS } from '../constants';
import { SaleCompParams } from '../saleComp';
import { UnlockSalesResponse } from './useUnlockSalesMutation';

export const updateCacheSaleById = (
	queryClient: QueryClient,
	data: UnlockSalesResponse
) => {
	queryClient.setQueriesData<SalesComp>(
		{
			predicate: (query) => {
				return isSaleByIdQuery(query, data);
			},
		},
		(prevData) => {
			if (!prevData) return;
			const unlockedComp = data.comps.find((s) => s.id === prevData.id);
			if (unlockedComp) {
				return unlockedComp;
			}
			return prevData;
		}
	);
};

export const isSaleByIdQuery = (query: Query, data: UnlockSalesResponse) => {
	const [staticKey, params] = query.queryKey;

	return (
		staticKey === QUERY_KEYS.SALE_COMP &&
		!!data.comps.find((c) => c.id === (params as SaleCompParams).id)
	);
};
