import { useSearchSalesQuery } from 'api';
import { FiltersObject } from 'models/filters/types';
import { filtersToServerJSON } from 'models/filters/util';
import { useMemo } from 'react';
import { SalesComp } from 'types';
import { createNavHeader } from './createNavHeader';
import { UseNavHeaderDataHook } from './types';
import { useNavHeaderPagination } from './useNavHeaderPagination';

type Args = {
	filters: FiltersObject;
};

export const {
	NavHeader: SearchSalesNavHeader,
	NavHeaderProvider: SearchSalesNavHeaderProvider,
	useNavHeaderContext: useSearchSalesNavHeaderContext,
} = createNavHeader({
	createUseNavDataHook: (args: { filters: FiltersObject }) =>
		createUseNavHeaderSaleData(args),
	getItemPath: (sale) => `/comps/sales/${sale.id}`,
	itemLabel: 'sale',
});

export const createUseNavHeaderSaleData = ({ filters }: Args) => {
	const useNavHeaderSaleData: UseNavHeaderDataHook<SalesComp> = ({
		initialIndex,
	}) => {
		const {
			currentIndex,
			startIndex,
			pageSize,
			setPrevIndex,
			setNextIndex,
			getIndexInsideOfPage,
		} = useNavHeaderPagination(initialIndex);

		const serverFilters = useMemo(() => {
			return filtersToServerJSON(filters);
		}, [filters]);

		const { data, fetchStatus } = useSearchSalesQuery({
			params: {
				filter: serverFilters,
				offset: startIndex,
				limit: pageSize,
				properties: ['id'],
				sort: filters.sortField,
				order: filters.sortDirection,
			},
		});

		const totalCount = data?.totalCount;

		return {
			currentIndex,
			currentItem: data?.comps?.[getIndexInsideOfPage()],
			isLoading: !data || fetchStatus === 'fetching',
			totalCount: data?.totalCount,
			requestPrevItem: setPrevIndex,
			requestNextItem: () => setNextIndex(totalCount),
		};
	};

	return useNavHeaderSaleData;
};
