import { reset } from 'actions/filters';
import { focusTypeahead } from 'actions/search';
import { deselectAll } from 'actions/selection';
import { PropertyByMarketIdAndBuildingAddressAndCityParams } from 'api';
import { FiltersObject, FiltersTypesKeys } from 'models/filters/types';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { filtersToServerJSON } from 'models/filters/util/serverJson';
import { setFilters } from 'models/filters/util/setFilters';
import { clearCurrentSavedSearch } from 'Pages/SavedSearches/actions';
import leasePointsService from 'services/leasePoints';
import mufaPropertyPointsService from 'services/mufaPropertyPoints';
import propertyService from 'services/property';
import propertyPointsService from 'services/propertyPoints';
import salePointsService from 'services/salePoints';
import { AppDispatch, AppGetState } from 'store';
import { LeaseComp, MufaPropertyComp, PropertyComp, SalesComp } from 'types';
import { ReduxPromiseAction } from 'types/redux-promise-middleware';
import { isMultifamily } from 'util/isMultifamily';
import { PropertyPopup } from './searchMapReducer';

export const LOAD_COMPS = 'LOAD_COMPS' as const;
export const FOCUS_PROPERTY = 'FOCUS_PROPERTY' as const;
export const REMOVE_PROPERTY = 'REMOVE_PROPERTY' as const;
export const FOCUS_FILTER_SECTION = 'FOCUS_FILTER_SECTION' as const;

export const PINS_LIMIT = 200000;

let batchRequestId = 1;

function loadMarketLeasesInternal({
	requestId,
	numberOfMarkets,
	filters,
	limit,
}: OnMarketCompsLoadArgs) {
	return {
		type: LOAD_COMPS,
		meta: {
			requestId,
			numberOfMarkets,
		},
		payload: {
			promise: leasePointsService
				.load({
					filter: filtersToServerJSON(filters),
					properties: [
						'geoPoint',
						'propertyId',
						'buildingAddressAndCity',
						'executionQuarter',
						'tenantName',
						'spaceType',
						'buildingClass',
						'opportunityZoneId',
						'submarketId',
					],
					limit,
				})
				.then((response) => {
					const compsByPropertiesAsMap = response.comps.reduce((acc, comp) => {
						const propertyId = comp.propertyId;
						!acc.get(propertyId) && acc.set(propertyId, []);
						acc.get(propertyId)!.push(comp);
						return acc;
					}, new Map<number, LeaseComp[]>());
					return {
						results: compsByPropertiesAsMap,
						totalCount: response.totalCount,
					};
				}),
		},
	};
}

function loadMarketSalesInternal({
	requestId,
	numberOfMarkets,
	filters,
	limit,
}: OnMarketCompsLoadArgs) {
	return {
		type: LOAD_COMPS,
		meta: {
			requestId,
			numberOfMarkets,
		},
		payload: {
			promise: salePointsService
				.load({
					filter: filtersToServerJSON(filters),
					properties: ['portfolio'],
					limit,
				})
				.then((response) => {
					const results = response.comps.reduce((acc, comp) => {
						comp.portfolio.forEach((p) => {
							const propertyId = p.propertyId;
							if (!acc.get(propertyId)) {
								acc.set(propertyId, []);
							}
							acc.get(propertyId)!.push(comp);
						});
						return acc;
					}, new Map<number, SalesComp[]>());

					return { results, totalCount: response.totalCount };
				}),
		},
	};
}

function loadMarketPropertiesInternal({
	requestId,
	numberOfMarkets,
	filters,
	limit,
	isMultifamily,
}: OnMarketCompsLoadArgs & { isMultifamily: boolean }) {
	const service = isMultifamily
		? mufaPropertyPointsService
		: propertyPointsService;
	return {
		type: LOAD_COMPS,
		meta: {
			requestId,
			numberOfMarkets,
		},
		payload: {
			promise: service
				.load({
					filter: filtersToServerJSON(filters),
					properties: [
						'geoPoint',
						'id',
						'buildingAddressAndCity',
						'opportunityZoneId',
						'submarketId',
					],
					limit,
				})
				.then((response) => {
					const results = response.properties.reduce((acc, property) => {
						acc.set(property.id, [property]);
						return acc;
					}, new Map<number, PropertyComp[] | MufaPropertyComp[]>());

					return { results, totalCount: response.totalCount };
				}),
		},
	};
}

export const loadLeases =
	(filters: FiltersObject) =>
	(dispatch: AppDispatch, getState: AppGetState) => {
		loadComps({
			dispatch,
			getState,
			filters,
			onMarketCompsLoad: (args: OnMarketCompsLoadArgs) =>
				loadMarketLeasesInternal(args),
		});
	};

export const loadSales =
	(filters: FiltersObject) =>
	(dispatch: AppDispatch, getState: AppGetState) => {
		loadComps({
			dispatch,
			getState,
			filters,
			onMarketCompsLoad: (args: OnMarketCompsLoadArgs) =>
				loadMarketSalesInternal(args),
		});
	};

export const loadProperties =
	(filters: FiltersObject) =>
	(dispatch: AppDispatch, getState: AppGetState) => {
		const store = getState();
		const isExchange = store.appConfig.isExchange;
		const permissions = store.permissions;

		const useMultifamily = isMultifamily({
			isExchange,
			markets: getFiltersMarkets(filters),
			permissions,
		});

		loadComps({
			dispatch,
			getState,
			filters,
			onMarketCompsLoad: (args: OnMarketCompsLoadArgs) =>
				loadMarketPropertiesInternal({
					...args,
					isMultifamily: useMultifamily,
				}),
		});
	};

type OnMarketCompsLoadArgs = {
	requestId: number;
	numberOfMarkets: number;
	filters: FiltersObject;
	limit?: number;
};

type LoadCompsArgs = {
	dispatch: AppDispatch;
	getState: AppGetState;
	filters: FiltersObject;
	onMarketCompsLoad: (args: OnMarketCompsLoadArgs) => void;
};

const loadComps = ({ dispatch, filters, onMarketCompsLoad }: LoadCompsArgs) => {
	batchRequestId++;

	const markets = getFiltersMarkets(filters);
	const numberOfMarkets = markets.length;
	// @ts-expect-error TS2345: Argument of type 'null' is not...
	const singleMarketFilters = setFilters(filters, 'markets', null);

	markets.forEach((market) => {
		dispatch(
			onMarketCompsLoad({
				requestId: batchRequestId,
				numberOfMarkets,
				// send single-market filters per market request
				filters: setFilters(singleMarketFilters, 'market', market),
				limit: Math.floor(PINS_LIMIT / numberOfMarkets),
			})
		);
	});
};

export function focusProperty(
	propertyIdOrObject:
		| number
		| PropertyByMarketIdAndBuildingAddressAndCityParams,
	propertyPopup?: PropertyPopup
) {
	return {
		type: FOCUS_PROPERTY,
		meta: {
			propertyPopup,
		},
		payload: {
			promise: propertyService.load(propertyIdOrObject),
		},
	};
}

export function removeProperty() {
	return {
		type: REMOVE_PROPERTY,
	};
}

export function focusFilterSection(filterSectionId: FiltersTypesKeys | null) {
	return {
		type: FOCUS_FILTER_SECTION,
		filterSectionId,
	};
}

type ResetMapArgs = {
	resetCurrentSavedSearch?: boolean;
	resetFilters?: boolean;
	resetSelection?: boolean;
};

export const resetSearch =
	({
		resetCurrentSavedSearch = true,
		resetFilters = false,
		resetSelection = true,
	}: ResetMapArgs) =>
	(dispatch: AppDispatch) => {
		dispatch(focusTypeahead());
		dispatch(removeProperty());
		resetFilters && dispatch(reset('main'));
		resetCurrentSavedSearch && dispatch(clearCurrentSavedSearch());
		resetSelection && dispatch(deselectAll());
	};

export type MapSearchAction =
	| ReduxPromiseAction<ReturnType<typeof loadMarketLeasesInternal>>
	| ReduxPromiseAction<ReturnType<typeof loadMarketSalesInternal>>
	| ReduxPromiseAction<ReturnType<typeof loadMarketPropertiesInternal>>
	| ReduxPromiseAction<ReturnType<typeof focusProperty>>
	| ReturnType<typeof removeProperty>
	| ReturnType<typeof focusFilterSection>;
