import {
	FeatureCollection,
	MultiPolygon,
	Polygon,
	Feature,
} from '@turf/helpers';
import { QUERY_KEYS } from '../constants';
import { createFetchFn, createQueryHook } from '../utils';

const fetchMufaSubmarketPolygons = createFetchFn<
	MufaSubmarketPolygonsParams,
	MufaSubmarketPolygonsResponse
>({
	method: 'get',
	getURL: ({ rpMarketId }) => `/mufa/rpMarkets/${rpMarketId}/submarketPolygons`,
});

export const useMufaSubmarketPolygonsQuery = createQueryHook({
	queryKey: QUERY_KEYS.MUFA_SUBMARKET_POLYGONS,
	fetchFn: fetchMufaSubmarketPolygons,
	enabled: ({ rpMarketId }) => rpMarketId != null,
});

type MufaSubmarketPolygonsParams = {
	rpMarketId?: number;
};

type MufaPolygonProperties = {
	areacount: number;
	centerlat: number;
	centerlon: number;
	regioncode: string;
	regioncolor: string;
	regiontype: string;
	updated: string;
	zoomlevel: number;
};

export type MufaSubmarketPolygonsResponse = FeatureCollection<
	Polygon | MultiPolygon,
	MufaPolygonProperties
>;

export type MufaSubmarketPolygon = Feature<
	Polygon | MultiPolygon,
	MufaPolygonProperties
>;
