import number from 'ui/util/number';

// @ts-expect-error TS7006: Parameter 'stackingPlan' impli...
export function isStackingPlanPopulated(stackingPlan) {
	const spaces = stackingPlan.spaces;
	let stackingPlanPopulated = false;

	for (let i = 0; i < spaces.length; i++) {
		if (spaces[i].placed) {
			stackingPlanPopulated = true;
			break;
		}
	}

	return stackingPlanPopulated;
}

// @ts-expect-error TS7006: Parameter 'space' implicitly h...
export function getTenant(space) {
	return space.tenant ? space.tenant : 'Unknown Tenant';
}

// @ts-expect-error TS7006: Parameter 'space' implicitly h...
export function getSize(space) {
	if (space.size && typeof space.size === 'number') {
		return number(space.size);
	}
	return 'Unknown';
}

// @ts-expect-error TS7006: Parameter 'space' implicitly h...
export function getSpaceClassName(space) {
	if (space.occupancy) {
		const occupancyType = space.occupancy.toLowerCase();
		if (occupancyType === 'vacant' || occupancyType === 'unrentable') {
			return 'space' + space.occupancy;
		}
	}

	const thisYear = new Date().getFullYear();
	const leaseYear = space.expirationDate
		? new Date(space.expirationDate).getFullYear()
		: 'Unknown';

	// @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
	let diff = leaseYear - thisYear;
	if (diff > 4) {
		diff = 4;
	}

	if (diff >= 0 && diff <= 4) {
		return 'yearsLeft' + diff;
	} else {
		return 'yearsLeftUnknown';
	}
}

// @ts-expect-error TS7006: Parameter 'label' implicitly h...
export function labelIsSpecial(label) {
	if (/\d+/.test(label)) {
		return false;
	}
	return true;
}

// @ts-expect-error TS7006: Parameter 'label' implicitly h...
export function floorSortValue(label) {
	switch (label.toLowerCase()) {
		case '1':
		case 'ground':
		case 'lobby':
			return 0;

		case 'mezzanine':
			return 1;

		case 'subbasement':
			return -50;

		case 'basement':
			return -40;

		case 'lower level':
			return -35;

		case 'lower ground':
			return -35;

		case 'concourse':
			return -30;
	}

	return parseInt(label);
}

// @ts-expect-error TS7006: Parameter 'a' implicitly has a...
export function floorSort(a, b) {
	const aVal = floorSortValue(a.label);
	const bVal = floorSortValue(b.label);

	if (aVal === bVal) {
		return 0;
	} else if (aVal > bVal) {
		return 1;
	} else {
		return -1;
	}
}

// @ts-expect-error TS7006: Parameter 'label' implicitly h...
export function getActualLabel(label) {
	if (label != null) {
		switch (label.toLowerCase()) {
			case '1':
			case 'ground':
			case 'lobby':
				return '1';
		}
		return label.toLowerCase();
	}
	return label;
}

// @ts-expect-error TS7006: Parameter 'label' implicitly h...
export function createEmptyFloor(label, size) {
	return {
		label: label,
		size,
	};
}

// @ts-expect-error TS7006: Parameter 'stackingPlan' impli...
export function createFloorsIfNeeded(stackingPlan) {
	if (stackingPlan.floors.length === 0 && stackingPlan.propertyFloors) {
		stackingPlan = { ...stackingPlan };
		stackingPlan.floors = [];

		const floorSizes = {};
		// @ts-expect-error TS7034: Variable 'specialLabels' impli...
		const specialLabels = [];
		// @ts-expect-error TS7034: Variable 'specialLabelActualTe...
		const specialLabelActualText = [];

		// @ts-expect-error TS7006: Parameter 'space' implicitly h...
		stackingPlan.spaces.forEach((space) => {
			if (space.floor) {
				const label = getActualLabel(space.floor);
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				floorSizes[label] = (floorSizes[label] || 0) + space.size;
				// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
				if (labelIsSpecial(label) && !specialLabels.includes(label)) {

					specialLabels.push(label);

					specialLabelActualText.push(space.floor);
				}
			}
		});

		// @ts-expect-error TS7006: Parameter 'acc' implicitly has...
		const biggestEntireFloor = stackingPlan.spaces.reduce((acc, space) => {
			if (space.entireFloor && space.size > acc) {
				return space.size;
			}
			return acc;
		}, 0);

		let biggestFloor = 0;
		if (biggestEntireFloor > 0) {
			// @ts-expect-error TS7006: Parameter 'acc' implicitly has...
			biggestFloor = stackingPlan.spaces.reduce((acc, space) => {
				if (space.size > acc) {
					return space.size;
				}
				return acc;
			}, biggestEntireFloor);
		}

		if (biggestFloor === 0) {
			biggestFloor = Object.keys(floorSizes).reduce((acc, key) => {
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				return Math.max(acc, floorSizes[key]);
			}, 0);
		}

		if (biggestFloor === 0 && stackingPlan.buildingSize) {
			biggestFloor = Math.round(
				stackingPlan.buildingSize / stackingPlan.propertyFloors
			);
		}

		for (let i = 0; i < stackingPlan.propertyFloors; i++) {
			stackingPlan.floors.push(
				createEmptyFloor((i + 1).toString(), biggestFloor)
			);
		}

		// @ts-expect-error TS7005: Variable 'specialLabelActualTe...
		specialLabelActualText.forEach((label) => {
			stackingPlan.floors.push(createEmptyFloor(label, biggestFloor));
		});

		stackingPlan.floors.sort(floorSort);
	}

	return stackingPlan;
}

// @ts-expect-error TS7006: Parameter 'stackingPlan' impli...
export function insertFloorIfNeeded(stackingPlan, newSpace) {
	if (newSpace.floor) {
		const actualLabel = getActualLabel(newSpace.floor);
		const floor = stackingPlan.floors.find(
			// @ts-expect-error TS7006: Parameter 'f' implicitly has a...
			(f) => getActualLabel(f.label) === actualLabel
		);

		if (floor) {
			return stackingPlan;
		} else {
			const oneFloorUpIndex = stackingPlan.floors.findIndex(
				// @ts-expect-error TS7006: Parameter 'f' implicitly has a...
				(f) => floorSortValue(f.label) > floorSortValue(newSpace.floor)
			);

			if (oneFloorUpIndex === -1) {
				let size;
				if (stackingPlan.floors.length === 0) {
					size = newSpace.size;
				} else {
					size = stackingPlan.floors[stackingPlan.floors.length - 1].size;
				}
				const newFloor = createEmptyFloor(newSpace.floor, size);
				stackingPlan = {
					...stackingPlan,
					floors: [...stackingPlan.floors, newFloor],
				};
			} else {
				const oneFloorUp = stackingPlan.floors[oneFloorUpIndex];
				const newFloor = createEmptyFloor(newSpace.floor, oneFloorUp.size);

				stackingPlan = {
					...stackingPlan,
					floors: [
						...stackingPlan.floors.slice(0, oneFloorUpIndex),
						newFloor,
						...stackingPlan.floors.slice(oneFloorUpIndex),
					],
				};
			}
		}
	}
	return stackingPlan;
}

// @ts-expect-error TS7006: Parameter 'floorIndex' implici...
export function getFloorsSizeChangeShouldAffect(floorIndex, size, floors) {
	const oldFloor = floors[floorIndex];
	let lowest, highest;

	lowest = highest = floorIndex;

	if (oldFloor.size > size) {
		// shrinking the floor, resize all floors
		// above until there is one that is smaller
		while (floors[highest + 1] && floors[highest + 1].size > size) {
			highest += 1;
		}
	} else {
		// expanding the floor, resize all floors
		// underneath until there is one larger
		while (floors[lowest - 1] && floors[lowest - 1].size < size) {
			lowest -= 1;
		}
	}

	return [lowest, highest];
}

// @ts-expect-error TS7006: Parameter 'space' implicitly h...
export function getExpirationQuarter(space) {
	if (space.expirationDate) {
		const expirationDate = new Date(space.expirationDate);
		const quarter = Math.floor((expirationDate.getMonth() + 3) / 3);
		return 'Q' + quarter + ' - ' + expirationDate.getFullYear();
	} else {
		return 'Unknown';
	}
}
