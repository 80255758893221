import {
	FilterableReferenceType,
	OtherAndUnkownReferenceType,
	ReferenceData,
} from 'api/referenceData/useReferenceDataQuery';
import {
	BOOTSTRAP,
	BootstrapAction,
	LOGOUT,
	LogoutAction,
} from 'Pages/Login/actions';
import { getPromiseActionType } from 'types/redux-promise-middleware';

export const mergeUnknownAndOtherOptions = <
	T extends { id: number; name: string },
>(
	options?: T[]
): FilterableReferenceType<T>[] => {
	const otherOption: OtherAndUnkownReferenceType = {
		name: 'Other',
		ids: [],
		names: [],
	};
	const mergedOptions = (options ?? []).reduce<FilterableReferenceType<T>[]>(
		(acc, option) => {
			if (option.name === 'Other' || option.name === 'Unknown') {
				otherOption.ids.push(option.id);

				otherOption.names.push(option.name);
			} else {
				acc.push(option);
			}
			return acc;
		},
		[]
	);
	mergedOptions.push(otherOption);
	return mergedOptions;
};

type ProcessedAction = BootstrapAction | LogoutAction;

export function referenceData(
	state: ReferenceData | null = null,
	action: ProcessedAction
): ReferenceData | null {
	switch (action.type) {
		case getPromiseActionType(BOOTSTRAP, 'FULFILLED'): {
			const refData = action.payload.referenceData;

			refData.filterableSpaceTypes = mergeUnknownAndOtherOptions(
				refData.spaceTypes
			);
			refData.filterableSpaceSubtypes = mergeUnknownAndOtherOptions(
				refData.spaceSubtypes
			);
			refData.filterablePropertyTypes = mergeUnknownAndOtherOptions(
				refData.propertyTypes
			);
			refData.filterablePropertySubtypes = mergeUnknownAndOtherOptions(
				refData.propertySubtypes
			);

			refData.insideviewStatus =
				action.payload.referenceData.insideviewStatus.map(({ name, id }) => ({
					id,
					name: name.replace('OutOfBusiness', 'Out of Business'),
				}));
			state = refData;
			break;
		}

		case LOGOUT:
		case getPromiseActionType(BOOTSTRAP, 'REJECTED'):
			state = null;
			break;
	}
	return state;
}
