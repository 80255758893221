import { useSplitCompset } from 'api/utils';
import { useMemo } from 'react';

type Props = {
	propertyId: number;
};

export const useCompsetIds = ({ propertyId }: Props) => {
	const { data: splitCompset, isFetching } = useSplitCompset({ propertyId });

	return {
		data: useMemo(() => {
			if (!splitCompset) return;

			return [
				splitCompset.subjectProperty.id,
				...splitCompset.compset.map((p) => p.property.id),
			];
		}, [splitCompset]),
		isFetching,
	};
};
