import { API, Market, MarketModel } from '@compstak/common';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { useAppSelector } from 'util/useAppSelector';

export const useMarketsQuery = (options?: UseQueryOptions<Market[]>) => {
	const markets = useAppSelector((store) => store.markets);

	const queryResult = useQuery({
		queryKey: [QUERY_KEYS.MARKETS],
		queryFn: async () => {
			const { data } = await API.get<MarketResponse[]>('/api/markets');
			return data.map((market) => new MarketModel(market as Market) as Market);
		},
		initialData: markets.list,
		...options,
	});

	return {
		...queryResult,
		data: queryResult.data!,
	};
};

export type MarketResponse = {
	id: number;
	name: string;
	areaLat: number;
	areaLon: number;
	areaZoom: number;
	displayName: string;
	monthly: boolean;
	status: string;
	countryCode: string;
	enterpriseBeta: boolean;
	exchangeBeta: boolean;
	leaseComps: boolean;
	salesComps: boolean;
	states: {
		code: string;
		id: number;
		name: string;
	}[];
	aliases: {
		name: string;
	}[];
};
