import { useMarketsHaveFeature } from '../marketsFeatures';
import { usePropertyByIdQuery } from '../propertyById';
import { useMufaPropertyLoanQuery } from './useMufaPropertyLoanQuery';
import { usePropertyLoanQuery } from './usePropertyLoanQuery';

type Props = {
	propertyId: number;
	isEnabled?: boolean;
};

/** Unifies the commercial/mufa dichotomy for property loan data */
export const usePropertyLoan = ({ propertyId, isEnabled = true }: Props) => {
	const { data: property } = usePropertyByIdQuery({ id: propertyId });

	const { data: hasMufaAccess } = useMarketsHaveFeature({
		feature: 'multifamilyAccess',
		marketIds: property ? [property.marketId] : [],
		isEnabled: isEnabled && !!property,
	});

	const propertyLoanResult = usePropertyLoanQuery(
		{ propertyId },
		{ enabled: isEnabled && hasMufaAccess != null && !hasMufaAccess }
	);
	const mufaPropertyLoanResult = useMufaPropertyLoanQuery(
		{ propertyId },
		{ enabled: isEnabled && hasMufaAccess != null && hasMufaAccess }
	);

	return hasMufaAccess ? mufaPropertyLoanResult : propertyLoanResult;
};
