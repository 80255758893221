export type ReduxPromiseAction<
	T extends { type: string; payload: { promise: Promise<unknown> } }
> =
	| T
	| (Omit<T, 'type' | 'payload'> & {
			type: GetPromiseActionType<T['type']>;
			payload: Awaited<T['payload']['promise']>;
	  });

export const getPromiseActionType = <T extends string>(
	type: T,
	subtype: 'PENDING' | 'FULFILLED' | 'REJECTED'
) => {
	return `${type}_${subtype}` as const;
};

export type GetPromiseActionType<T extends string> =
	| `${T}_PENDING`
	| `${T}_FULFILLED`
	| `${T}_REJECTED`;
