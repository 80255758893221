import {
	AttributeToPlotCommercialValues,
	AttributeToPlotMufaValues,
	SalesAttributeToPlotValues,
} from 'Components/Graphs/Selectors';
import { DataSetType } from '../analytics';
import {
	ATTR_TO_PLOT_DEFAULT_COMMERCIAL,
	ATTR_TO_PLOT_DEFAULT_MUFA,
	ATTR_TO_PLOT_DEFAULT_SALES,
} from './Components/Modals/DataSets/dataSetConstants';
import { DataSetGroupTitles } from '../analytics';
import { AttributeType as AttributeTypeFormatting } from 'util/comp-format/attributes/index';

export const DEFAULT_NEW_CHART_TITLE = 'New Chart';

export const DATASET_TYPES_URL_SEARCH_PARAM_KEY = 'dataset-types';

// TODO: See why this is the max value? Is it because of colors?!
export const MAX_DATASETS_ALLOWED = 10;

export const DEFAULT_CHART = {
	title: DEFAULT_NEW_CHART_TITLE,
	dataSets: [],
	trendMonths: 3,
	chartType: 'line' as const,
	timespan: 60,
};

export const dataSetTypeToSeriesMap = {
	[DataSetType.COMMERCIAL]: ATTR_TO_PLOT_DEFAULT_COMMERCIAL,
	[DataSetType.MUFA]: ATTR_TO_PLOT_DEFAULT_MUFA,
	[DataSetType.SALES]: ATTR_TO_PLOT_DEFAULT_SALES,
};

export const dataSetGroupHeadingMap = {
	[DataSetType.COMMERCIAL]: DataSetGroupTitles.LEASES, // Until BE's switch to "commercialLeases"
	[DataSetType.LEASES]: DataSetGroupTitles.LEASES,
	[DataSetType.SALES]: DataSetGroupTitles.SALES,
	[DataSetType.MUFA]: DataSetGroupTitles.MUFA,
};

export const attributeToDataSetType = {
	[AttributeToPlotCommercialValues.STARTING_RENT]: DataSetType.COMMERCIAL,
	[AttributeToPlotCommercialValues.EFFECTIVE_RENT]: DataSetType.COMMERCIAL,
	[AttributeToPlotCommercialValues.FREE_RENT]: DataSetType.COMMERCIAL,
	[AttributeToPlotCommercialValues.TENANT_IMPROVEMENT]: DataSetType.COMMERCIAL,
	[AttributeToPlotCommercialValues.LEASE_TERM]: DataSetType.COMMERCIAL,

	[SalesAttributeToPlotValues.SALES_PRICE]: DataSetType.SALES,
	[SalesAttributeToPlotValues.SALES_VOLUME]: DataSetType.SALES,
	[SalesAttributeToPlotValues.CAP_RATE]: DataSetType.SALES,

	[AttributeToPlotMufaValues.ASKING_RPSF]: DataSetType.MUFA,
	[AttributeToPlotMufaValues.EFFECTIVE_RPSF]: DataSetType.MUFA,
	[AttributeToPlotMufaValues.CONCESSION]: DataSetType.MUFA,
	[AttributeToPlotMufaValues.OCCUPANCY]: DataSetType.MUFA,
};

export const dataSetTypeToFormattingAttr: Record<
	DataSetType,
	AttributeTypeFormatting
> = {
	[DataSetType.COMMERCIAL]: 'lease',
	[DataSetType.LEASES]: 'lease',
	[DataSetType.SALES]: 'sale',
	[DataSetType.MUFA]: 'property',
};
